// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/ValueExtDeleteService
 */

import app from 'app';
import _commonUtil from 'js/UnileverCommonUtil';
import _appCtxSvc from 'js/appCtxService';

    'use strict';

    var exports = {};
	
	exports.deleteValueExt = function () {

        /*var selectedVaueExts = getSelectedValueExt();
        if(selectedVaueExts){

        }*/


    };

   /*  var getSelectedValueExt = function () {


        var selectedObjs = _appCtxSvc.ctx.mselected
        var selectedVaueExts = [];




        for (var i in selectedObjs) {

            if (!(selectedObjs[i].modelType.typeHierarchyArray.indexOf('U4_ValueExt') !== -1)) {

                continue;
            }


            selectedVaueExts.push({
                uid: selectedObjs[i].uid,
                type: selectedObjs[i].type
            });
        }

        if (selectedVaueExts.length === 0) {

            _messagingSvc.showError('Please select value extractor to delete.');

            return;
        }


        return selectedVaueExts;
    }
 */
 
/*
export default exports = {
	moduleServiceNameToInject
};
*/
export let moduleServiceNameToInject = 'ValueExtDeleteService';


app.factory('ValueExtDeleteService', () => exports );
