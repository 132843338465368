import app from 'app';
import ngModule from 'angular';
import eventBus from 'js/eventBus';
import ngUtils from 'js/ngUtils';
import browserUtils from 'js/browserUtils';
import declUtils from 'js/declUtils';
import 'js/declarativeDataCtxService';
import _soaSvc from 'soa/kernel/soaService';
import _messagingSvc from 'js/messagingService';
import _appCtxSvc from 'js/appCtxService';
import _commonUtil from 'js/UnileverCommonUtil';
import awPromiseService from 'js/awPromiseService';
import _cdm from 'soa/kernel/clientDataModel';


    'use strict';   
    var _$http,exports = {}, 
        _endPoint=null,
        isRequestCalled=false;
    
export let updateUIDetails = function (ctx,selectedPam,data) {

    var selectedPam = ctx.selected;
    if(ctx.selected.modelType.parentTypeName === "U4_ComponentRevision"){
        var pamUID = ctx.selected.props.U4_PAMSpecification.dbValues[0];
            selectedPam = _cdm.getObject(pamUID);
            data.pamId.dbValue = selectedPam.props.item_id.dbValues[0];
            data.Requester.dbValue = ctx.userSession.props.user_id.dbValues[0];
            data.pamId.dispValue = selectedPam.props.item_id.dbValues[0];
            data.Requester.dispValue = ctx.userSession.props.user_id.dbValues[0];

    }
    else if(ctx.selected.modelType.parentTypeName === "U4_PAMRevision"){
        data.pamId.dbValue = selectedPam.props.item_id.dbValues[0];
        data.Requester.dbValue = ctx.userSession.props.user_id.dbValues[0];
        data.pamId.dispValue = selectedPam.props.item_id.dbValues[0];
        data.Requester.dispValue = ctx.userSession.props.user_id.dbValues[0];
    }
        
        var inputData = {
            objects: [{
                uid: ctx.selected.uid,
                type: ctx.selected.type
            }],
            attributes: ['U4_MatlClassRelation']
        };

         _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (response) {

                console.log(response);
             var objMap = [];
            var types = [];
            for (var element in response.modelObjects) {
                if (response.modelObjects[element].modelType.name == "U4_MaterialClassificationForm") {
                    var matCommodity = response.modelObjects[element].props.u4_material_commodity.dbValues["0"];
                    var commCommidity = response.modelObjects[element].props.u4_component_commodity.dbValues["0"];
                    var finalValue = commCommidity +' - '+ matCommodity;
                    data.MatCommCode.dbValue = finalValue;
                    data.MatCommCode.dispValue = finalValue;
            }
        } 
        }); 
		
};
export let activatePanel = function (ctx,data) {

    var selectedPam = ctx.selected;
	if (ctx.selected.modelType.parentTypeName === "U4_ComponentRevision"){
            var pamUID = ctx.selected.props.U4_PAMSpecification.dbValues[0];
            selectedPam = _cdm.getObject(pamUID);
			data.snet_Enabled = selectedPam.props.u4_sent_to_snet.uiValues[0];
            console.log("Data Sent in PACK: ",data.snet_Enabled);
		
	}
	else if (ctx.selected.modelType.parentTypeName === "U4_PAMRevision"){
				
			data.snet_Enabled = selectedPam.props.u4_sent_to_snet.uiValues[0];
			console.log("Data Sent in PAM: ",data.snet_Enabled);
			//data.snet_Enabled = _appCtxSvc.ctx.selected.props.u4_sent_to_snet.uiValue;
		}
};

export let setSNETProperties = function (ctx) {
	var selectedPam = ctx.selected;
    if(ctx.selected.modelType.parentTypeName === "U4_ComponentRevision"){
        var pamUID = ctx.selected.props.U4_PAMSpecification.dbValues[0];
        selectedPam = _cdm.getObject(pamUID);
        console.log("Selected Pam: ",selectedPam);
        var puid =  selectedPam.uid; 
		var ObjStrngValue = selectedPam.props.object_string.dbValues[0];
		_commonUtil.initiateUnileverWorkflow(ObjStrngValue, puid, 'SNET', function () {}, 'Workflow to set snet Properties');

    }
    else if(ctx.selected.modelType.parentTypeName === "U4_PAMRevision"){
        var puid =  selectedPam.uid; 
        var ObjStrngValue = selectedPam.props.object_string.dbValues[0];
        _commonUtil.initiateUnileverWorkflow(ObjStrngValue, puid, 'SNET', function () {}, 'Workflow to set snet Properties');
    }		
};
export let moduleServiceNameToInject = 'UnileverSendToSNETView';
export default exports = {
	updateUIDetails,
	setSNETProperties,
	activatePanel,
	moduleServiceNameToInject
};
app.factory('UnileverSendToSNETView', () => exports );