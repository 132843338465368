// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */
import app from 'app';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import _commUtil from 'js/UnileverCommonUtil';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import _mctSublocSvc from 'js/Mct_PnP_SublocationService';
import _policySvc from 'soa/kernel/propertyPolicyService';
import _queryUtil from 'js/UnileverQueryUtil';
import _mctUtil from 'js/Mct_PnP_Util';
import eventBus from 'js/eventBus';

/**
 * Mct list service
 *
 * @module js/Mct_PnP_Util
 */

'use strict';

var exports = {};


var MctDataStructure = null;

export let addToMct = function (MctRevisionObj, data, callback) {
    var selectedPnP = exports.getSelectedPnP('pnp', data);
    if (selectedPnP) {
        exports.getAllTypeOfPnP(selectedPnP, data, function () {
            var filteredPnP = [];
            var mctPnPType = "Metric";
            if (_appCtxSvc.ctx._mctPnPType === "showMetricAndImperial") {
                if (data.radioMctPnPType.dbValue === true) {
                    mctPnPType = "Metric";
                } else if (data.radioMctPnPType.dbValue === false) {
                    mctPnPType = "Imperial";
                }
            }
            else if (_appCtxSvc.ctx._mctPnPType === "showMetric") {
                mctPnPType = "Metric";
            } else if (_appCtxSvc.ctx._mctPnPType === "showImperial") {
                mctPnPType = "Imperial";
            }
            for (var i in data.ddeUOMPnPObjects) {
                var typePnPObject = data.ddeUOMPnPObjects[i];
                if (typePnPObject.pnpType === mctPnPType) {
                    filteredPnP.push({
                        uid: typePnPObject.pnpObj.uid,
                        type: typePnPObject.pnpObj.type
                    });
                }
            }
            if (filteredPnP.length > 0) {
                var getPropertiesInputData = {
                    objects: [MctRevisionObj],
                    attributes: ['U4_MCT_PnP_Relation', 'U4_MCT_PnP_Temp_Relation', 'item_id']
                };

                _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', getPropertiesInputData).then(function (getPropertiesResponse) {

                    var pnpsToAdd = [];
                    var pnpsAlreadyPresent = 0;
                    var uids = getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]].props.U4_MCT_PnP_Relation.dbValues;
                    for (var h in filteredPnP) {
                        var isAlreadyPresent = false;
                        for (var t in uids) {
                            var pnpUid = getPropertiesResponse.modelObjects[uids[t]].uid;
                            if (filteredPnP[h].uid === pnpUid) {
                                isAlreadyPresent = true;
                            }
                        }
                        if (isAlreadyPresent) {
                            ++pnpsAlreadyPresent;
                        } else {
                            pnpsToAdd.push(filteredPnP[h]);
                        }
                    }
                    if (pnpsToAdd.length > 0) {
                        _commUtil.addChildrenUnderParent(pnpsToAdd, {
                            uid: MctRevisionObj.uid,
                            type: MctRevisionObj.type
                        }, 'U4_MCT_PnP_Relation', function () {

                            if (pnpsAlreadyPresent > 0) {
                                _messagingSvc.showError(pnpsAlreadyPresent + ' PnP(s) in selection already present under MCT. ' + pnpsToAdd.length + ' PnP(s) added to MCT Successfully.');
                            } else {
                                _messagingSvc.showError(pnpsToAdd.length + ' PnP(s) added to MCT Successfully.');
                            }
                            callback();
                        });
                    } else {
                        _messagingSvc.showError('All PnP(s) in selection already present under MCT.');
                    }

                });
            }
        });
    }
}

export let createAndAddToMct = function (MctName, data, callback) {
    var selectedPnp = exports.getSelectedPnP('pnp', data);
    if (selectedPnp) {
        exports.getAllTypeOfPnP(selectedPnp, data, function () {
            var filteredPnP = [];
            var mctPnPType = "Metric";
            if (_appCtxSvc.ctx._mctPnPType === "showMetricAndImperial") {
                if (data.radioMctPnPType.dbValue === true) {
                    mctPnPType = "Metric";
                } else if (data.radioMctPnPType.dbValue === false) {
                    mctPnPType = "Imperial";
                }
            }
            else if (_appCtxSvc.ctx._mctPnPType === "showMetric") {
                mctPnPType = "Metric";
            } else if (_appCtxSvc.ctx._mctPnPType === "showImperial") {
                mctPnPType = "Imperial";
            }
            for (var i in data.ddeUOMPnPObjects) {
                var typePnPObject = data.ddeUOMPnPObjects[i];
                if (typePnPObject.pnpType === mctPnPType) {
                    filteredPnP.push({
                        uid: typePnPObject.pnpObj.uid,
                        type: typePnPObject.pnpObj.type
                    });
                }
            }
            if (filteredPnP.length > 0) {
                createMct(MctName, mctPnPType, function (newMctRev) {
                    _commUtil.addChildrenUnderParent(filteredPnP, newMctRev, 'U4_MCT_PnP_Relation', function () {
                        getOrCreatePnpTemplate(newMctRev, mctPnPType, function (newPnPrev) {
                            _commUtil.getCurrentUserNewstuffFolder(function (newstuffFolder) {
                                var addChildrenInputData = {
                                    inputData: [{
                                        parentObj: newstuffFolder,
                                        childrenObj: [newMctRev],
                                        propertyName: ''
                                    }, {
                                        parentObj: newMctRev,
                                        childrenObj: [{
                                            uid: newPnPrev.uid,
                                            type: newPnPrev.type
                                        }],
                                        propertyName: 'U4_MCT_PnP_Temp_Relation'
                                    }]
                                };
                                _soaSvc.post('Core-2014-10-DataManagement', 'addChildren', addChildrenInputData).then(function (addChildrenResponse) {
                                    var pnpStr = (filteredPnP.length === 1) ? 'PnP' : 'PnP\'s';
                                    pnpStr = 'MCT <strong>' + MctName + '</strong> created with selected ' + pnpStr + ' and added to NewStuff folder.';
                                    _messagingSvc.showInfo(pnpStr, true);
                                    callback(newMctRev);
                                });
                            });
                        });

                    });

                });
            }
        });
    }
}


var getOrCreatePnpTemplate = function (newMctRev, mctPnPType, callback) {
    var templateName;
    var masterTemplate_ID;
    if (mctPnPType === "Metric") {
        templateName = "MCT PnP Metric";
        masterTemplate_ID = "540000000000";
    } else if (mctPnPType === "Imperial") {
        templateName = "MCT PnP Imperial";
        masterTemplate_ID = "540000000002";
    }

    var entries = ['Type', 'Item ID', 'Name', 'Owning User'];
    var values = ['P&P Spec', 'T*', templateName, _appCtxSvc.ctx.userSession.props.user.displayValues[0]];

    var policy = {
        'types': [{
            'name': 'U4_PnPSpec',
            'properties': [{
                'name': 'revision_list'
            }, {
                'name': 'item_id'
            }]
        }]
    };
    _queryUtil.executeQuery('Item...', entries, values, policy, true, function (queryOutput) {

        var thisUserPNPmctTemplateRevUid = null;
        if (queryOutput && queryOutput.length > 0) {
            for (var u in queryOutput) {
                if (queryOutput[u].props.item_id && queryOutput[u].props.item_id.dbValues[0][0] === 'T') {
                    thisUserPNPmctTemplateRevUid = queryOutput[u].props.revision_list.dbValues[0];
                    break;
                }
            }
        }


        if (thisUserPNPmctTemplateRevUid) {
            callback({
                uid: thisUserPNPmctTemplateRevUid,
                type: 'U4_PnPSpecRevision'
            });
        } else {
            var entries = ['Type', 'Item ID'];
            var values = ['P&P Spec', masterTemplate_ID]

            _queryUtil.executeQuery('Item...', entries, values, policy, true, function (queryOutput) {


                var pnpMasterTemplateLatestRevUid = null;
                if (queryOutput && queryOutput.length > 0) {
                    for (var u in queryOutput) {
                        if (queryOutput[u].props.item_id && queryOutput[u].props.item_id.dbValues[0] === '540000000000') {
                            pnpMasterTemplateLatestRevUid = queryOutput[u].props.revision_list.dbValues[queryOutput[u].props.revision_list.dbValues.length - 1];
                            break;
                        }
                        else if (queryOutput[u].props.item_id && queryOutput[u].props.item_id.dbValues[0] === '540000000002') {
                            pnpMasterTemplateLatestRevUid = queryOutput[u].props.revision_list.dbValues[queryOutput[u].props.revision_list.dbValues.length - 1];
                            break;
                        }
                    }
                }
                if (pnpMasterTemplateLatestRevUid) {

                    var getNextIdsInputData = {
                        vInfoForNextId: [{
                            typeName: 'U4_PnPSpec',
                            propName: "item_id",
                            pattern: '"T"NNNNNNNNNNNN'
                        }]
                    };

                    _soaSvc.post('Core-2008-06-DataManagement', 'getNextIds', getNextIdsInputData).then(function (getNextIdsResponse) {

                        var saveAsNewItemInputData = {
                            info: [{
                                itemRevision: {
                                    uid: pnpMasterTemplateLatestRevUid,
                                    type: 'U4_PnPSpecRevision'
                                },
                                itemId: getNextIdsResponse.nextIds[0],
                                name: templateName,
                                description: "Item for PnP MCT",
                            }]
                        };

                        _soaSvc.post('Core-2007-01-DataManagement', 'saveAsNewItem', saveAsNewItemInputData).then(function (saveAsNewItemResp) {
                            var newPnPrev = null;
                            for (var t in saveAsNewItemResp.ServiceData.modelObjects) {
                                if (saveAsNewItemResp.ServiceData.modelObjects[t].type === 'U4_PnPSpecRevision') {
                                    newPnPrev = saveAsNewItemResp.ServiceData.modelObjects[t];
                                    break;
                                }
                            }
                            callback(newPnPrev);
                        });
                    });

                }
            });
        }

    });

}

export let getSelectedPnP = function (type, data) {

    var isPnpOperation = (type === 'pnp');
    var isPnpTemplateOperation = (type === 'pnpTemplate');

    var selectedObjs = _appCtxSvc.getCtx('mselected');

    if (0 === selectedObjs.length) {
        _messagingSvc.showError('Please select to proceed.');
        return;
    }
    

    var selectedPnP = [];

    var mctPnPTemplateSelectionCount = 0;
    var pnpTemplateSelectionCount = 0;
    var pnpSelectionCount = 0;
    var releasedPnpCount = 0;

    for (var i in selectedObjs) {

        var isValidPnp = true;

        if (!(selectedObjs[i].modelType.typeHierarchyArray.indexOf('U4_PnPSpecRevision') !== -1)) {
            isValidPnp = false; // not a pnp
        } else {

            var isMCTPnpTemplate = (selectedObjs[i].props.object_string.dbValues[0].substring(0, 1) === 'T');

            if ((!isMCTPnpTemplate) && ((!selectedObjs[i].props.object_string) || (!selectedObjs[i].props.object_string.dbValues[0]) || (selectedObjs[i].props.object_string.dbValues[0].indexOf('540000000000') !== -1) || (selectedObjs[i].props.object_string.dbValues[0].indexOf('540000000002') !== -1))) {
                isValidPnp = false; // not a pnp template
                pnpTemplateSelectionCount++;
            }

            if (isPnpOperation && isMCTPnpTemplate) {
                isValidPnp = false;
                mctPnPTemplateSelectionCount++;
            }

            if (isPnpTemplateOperation && !isMCTPnpTemplate) {
                isValidPnp = false;
                pnpSelectionCount++;
            }

            if ((selectedObjs[i].props.release_status_list.dbValues.length > 0) && (selectedObjs[i].props.release_status_list.dbValues[0])) {
                isValidPnp = false;
                releasedPnpCount++;
            }

            if ((selectedObjs[i].props.process_stage_list.dbValues.length > 0) && (selectedObjs[i].props.process_stage_list.dbValues[0])) {
                isValidPnp = false;
            }

        }



        if (isValidPnp) {
            selectedPnP.push({
                uid: selectedObjs[i].uid,
                type: selectedObjs[i].type
            });
        }

    }



    if (pnpTemplateSelectionCount === selectedObjs.length) {
        _messagingSvc.showError('Only PnP Template(s) found in the selection. Please change the selection.');
        return;
    }

    if ((!isPnpTemplateOperation) && (mctPnPTemplateSelectionCount === selectedObjs.length)) {
        _messagingSvc.showError('Only PnP Template(s) found in the selection. Please change the selection.');
        return;
    }

    if ((!isPnpOperation) && (pnpSelectionCount === selectedObjs.length)) {
        _messagingSvc.showError('Only PnP(s) found in the selection. Please change the selection.');
        return;
    }

    if (releasedPnpCount === selectedObjs.length) {
        _messagingSvc.showError('Only released PnP(s) found in the selection. Please change the selection.');
        return;
    }

    if (selectedPnP.length === 0) {
        _messagingSvc.showError('No valid PnP\'s are found in selection. Please change the selection.');
        return;
    }

    return selectedPnP;
}

export let getAllTypeOfPnP = function (selectedObjs, data, callback) {

    var pNpRevUidList = [];

    for (let pNpRevUidCounter in selectedObjs) {
        pNpRevUidList.push({
            uid: selectedObjs[pNpRevUidCounter].uid
        });
    }
    var expandGrmInputData = {
        secondaryObjects: pNpRevUidList,
        pref: {
            expItemRev: false,
            info: [{
                relationName: "U4_PnPSpecificationRelation"
            }]
        }
    };

    var policy = {
        "types": [{
            "name": "ItemRevision",
            "properties": [
                {
                    "name": "u4_units_of_measure"
                }]
        }]
    };
    var policyDDE = _policySvc.register(policy);
    _soaSvc.post('Core-2007-06-DataManagement', 'expandGRMRelationsForSecondary', expandGrmInputData).then(function (expandGrmResponse) {
        if (policyDDE)
            _policySvc.unregister(policyDDE);

        var ddeUOMPnPObjects = [];
        var isMetric = false;
        var isImperial = false;
        for (let i in expandGrmResponse.output) {
            var pnpType = expandGrmResponse.output[i].otherSideObjData[0].otherSideObjects[0].props.u4_units_of_measure.dbValues[0];
            ddeUOMPnPObjects.push({
                pnpObj: expandGrmResponse.output[i].inputObject,
                pnpType: pnpType
            })
            if (pnpType === "Metric") {
                isMetric = true;
            }
            if (pnpType === "Imperial") {
                isImperial = true;
            }
        }
        if (isMetric === true && isImperial === true) {
            data.mctPnPType = "showMetricAndImperial";
            _appCtxSvc.updateCtx("_mctPnPType", "showMetricAndImperial");
        } else if (isMetric === true) {
            data.mctPnPType = "showMetric";
            _appCtxSvc.updateCtx("_mctPnPType", "showMetric");
        } else if (isImperial === true) {
            data.mctPnPType = "showImperial";
            _appCtxSvc.updateCtx("_mctPnPType", "showImperial");
        }
        data.ddeUOMPnPObjects = ddeUOMPnPObjects;
        callback();
    });
}


/* var removeAllChildrenUnderParent = function (parent, relation, callback) {

    var inputData = {
        objects: [parent],
        attributes: [relation]
    };

    _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (response) {

        var objs = [];

        var uids = response.modelObjects[response.plain[0]].props.U4_MCT_PnP_Relation.dbValues;
        for (var t in uids) {
            objs.push({
                uid: response.modelObjects[uids[t]].uid,
                type: response.modelObjects[uids[t]].type
            });
        }

        if (objs.length === 0) {
            callback();
        } else {
            var removeChildrenInputData = {
                inputData: [{
                    parentObj: parent,
                    childrenObj: objs,
                    propertyName: relation
                }]
            };
            _soaSvc.post('Core-2014-10-DataManagement', 'removeChildren', removeChildrenInputData).then(function (removeChildrenResponse) {
                callback();
                //   console.log(addChildrenResponse);
            });
        }

    });

} */



var createMct = function (MctName, mctPnPType, callback) {


    var createItemsInputData = {
        properties: [{
            name: MctName,
            type: 'U4_MCT_PnP',
            description: 'Item for MCT operation.'
        }]
    };

    _soaSvc.post('Core-2006-03-DataManagement', 'createItems', createItemsInputData).then(function (createItemsResponse) {
        var setPropertiesInputData = {
            info: [{
                object: createItemsResponse.output[0].itemRev,
                vecNameVal: [{
                    name: 'u4_mctPnPType',
                    values: [mctPnPType]
                }]
            }],
            options: []
        };

        _soaSvc.post('Core-2010-09-DataManagement', 'setProperties', setPropertiesInputData).then(function (setPropertiesResponse) {
            callback({
                uid: createItemsResponse.output[0].itemRev.uid,
                type: createItemsResponse.output[0].itemRev.type
            });
        });

    });

}


/* var createTemplate = function (MctRev, callback) {

    var inputData = {
        mopObject: {
            uid: MctRev.uid
        }
    };
    //template creation goes here
    _soaSvc.post('AWC-2016-10-MultipleOperations', 'createPAMObjectsFromTemplate', inputData).then(function (response) {


        if (response.pmlTemplates && response.pmlTemplates.length > 0) {
            var newPamTemplates = [];
            for (var t in response.pmlTemplates) {
                newPamTemplates.push(response.pmlTemplates[t].uid);
            }
            MctOperation('refreshNewTemplate', newPamTemplates, null, MctRev, function () {
                callback();
            });

        } else {
            callback();
        }

    });

} */


var getSelectedMct = function () {


    return {
        uid: sessionStorage._Mct_PnP_Rev_UID
    };
}


var MctOperation = function (operation, pnpTemplatesToRefresh, pnpTemplateToUpdate, _MctRev, callback) {

    var isSinglePnpTemplateUpdate = (operation === 'singlePnpTemplateUpdate');
    var isRefreshTemplateOperation = (operation === 'refreshTemplate');
    var isRefreshNewTemplateOperation = (operation === 'refreshNewTemplate');

    /* 
    MctDataStructure schema :
    
    {
    hasReleasePnp: true/false,
    MctRevision: {},
    pnpRelations:[]
    U4_MCT_PnP_Relation: [{
        pnpObject: {},
        relation: [{
            pnpFormObject : {}
        }]

    }],
    U4_MCT_PnP_Temp_Relation: [{
        pnpTemplateObject: {},
        relation: [{
            templateFormObject : {}
        }]
    }],

}

     */

    MctDataStructure = {};

    var MctRev = null;

    if (isRefreshNewTemplateOperation) {
        MctRev = _MctRev;
    } else {
        MctRev = getSelectedMct();
    }

    if (MctRev) {

        _appCtxSvc.ctx._mctpnpRevision = MctRev;

        exports.getRelationsUnderPnP(function (pnpRelations) {
            MctDataStructure.pnpRelations = pnpRelations;
            var isTemplateOperation = (isRefreshTemplateOperation || isRefreshNewTemplateOperation);
            getPnpsFromMct(MctRev, isTemplateOperation, isSinglePnpTemplateUpdate, pnpTemplateToUpdate, function () {
                if (MctDataStructure.U4_MCT_PnP_Temp_Relation.length === 0) {
                    _messagingSvc.showError('No PnP Templates found under MCT');
                } else if (isSinglePnpTemplateUpdate && (MctDataStructure.U4_MCT_PnP_Relation.length === 0) && (MctDataStructure.hasReleasePnp)) {
                    _messagingSvc.showError('Update can not be done since only released PnP\'(s) available for the selected PnP template under MCT.');
                } else if (isSinglePnpTemplateUpdate && (MctDataStructure.U4_MCT_PnP_Relation.length === 0) && (MctDataStructure.hasNotPrivilegedPnp)) {
                    _messagingSvc.showError('Update can not be done since only non-privileged PnP\'(s) available for the selected PnP template under MCT.');
                } else if (isSinglePnpTemplateUpdate && (MctDataStructure.U4_MCT_PnP_Relation.length === 0)) {
                    _messagingSvc.showError('No PnP\'s found for the selected PnP template under MCT.');
                } else {
                    loadFormsOfPAM(function () {

                        if (isSinglePnpTemplateUpdate) {
                            updatePnPFromTemplate_AND_updateTemplateFromMasterTemplate(false, null, function () {
                                callback();
                            });
                        }

                        if (isTemplateOperation) {
                            updatePnPFromTemplate_AND_updateTemplateFromMasterTemplate(true, pnpTemplatesToRefresh, function () {
                                callback();
                            });
                        }
                    });
                }
            });
        });

    }

}

var updatePnPFromTemplate_AND_updateTemplateFromMasterTemplate = function (isTemplateOperation, pnpTemplatesToRefresh, callback) {



    var refreshInfo = null;
    var masterTemplateId = '540000000000'; // Metric UOM ( 540000000000 ) , Imperial UOM ( 540000000002 )

    if (MctDataStructure.U4_MCT_PnP_Temp_Relation.length > 0) {
        if (MctDataStructure.U4_MCT_PnP_Temp_Relation[0].pnpTemplateObject.props.object_name.dbValues[0] === 'MCT PnP Imperial') {
            masterTemplateId = '540000000002'; // Imperial UOM
        }
    }

    var relatedModelObjs = {};
    for (var y in MctDataStructure.U4_MCT_PnP_Temp_Relation[0]) {
        if (y !== 'pnpTemplateObject') {
            for (var z in MctDataStructure.U4_MCT_PnP_Temp_Relation[0][y]) {
                var obj = MctDataStructure.U4_MCT_PnP_Temp_Relation[0][y][z].templateFormObject;
                if (obj) {
                    relatedModelObjs[obj.uid] = obj;
                }
            }
        }
    }

    refreshInfo = {
        masterTemplateId: masterTemplateId,
        pnpTemplate: MctDataStructure.U4_MCT_PnP_Temp_Relation[0].pnpTemplateObject,
        relatedModelObjs: relatedModelObjs
    };

    var revisionToRevisionSetInfo = [];
    loadMasterTemplatesAndForms([masterTemplateId], function (masterTemplatesInfo, loadObjectsResponse) {

        var info = {};

        info.toRevision = {
            modelObj: refreshInfo.pnpTemplate,
            relatedObjs: refreshInfo.relatedModelObjs
        };
        var obj1 = _.set({}, 'itemModelObj.props.item_id.dbValues[0]', refreshInfo.masterTemplateId);
        var obj2 = _.find(masterTemplatesInfo, obj1);
        if (obj2 && obj2.latestRevModelObj) {
            info.fromRevision = {
                modelObj: obj2.latestRevModelObj,
                relatedObjs: loadObjectsResponse.modelObjects
            };
        }
        revisionToRevisionSetInfo.push(info);

        setRevisionToRevision(revisionToRevisionSetInfo, isTemplateOperation, function () {
            callback();
        });

    });



}

var loadMasterTemplatesAndForms = function (itemIds, callback) {



    _mctUtil.getRelationsUnderPnP(function (pnpRelations) {


        var array = [];
        for (var t in itemIds) {
            array.push({
                itemAttributes: {
                    item_id: itemIds[t]
                }
            });
        }


        var getItemFromIdInputData = {
            infos: array,
            nRev: 1,
            pref: {
                prefs: [{
                    relationTypeName: '',
                    objectTypeNames: []
                }]
            }
        };

        var properties = [];
        for (var i in pnpRelations) {
            properties.push({
                'name': pnpRelations[i],
                'modifiers': [{
                    'name': 'withProperties',
                    'Value': 'true'
                }]
            });

        }

        let policy = {
            "types": []
        };

        policy.types.push({
            "name": "U4_PnPSpec",
            "properties": [{
                "name": "item_id"
            }]
        });

        policy.types.push({
            "name": 'U4_PnPSpecRevision',
            "properties": properties
        });

        policy.types.push({
            "name": 'U4_CommonForm',
            "properties": [{
                name: 'object_name'
            }, {
                name: 'object_desc'
            }]
        });


        var policyID = _policySvc.register(policy);


        _soaSvc.post('Core-2009-10-DataManagement', 'getItemFromAttribute', getItemFromIdInputData).then(function (getItemFromIdResponse) {
            _policySvc.unregister(policyID);



            var u4CommonFormPropsToload = policy.types[policy.types.length - 1].properties;

            var containsCheckList = [];
            for (var i in getItemFromIdResponse.ServiceData.modelObjects) {
                var modelObj = getItemFromIdResponse.ServiceData.modelObjects[i];
                if (modelObj.modelType.typeHierarchyArray.indexOf('U4_CommonForm') !== -1) {
                    var u4Props = _mctUtil.getU4Props(modelObj);
                    for (var propName in modelObj.modelType.propertyDescriptorsMap) {
                        if (_.includes(u4Props, propName)) {
                            if (containsCheckList.indexOf(propName) === -1) {
                                containsCheckList.push(propName);
                                u4CommonFormPropsToload.push({
                                    name: propName
                                });
                            }
                        }
                    }
                }
            }

            var itemUids = [];

            for (var b in getItemFromIdResponse.output) {
                itemUids.push(getItemFromIdResponse.output[b].itemRevOutput[0].itemRevision.uid);
            }

            var loadObjectsInputData = {
                uids: itemUids
            };
            var policyID1 = _policySvc.register(policy);

            _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', loadObjectsInputData).then(function (loadObjectsResponse1) {

                _policySvc.unregister(policyID1);

                var masterTemplatesInfo = [];

                for (var t in getItemFromIdResponse.output) {
                    var itemModelObj = getItemFromIdResponse.ServiceData.modelObjects[getItemFromIdResponse.output[t].item.uid];
                    var latestRevModelObj = loadObjectsResponse1.modelObjects[getItemFromIdResponse.output[t].itemRevOutput[0].itemRevision.uid];
                    masterTemplatesInfo.push({
                        itemModelObj: itemModelObj,
                        latestRevModelObj: latestRevModelObj
                    });
                }


                callback(masterTemplatesInfo, loadObjectsResponse1);

            });



        });
    });

}

var setRevisionToRevision = function (revisionToRevisionSetInfo, isTemplateOperation, callback) {

    exports.getRelationsUnderPnP(function (pnpRelations) {

        var info = [];

        for (var b in revisionToRevisionSetInfo) {

            var fromRevisionModelObj = revisionToRevisionSetInfo[b].fromRevision.modelObj;
            var fromRevisionRelatedObjs = revisionToRevisionSetInfo[b].fromRevision.relatedObjs;

            var toRevisionModelObj = revisionToRevisionSetInfo[b].toRevision.modelObj;
            var toRevisionRelatedObjs = revisionToRevisionSetInfo[b].toRevision.relatedObjs;

            for (var c in pnpRelations) {
                if (fromRevisionModelObj.props[pnpRelations[c]]) {
                    var from_relatedObjUids = fromRevisionModelObj.props[pnpRelations[c]].dbValues;

                    for (var d in from_relatedObjUids) {

                        var fromForm = fromRevisionRelatedObjs[from_relatedObjUids[d]];
                        if (toRevisionModelObj.props[pnpRelations[c]]) {
                            var to_relatedObjUids = toRevisionModelObj.props[pnpRelations[c]].dbValues;
                            for (var e in to_relatedObjUids) {

                                var toFrom = toRevisionRelatedObjs[to_relatedObjUids[e]];

                                if ((fromForm.props.object_name.dbValues[0] === toFrom.props.object_name.dbValues[0]) && (fromForm.type === toFrom.type)) {

                                    var vecNameVal = [];
                                    var u4Props = exports.getU4Props(toFrom);
                                    for (var propName in toFrom.props) {
                                        if (_.includes(u4Props, propName)) {
                                            if (fromForm.props[propName].dbValues[0] !== toFrom.props[propName].dbValues[0]) {
                                                var propValue = fromForm.props[propName].dbValues[0];
                                                propValue = (propValue === null) ? '' : propValue;
                                                vecNameVal.push({
                                                    name: propName,
                                                    values: [propValue]
                                                });
                                            }
                                        }
                                    }

                                    if (vecNameVal.length > 0) {
                                        if (!isTemplateOperation) {
                                            var pnpTempobj = revisionToRevisionSetInfo[b].toRevision.modelObj;
                                            var info1 = getUpdateInfoFromPnPTemplateToPnP(pnpTempobj, pnpRelations[c], toFrom, vecNameVal);
                                            info = info.concat(info1);

                                            var infoValue1 = [];
                                            infoValue1 = ({
                                                type: toFrom.type,
                                                uid: toFrom.uid,
                                            });

                                            var v_obj = {};
                                            // v_obj.bObject = toFrom;
                                            // v_obj.nameVals = vecNameVal;
                                            v_obj.bObject = infoValue1;
                                            v_obj.nameVals = vecNameVal

                                            info.push(v_obj);
                                        }
                                        else {
                                            var obj = {};
                                            obj.object = toFrom;
                                            obj.vecNameVal = vecNameVal;
                                            info.push(obj);
                                        }
                                    }
                                    break;
                                }

                            }
                        }

                    }
                }
            }

        }

        if (info.length > 0 && isTemplateOperation) {
            var setPropertiesInputData = {
                info: info,
                options: []
            };

            _soaSvc.post('Core-2010-09-DataManagement', 'setProperties', setPropertiesInputData).then(function (setPropertiesResponse) {
                _appCtxSvc.ctx.__isUpdateHappened = true;
                callback();
            });
        }
        else if (info.length > 0 && !isTemplateOperation) {
            var setPropertiesInputData = {
                info: info,
                tcItemRevision: _appCtxSvc.ctx._mctpnpRevision
            };

            let pnpNames = getPnPUpdateMsg();
            _messagingSvc.showInfo("MCT_PNP update initiated from PNP Template(s) to PNP\'s " + pnpNames + ". You will get a notification after it complete.", true);
            _soaSvc.post('AWC-2019-06-MctService', 'setPropertiesToObjects', setPropertiesInputData).then(function (setPropertiesResponse) {
                _appCtxSvc.ctx.__isUpdateHappened = true;
            });

        } else {
            _appCtxSvc.ctx.__isUpdateHappened = false;
            callback();
        }

    });

}



var getUpdateInfoFromPnPTemplateToPnP = function (pnpTempobj, pnpRelation, formObj, propertiesChanged) {

    var info = [];
    var objName = formObj.props.object_name.dbValues[0];
    for (var t in MctDataStructure.U4_MCT_PnP_Relation) {
        var relatedObjs = MctDataStructure.U4_MCT_PnP_Relation[t][pnpRelation];
        for (var u in relatedObjs) {
            var objName1 = relatedObjs[u].pnpFormObject.props.object_name.dbValues[0];
            if (objName === objName1) {
                var vecNameVal = [];
                for (var v in propertiesChanged) {
                    var propName = propertiesChanged[v].name;
                    if (formObj.props[propName].dbValues[0] !== relatedObjs[u].pnpFormObject.props[propName].dbValues[0]) {
                        var propValue = formObj.props[propName].dbValues[0];
                        propValue = (propValue === null) ? '' : propValue;
                        vecNameVal.push({
                            name: propName,
                            values: [propValue]
                        });
                    }
                }
                if (vecNameVal.length > 0) {
                    //var obj = {};
                    //obj.object = relatedObjs[u].pnpFormObject;
                    //obj.vecNameVal = vecNameVal;
                    //info.push(obj);

                    var objVar = {};                     
					var infoValue3 = [];                                                 
					//objVar.bObject = relatedObjs[u].pamFormObject;                         
					infoValue3 = ({                             
						type: relatedObjs[u].pnpFormObject.type,                             
						uid: relatedObjs[u].pnpFormObject.uid,                         
					});                                                 
					
					objVar.bObject = infoValue3;                         
					objVar.nameVals = vecNameVal;                                                 
					info.push(objVar);
                }
                break;
            }
        }
    }
    return info;
}



export let getRelationsUnderPnP = function (callback) {

    callback(["U4_PalletRefRelation", "U4_CaseBagEtcRefRelation", "U4_CURefRelation", "U4_CUContRefRelation", "U4_ListOfPAMRelation", "U4_PnPToPAMDURelation", "U4_PnPToPAMCURelation", "U4_PnPToPAMCaseUnitRelation", "U4_DeltaRelation", "U4_GeneralInfoRelation", "U4_TransportUnitRelation", "U4_TertiaryPkgEnvRelation", "U4_SubConsumerUnitRelation", "U4_SecondaryPkgEnvRelation", "U4_ProcessDescRelation", "U4_PrimaryPkgEnvRelation", "U4_PalletRelation", "U4_PalletDefectRelation", "U4_LocalInformationRelation", "U4_LayerRelation", "U4_IntermediateUnitRelation", "U4_DisplayUnitRelation", "U4_DefectRelation", "U4_ConsumerUnitRelation", "U4_ConsumerUnitContRelation", "U4_ConfigurationRelation", "U4_CodingLabellingRelation", "U4_CaseBagRelation", "U4_CaseBagEtcRelation", "U4_CURelation", "U4_CUCRelation"]);
    /* if (_appCtxSvc.ctx.__PnpRevisionRelations) {
        callback(_appCtxSvc.ctx.__PnpRevisionRelations);
    } else {
        var options = {
            PropertyExclusions: ["RendererReferences", "NamingRules"],
            TypeExclusions: ["ToolInfo", "DirectChildTypesInfo", "RevisionNamingRules"]
        };
        var relations = [];
        var inputData1 = {
            'typeNames': ['U4_PnPSpecRevision'],
            options: options
        };
        _soaSvc.post('Core-2015-10-Session', 'getTypeDescriptions2', inputData1).then(function (response1) {
            // console.log(response1);
            //    console.log(JSON.stringify(response1));
            var props = [];
            var propDescArray = response1.types[0].propertyDescriptors;
            for (var t in propDescArray) {
                if (_.startsWith(propDescArray[t].name, 'U4_')) //custom relations always starts with U4_
                    props.push(propDescArray[t].name);
            }
            var inputData2 = {
                'typeNames': props,
                options: options
            };
            _soaSvc.post('Core-2015-10-Session', 'getTypeDescriptions2', inputData2).then(function (response2) {

                for (var t in response2.types) {
                    if (_.includes(response2.types[t].typeHierarchy, 'U4_CommonRelation'))
                        relations.push(response2.types[t].name);
                }

                _appCtxSvc.ctx.__PnpRevisionRelations = relations;
              //  console.log(relations);
               callback(_appCtxSvc.ctx.__PnpRevisionRelations);
            });

        });
    } */
}

var getPnpsFromMct = function (MctRev, isTemplateOperation, isSinglePnpTemplateUpdate, pnpTemplateToUpdate, callback) {

    var inputData = {
        objects: [MctRev],
        attributes: ['U4_MCT_PnP_Temp_Relation']
    };

    if (!isTemplateOperation) {
        inputData.attributes.push('U4_MCT_PnP_Relation');
    }

    _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (getPropertiesResponse) {

        var pnpUids = (!isTemplateOperation) ? getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]].props.U4_MCT_PnP_Relation.dbValues : [];
        var pnpTemplateUid = getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]].props.U4_MCT_PnP_Temp_Relation.dbValues[0];

        MctDataStructure.MctRevision = getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]];
        MctDataStructure.U4_MCT_PnP_Relation = [];
        MctDataStructure.U4_MCT_PnP_Temp_Relation = [];


        //set pnp template into datastructure
        var templateObj = {
            pnpTemplateObject: getPropertiesResponse.modelObjects[pnpTemplateUid]
        };
        MctDataStructure.U4_MCT_PnP_Temp_Relation.push(templateObj);


        //set pnp's into datastructure
        for (var i in pnpUids) {

            var pnpObj = {
                pnpObject: getPropertiesResponse.modelObjects[pnpUids[i]]
            };


            var is_released_pnp = ((pnpObj.pnpObject.props.release_status_list.dbValues.length > 0) && (pnpObj.pnpObject.props.release_status_list.dbValues[0]));
            var is_pnp_in_process_stage_list = ((pnpObj.pnpObject.props.process_stage_list.dbValues.length > 0) && (pnpObj.pnpObject.props.process_stage_list.dbValues[0]));
            //add only non released pnps
            if (!is_released_pnp && !is_pnp_in_process_stage_list) {
                if (pnpObj.pnpObject.props.is_modifiable.dbValues[0] === '1') {
                    MctDataStructure.U4_MCT_PnP_Relation.push(pnpObj);
                } else {
                    MctDataStructure.hasNotPrivilegedPnp = true; // pnps which has no access for this current user is present in this mct
                }

            } else {
                MctDataStructure.hasReleasePnp = true;
            }



        }


        callback();

        // console.log(getPropertiesResponse);
        //console.log(MctDataStructure);
    });

}

var loadFormsOfPAM = function (callback) {

    var pams = [];

    for (var i in MctDataStructure.U4_MCT_PnP_Relation) {
        pams.push({
            uid: MctDataStructure.U4_MCT_PnP_Relation[i].pnpObject.uid,
            type: MctDataStructure.U4_MCT_PnP_Relation[i].pnpObject.type
        });
    }

    for (var i in MctDataStructure.U4_MCT_PnP_Temp_Relation) {
        pams.push({
            uid: MctDataStructure.U4_MCT_PnP_Temp_Relation[i].pnpTemplateObject.uid,
            type: MctDataStructure.U4_MCT_PnP_Temp_Relation[i].pnpTemplateObject.type
        });
    }

    var propsToGet = MctDataStructure.pnpRelations;
    var inputData = {
        objects: pams,
        attributes: propsToGet
    };

    _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (getPropertiesResponse) {

        //   console.log(getPropertiesResponse);

        for (var i in MctDataStructure.U4_MCT_PnP_Relation) {
            MctDataStructure.U4_MCT_PnP_Relation[i].pnpObject = getPropertiesResponse.modelObjects[MctDataStructure.U4_MCT_PnP_Relation[i].pnpObject.uid];
        }

        for (var i in MctDataStructure.U4_MCT_PnP_Temp_Relation) {
            MctDataStructure.U4_MCT_PnP_Temp_Relation[i].pnpTemplateObject = getPropertiesResponse.modelObjects[MctDataStructure.U4_MCT_PnP_Temp_Relation[i].pnpTemplateObject.uid];
        }



        var forms = [];
        for (var t in getPropertiesResponse.modelObjects) {
            if (getPropertiesResponse.modelObjects[t].modelType.typeHierarchyArray.indexOf('Form') !== -1) {
                forms.push({
                    obj: getPropertiesResponse.modelObjects[t],
                    propsToGet: exports.getU4Props(getPropertiesResponse.modelObjects[t])
                });
            }
        }

        loadCustomPropertiesOfForm(forms, function (propsLoaded) {
            //   console.log(MctDataStructure);

            setFormsToDataStructure('U4_MCT_PnP_Relation', 'pnpObject', 'pnpFormObject', propsLoaded);
            setFormsToDataStructure('U4_MCT_PnP_Temp_Relation', 'pnpTemplateObject', 'templateFormObject', propsLoaded);
            callback();
            //  console.log(MctDataStructure);
        });

        // console.log(forms);
    });

}

var setFormsToDataStructure = function (pnpRelation, pnpVariableName, formVariableName, propsLoaded) {
    for (var i in MctDataStructure[pnpRelation]) {
        for (var n in MctDataStructure.pnpRelations) {
            var relation = MctDataStructure.pnpRelations[n];
            if (MctDataStructure[pnpRelation][i][pnpVariableName].props[relation]) {
                var formUids = MctDataStructure[pnpRelation][i][pnpVariableName].props[relation].dbValues;
                if (formUids && formUids.length > 0) {
                    MctDataStructure[pnpRelation][i][relation] = [];
                    for (var y in formUids) {
                        var form1 = _.zipObjectDeep([formVariableName], [propsLoaded[formUids[y]]]);
                        MctDataStructure[pnpRelation][i][relation].push(form1);
                    }
                }
            }
        }
    }
}

var loadCustomPropertiesOfForm = function (forms, callback) {

    var properties = ['object_name'];
    var formArray = [];
    for (var h in forms) {
        formArray.push(forms[h].obj);
        for (var g in forms[h].propsToGet) {
            var property = forms[h].propsToGet[g];
            if (!(properties.indexOf(property) !== -1)) {
                properties.push(property);
            }

        }
    }

    var inputData = {
        objects: formArray,
        attributes: properties
    };

    _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (getPropertiesResponse) {
        //  console.log(getPropertiesResponse);
        callback(getPropertiesResponse.modelObjects);
    });

}

export let getU4Props = function (formObj) {

    var u4props = [];
    for (var prop in formObj.modelType.propertyDescriptorsMap) {
        if (_.startsWith(prop, 'u4_') || _.startsWith(prop, 'U4_')) {
            var basedOn = formObj.modelType.propertyDescriptorsMap[prop].basedOn;
            if (basedOn && (basedOn.sourceType === (formObj.type + 'S'))) { // dont take runtime properties
                u4props.push(prop);
            }
        }
    }
    return u4props;
}

export let Mct_PnP_refresh = function (data) {



    var selectedPnpTemplates = exports.getSelectedPnP('pnpTemplate', data);

    var MctRev = getSelectedMct();

    if (MctRev && selectedPnpTemplates) {

        if (selectedPnpTemplates.length === 0) {
            _messagingSvc.showError('Please select PnP Template to clear.');
        } else {

            MctOperation('refreshTemplate', [selectedPnpTemplates[0].uid], null, MctRev, function () {

                if (_appCtxSvc.ctx.__isUpdateHappened)
                    _messagingSvc.showInfo('PnP template has been cleared.');
                else
                    _messagingSvc.showInfo('No changes were found to clear.');
            });
        }

    }

}

export let Mct_PnP_remove = function (data) {

    var selectedPnp = exports.getSelectedPnP('pnp',data);

    var MctRev = getSelectedMct();

    if (MctRev && selectedPnp) {

        if (selectedPnp.length === 0) {
            _messagingSvc.showError('Please select PnP(s) to proceed.');
        } else {
            var removeChildrenInputData = {
                inputData: [{
                    parentObj: MctRev,
                    childrenObj: selectedPnp,
                    propertyName: 'U4_MCT_PnP_Relation'
                }]
            };
            _soaSvc.post('Core-2014-10-DataManagement', 'removeChildren', removeChildrenInputData).then(function (removeChildrenResponse) {

                //  console.log(removeChildrenResponse);
                _commUtil.refreshObjectsInUI([MctRev]);
                _mctSublocSvc.updateCountAfterPnPremoval(removeChildrenResponse, MctRev);
                var pnpStr = (selectedPnp.length === 1) ? 'PnP' : 'PnP\'s';
                _messagingSvc.showInfo('Selected ' + pnpStr + ' successfully removed from MCT.');
            });
        }
    }

}

export let Mct_PnP_SingleUpdate = function (data) {

    var selectedPnPTemplates = exports.getSelectedPnP('pnpTemplate', data);
    if (selectedPnPTemplates) {
        if (selectedPnPTemplates.length > 1) {
            _messagingSvc.showError('Please select only one PnP Template to proceed.');
        } else if (selectedPnPTemplates.length === 0) {
            _messagingSvc.showError('Please select a PnP Template to proceed.');
        } else {
            var MctRev = getSelectedMct();

            var selectedPnPTemplate = selectedPnPTemplates[0];

            if (MctRev && selectedPnPTemplate) {

                MctOperation('singlePnpTemplateUpdate', null, selectedPnPTemplate, MctRev, function () {

                    let pnpNames = getPnPUpdateMsg();

                    if (_appCtxSvc.ctx.__isUpdateHappened)
                        _messagingSvc.showInfo('Properties successfully updated from PnP Template to PnP\'s ' + pnpNames + '.', true);
                    else
                        _messagingSvc.showInfo('No changes were found to update.');
                });
            }
        }
    }
}


let getPnPUpdateMsg = function () {

    let pnpNames = '';
    let first = true;

    for (let i in MctDataStructure.U4_MCT_PnP_Relation) {

        let objName = MctDataStructure.U4_MCT_PnP_Relation[i].pnpObject.props.object_name.dbValues[0];
        pnpNames = pnpNames + ((first) ? '' : ', ') + objName;
        first = false;
    }
    if (pnpNames.length > 0) {
        pnpNames = "<strong>" + pnpNames + "</strong>";
    }
    return pnpNames;

}

/*
clears breadcrumb title for mct sublocation
*/
export let clearBreadCrumbText = function () {
    //send location name
    _commUtil.clearBreadCrumbText('MctPnPLocation');
}

export let sortByIDAscendMct_PnP_CommandAction = function () {

    eventBus.publish("sortByIDAscendMct_PnP_CommandAction");
}

export let sortByIDDescendMct_PnP_CommandAction = function () {

    eventBus.publish("sortByIDDescendMct_PnP_CommandAction");
}
export let sortByNameAscendMct_PnP_CommandAction = function () {

    eventBus.publish("sortByNameAscendMct_PnP_CommandAction");
}
export let sortByNameDescendMct_PnP_CommandAction = function () {

    eventBus.publish("sortByNameDescendMct_PnP_CommandAction");
}




export let moduleServiceNameToInject = 'Mct_PnP_Util';

export default exports = {
    getAllTypeOfPnP,
    addToMct,
    createAndAddToMct,
    getSelectedPnP,
    getRelationsUnderPnP,
    getU4Props,
    Mct_PnP_refresh,
    Mct_PnP_remove,
    Mct_PnP_SingleUpdate,
    clearBreadCrumbText,
    moduleServiceNameToInject,
    sortByIDDescendMct_PnP_CommandAction,
    sortByIDAscendMct_PnP_CommandAction,
    sortByNameAscendMct_PnP_CommandAction,
    sortByNameDescendMct_PnP_CommandAction

};
app.factory('Mct_PnP_Util', () => exports);