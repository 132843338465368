import awPromiseService from 'js/awPromiseService';
import app from 'app';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import eventBus from 'js/eventBus';
import _lovUtil from 'js/UnileverLovUtil';
import _commonUtil from 'js/UnileverCommonUtil';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import $ from 'jquery';
import _propertyPolicySvc from "soa/kernel/propertyPolicyService";
import _getPackFromPam from "js/ProductionUpdatePCRService";
import _cdm from 'soa/kernel/clientDataModel';
'use strict';

var exports = {};
var userPartOfDesigner = false;

export let enableDisableProdCreatebutton = function (data) {
    data.disableProdCreate = false;
    return data.disableProdCreate;
}

export let getGroupsDetails = function (response) {
    console.log("entered in getGroupsDetails");
    var devVal = _appCtxSvc.ctx.userSession.props.user_id.dbValue;
    _appCtxSvc.registerCtx("PackagingDeveloper", devVal);
    for (var i = 0; i < response.groupMembers.length; i++) {
        if (response.groupMembers[i].props.role.uiValues[0] == "Packaging_Designer") {
            userPartOfDesigner = true;
            var desVal = _appCtxSvc.ctx.userSession.props.user_id.dbValue;
            _appCtxSvc.registerCtx("PackagingDesigner", desVal);
            break;
        }
    }
}

export let CheckReleaseApprover = function (ctx) {
    var isApproverCtm = true;
    _appCtxSvc.registerCtx("checkReleaseApprover.isApproverCtm", isApproverCtm);
    var PrefValues = []; var prefName;
    PrefValues.push(ctx.preferences.U4_CTM_Approval_Reqd_By_Commodity);
    for (let prefValueCount = 0; prefValueCount < PrefValues[0].length; prefValueCount++) {
        var [componentClass, componentCommodity,] = PrefValues[0][prefValueCount].split("::");
        if (ctx.selected.props.u4_component_class.dbValue === componentClass) {
            if (ctx.selected.props.u4_component_commodity.dbValue === componentCommodity) {
                _appCtxSvc.updateCtx("checkReleaseApprover.isApproverCtm", true);
                prefName = "AWS_Suggested_Reviewers_List_PUCTM";

                break;
            }
        } else {
            _appCtxSvc.updateCtx("checkReleaseApprover.isApproverCtm", false);
            prefName = "AWS_Suggested_Reviewers_List_PUPAD";
        }

    }
    var inputData = {
        "preferenceNames": [
            prefName
        ],
        "includePreferenceDescriptions": false
    };

    _soaSvc.post('Administration-2012-09-PreferenceManagement', 'getPreferences', inputData).then(function (response) {
        console.log("Preference is called");
        if (response && response.response[0].values.values && response.response[0].values.values) {
            var prefValue = response.response[0].values.values[0];
            var userName = prefValue.split(";");
            // _appCtxSvc.registerCtx("prefValueCTMorPAD", userName[0]);
            if (_appCtxSvc.ctx.checkReleaseApprover.isApproverCtm) {
                _appCtxSvc.registerCtx("releaseApproverCTM", userName[0]);
            } else if (!_appCtxSvc.ctx.checkReleaseApprover.isApproverCtm) {
                _appCtxSvc.registerCtx("releaseApproverPAD", userName[0]);
            }


        } /* else {
            _appCtxSvc.registerCtx("releaseApproverPAD", "");
            _appCtxSvc.registerCtx("releaseApproverCTM", "");
        } */
    });

    var devVal = _appCtxSvc.ctx.userSession.props.user_id.dbValue;
    _appCtxSvc.registerCtx("PackagingDeveloper", devVal);

    var projectList = _appCtxSvc.ctx.mselected[0].props.project_list.uiValue;
    _appCtxSvc.registerCtx("projectName", projectList);

};

export let selTypeAction = function (data) {

    if (data.typeOfChange && data.typeOfChange.dbValue == 'Developer Only Update') {
        data.packagingdesigner.dbValue = '';
        const newDes = _.clone(data.packagingdesigner);
        newDes.displayValues = '';
        newDes.uiValue = '';
        newDes.uiValue = '';
        newDes.dbValues = '';
        newDes.uiValues[0] = '';
        newDes.dispValue = '';
        // data.packagingdesigner.propertyDescriptor.constantsMap.required = '0';
        newDes.isRequired = false;
        newDes.propertyRequiredText = '';

        newDes.isEnabled = false;
        //data.packagingdesigner.isEditable = false;
        data.dispatch({ path: 'data.packagingdesigner', value: newDes });
    }
    if (data.typeOfChange && data.typeOfChange.dbValue == 'Designer & Developer Update' && userPartOfDesigner == true) {
        data.packagingdesigner.dbValue = _appCtxSvc.ctx.userSession.props.user_id.displayValues[0];
        const newDesVal = _.clone(data.packagingdesigner);
        newDesVal.displayValues = _appCtxSvc.ctx.userSession.props.user_id.displayValues[0];
        newDesVal.uiValue = _appCtxSvc.ctx.userSession.props.user_id.displayValues[0];
        newDesVal.propertyRequiredText = 'Required';
        newDesVal.isRequired = true;
        newDesVal.isEnabled = true;
        data.dispatch({ path: 'data.packagingdesigner', value: newDesVal });
    }
    if (data.typeOfChange && data.typeOfChange.dbValue == 'Designer & Developer Update' && userPartOfDesigner == false) {
        data.packagingdesigner.dbValue = '';
        const newDesEmptyVal = _.clone(data.packagingdesigner);
        newDesEmptyVal.displayValues = [""];
        newDesEmptyVal.dbValue = "";
        newDesEmptyVal.uiValue = "";
        newDesEmptyVal.propertyRequiredText = 'Required';
        newDesEmptyVal.isRequired = true;
        newDesEmptyVal.isEnabled = true;
        data.dispatch({ path: 'data.packagingdesigner', value: newDesEmptyVal });
    }
    // return data.packagingdesigner;
}

export let preValProdUpdateChkMethod = function (data) {
    try {
        var deferred = awPromiseService.instance.defer();
        var selectedPack,
            selectedPam;
        var selectedObject,
            packRevObjUID;
        data.preValidationProductionUpdateCheck = false;
        if (_appCtxSvc.ctx.selected.modelType.parentTypeName === "U4_PAMRevision") {
            selectedPam = _appCtxSvc.ctx.selected;
            _getPackFromPam.getPackFromPam(deferred);
            deferred.promise.then(function (packRevObjUID) {
                selectedPack = _cdm.getObject(packRevObjUID);
                selectedObject = selectedPack;

                if (selectedObject.props.u4_related_change.dbValues[0] !== '') {

                    var revisions = [];
                    revisions.push({
                        //uid: _appCtxSvc.ctx.selected.uid
                        uid: selectedObject.uid
                    });

                    var inputData = {
                        itemRevisions: revisions
                    };
                    _soaSvc.post('AWC-2016-10-EnvReportChanges', 'getLatestItemRevisions', inputData).then(function (response) {

                        console.log(response);
                        if (response.latestItemRevs[0].uid === selectedObject.uid) {
                            console.log("Pam Spec is already in production update...");
                        }
                        deferred.resolve();
                    });
                    return deferred.promise;
                }
            });
        } else {
            if (_appCtxSvc.ctx.selected.props.u4_related_change && _appCtxSvc.ctx.selected.props.u4_related_change.dbValues[0] !== '') {
                var revisions = [];
                revisions.push({
                    uid: _appCtxSvc.ctx.selected.uid
                });

                var inputData = {
                    itemRevisions: revisions
                };
                _soaSvc.post('AWC-2016-10-EnvReportChanges', 'getLatestItemRevisions', inputData).then(function (response) {
                    console.log(response);
                    if (response.latestItemRevs[0].uid === _appCtxSvc.ctx.selected.uid) {
                        data.preValidationProductionUpdateCheck = true;
                    }
                    deferred.resolve();
                });
                return deferred.promise;
            }
        }
    } catch (err) {
        _messagingSvc.showError("Something went wrong in production update action.");
    }
}

export let onReveal = function (data, ctx) {

    data.typeOfChange.propertyRequiredText = 'Required';
    var deferred = awPromiseService.instance.defer();
    var mainPromise = awPromiseService.instance.defer();
    var selectedPack,
        selectedPam;
    var selectedObject,
        packRevObjUID;
    data.typeOfChangeList = [];
    if (ctx.selected.modelType.parentTypeName === "U4_PAMRevision") {
        _getPackFromPam.getPackFromPam(mainPromise);
        mainPromise.promise.then(function (packRevObjUID) {
            selectedPack = _cdm.getObject(packRevObjUID);
            data.packId = selectedPack;
        });
    }

    _lovUtil.getLOVvalue('U4_ProdNoticeRevision', 'u4_type_of_change', 'lov_values', '', null, data.typeOfChangeList, function (serviceData) {

        console.log('Type of change loaded');
    });

    if (ctx.selected.props.u4_design_name && ctx.selected.props.u4_design_name.dbValue) {
        data.designname.dbValue = ctx.selected.props.u4_design_name.dbValue;
        console.log("Design Name from Pack---->:" + data.designname.dbValue);
    }

    return deferred.promise;
};


/**
 * get the preffered user and set it to CTM or PAD field
 *
 * @param {Object}  data ViewModel object
 * @return
 *
 */
export let setDefaultApprovers = function (data) {

    var lovValues = [];
    for (var i = 0; i < data.projectValues.length; i++) {
        var projLOVValue = data.projectValues[i].dispValue;
        if (projLOVValue != "" && projLOVValue != undefined && projLOVValue != null) {
            lovValues.push(projLOVValue);
        }
    }
    let projList = _appCtxSvc.ctx.mselected[0].props.project_list.uiValue;
    const valFnd = lovValues.filter(element => projList.indexOf(element) !== -1);
    if (valFnd.length > 0) {
        data.project.uiValue = valFnd[0];
        const newProjVal = _.clone(data.project);
        newProjVal.dbValue = valFnd[0];
        newProjVal.uiValue = valFnd[0];
        data.dispatch({ path: 'data.project', value: newProjVal });
        // _appCtxSvc.updateCtx("projectName", valFnd[0]);
    } else {
        // _appCtxSvc.updateCtx("projectName", "");
        data.project.uiValue = "";
        const newProjVal = _.clone(data.project);
        newProjVal.dbValue = "";
        newProjVal.uiValue = "";
        data.dispatch({ path: 'data.project', value: newProjVal });
    }


};

/**
 *
 * Does production update operation with selected objects using OOTB soa call
 *
 * @param {Array} selectedObjs array of objects which is selected to do pilot update
 * @param {Object} data ViewModel object
 * @param {Object} conditions condition Object
 *
 *
 */
export let doProductionUpdateOperation = function (ctx, data, conditions) {
    var checkUser = true;
    if (data.releaseapproverpad.dbValue === _appCtxSvc.ctx.userSession.props.user_id.dbValue) {
        _messagingSvc.showError('Logged-in user and PAD user are same. Please select another user');
        checkUser = false;
        console.log("checkUser", checkUser);
        setEmptyValue(data, data.releaseapproverpad, 'data.releaseapproverpad');
    }
    if (data.releaseapproverctm.dbValue === _appCtxSvc.ctx.userSession.props.user_id.dbValue) {
        _messagingSvc.showError('Logged-in user and CTM user are same. Please select another user');
        checkUser = false;
        console.log("checkUser", checkUser);
        setEmptyValue(data, data.releaseapproverctm, 'data.releaseapproverctm');
    }

    try {
        var selectedObjs = [];
        if (ctx && ctx.mselected) {
            for (var i = 0; i < ctx.mselected.length; i++) {
                selectedObjs.push(ctx.mselected[i]);
            }
        }
        if (checkUser == true) {
            //removeAdditionalProps(data);
            data.disableProdCreate = true;
            var inputData = {
                input: [{
                    clientId: 'AWClient',
                    data: {
                        boName: 'U4_ProdNotice',
                        stringProps: {
                            // object_name: data.modelPropertyForProdRev.props.u4_reason_for_change.dbValue,
                            //object_desc: data.modelPropertyForProd.props.object_desc.dbValue
                            object_name: data.reasonforchange.dbValue,
                            object_desc: data.descriptionofchange.dbValue
                        },
                        compoundCreateInput: {
                            revision: [{
                                boName: 'U4_ProdNoticeRevision',
                                stringProps: {
                                    u4_reason_for_change: data.reasonforchange.dbValue,
                                    u4_type_of_change: data.typeOfChange.dbValue,
                                    u4_project_association: data.project.dbValue,
                                    u4_component_updater: data.packagingdesigner.dbValue,
                                    u4_pam_updater: data.packagingdeveloper.dbValue,
                                    u4_release_approver: data.releaseapproverpad.dbValue === "" ? null : data.releaseapproverpad.dbValue,
                                    u4_release_approver1: data.releaseapproverctm.dbValue === "" ? null : data.releaseapproverctm.dbValue
                                }
                            }
                            ]
                        }
                    }
                }
                ]
            };
            _commonUtil.logOnConsole('\nProperties of new production update item : ' + JSON.stringify(inputData.input[0].data));
            _soaSvc.post('Core-2008-06-DataManagement', 'createObjects', inputData).then(function (response) {
                var prodRev = response.output[0].objects[2];
                var prodItem = response.output[0].objects[0];
                data.prodObjStrngValue = response.output[0].objects[2].props.object_string.dbValues[0];
                var prodRevPuid = response.output[0].objects[2].uid;

                getModelObjectsToRelate(selectedObjs, data, function (selectedIncludingPam) {
                    var arr = [];
                    var uids = [];
                    for (let index = 0; index < selectedIncludingPam.length; index++) {
                        const element = selectedIncludingPam[index];
                        if (uids.includes(element.uid) == false) {
                            arr.push({
                                clientId: 'AWClient',
                                relationType: 'CMHasProblemItem',
                                primaryObject: {
                                    uid: prodRev.uid,
                                    type: prodRev.type
                                },
                                secondaryObject: element
                            });
                            uids.push(element.uid);
                        }
                    }

                    var inputData1 = {
                        input: arr
                    };
                    console.log("Array: ", arr);
                    _soaSvc.post('Core-2006-03-DataManagement', 'createRelations', inputData1).then(function (response1) {

                        _commonUtil.postPilotProductionUpdate(prodRev, prodItem, _appCtxSvc.ctx.UnileverWorkspace.PilotProductionUpdate.isPrimary, function () {
                            var workflowName = "Production Change Process";
                            var prodUpdateSuccessMsg = "Production Updation done successfully.<br>'Production Change Process' workflow initiated successfully on <strong>" + data.prodObjStrngValue + "</strong>.</br>Check the list of data missing parameters between old and new revision under attachments in Workflow tab.<br>";
                            var pcrData = _appCtxSvc.ctx.PCRData;
                            var workflowdescription = "";
                            if (conditions.isPDFPrintSectionVisible2 == true) {
                                var pdfPrint = data.pdf_print.dbValue == true ? "Yes" : "No";
                                workflowdescription = "##" + pdfPrint;
                            }
                            if (pcrData && pcrData.considerSelection == true && pcrData.pcrObjectsSelected && pcrData.pcrObjectsSelected.length > 1) {
                                workflowName = "Production Change Process - MCT";
                                prodUpdateSuccessMsg = "Mass Production Update has been initiated, process will happen in the background. You should get notification in alert/bell icon after successful completion. </br>Post the confirmation, pls ensure to update the data inside the specs using mass change template or edit one by one as per your regular practice.</br> Pls note, task approval/reject will be applied on all the specs.";
                            } else if (conditions.isPCRSectionVisible == true) {
                                var containsPCR = data.pcrPD.dbValue == true ? "Yes" : "No";
                                if (data.pcrPD.dbValue == true) {
                                    workflowdescription = containsPCR + "$$" + data.pcr_percentagePD.dbValue + workflowdescription;
                                } else {
                                    workflowdescription = containsPCR + workflowdescription;
                                }
                            }

                            //BRMS-1304- Hanuman
                            if (conditions.isDesignNameVisible == true) {
                                var designDBValue = data.designname.dbValue;
                                console.log("designDBValue: ", designDBValue);

                                if (designDBValue == null || designDBValue == "") {
                                    designDBValue = "EMPTYUL";
                                    console.log("designDBValue11111111111: ", designDBValue);
                                } else {
                                    console.log("The Field Design Name is EMPTY----->", designDBValue);
                                }

                                workflowdescription = designDBValue + "&&" + workflowdescription;
                            }

                            _commonUtil.initiateUnileverWorkflow(data.prodObjStrngValue, prodRevPuid, workflowName, function () {
                                _messagingSvc.showInfo(prodUpdateSuccessMsg, true);
                                _commonUtil.openNewObject(prodRev.uid);
                            }, workflowdescription);

                            delete ctx.projectName;
                            delete ctx.PackagingDeveloper;
                            delete ctx.PackagingDesigner;
                        });

                    });
                });

            });
        }
    } catch (error) {
        _messagingSvc.showError('Something went wrong in production update action.');
        data.disableProdCreate = false;
    }
    return data.disableProdCreate;
}

var getModelObjectsToRelate = function (selectedObjArray, data, callback) {

    var mainPromise = awPromiseService.instance.defer();
    var objectsToRelate = [];
    var ctx = _appCtxSvc.ctx;
    var deferred = awPromiseService.instance.defer();
    var selectedPack,
        selectedPam;
    var selectedObject;
    var packRevObjUID = _appCtxSvc.getCtx("packRevObjUID");
    if (selectedObjArray.length == 1 && selectedObjArray[0].modelType.parentTypeName == "U4_PAMRevision") {
        selectedObjArray.pop();
        console.log("selectedObjArray: ", selectedObjArray);
        _getPackFromPam.getPackFromPam(mainPromise);
        //     mainPromise.promise.then(function (packRevObjUID) {
        //     selectedPack = _cdm.getObject(packRevObjUID);
        //     data.packId = selectedPack;
        // });
        selectedPack = _cdm.getObject(packRevObjUID);
        data.packId = selectedPack;
        console.log("Pack UID:", data.packId);
        selectedObjArray.push(data.packId);
    }
    if (ctx.PCRData && ctx.PCRData.considerSelection == true && ctx.PCRData.pcrObjectsSelected && ctx.PCRData.pcrObjectsSelected.length > 0) {
        _.forEach(ctx.PCRData.pcrObjectsSelected, function (pcrObject) {
            objectsToRelate.push({
                uid: pcrObject.uid,
                type: pcrObject.type
            });
        });
    } else {
        for (let index = 0; index < selectedObjArray.length; index++) {
            const element = selectedObjArray[index];
            objectsToRelate.push({
                uid: element.uid,
                type: element.type
            });
        }
    }

    var inputData = {
        objects: objectsToRelate,
        attributes: ['U4_PAMSpecification']
    };

    _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (response) {

        //    console.log(response);
        var objMap = [];
        var types = [];
        for (var element in response.modelObjects) {
            objMap.push({
                uid: response.modelObjects[element].uid,
                type: response.modelObjects[element].type
            });
            if (!(types.indexOf(response.modelObjects[element].type) > -1)) // if not contains
                types.push(response.modelObjects[element].type);
        }
        if (types.length > 0) {
            var inputData1 = {
                'typeNames': types,
                'options': {
                    'TypeExclusions': ['ToolInfo', 'DirectChildTypesInfo', 'RevisionNamingRules'],
                    'PropertyExclusions': ['RendererReferences', 'NamingRules']
                }
            };
            _soaSvc.post('Core-2015-10-Session', 'getTypeDescriptions2', inputData1).then(function (response1) {
                //     console.log(response1);
                if (response1) {
                    for (let index = 0; index < response1.types.length; index++) {
                        const element = response1.types[index];
                        if (element.parentTypeName && element.parentTypeName == 'U4_PAMRevision') {
                            for (let index = 0; index < objMap.length; index++) {
                                const element1 = objMap[index];
                                if (element1.type == element.name) {

                                    if (!(objectsToRelate.indexOf(element1) > -1)) // if not contains
                                        objectsToRelate.push(element1);
                                    /*  console.log('>>' + element1);
                                    console.log('>>1' + element1.uid);
                                    console.log('>>2' + element1.type); */
                                }

                            }
                        }

                    }

                    //   console.log('>>>||>> objectsToRelate ' + objectsToRelate.length);
                    callback(objectsToRelate);
                }

            });
        } else {
            //   console.log('>>>||>> objectsToRelate ' + objectsToRelate.length);
            callback(objectsToRelate);
        }

    });
    return;
}

export let closePanelJs = function (data, ctx) {
    //var deferred = awPromiseService.instance.defer();
    data.valuesEntered = false;
    if ((ctx.sidenavCommandId == "ProductionUpdate") || ($('form.sw-column').find('.sw-property-val')[0] && $('form.sw-column').find('.sw-property-val')[0].value == "Tube Chassis") || ($('form.sw-column').find('.sw-property-val')[0] && $('form.sw-column').find('.sw-property-val')[0].value == "Decoration Chassis") || ($('form.sw-column').find('.sw-property-val')[0] && $('form.sw-column').find('.sw-property-val')[0].value == "PNG Resin") || ($('form.sw-column').find('.sw-property-val')[0] && $('form.sw-column').find('.sw-property-val')[0].value == "PNG Substrate") || ($('form.sw-column').find('.sw-property-val')[0] && $('form.sw-column').find('.sw-property-val')[0].value == "Paper & Board")) {
        var totalVal = [];

        $('form.sw-column').find('.sw-property-val').each(function (idx, textarea) {

            if (textarea.value != "" && textarea.value != "Tube Chassis" && textarea.value != "Decoration Chassis" && textarea.value != "PNG Resin" && textarea.value != "PNG Substrate" && textarea.value != "Paper & Board") {
                totalVal.push(1);
            }
        });

        if (totalVal.length > 0) {
            data.valuesEntered = true;
        }
    }

    return data;
}

export let gettypeofChangeList = function (response) {

    // console.log("This gettypeofChangeList is called");
    var approver = true;
    _appCtxSvc.registerCtx("approver", approver);
    // return response.lovValues.map(function (obj) {
    //     return {
    //         propDisplayValue: obj.propDisplayValues.lov_values[0],
    //         propDisplayDescription: obj.propDisplayValues.lov_value_descriptions ? obj.propDisplayValues.lov_value_descriptions[0] : obj.propDisplayValues.lov_values[0],
    //         propInternalValue: obj.propInternalValues.lov_values[0]
    //     };
    // });
    if (response) {
        var typeOfChangeList = [];
        typeOfChangeList.push({
            propDisplayValue: '',
            propDisplayDescription: '',
            dispValue: '',
            propInternalValue: ''
        });
        for (var i = 0; i < response.lovValues.length; i++) {
            var typeOfChange = response.lovValues[i].propDisplayValues.lov_values[0];
            typeOfChangeList.push({
                propDisplayValue: typeOfChange,
                propDisplayDescription: '',
                dispValue: typeOfChange,
                propInternalValue: typeOfChange
            });
        }
        return typeOfChangeList;
    }
};

export let getProjectList = function (response) {
    if (response) {
        var projAssociationList = [];
        projAssociationList.push({
            propDisplayValue: '',
            propDisplayDescription: '',
            dispValue: '',
            propInternalValue: ''
        });
        for (var i = 0; i < response.lovValues.length; i++) {
            var projAssociation = response.lovValues[i].propDisplayValues.project_id[0];
            projAssociationList.push({
                propDisplayValue: projAssociation,
                propDisplayDescription: '',
                dispValue: projAssociation,
                propInternalValue: projAssociation
            });
        }
        return projAssociationList;
    }
};

export let getpackagingDesignerLovList = function (response) {
    if (response) {
        var packagingDesignerList = [];
        packagingDesignerList.push({
            propDisplayValue: '',
            propDisplayDescription: '',
            dispValue: '',
            propInternalValue: ''
        });
        for (var i = 0; i < response.lovValues.length; i++) {
            var packagingDesignerID = response.lovValues[i].propDisplayValues.user_id[0];
            var packagingDesignerName = response.lovValues[i].propDisplayValues.user_name[0];
            packagingDesignerList.push({
                propDisplayValue: packagingDesignerID,
                propDisplayDescription: packagingDesignerName,
                dispValue: packagingDesignerID,
                propInternalValue: packagingDesignerID
            });
        }
        //console.log(response);
        return packagingDesignerList;
    }
};

export let getpackagingDeveloperLovList = function (response) {

    if (response) {
        var packagingDeveloperList = [];
        packagingDeveloperList.push({
            propDisplayValue: '',
            propDisplayDescription: '',
            dispValue: '',
            propInternalValue: ''
        });
        for (var i = 0; i < response.lovValues.length; i++) {
            var packagingDeveloperID = response.lovValues[i].propDisplayValues.user_id[0];
            var packagingDeveloperName = response.lovValues[i].propDisplayValues.user_name[0];
            packagingDeveloperList.push({
                propDisplayValue: packagingDeveloperID,
                propDisplayDescription: packagingDeveloperName,
                dispValue: packagingDeveloperID,
                propInternalValue: packagingDeveloperID
            });
        }
        return packagingDeveloperList;
    }
};

export let getreleaseApproverPADLovList = function (response) {

    if (response) {

        var releaseApproverPADList = [];
        releaseApproverPADList.push({
            propDisplayValue: '',
            propDisplayDescription: '',
            dispValue: '',
            propInternalValue: ''
        });
        for (var i = 0; i < response.lovValues.length; i++) {
            var releaseApproverPADID = response.lovValues[i].propDisplayValues.user_id[0];
            var releaseApproverPADName = response.lovValues[i].propDisplayValues.user_name[0];
            releaseApproverPADList.push({
                propDisplayValue: releaseApproverPADID,
                propDisplayDescription: releaseApproverPADName,
                dispValue: releaseApproverPADID,
                propInternalValue: releaseApproverPADID
            });
        }
        return releaseApproverPADList;
    }
};

export let getreleaseApproverCTMLovList = function (response) {
    if (response) {
        var releaseApproverCTMList = [];
        releaseApproverCTMList.push({
            propDisplayValue: '',
            propDisplayDescription: '',
            dispValue: '',
            propInternalValue: ''
        });
        for (var i = 0; i < response.lovValues.length; i++) {
            var releaseApproverCTMID = response.lovValues[i].propDisplayValues.user_id[0];
            var releaseApproverCTMName = response.lovValues[i].propDisplayValues.user_name[0];
            releaseApproverCTMList.push({
                propDisplayValue: releaseApproverCTMID,
                propDisplayDescription: releaseApproverCTMName,
                dispValue: releaseApproverCTMID,
                propInternalValue: releaseApproverCTMID
            });
        }
        return releaseApproverCTMList;
    }
};
export let setProjectValues = function (ctx, data, conditions) {
    if (data.projectResponse) {
        data.hasAssembly = data.projectResponse.hasAssembly;
        data.selectedTab = data.projectResponse.selectedTab;
        var projectNameList = [];
        projectNameList.push(_lovUtil.getEmptyLov()[0])
        for (var i = 0; i < data.projectResponse.projectList.length; i++) {
            var projectName = data.projectResponse.projectList[i];
            projectNameList.push({
                propDisplayValue: projectName,
                propDisplayDescription: '',
                dispValue: projectName,
                propInternalValue: projectName
            });
        }
    }

    return projectNameList;
};

export let checkLoginPADUser = function (data) {
    console.log("user id:", _appCtxSvc.ctx.userSession.props.user_id.dbValue);
    if (data.releaseapproverpad.dbValue === _appCtxSvc.ctx.userSession.props.user_id.dbValue) {
        _messagingSvc.showError('Logged-in user and PAD user are same. Please select another user');
        setEmptyValue(data, data.releaseapproverpad, 'data.releaseapproverpad');
    }
}

export let checkLoginCTMUser = function (data) {
    console.log("user id:", _appCtxSvc.ctx.userSession.props.user_id.dbValue);
    if (data.releaseapproverctm.dbValue === _appCtxSvc.ctx.userSession.props.user_id.dbValue) {
        _messagingSvc.showError('Logged-in user and CTM user are same. Please select another user');
        setEmptyValue(data, data.releaseapproverctm, 'data.releaseapproverctm');
    }
}

var setEmptyValue = function (data, Object, propname) {
    var modelobject = _.clone(Object);
    modelobject.dbValue = "";
    modelobject.uiValue = "";
    data.dispatch({ path: propname, value: modelobject });
}

export default exports = {
    checkLoginPADUser,
    checkLoginCTMUser,
    preValProdUpdateChkMethod,
    onReveal,
    doProductionUpdateOperation,
    closePanelJs,
    setDefaultApprovers,
    CheckReleaseApprover,
    gettypeofChangeList,
    getProjectList,
    getpackagingDesignerLovList,
    getpackagingDeveloperLovList,
    getreleaseApproverPADLovList,
    getreleaseApproverCTMLovList,
    setProjectValues,
    selTypeAction,
    getGroupsDetails,
    enableDisableProdCreatebutton
};