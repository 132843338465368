import app from 'app';
import _ from 'lodash';
import eventBus from 'js/eventBus';
import _editHandlerService from 'js/editHandlerService';
import _appCtxService from 'js/appCtxService';
import _soaSvc from 'soa/kernel/soaService';
import _uwPropertyService from 'js/uwPropertyService';
import _messagingSvc from 'js/messagingService';

'use strict';

var exports = {};
export let initiateworkflow = function (ctx) {
	if(ctx.userSession.props.group_name.dbValues[0] === 'R&D_Category_Tea'){
		if(ctx.selected.props.u4_lead_category.dbValues[0] === 'R&D Category Tea'){
			_soaSvc.post('Workflow-2008-06-Workflow', 'createInstance', {
        "startImmediately": true,
        "name": ctx.selected.modelType.displayName,
        "contextData": {
            "processTemplate": "User Assign to Project",
            "attachmentCount": 1,
            "attachmentTypes": [
                1
            ],
            "attachments": [
                ctx.selected.uid
            ]
        }
		}).then(function (createInstanceResponse) {
			console.log("Project subscription is successfully initiated");
		}, function (error) {
			_messagingSvc.showInfo(error.message, true);
		});
		}
		else{
			_messagingSvc.showInfo("Project Subscription can be initiated only for Tea Project");
		}
	}
	else{
		if(ctx.selected.props.u4_lead_category.dbValues[0] === 'R&D Category Tea'){
			_messagingSvc.showInfo("Only R&D_Category_Tea Users can initiate Project Subscription");
		}
		else{
			_soaSvc.post('Workflow-2008-06-Workflow', 'createInstance', {
        "startImmediately": true,
        "name": ctx.selected.modelType.displayName,
        "contextData": {
            "processTemplate": "User Assign to Project",
            "attachmentCount": 1,
            "attachmentTypes": [
                1
            ],
            "attachments": [
                ctx.selected.uid
            ]
        }
		}).then(function (createInstanceResponse) {
			console.log("Project subscription is successfully initiated");
		}, function (error) {
			_messagingSvc.showInfo(error.message, true);
		});
		}
	}
   return;
}

export let moduleServiceNameToInject = 'unileverProjSubscriptionService';
export default exports = {
    initiateworkflow,
    moduleServiceNameToInject
};
app.factory('unileverProjSubscriptionService', () => exports);
