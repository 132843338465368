import _msgSvc from 'js/messagingService';
import _appCtxSvc from 'js/appCtxService';
import _soaSVC from 'soa/kernel/soaService';

/**
 * An OTB SOA call to get the Primary Object i.e PackComp obj based on Relation then opening PackComp Obj
 * @param {Object} data ViewModel object
 *
 */
import app from 'app';
    'use strict';

 
    var exports = {};
    export let getPACKFromPAM = function( data ) {

        var InputData = {
                secondaryObjects: [_appCtxSvc.ctx.selected],
                pref:
                {
                expItemRev: true,
                returnRelations: true,
                info:
                [{
                relationTypeName: "U4_PAMSpecification",
                otherSideObjectTypes: []
                }]
                }

        };

        _soaSVC.post('Core-2007-09-DataManagement','expandGRMRelationsForSecondary',InputData).then(function(response)
        {
            if(response.output[0].relationshipData[0].relationshipObjects.length === 0)
            {
                _msgSvc.showError("For selected component, Pack Component doesn't exist");// This case never happen,but handling native case.
            }
            var packRevObjUID = response.output[0].relationshipData[0].relationshipObjects[0].otherSideObject.uid;
            if(packRevObjUID)
            {
                var newURLwithNewObject = window.location.origin + window.location.pathname + 
                '#/com.siemens.splm.clientfx.tcui.xrt.showObject?uid=' + packRevObjUID;
                window.location.href = newURLwithNewObject;
            }
        }).catch(function(error){
            _msgSvc.showError("ERROR:Unable to get PAM from PACK due to:\t"+_msgSvc.getSOAErrorMessage(error));
        });
    };
export let moduleServiceNameToInject = 'UnileverPAMToPackService';
export default exports = {
	getPACKFromPAM,
	moduleServiceNameToInject
};
app.factory('UnileverPAMToPackService', () => exports );