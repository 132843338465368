import awPromiseService from 'js/awPromiseService';
import app from 'app';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import eventBus from 'js/eventBus';
import $ from 'jquery';
import _commonUtil from 'js/UnileverCommonUtil';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import _lovUtil from 'js/UnileverLovUtil';
import _policySvc from 'soa/kernel/propertyPolicyService';


    'use strict';


    var exports = {};
    var technology_description = null;
    var noLOVtext = 'Select a value for the property ';
    var commonCtx;
    var globalConditions;
    var prefMap = new Map();
    var sourceCADData = new Array();
    var destCADData = new Array();
    var prevUOMVal = [];
    /**
     *
     * checks whether current user has privilege for selected project.
     * Selected Project should be in projects of current user to allow to create pack component.
     *
     *
     * @param {Object} data ViewModel object from module.json
     *
     */
    export let checkUserPrivilege = function (data) {
        data.privileged = undefined;
        data.projectsAssigned = undefined;
        data.projectName = _appCtxSvc.ctx.selected.props.object_string.dbValues[0];
        data.projectsAssigned = _appCtxSvc.ctx.selected.props.project_list.dbValues[0];
        data.modifiablePack = undefined;

        if (_appCtxSvc.ctx.selected.props.is_modifiable.uiValues[0] === 'False') {
            data.modifiablePack = false;
            data.privileged = false;
        }
        // query response has projects of current user
        var projectList__ = data.executeProjectQueryResponse[0].projectsInfo;
        //  _commonUtil.logOnConsole('length ' + projectList__.length);
        for (var i in projectList__) {
            if (_appCtxSvc.ctx.selected.props.object_string.dbValues[0] === (
                'Project ' + (projectList__[i].project.props.object_string.dbValues[0]))) {
                // set user has privilege
                data.privileged = true;
                _commonUtil.logOnConsole('User has Privilege to create component on this Project.');
                break;
            }
        }

    };
    export let metaDataChangePreAction = function (data, ctx, conditions, compClass) {

        var deferred = awPromiseService.instance.defer();
        var matFormResponse = undefined;
        var compRevResponse = undefined;

        try {
            console.log("metaDataChangePreAction starts");
            globalConditions = conditions;
            var compRev = {
                uid: ctx.selected.uid
            };
            var inputData = {
                objects: [compRev],
                attributes: ['u4_contains_pcr', 'u4_pcr_percentage', 'u4_component_class', 'u4_component_commodity', 
                'u4_material_class', 'u4_material_commodity', 'U4_MatlClassRelation', 'u4_technology', 
                'u4_cp_cu_weight_volume', 'u4_cp_manufacturing_processes', 'u4_cp_shape', 'u4_cp_size', 
                'u4_cp_unique_descriptor', 'u4_lead_category', 'u4_cp_component_description', 'u4_consumer_unit', 
                'u4_component_type', 'object_desc', 'u4_reason_for_issue', 'u4_base_uom', 'u4_design_name','U4_PAMSpecification']
            };
            _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (response) {

                compRevResponse = response.modelObjects[response.plain[0]];
                
                var matForm = {
                    uid: compRevResponse.props.U4_MatlClassRelation.dbValues[0]
                };
                var inputData = {
                    objects: [matForm],
                    attributes: ['u4_delivered_form', 'u4_pam_frame_type', 'u4_lead_size', 'u4_material_class','u4_material_commodity']
                };

                _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (response) {
                           
                    matFormResponse = response.modelObjects[response.plain[0]]; 
                 
                    return deferred.resolve({
                        matFormDetails:matFormResponse, 
                        compRevDetails:compRevResponse
                        });                        
                });
            });
            console.log("metaDataChangePreAction Ends");
        } catch (error) {
            _messagingSvc.showError('Something went wrong in pack component create action.');
            conditions.ChangeButtonEnability = true;
        }
        return deferred.promise;
    }    

    export let loadInitialLovs = async function (data, ctx, compRevResponse, matFormResponse) 
    {      

        if (ctx.gmcItemID == undefined) {
            _appCtxSvc.registerCtx('gmcItemID', ctx.selected.uid);
        } else {
            _appCtxSvc.updateCtx('gmcItemID', ctx.selected.uid);
        }
        
        //Adding component type LOV
        //If PAM present, add only the current value to the LOV, which will not be allowed
        //to change from the UI
        if(ctx.selected.props.U4_PAMSpecification && ctx.selected.props.U4_PAMSpecification.dbValues.length === 1)
        {
            if((ctx.selected.props.u4_component_type.dbValues.length == 0) || (ctx.selected.props.u4_component_type.dbValues[0] == null))
            {
                var lovpair = [{
                    lovvalue: "New Component - Unique"
                }, {
                    lovvalue: "New Component - Stock"
                }
                ];
            }
            else
            {
                var lovpair = [{
                    lovvalue: ctx.selected.props.u4_component_type.dbValues[0]
                }
                ];
            }
        }
        else{
            var lovpair = [{
                lovvalue: "New Component - Unique"
            }, {
                lovvalue: "New Component - Stock"
            }
            ];
        }
        var compTypeLOV = exports.convertDataToLov(lovpair);

        data.dispatch({path:'data.compTypeLOV', value:compTypeLOV});

        setChildDefaultValue(data,data.componentType,"data.componentType", null, compRevResponse.props.u4_component_type.dbValues[0]);
        
        var inputData = {
            lovName: 'U4_ComponentClassLOV'
        };
        var compClassLOVValues = undefined;   
        const soaResponse = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);                 
        if (soaResponse) 
        {            
            compClassLOVValues = exports.convertDataToLov(soaResponse.lovpair);  
        }

        var compCommoditylovs = await asyncLoadDependentLov(data, compClassLOVValues, compRevResponse.props.u4_component_class.dbValues[0]);
        var matClasslovs = await asyncLoadDependentLov(data, compCommoditylovs, compRevResponse.props.u4_component_commodity.dbValues[0]);
        var matCommoditylovs = await asyncLoadDependentLov(data, matClasslovs, matFormResponse.props.u4_material_class.dbValues[0]);
        var delFormlovs = await asyncLoadDependentLov(data, matCommoditylovs, matFormResponse.props.u4_material_commodity.dbValues[0]);
        var pamframetypeLovs = await asyncLoadDependentLov(data, delFormlovs, matFormResponse.props.u4_delivered_form.dbValues[0]);
        var technologyLovs = await asyncLoadDependentLov(data, delFormlovs, matFormResponse.props.u4_pam_frame_type.dbValues[0]);
        
		data.dispatch({path: "data.designname.dbValue", value: ctx.selected.props.u4_design_name.dbValues[0]});
        return ({
            compClassLOVValues:compClassLOVValues,
            compCommoditylovs:compCommoditylovs,
            matClasslovs:matClasslovs,
            matCommoditylovs:matCommoditylovs,
            delFormlovs:delFormlovs,
            pamframetypeLovs:pamframetypeLovs,
            technologyLovs:technologyLovs});         
    };  

    export let updateDefaultVals =  function (data, ctx, compRevResponse, matFormResponse) { 

        if (matFormResponse.props.u4_lead_size.dbValues[0] == 1)
            data.leadSize.dbValue = true;
        else
            data.leadSize.dbValue = false;

        if (compRevResponse.props.u4_consumer_unit.dbValues[0] == 1)
            data.consumerUnit.dbValue = true;
        else
            data.consumerUnit.dbValue = false; 

        data.objDesc.dbValue = compRevResponse.props.object_desc.dbValues[0];

        data.size.dbValue = compRevResponse.props.u4_cp_size.dbValues[0];
        data.uniqDescriptor.dbValue = compRevResponse.props.u4_cp_unique_descriptor.dbValues[0];

        setChildDefaultValue(data,data.baseDiffUOM,"data.baseDiffUOM", null, compRevResponse.props.u4_base_uom.dbValues[0]);

        data.reasonforissue.dbValue = compRevResponse.props.u4_reason_for_issue.dbValues[0];   

        data.designname.dbValue = compRevResponse.props.u4_design_name.dbValues[0];

        data.pcrGMC.dbValue = compRevResponse.props.u4_contains_pcr.dbValues[0] == "Yes" ? true : false;        
        data.pcr_percentageGMC.dbValue = compRevResponse.props.u4_pcr_percentage.dbValues[0] == null ? "" : compRevResponse.props.u4_pcr_percentage.dbValues[0];
        

        setChildDefaultValue(data,data.compClass,"data.compClass", data.compClassLOVValues, compRevResponse.props.u4_component_class.dbValues[0]);
        setChildDefaultValue(data,data.cuWeightVolume,"data.cuWeightVolume", null , compRevResponse.props.u4_cp_cu_weight_volume.dbValues[0]);
        setChildDefaultValue(data,data.manufacProcessess,"data.manufacProcessess", null , compRevResponse.props.u4_cp_manufacturing_processes.dbValues[0]);
        setChildDefaultValue(data,data.shape,"data.shape", null , compRevResponse.props.u4_cp_shape.dbValues[0]);

        return ;   
    };

    /**
     *
     * Loads Component Class LOV.
     * Called on Click of Create Component command
     *
     * @param {Object} data ViewModel object
     * @return
     */
    export let loadCompClassLov = function (data) {
        var compClassLOVValues = data.compClassLOVValues;
        var compClassLOVLength = data.compClassLOVValues.Length;
    return ({           
            compClassLOVValues:compClassLOVValues,
            compClassLOVLength:compClassLOVLength});  
    };  
    
    export let dataproviderInfo = function (lov) {
        var returnLOVValue = lov;
        var returnLOVValueCount = lov.Length;
    return ({           
            returnLOVValue:returnLOVValue,
            returnLOVValueCount:returnLOVValueCount});  
    }; 


    export let updateCompClass = async function (data, ctx) {
        console.log('updateCompClass');
        var compCommoditylovs = await asyncLoadDependentLov(data, data.compClassLOVValues, data.compClass.dbValue);
        if (data.compRevResponse && data.compClass.dbValue === data.compRevResponse.props.u4_component_class.dbValues[0])
        {               
            var defValueCompComm = data.compRevResponse.props.u4_component_commodity.dbValues[0];
            var defValueWtVol = data.compRevResponse.props.u4_cp_cu_weight_volume.dbValues[0];
            var defValueMfgPro = data.compRevResponse.props.u4_cp_manufacturing_processes.dbValues[0];
            var defValueShape = data.compRevResponse.props.u4_cp_shape.dbValues[0];
            //setChildDefaultValue(data,data.compCommodity,"data.compCommodity",data.compCommodityLOVValues,defValueCompComm);
            setChildDefaultValue(data,data.compCommodity,"data.compCommodity",compCommoditylovs, defValueCompComm);
            setChildDefaultValue(data,data.cuWeightVolume,"data.cuWeightVolume",null, defValueWtVol);
            setChildDefaultValue(data,data.manufacProcessess,"data.manufacProcessess",null, defValueMfgPro);
            setChildDefaultValue(data,data.shape,"data.shape",null, defValueShape);
        }
        else
        {                       
            setChildEmptyValue(data, data.compCommodity,"data.compCommodity"); 
            setChildEmptyValue(data, data.cuWeightVolume,"data.cuWeightVolume"); 
            setChildEmptyValue(data, data.manufacProcessess,"data.manufacProcessess"); 
            setChildEmptyValue(data, data.shape,"data.shape");
        }

        return ({compCommoditylovs:compCommoditylovs}); 
    };
    export let updateCompCommodity = async function (data, ctx) {
        console.log('updateCompCommodity');        
        var matClasslovs = await asyncLoadDependentLov(data, data.compCommodityLOVValues, data.compCommodity.dbValue);
        if (data.compRevResponse && data.compCommodity.dbValue === data.compRevResponse.props.u4_component_commodity.dbValues[0])
        {               
            var defValue = data.matFormResponse.props.u4_material_class.dbValues[0];
            setChildDefaultValue(data,data.matClass,"data.matClass", matClasslovs, defValue);
        }
        else
        {           
            setChildEmptyValue(data, data.matClass,"data.matClass"); 
        }  
        return ({matClasslovs:matClasslovs});   
    };
    export let updateMatClass = async function (data, ctx) {
        console.log('updateMatClass');
        var matCommoditylovs = await asyncLoadDependentLov(data, data.matClassLOVValues, data.matClass.dbValue);
        if (data.matFormResponse && data.matClass.dbValue === data.matFormResponse.props.u4_material_class.dbValues[0])
        {               
            var defValue = data.matFormResponse.props.u4_material_commodity.dbValues[0];
            setChildDefaultValue(data,data.matCommodity,"data.matCommodity", matCommoditylovs, defValue);
        }
        else
        {           
            setChildEmptyValue(data, data.matCommodity,"data.matCommodity"); 
        }   
        return ({matCommoditylovs:matCommoditylovs});  
    };
    export let updateMatComm = async function (data, ctx) {
        clearPCRPercentage(data);
        if (ctx.selected && ctx.preferences.U4_PCR_Technology_Types && ctx.preferences.U4_PCR_Technology_Types.length > 0) {
            var componentType = ctx.selected.props.object_type.uiValues[0];
            if (ctx.preferences.U4_PCR_Technology_Types.includes(componentType) == true) {
                var pcrgmcClone = _.clone(data.pcrGMC); 
                if (data.matClass.dbValue == "Plastic" && data.matCommodity.dbValue.includes('Recycled') == true) {                    
                    pcrgmcClone.dbValue = true;      
                    pcrgmcClone.uiValue = true;     
                    pcrgmcClone.isEnabled = false;  
                } else {
                    pcrgmcClone.isEnabled = true;                           
                }   
                data.dispatch({path:'data.pcrGMC', value:pcrgmcClone});
            }
        }
        var delFormlovs = await asyncLoadDependentLov(data, data.matCommodityLOVValues, data.matCommodity.dbValue);
        if (data.matFormResponse && data.matCommodity.dbValue === data.matFormResponse.props.u4_material_commodity.dbValues[0])
        {       
            var defValue = data.matFormResponse.props.u4_delivered_form.dbValues[0];
            //setChildDefaultValue(data,data.deliForm,"data.deliForm",data.deliFormLOVValues, defValue);
            setChildDefaultValue(data,data.deliForm,"data.deliForm",delFormlovs, defValue);
        }
        else
        {                 
            setChildEmptyValue(data, data.deliForm,"data.deliForm"); 
        } 
        return ({delFormlovs:delFormlovs});    
    };
    export let updatedeliForm = async function (data, ctx) {
        var pamframetypeLovs = await asyncLoadDependentLov(data, data.deliFormLOVValues, data.deliForm.dbValue);
        if (data.matFormResponse){
            var defValue = data.matFormResponse.props.u4_pam_frame_type.dbValues[0];
            var flag=0;
            for (var i in pamframetypeLovs){
                if (pamframetypeLovs[i].propDisplayValue === defValue){
                    flag=flag+1;
                    console.log(flag);
                    break;
              }
            }
            if (data.deliForm.dbValue === data.matFormResponse.props.u4_delivered_form.dbValues[0])
            {     
                console.log("defcheck");
                //var defValue = data.matFormResponse.props.u4_pam_frame_type.dbValues[0];
                //setChildDefaultValue(data,data.pamFrameType,"data.pamFrameType",data.pamFrameTypeLOVValues, defValue);
                setChildDefaultValue(data,data.pamFrameType,"data.pamFrameType",pamframetypeLovs, defValue);
            }
            else if (flag==1){
                
                setChildDefaultValue(data,data.pamFrameType,"data.pamFrameType",pamframetypeLovs, defValue);
            }
            else
            {
                
                setChildEmptyValue(data, data.pamFrameType,"data.pamFrameType"); 
            } 
            
        }
        else
            { 
                console.log("defcheck entered in outer else block");
                setChildEmptyValue(data, data.pamFrameType,"data.pamFrameType"); 
            } 
        return ({pamframetypeLovs:pamframetypeLovs});   
    };
    export let  updatepamFrameType = async function (data, ctx) {
        clearPCRPercentage(data);
        var technologyLovs = await asyncLoadDependentLov(data, data.pamFrameTypeLOVValues, data.pamFrameType.dbValue);
        if (data.matFormResponse && data.pamFrameType.dbValue === data.matFormResponse.props.u4_pam_frame_type.dbValues[0])
        {       
            var defValue = data.compRevResponse.props.u4_technology.dbValues[0];
            //setChildDefaultValue(data,data.technology,"data.technology",data.technologyLOVValues, defValue);
            setChildDefaultValue(data,data.technology,"data.technology",technologyLovs, defValue);
        }
        else
        {                 
            setChildEmptyValue(data, data.technology,"data.technology"); 
        } 
        return ({technologyLovs:technologyLovs});   
    };
    export let updateTechnology = function (data, ctx) {
        var prefValue = [];
        var prefValue2 = [];
        var story = [];
        var frame = data.pamFrameType.dbValue;
        var technoValue = data.technology.dbValue;

        if (technoValue && technoValue.length > 0) {

            var prefName1 = 'U4_GMCPackComponentType';
            var prefName2 = 'UL_primary_packaging_list';

            prefValue = ctx.preferences[prefName1];
            prefValue2 = ctx.preferences[prefName2];

            if (_commonUtil.isPreferencesAvailable([prefName1, prefName2], true)) {

                for (var i = 0; i < prefValue.length; i++) {
                    story = prefValue[i].split('#');

                    if ((story.length == 3) && (story[0] == frame) && (story[1] == technoValue)) {
                        //   _commonUtil.logOnConsole('found the respective technovalue');
                        var techFromPref = story[2];
                    }
                }
                let found = false;
                for (var j = 0; j < prefValue2.length; j++) {
                    if (prefValue2[j] == techFromPref) {
                        found = true;
                        break;
                    }

                }

                var vmoClone = _.clone(data.consumerUnit);                    
                vmoClone.dbValue = found;                
                data.dispatch({path:'data.consumerUnit', value:vmoClone});
                //exports.changeButtonVisibilityCheck(data, commonCtx);

            }
        }  
        showTechnologyInfo(technoValue);  
    };
    
    export let loadUomValues = function (data, ctx) {
        
        var currentCompClass = data.compClass.dbValue;
        var currentCompCommodity = data.compCommodity.dbValue;
        var currentmatClass = data.matClass.dbValue;
        var currentmatCommodity = data.matCommodity.dbValue;

        var lExistingUOM = false;

        var uombasedonClassNCom = 'U4_UOM_basedon_Class_Commodity';
        if (_commonUtil.isPreferencesAvailable([uombasedonClassNCom], true)) 
        {
            var uomValues = [];
            var base_uom_values = ctx.preferences[uombasedonClassNCom];
            if (base_uom_values) 
            {
                for (var inx = 0; inx < base_uom_values.length; inx++) 
                {
                    var str = base_uom_values[inx].trim().split(':');
                    if (str.length == 2)
                        var result = str[0].includes('#');
                    // only comp class value present
                    if (result != true) 
                    {
                        if (currentCompClass == str[0]) {
                            var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                            var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);                            
                                uomValues.push({
                                propDisplayValue: onlyUOM[0],
                                propInternalValue: onlyUOM[0],
                                propDisplayDescription: propDesc[1]
                                });
                                if(onlyUOM[0] === data.compRevResponse.props.u4_base_uom.dbValues[0])
                                {
                                    lExistingUOM = true;
                                }
                        }
                    }
                    else
                    {
                        var classncomValues = str[0].trim().split('#');
                        if (classncomValues.length == 2) {
                            if (currentCompClass == classncomValues[0] && currentCompCommodity == classncomValues[1]) {
                                var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                uomValues.push({
                                        propDisplayValue: onlyUOM[0],
                                        propInternalValue: onlyUOM[0],
                                        propDisplayDescription: propDesc[1]
                                    });
                                if(onlyUOM[0] === data.compRevResponse.props.u4_base_uom.dbValues[0])
                                {
                                    lExistingUOM = true;
                                }

                            }
                        }
                        if (classncomValues.length == 3) {
                            if (currentCompClass == classncomValues[0] && currentCompCommodity == classncomValues[1] && currentmatClass == classncomValues[2]) {
                                var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                uomValues.push({
                                        propDisplayValue: onlyUOM[0],
                                        propInternalValue: onlyUOM[0],
                                        propDisplayDescription: propDesc[1]
                                    });
                                if(onlyUOM[0] === data.compRevResponse.props.u4_base_uom.dbValues[0])
                                {
                                    lExistingUOM = true;
                                }
                            }
                        }
                        if (classncomValues.length == 4) {
                            if (currentCompClass == classncomValues[0] && currentCompCommodity == classncomValues[1] && currentmatClass == classncomValues[2] && currentmatCommodity == classncomValues[3]) {
                                var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                uomValues.push({
                                        propDisplayValue: onlyUOM[0],
                                        propInternalValue: onlyUOM[0],
                                        propDisplayDescription: propDesc[1]
                                    });
                                if(onlyUOM[0] === data.compRevResponse.props.u4_base_uom.dbValues[0])
                                {
                                    lExistingUOM = true;
                                }
                            }
                        }
                    }

                }
            }
        }
        if(!lExistingUOM)
        {
            if(data.compClass.dbValue === "Flexible Packaging")
            {                
                var curBUOMVal = data.compRevResponse.props.u4_base_uom.dbValues[0];                
                uomValues.push({
                    propDisplayValue: curBUOMVal,
                    propInternalValue: curBUOMVal,
                    propDisplayDescription: ""
                });                
            }
        } 
        if (uomValues.length === 0) {
            
            var deferred = awPromiseService.instance.defer(); 
            var baseUOMLOVValues = [];
            _lovUtil.getLOVvalue('U4_ComponentRevision', 'u4_base_uom', 'lov_values', 'lov_value_descriptions', null, baseUOMLOVValues, function (serviceData) {

                console.log('Type of change loaded');
                uomValues = Array.from(baseUOMLOVValues);
                return deferred.resolve({
                        returnLOVValue:uomValues,
                        returnLOVValueCount:uomValues.length});
            });
            return deferred.promise;
        }
        return ({
            returnLOVValue:uomValues,
            returnLOVValueCount:uomValues.length});
    }
    

    /**
     *
     * Loads 'Component Commodity', 'Shape / Feature', 'CU Declared Weight/Volume', 'Manufacturing Processes' LOV.
     *
     * Called on selection of 'Component Class'
     *
     * @param {Object} data ViewModel object
     * @return
     */
    export let loadCompCommodityLov = function (data, compClassLOVValues) {
        
        var deferred = awPromiseService.instance.defer();  
        var currentCompClass;

        if (data.compClass.dbValue)
        {
            currentCompClass = data.compClass.dbValue;
        }
        if (currentCompClass && currentCompClass.length > 0) 
        {       
            var CompCommlovName = exports.getLOVValueIn(currentCompClass, compClassLOVValues);
            if (CompCommlovName) {
                //get comp commodity lov
                var inputData = {
                    lovName: CompCommlovName
                };
                var compCommodityLOVValuesL = undefined;
                var compCommodityLOVCountL = undefined;
                _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData).then(function (response) {
                    if (response) {
                        compCommodityLOVValuesL = exports.convertDataToLov(response.lovpair); 
                        compCommodityLOVCountL = compCommodityLOVValuesL.length;                        
                        _commonUtil.logOnConsole('Component Commodity updated.');
                        return deferred.resolve({
                            compCommodityLOVValues:compCommodityLOVValuesL,
                            compCommodityLOVCount:compCommodityLOVCountL});
                    }
                });
            }
            return deferred.promise;

        } else {           

            return ({
                compCommodityLOVValues:[],
                compCommodityLOVCount:0});


        }
        //return deferred.promise;
    };

 

    export let asyncLoadDependentLov = async function (data, parentLOVValues, parentValueSet) {         
         
        var currentParentVal;       
        currentParentVal = parentValueSet;
         
        if (currentParentVal && currentParentVal.length > 0 && parentLOVValues && parentLOVValues.length > 0) 
        {
       
            var currentlovName = exports.getLOVValueIn(currentParentVal, parentLOVValues);
            if (currentlovName) 
            {
                var inputData = {
                    lovName: currentlovName
                };
                var returnLOVValue = undefined;
                
                const soaResponse = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);                 
                if (soaResponse) 
                {                    
                    returnLOVValue = exports.convertDataToLov(soaResponse.lovpair);                     
                    return returnLOVValue;
                }
            }
        } 
    };


    export let loadDependentLov = function (data, parentLOVValues, parentValueSet) {   
                        
        var deferred = awPromiseService.instance.defer();  
        var currentParentVal;
        if (parentValueSet.dbValue)
        {
            currentParentVal = parentValueSet.dbValue;
        }
        if (currentParentVal && currentParentVal.length > 0 && parentLOVValues && parentLOVValues.length > 0) 
        {
       
            var currentlovName = exports.getLOVValueIn(currentParentVal, parentLOVValues);
            if (currentlovName) {
                var inputData = {
                    lovName: currentlovName
                };
                var returnLOVValue = undefined;
                var returnLOVValueCount = undefined;
                _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData).then(function (response) {
                    if (response) {
                        returnLOVValue = exports.convertDataToLov(response.lovpair); 
                        returnLOVValueCount = returnLOVValue.length;                        
                        
                        return deferred.resolve({
                            returnLOVValue:returnLOVValue,
                            returnLOVValueCount:returnLOVValueCount});
                    }
                });
            }
            return deferred.promise;

        } else {           

            return ({
                returnLOVValue:[],
                returnLOVValueCount:0});


        }
    };

    export let loadDependentLov_indirect = function (data, parentValueSet,lovname) {
        
        var deferred = awPromiseService.instance.defer();  
        var currentParentVal;
        if (parentValueSet.dbValue)
        {
            currentParentVal = parentValueSet.dbValue;
        }
        if (currentParentVal && currentParentVal.length > 0) 
        {   
            var inputData = {
                selectedValue: currentParentVal,
                softLinkLov: lovname
            };
            var returnLOVValue = undefined;
            var returnLOVValueCount = undefined;
            _soaSvc.post('AWC-2016-10-Create', 'getVIDLSubLovNLov', inputData).then(function (response) {
                if (response) {
                    returnLOVValue = exports.convertDataToLov(response.lovpair); 
                    returnLOVValueCount = returnLOVValue.length;                        
                    
                    return deferred.resolve({
                        returnLOVValue:returnLOVValue,
                        returnLOVValueCount:returnLOVValueCount});
                }
            });
            return deferred.promise;

        } else {           

            return ({
                returnLOVValue:[],
                returnLOVValueCount:0});


        }
    };

    /**
     *
     * Loads 'Material Class' LOV.
     *
     * Called on selection of 'Component Commodity'
     *
     * @param {Object} data ViewModel object
     * @return
     */
    export let onCompCommoditySelected = function (data,selValue,compClassValue) {

        var currentCompCommodity;
        var currentCompClass;

        if (selValue && selValue.length > 0)
            currentCompCommodity = selValue;
        else
            currentCompCommodity = data.compCommodity.dbValue;
        if (compClassValue && compClassValue.length > 0)
            currentCompClass = compClassValue;
        else
            currentCompClass = data.compClass.dbValue;

        if (currentCompCommodity && currentCompCommodity.length > 0) {

            var lovName1 = exports.getLOVValueIn(currentCompCommodity, data.compCommodityLOVValues);

            if (lovName1) {

                var inputData = {
                    lovName: lovName1
                };
                _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData).then(function (response) {
                    if (response) {
                        data.matClassLOVValues = exports.convertDataToLov(response.lovpair); // set new lov
                        data.matClass.dbValue = data.matFormResponse.props.u4_material_class.dbValues[0]; // set to empty
                        exports.onmatClassSelected(data, data.matClass.dbValue)
                        _commonUtil.logOnConsole('Material Class updated.');
                    }

                });
                //data.uomValues = [];
                var lExistingUOM = false;
                
                var uombasedonClassNCom = 'U4_UOM_basedon_Class_Commodity';
                if (_commonUtil.isPreferencesAvailable([uombasedonClassNCom], true)) {
                    var base_uom_values = _appCtxSvc.ctx.preferences[uombasedonClassNCom];
                    if (base_uom_values) {
                        for (var inx = 0; inx < base_uom_values.length; inx++) {
                            var str = base_uom_values[inx].trim().split(':');
                            if (str.length == 2)
                                var classncomValues = str[0].trim().split('#');
                            data.newbaseUOMLOVValues = [];
                            if (classncomValues.length == 2) {
                                if (currentCompClass == classncomValues[0] & currentCompCommodity == classncomValues[1]) {
                                    var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                    var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                    data.uomValues.push({
                                            propDisplayValue: onlyUOM[0],
                                            propInternalValue: onlyUOM[0],
                                            propDisplayDescription: propDesc[1]
                                        });
                                    if(onlyUOM[0] === _appCtxSvc.ctx.mselected[0].props.u4_base_uom.dbValues[0])
                                    {
                                        lExistingUOM = true;
                                    }

                                }
                            }
                        }
                        if(!lExistingUOM)
                            {
                                exports.baseUOMCustomization(data,_appCtxSvc.ctx,selValue);
                            }
                        console.log("On CComm selection    :   ", data.uomValues);
                        if (data.uomValues.length === 0) {
                            data.baseUOMLOVValues = [];
                            _lovUtil.getLOVvalue('U4_ComponentRevision', 'u4_base_uom', 'lov_values', 'lov_value_descriptions', null, data.baseUOMLOVValues, function (serviceData) {
                                console.log('Type of change loaded');
                                data.uomValues = Array.from(data.baseUOMLOVValues);
                            });
                        }
                        //_appCtxSvc.registerCtx('baseDiffValue', baseDiffValue);
                    }
                }
                if (data.uomValues.length === 0) {
                    data.uomValues = Array.from(data.baseUOMLOVValues);
                }
            }

        } else {

            var compCommStr = '\"Component Commodity\".';

            exports.resetLov(data, 'matClass');

            data.matClass.lovNoValsText = noLOVtext + compCommStr;
            data.matCommodity.lovNoValsText = noLOVtext + compCommStr;
            data.deliForm.lovNoValsText = noLOVtext + compCommStr;
            data.pamFrameType.lovNoValsText = noLOVtext + compCommStr;
            data.technology.lovNoValsText = noLOVtext + compCommStr;

        }
        exports.resetLov(data, 'matCommodity');
        exports.resetLov(data, 'deliForm');
        exports.resetLov(data, 'pamFrameType');
        exports.resetLov(data, 'technology');
        return;
    };

    /**
     *
     * Loads 'Material Commodity' LOV.
     *
     * Called on selection of 'Material Class'
     *
     * @param {Object} data ViewModel object
     * @return
     */
    export let onmatClassSelected = function (data, selValue) {

        var currentmatClass;

        if (selValue && selValue.length > 0)
            currentmatClass = selValue;
        else
            currentmatClass = data.matClass.dbValue;

        if (currentmatClass && currentmatClass.length > 0) {

            var lovName1 = exports.getLOVValueIn(currentmatClass, data.matClassLOVValues);

            if (lovName1) {

                var inputData = {
                    lovName: lovName1
                };
                _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData).then(function (response) {
                    if (response) {
                        data.matCommodityLOVValues = exports.convertDataToLov(response.lovpair); // set new lov
                        data.matCommodity.dbValue = commonCtx.selected.props.u4_material_commodity.dbValues[0]; // set to empty
                        exports.onmatCommoditySelected(data, data.matCommodity.dbValue, data.matClass.dbValue, data.compClass.dbValue, data.compCommodity.dbValue);
                        _commonUtil.logOnConsole('Material Commodity updated.');
                    }
                });
            }

        } else {

            var matClassStr = '\"Material Class\".';

            exports.resetLov(data, 'matCommodity');

            data.matCommodity.lovNoValsText = noLOVtext + matClassStr;
            data.deliForm.lovNoValsText = noLOVtext + matClassStr;
            data.pamFrameType.lovNoValsText = noLOVtext + matClassStr;
            data.technology.lovNoValsText = noLOVtext + matClassStr;

        }

        exports.resetLov(data, 'deliForm');
        exports.resetLov(data, 'pamFrameType');
        exports.resetLov(data, 'technology');
        return;
    };

    /**
     *
     * Loads 'Delivered Form' LOV.
     *
     * Called on selection of 'Material Commodity'
     *
     * @param {Object} data ViewModel object
     * @return
     */
    export let onmatCommoditySelected = function (data, selValue, matclassValue, compclassValue, compCommodityValue) {
        exports.clearPCRPercentage(data);
//CR1310
    if (commonCtx.selected && commonCtx.preferences.U4_PCR_Technology_Types && commonCtx.preferences.U4_PCR_Technology_Types.length > 0) {
        var componentType = commonCtx.selected.props.object_type.uiValues[0];
        if (commonCtx.preferences.U4_PCR_Technology_Types.includes(componentType) == true) {
            if (data.matClass.dbValue == "Plastic" && data.matCommodity.dbValue.includes('Recycled') == true) {
                data.pcrGMC.dbValue = true;      
                data.pcrGMC.uiValue = true;     
                data.pcrGMC.isEnabled = false;  
            } else {
                data.pcrGMC.isEnabled = true;                           
            }   
        }
    }
        var currentmatCommodity;
        var currentmatClass = data.matClass.dbValue;
        var currentCompCommodity = data.compCommodity.dbValue;
        var currentCompClass = data.compClass.dbValue;
        if (selValue && selValue.length > 0)
            currentmatCommodity = selValue;
        else
            currentmatCommodity = data.matCommodity.dbValue;

        if (currentmatCommodity && currentmatCommodity.length > 0) {

            var lovName1 = exports.getLOVValueIn(currentmatCommodity, data.matCommodityLOVValues);

            if (lovName1) {

                var inputData = {
                    lovName: lovName1
                };
                _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData).then(function (response) {
                    if (response) {
                        data.deliFormLOVValues = exports.convertDataToLov(response.lovpair); // set new lov
                        data.deliForm.dbValue = data.matFormResponse.props.u4_delivered_form.dbValues[0]; // set to empty
                        exports.ondeliFormSelected(data, data.deliForm.dbValue);
                        _commonUtil.logOnConsole('Delivered Form updated.');
                    }
                });
                var lExistingUOM = false;
                
                var uombasedonClassNCom = 'U4_UOM_basedon_Class_Commodity';
                if (_commonUtil.isPreferencesAvailable([uombasedonClassNCom], true)) {
                    var base_uom_values = _appCtxSvc.ctx.preferences[uombasedonClassNCom];
                    if (base_uom_values) {
                        for (var inx = 0; inx < base_uom_values.length; inx++) {
                            var str = base_uom_values[inx].trim().split(':');
                            if (str.length == 2)
                                var classncomValues = str[0].trim().split('#');
                            data.newbaseUOMLOVValues = [];
                            if (classncomValues.length == 4) {
                                if (currentCompClass == classncomValues[0] & currentCompCommodity == classncomValues[1] & currentmatClass == classncomValues[2] & currentmatCommodity == classncomValues[3]) {
                                    data.uomValues = [];
                                    var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                    var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                    data.uomValues.push({
                                            propDisplayValue: onlyUOM[0],
                                            propInternalValue: onlyUOM[0],
                                            propDisplayDescription: propDesc[1]
                                        });
                                    if(onlyUOM[0] === _appCtxSvc.ctx.mselected[0].props.u4_base_uom.dbValues[0])
                                    {
                                        lExistingUOM = true;
                                    }
                                }
                            }
                        }
                        if(!lExistingUOM)
                        {
                            exports.baseUOMCustomization(data,_appCtxSvc.ctx,selValue);
                        }
                        console.log("On MComm selection    :   ", data.uomValues);
                        if (data.uomValues.length === 0) {
                            data.baseUOMLOVValues = [];
                            _lovUtil.getLOVvalue('U4_ComponentRevision', 'u4_base_uom', 'lov_values', 'lov_value_descriptions', null, data.baseUOMLOVValues, function (serviceData) {

                                console.log('Type of change loaded');
                                data.uomValues = Array.from(data.baseUOMLOVValues);
                            });
                        }
                        //_appCtxSvc.registerCtx('baseDiffValue', baseDiffValue);
                    }
                }
            }
        } else {

            var matCommStr = '\"Material Commodity\".';

            exports.resetLov(data, 'deliForm');

            data.deliForm.lovNoValsText = noLOVtext + matCommStr;
            data.pamFrameType.lovNoValsText = noLOVtext + matCommStr;
            data.technology.lovNoValsText = noLOVtext + matCommStr;

        }

        prevUOMVal = data.uomValues;

        exports.resetLov(data, 'pamFrameType');
        exports.resetLov(data, 'technology');
        return;
    };

    /**
     *
     * Loads 'PAM Frame Type' LOV.
     *
     * Called on selection of 'Delivered Form'
     *
     * @param {Object} data ViewModel object
     * @return
     */
    export let ondeliFormSelected = function (data, selValue) {

        var currentdeliForm;
        var currentCompClass = data.compClass.dbValue;
        var currentCompCommodity = data.compCommodity.dbValue;
        var currentmatClass = data.matClass.dbValue;
        var currentmatCommodity = data.matCommodity.dbValue;
        if (selValue && selValue.length > 0)
            currentdeliForm = selValue;
        else
            currentdeliForm = data.deliForm.dbValue;

        if (currentdeliForm && currentdeliForm.length > 0 && currentdeliForm != ' ') {

            var lovName1 = exports.getLOVValueIn(currentdeliForm, data.deliFormLOVValues);

            if (lovName1) {

                var inputData = {
                    lovName: lovName1
                };
                _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData).then(function (response) {
                    if (response) {
                        data.pamFrameTypeLOVValues = exports.convertDataToLov(response.lovpair); // set new lov
                        data.pamFrameType.dbValue = data.matFormResponse.props.u4_pam_frame_type.dbValues[0]; // set to empty
                        exports.onpamFrameTypeSelected(data, data.pamFrameType.dbValue);
                        _commonUtil.logOnConsole('PAM Frame Type updated.');
                    }
                });
                if (data.uomValues.length != 0) {
                    data.uomValues = [];
                }
                var lExistingUOM = false;
                
                var uombasedonClassNCom = 'U4_UOM_basedon_Class_Commodity';
                if (_commonUtil.isPreferencesAvailable([uombasedonClassNCom], true)) {
                    var base_uom_values = _appCtxSvc.ctx.preferences[uombasedonClassNCom];
                    if (base_uom_values) {
                        for (var inx = 0; inx < base_uom_values.length; inx++) {
                            var str = base_uom_values[inx].trim().split(':');
                            if (str.length == 2)
                                var classncomValues = str[0].trim().split('#');
                            data.newbaseUOMLOVValues = [];
                            if (classncomValues.length == 3) {
                                if (currentCompClass == classncomValues[0] & currentCompCommodity == classncomValues[1] & currentdeliForm == classncomValues[2]) {
                                   // data.uomValues = [];
                                    var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                    var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                    data.uomValues.push({
                                            propDisplayValue: onlyUOM[0],
                                            propInternalValue: onlyUOM[0],
                                            propDisplayDescription: propDesc[1]
                                        });
                                    if(onlyUOM[0] === _appCtxSvc.ctx.mselected[0].props.u4_base_uom.dbValues[0])
                                    {
                                        lExistingUOM = true;
                                    }
                                }
                            } else if (classncomValues.length == 2) {
                                if (currentCompClass == classncomValues[0] & currentCompCommodity == classncomValues[1]) {
                                    //data.uomValues = [];
                                    //data.baseDiffUOM.dbValue=str[1];
                                    //data.newbaseUOMLOVValues=str[1];

                                    var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                    var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                    
                                    data.uomValues.push({
                                        propDisplayValue: onlyUOM[0],
                                        propInternalValue: onlyUOM[0],
                                        propDisplayDescription: propDesc[1]
                                    });
                                    if(onlyUOM[0] === _appCtxSvc.ctx.mselected[0].props.u4_base_uom.dbValues[0])
                                    {
                                        lExistingUOM = true;
                                    }
                                }
                                if (currentCompClass == classncomValues[0] & currentdeliForm == classncomValues[1]) {
                                    //data.uomValues = [];
                                    //data.baseDiffUOM.dbValue=str[1];
                                    //data.newbaseUOMLOVValues=str[1];

                                    var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                    var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                    data.uomValues.push({
                                        propDisplayValue: onlyUOM[0],
                                        propInternalValue: onlyUOM[0],
                                        propDisplayDescription: propDesc[1]
                                    });
                                    if(onlyUOM[0] === _appCtxSvc.ctx.mselected[0].props.u4_base_uom.dbValues[0])
                                    {
                                        lExistingUOM = true;
                                    }
                                }

                            } else if (classncomValues.length == 4) {
                                if (currentCompClass == classncomValues[0] & currentCompCommodity == classncomValues[1] & currentmatClass == classncomValues[2] & currentmatCommodity == classncomValues[3]) {
                                    //data.uomValues = [];

                                    var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                    var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                    data.uomValues.push({
                                        propDisplayValue: onlyUOM[0],
                                        propInternalValue: onlyUOM[0],
                                        propDisplayDescription: propDesc[1]
                                    });
                                    if(onlyUOM[0] === _appCtxSvc.ctx.mselected[0].props.u4_base_uom.dbValues[0])
                                    {
                                        lExistingUOM = true;
                                    }
                                }
                            } else if (classncomValues.length == 1) {
                                if (currentCompClass == str[0]) {

                                    var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                    var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                    data.uomValues.push({
                                        propDisplayValue: onlyUOM[0],
                                        propInternalValue: onlyUOM[0],
                                        propDisplayDescription: propDesc[1]
                                    });
                                    if(onlyUOM[0] === _appCtxSvc.ctx.mselected[0].props.u4_base_uom.dbValues[0])
                                    {
                                        lExistingUOM = true;
                                    }
                                }

                            }
                        }
                        if(!lExistingUOM)
                        {
                            exports.baseUOMCustomization(data,_appCtxSvc.ctx,selValue);
                        }
                        console.log("On DF selection    :   ", data.uomValues);
                        if (data.uomValues.length === 0) {
                            data.baseUOMLOVValues = [];
                            _lovUtil.getLOVvalue('U4_ComponentRevision', 'u4_base_uom', 'lov_values', 'lov_value_descriptions', null, data.baseUOMLOVValues, function (serviceData) {

                                console.log('Type of change loaded');
                                data.uomValues = Array.from(data.baseUOMLOVValues);
                            });
                        }
                        //_appCtxSvc.registerCtx('baseDiffValue', baseDiffValue);
                    }
                }
            }

        } else {

            var delFormStr = '\"Delivered Form\".';

            exports.resetLov(data, 'pamFrameType');

            data.pamFrameType.lovNoValsText = noLOVtext + delFormStr;
            data.technology.lovNoValsText = noLOVtext + delFormStr;

        }
        exports.resetLov(data, 'technology');
        return;
    };

    /**
     *
     * Loads 'Technology' LOV.
     *
     * Called on selection of 'PAM Frame Type'
     *
     * @param {Object} data ViewModel object
     * @return
     */
    export let onpamFrameTypeSelected = function (data, selValue) {

        exports.clearPCRPercentage(data);

       var currentpamFrameType;
        var currentpamComponentClass;

        if (selValue && selValue.length > 0)
        {
            currentpamFrameType = selValue;
            currentpamComponentClass = selValue;
        }
        else
        {
            currentpamFrameType = data.pamFrameType.dbValue;
            currentpamComponentClass = data.compClass.dbValue;
        }

        if (currentpamFrameType && currentpamFrameType.length > 0) {

            var lovName1 = exports.getLOVValueIn(currentpamFrameType, data.pamFrameTypeLOVValues);

            if (lovName1) {

                var inputData = {
                    lovName: lovName1
                };
                _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData).then(function (response) {
                    if (response) {
                        data.technologyLOVValues = exports.convertDataToLov(response.lovpair); // set new lov
                        data.technology.dbValue = data.compRevResponse.props.u4_technology.dbValues[0]; // set to empty
                        exports.consumerunitTypeUpdate(data, commonCtx, data.technology.dbValue);
                        _commonUtil.logOnConsole('Technology updated.');
                        //exports.changeButtonVisibilityCheck(data, commonCtx);
                    }
                });
            }
        } else {

            var pamframeStr = '\"PAM Frame Type\".';

            exports.resetLov(data, 'technology');

            data.technology.lovNoValsText = noLOVtext + pamframeStr;
        }
        return;
    };

    /**
     *
     * Called on selection of 'Technology'
     *
     * set consumerUnit based on 'Technology' and 'PAM Frame Type'
     *
     * @param {Object} data  ViewModel object
     * @param {Object} ctx ctx Object
     *
     * @return
     */
    export let consumerunitTypeUpdate = function (data, ctx, selValue) {

        if (selValue && selValue.length > 0) {

            if (data.compRevResponse.props.u4_consumer_unit.dbValues[0] == 1)
                data.consumerUnit.dbValue = true;
            else
                data.consumerUnit.dbValue = false;

        } else {

            var prefValue = [];
            var prefValue2 = [];
            var story = [];
            var frame = data.pamFrameType.dbValue;
            var technoValue = data.technology.dbValue;

            if (technoValue && technoValue.length > 0) {

                var prefName1 = 'U4_GMCPackComponentType';
                var prefName2 = 'UL_primary_packaging_list';

                prefValue = ctx.preferences[prefName1];
                prefValue2 = ctx.preferences[prefName2];

                if (_commonUtil.isPreferencesAvailable([prefName1, prefName2], true)) {

                    for (var i = 0; i < prefValue.length; i++) {
                        story = prefValue[i].split('#');

                        if ((story.length == 3) && (story[0] == frame) && (story[1] == technoValue)) {
                            //   _commonUtil.logOnConsole('found the respective technovalue');
                            var techFromPref = story[2];
                        }
                    }
                    let found = false;
                    for (var j = 0; j < prefValue2.length; j++) {
                        if (prefValue2[j] == techFromPref) {
                            found = true;
                            break;
                        }

                    }

                    data.consumerUnit.dbValue = found;
                    exports.changeButtonVisibilityCheck(data, commonCtx);

                }
            }

            showTechnologyInfo(technoValue);
            data.drwsheets = showNewTechnologyDrwSheets(technoValue, techFromPref);
        }
        console.log("Data drwsheet: " + data.drwsheets);
        //exports.changeButtonVisibilityCheck(data, ctx);
        return;
    }

    export let changeButtonVisibilityCheck = function (data, ctx) {

        if (data.compRevResponse.props.u4_technology.dbValues[0] == data.technology.dbValue)
            globalConditions.ChangeButtonEnability = false;
        else
            globalConditions.ChangeButtonEnability = true;

        return;
    }

    /**
     *
     * converts LOV string values into objects
     *
     * @param {Object} lovPair LOV name and Dependent LOV realname pair
     * @return {Array} array lov objects
     */
    export let convertDataToLov = function (lovPair) {

        var lov = [];
        lov.push({
            propDisplayValue: '',
            propDisplayDescription: '',
            propInternalValue: '',
            propDep: ''
        });
        if (lovPair !== undefined)
        {
            for (var i in lovPair) {
                // sub LOV will be stored in 'propDep' property
                var val__ = lovPair[i].lovvalue;
                var subLov__ = lovPair[i].lovname;
                lov.push({
                    propDisplayValue: val__,
                    propDisplayDescription: '',
                    propInternalValue: val__,
                    propDep: subLov__
                });
        }

        }

        return lov;
    };

    /**
     *
     * Resets property and respective LOV to empty.
     *
     * @param {Object} data ViewModel object
     * @param {String} propString property to reset
     *
     * @return
     */
    export let resetLov = function (data, propString) {

        data[(propString + 'LOVValues')] = [''];
        data[propString].dbValue = '';
        data[propString].displayValues = undefined;
        data[propString].uiValue = undefined;
        data[(propString + 'LOVValues')] = [];

        return;
    };

    /**
     *
     * Finds selected LOV's dependent LOV
     *
     * @param {String} selected selected LOV value
     * @param {Array} lovArray LOV array
     *
     * @return {String} Dependent LOV
     */
    export let getLOVValueIn = function (selected, lovArray) {

        for (var i in lovArray) {

            if (lovArray[i].propDisplayValue === selected) {
                //   _commonUtil.logOnConsole('Value matched >> ' + lovArray[i].propDep);
                return lovArray[i].propDep;
            }
        }
        //   _commonUtil.logOnConsole('Match not found');
        return;
    };

    /**
     *
     * Shows information about selected technology as notification based on preference
     *
     * @param {String} technoValue selected technology
     *
     *
     */
    var showTechnologyInfo = function (technoValue) {

        if (technoValue) {
            var techDescPref = 'U4_TechnologyDescription';
            if (technology_description == undefined && _commonUtil.isPreferencesAvailable([techDescPref], true)) {
                technology_description = {};
                var technology_description_values = _appCtxSvc.ctx.preferences[techDescPref];
                if (technology_description_values) {
                    for (var inx = 0; inx < technology_description_values.length; inx++) {
                        var str = technology_description_values[inx].trim().split('#');
                        if (str.length == 2)
                            technology_description[str[0]] = str[1];
                    }
                }

            }
            if (technology_description && technology_description[technoValue] != undefined) {
                var desc_value = technology_description[technoValue];
                if (desc_value)
                    _messagingSvc.showError(desc_value);
            }

        }

    }

    var showNewTechnologyDrwSheets = function (technoValue, techFromPref) {
        var story1 = [];
        var prefValue1 = [];
        var techrevisionName = techFromPref;

        techrevisionName += 'Revision';
        console.log("Tech pref appending revision: " + techrevisionName);
        if (technoValue) {
            //var prefName1 = 'U4_GMCPackComponentType';
            var prefName1 = 'U4_GMC_Component_CAD_Templates';

            //prefValue = ctx.preferences[prefName1];
            prefValue1 = _appCtxSvc.ctx.preferences[prefName1];
            console.log("CAD Comp values: " + prefValue1);
            var techrevSheetSizes = [];
            var splitSheetSize = [];
            var techrevSheetSize = [];
            for (var i = 0; i < prefValue1.length; i++) {
                if (prefValue1[i]) {
                    console.log("Each value from CAD pref: " + prefValue1[i]);
                    techrevSheetSizes = prefValue1[i].split(':');
                    if (techrevisionName === techrevSheetSizes[0]) {
                        splitSheetSize = techrevSheetSizes[1].split(',');
                        for (var j = 0; j < splitSheetSize.length; j++) {
                            techrevSheetSize.push(splitSheetSize[j]);

                        }
                    }

                }
            }

            if (techrevSheetSize !== 'undefined' && techrevSheetSize.length > 0) {
                console.log("Sheet Size from more length: " + techrevSheetSize);
                //return techrevSheetSize;
            } else {

                //Display message about no drawing sheet available..
            }

        }
        return techrevSheetSize;
    }
    /**
     *
     * Check selected commodity is present in a preference
     *
     * @param {String} commodity selected commodity
     * @param {Array} prefValue preference value
     *
     */
    var updateFormPropertyValue = function (commodity, prefValue) {

        if (prefValue) {
            for (var i = 0; i < prefValue.length; i++) {

                if (prefValue[i]) {

                    var val = prefValue[i].trim().split('#');

                    if (val.length == 2)
                        if (commodity.toUpperCase() === val[0].toUpperCase())
                            return val[1];
                }
            }
        }
        return '';
    }

    /**
     *
     * Check selected frame and technology is present in a preference
     *
     * @param {String} frame selected frame
     * @param {String} tech selected technology
     * @param {Array} prefValue preference value
     * @return value from preference after evaluation with technology and frame value
     *
     */
    var getDesignComponentType = function (frame, tech, prefValue) {

        for (var i = 0; i < prefValue.length; i++) {
            var str = prefValue[i].trim().split('#');

            if (str && str.length == 3 && (str[0].toUpperCase() === frame.toUpperCase()) && (str[1].toUpperCase() === tech.toUpperCase()))
                return str[2];
        }

        return undefined;
    }

    /**
     *
     * Validates properties selected with preference values
     *
     * @param {Object} data ViewModel object
     * @param {Object} ctx ctx Object
     * @return true allows to proceed pack component creation
     *
     */
    var createPackPreCheck = function (data, ctx) {

        var compAbbPref = 'U4_GMC_Component_Abbreviation';
        var compMatPref = 'U4_GMC_Material_Abbreviation';
        var packCompTypePref = 'U4_GMCPackComponentType';

        if (_commonUtil.isPreferencesAvailable([compAbbPref, compMatPref, packCompTypePref], true)) {

            var cDesc = updateFormPropertyValue(data.compCommodity.dbValue, ctx.preferences[compAbbPref]);
            var mDesc = updateFormPropertyValue(data.matCommodity.dbValue, ctx.preferences[compMatPref]);

            if (cDesc && mDesc && cDesc.length != 0 && mDesc.length != 0) {

                var frame = data.pamFrameType.dbValue;
                var tech = data.technology.dbValue;

                var compType = getDesignComponentType(frame, tech, ctx.preferences[packCompTypePref]);

                if (compType && compType.length >= 1) {
                    return true;
                } else {
                    var str = '\nFor selected Pam Frame Type ( ' + frame + ' ) & Technology ( ' + tech + ' )';
                    _messagingSvc.showError('Pack Component Type Missing in Preference.' + str);
                    return false;
                }

            } else {
                if (mDesc.length == 0)
                    _messagingSvc.showError('Material Description Value is Missing in Preference <U4_GMC_Material_Abbreviation>.');
                else
                    _messagingSvc.showError('Component Description Value is Missing in Preference <U4_GMC_Component_Abbreviation>.');

                return false;
            }

        } else {
            return false;
        }

    }

    export let metaDataChangeAction = async function (data, ctx, conditions) {

        var designName = "";
        
        if(conditions.isDesignNameVisible===true && data.designname.dbValue)
        {
            designName = data.designname.dbValue;
        }                
        var queryInput = {
            inputCriteria: [{
                queryNames: ["Item Revision..."]
            }
            ]

        }

        var queryResp =  await _soaSvc.post('Query-2010-04-SavedQuery', 'findSavedQueries', queryInput);
        console.log(queryResp);
        if (queryResp.savedQueries.length != 1) 
        {
            _messagingSvc.showError('Component MetaDataChange failed...ItemRevision Query Not found');
            _commonUtil.closeTNIpanel();
            return;
        }

        var queryInput1 = {
            query: queryResp.savedQueries[0],
            entries: ["Item ID", "Type"],
            values: [ctx.selected.props.item_id.dbValues[0], data.technology.dbValue],
            limit: 0
        }
        var queryResp1 =  await _soaSvc.post('Query-2006-03-SavedQuery', 'executeSavedQuery', queryInput1); 
                
        console.log("nfound:" + queryResp1.nFound);

        var itemRevInput = {
            itemRevisions: [ctx.selected]
        }
        var multipleItemTypeExists = false;
        multipleItemTypeExists = verifyMultipleItemType(data, ctx, queryResp1);
        if (multipleItemTypeExists == true)
        {
            _messagingSvc.showError('Same Technology Already exists with same ID...Cannot change to this Technology');
            return;
        }

        if (queryResp1.nFound === 0) 
        {
            try 
            {

                //   Disables create button once it is clicked. Not allowing user to click again.
                var buttonClone = _.clone(data.buttonEnable); 
                buttonClone.dbValue = false; 
                data.dispatch({path:'data.buttonEnable', value:buttonClone}); 
                var inputData = {
                    'selectedCompRev': {
                        'uid': ctx.selected.uid,
                        'type': ctx.selected.type
                    },
                    'leadSize': data.leadSize.dbValue,
                    'isCreate': true,
                    'packProps': {
                        'packProps': {
                            'u4_component_class': data.compClass.dbValue,
                            'u4_component_class_shape': data.compClass.dbValue,
                            'u4_component_class_mfgpro': data.compClass.dbValue,
                            'u4_component_class_wv': data.compClass.dbValue,
                            'u4_component_commodity': data.compCommodity.dbValue,
                            'u4_material_class': data.matClass.dbValue,
                            'u4_material_commodity': data.matCommodity.dbValue,
                            'u4_cu_weight_volume': data.cuWeightVolume.dbValue,
                            'u4_manufacturing_processes': data.manufacProcessess.dbValue,
                            'u4_shape': data.shape.dbValue,
                            'u4_delivered_form': data.deliForm.dbValue,
                            'u4_pam_frame_type': data.pamFrameType.dbValue,
                            'u4_technology': data.technology.dbValue,
                            'u4_size': data.size.dbValue,
                            'u4_unique_descriptor': data.uniqDescriptor.dbValue,
                            'object_desc': data.objDesc.dbValue,
                            'u4_reason_for_issue': data.reasonforissue.dbValue,
                            'u4_consumer_unit': data.consumerUnit.dbValue.toString(),
                            'u4_component_type': data.componentType.dbValue,
                            'u4_design_name': designName,
                            'item_id': ctx.selected.props.item_id.dbValues[0],
                            'u4_base_uom': data.baseDiffUOM.dbValue

                        }
                    }
                };

                if (conditions.isPCRSectionVisible == true) {
                    inputData.packProps.packProps["u4_contains_pcr"] = data.pcrGMC.dbValue == true ? "Yes" : "No";

                    if (data.pcrGMC.dbValue == false)
                    {
                        inputData.packProps.packProps["u4_pcr_percentage"] = "0";
                    }
                    if (data.pcr_percentageGMC.dbValue)
                        inputData.packProps.packProps["u4_pcr_percentage"] = data.pcr_percentageGMC.dbValue;
                    else
                        inputData.packProps.packProps["u4_pcr_percentage"] = "";
                } else {
                    inputData.packProps.packProps["u4_contains_pcr"] = "";
                    inputData.packProps.packProps["u4_pcr_percentage"] = "";
                }

                _commonUtil.logOnConsole('\nProperties of new pack component item : ' + JSON.stringify(inputData.packProps.packProps));

                var response = await _soaSvc.post('AWC-2016-10-CompMetaDataChange', 'metaDataChange', inputData);

                try {
                    var gmccreatedItem = response.modelObjects[response.created[0]];
                    data.gmccreatedItem1 = [{
                        uid: response.modelObjects[response.created[0]].uid
                    }
                    ];
                    data.srcSelectedItemRev = [{
                        uid: ctx.selected.uid
                    }
                    ];
                    data.newCreatedItem = response.modelObjects[response.created[0]].props.object_string.dbValues[0];
                    var sourceDestObjs = [];

                    sourceDestObjs.push(ctx.selected);
                    sourceDestObjs.push(response.modelObjects[response.created[0]]);

                    var objName = ctx.selected.props.object_name.dbValues[0];

                    exports.getRelationProp(sourceDestObjs, ctx.selected.uid, ctx.selected.type, objName);

                    exports.transferDatasets(sourceDestObjs, function () {
                        //_commonUtil.closeTNIpanel();
                        //_messagingSvc.reportNotyMessage(data, data._internal.messages, 'metaDataChangeSuccessMsg');
                        //var objs = _commonUtil.getSelectedObjsToRefresh(ctx);
                        //_commonUtil.refreshObjectsInUI(objs);

                    });
                    var selectedsheets = [];
                    if (data.A0.dbValue) {
                        selectedsheets.push('A0');
                    }
                    if (data.A1.dbValue) {
                        selectedsheets.push('A1');
                    }
                    if (data.A2.dbValue) {
                        selectedsheets.push('A2');
                    }
                    if (data.A3.dbValue) {
                        selectedsheets.push('A3');
                    }
                    if (data.A4.dbValue) {
                        selectedsheets.push('A4');
                    }
                    var inputData = {
                        "comprev": {

                            "uid": gmccreatedItem.uid,
                            "type": gmccreatedItem.type

                        },
                        //getPropsToGet(sourceObj);
                        "dwgsheet": selectedsheets
                    };
                    var cadDataResponse = await _soaSvc.post('AWC-2016-10-CADComponent', 'addCadData', inputData);
                    if (cadDataResponse) {
                        console.log("Successfully copied dataset");
                        //data.newtechdatasetuid = cadDataResponse.datasetName.uiValues;
                    }
                                        
                    copydrawingandattrNX(data.gmccreatedItem1, data.srcSelectedItemRev);

                    _commonUtil.closeTNIpanel();
                    _messagingSvc.reportNotyMessage(data, data._internal.messages, 'metaDataChangeSuccessMsg');
                    var objs = _commonUtil.getSelectedObjsToRefresh(ctx);
                    _commonUtil.refreshObjectsInUI(objs);
                } 
                catch (error) 
                {
                    _messagingSvc.showError('Component MetaDataChange failed...');
                }                            
            } 
            catch (error) 
            {
                _messagingSvc.showError('Something went wrong in metaDataChange action.');
                var buttonClone = _.clone(data.buttonEnable); 
                buttonClone.dbValue = true; 
                data.dispatch({path:'data.buttonEnable', value:buttonClone});
            }
        }
        else 
        {
            // add condition to check for same item type
            var buttonClone = _.clone(data.buttonEnable); 
            buttonClone.dbValue = false; 
            data.dispatch({path:'data.buttonEnable', value:buttonClone}); 

            var inputData = {
                'selectedCompRev': {
                    'uid': ctx.selected.uid,
                    'type': ctx.selected.type
                },
                'leadSize': data.leadSize.dbValue,
                'isCreate': false,
                'packProps': {
                    'packProps': {
                        'u4_component_class': data.compClass.dbValue,
                        'u4_component_class_shape': data.compClass.dbValue,
                        'u4_component_class_mfgpro': data.compClass.dbValue,
                        'u4_component_class_wv': data.compClass.dbValue,
                        'u4_component_commodity': data.compCommodity.dbValue,
                        'u4_material_class': data.matClass.dbValue,
                        'u4_material_commodity': data.matCommodity.dbValue,
                        'u4_cu_weight_volume': data.cuWeightVolume.dbValue,
                        'u4_manufacturing_processes': data.manufacProcessess.dbValue,
                        'u4_shape': data.shape.dbValue,
                        'u4_delivered_form': data.deliForm.dbValue,
                        'u4_pam_frame_type': data.pamFrameType.dbValue,
                        'u4_technology': data.technology.dbValue,
                        'u4_size': data.size.dbValue,
                        'u4_unique_descriptor': data.uniqDescriptor.dbValue,
                        'object_desc': data.objDesc.dbValue,
                        'u4_reason_for_issue': data.reasonforissue.dbValue,
                        'u4_consumer_unit': data.consumerUnit.dbValue.toString(),
                        'u4_component_type': data.componentType.dbValue,
                        'u4_design_name': designName,
                        'u4_base_uom': data.baseDiffUOM.dbValue

                    }
                }
            };

            if (conditions.isPCRSectionVisible == true) {
                inputData.packProps.packProps["u4_contains_pcr"] = data.pcrGMC.dbValue == true ? "Yes" : "No";
                 if (data.pcr_percentageGMC.dbValue)
                    inputData.packProps.packProps["u4_pcr_percentage"] = data.pcr_percentageGMC.dbValue;
                else
                    inputData.packProps.packProps["u4_pcr_percentage"] = "";
            } else {
                inputData.packProps.packProps["u4_contains_pcr"] = "";
                inputData.packProps.packProps["u4_pcr_percentage"] = "";
            }                        
            var compmetaResponse = await _soaSvc.post('AWC-2016-10-CompMetaDataChange', 'metaDataChange', inputData);
                
            console.log(compmetaResponse);
            _commonUtil.closeTNIpanel();
            var objs = _commonUtil.getSelectedObjsToRefresh(ctx);
            _commonUtil.refreshObjectsInUI(objs);
            _messagingSvc.reportNotyMessage(data, data._internal.messages, 'metaDataChangeUpdateMsg');                  
                    
        }
    }

   

    
    export let precheckCompMetaDataChange = function (ctx, data) {

        console.log("Entering precheckCompMetaDataChange");
        var deferred = awPromiseService.instance.defer();
        console.log("ctx.GMCChangePreCheck : ",ctx.GMCChangePreCheck);
        if (ctx.GMCChangePreCheck === undefined) {
            _appCtxSvc.registerCtx('GMCChangePreCheck', false);
            deferred.resolve();
        }
        deferred.resolve();
        _appCtxSvc.updateCtx('GMCChangePreCheck', true);
        console.log("Leaving precheckCompMetaDataChange");
        return deferred.promise;
    }

    export let getRelationProp = function (paramObjects, itemUid, itemType, itemName) {

        getRelationProperties(itemType, function (propsArray) {
            exports.UpdatePackProproties(propsArray, paramObjects, itemUid, itemName)
        });
    }

    export let UpdatePackProproties = function (propsArray, paramObjects, itemUid, itemName) {

        console.log("\n Start of UpdateProperties");

        /*
         * This methods will return the custom relation properties for the specific ItemType
         */
        //var propsArray = getRelationProperties(itemType);

        var inputData = {
            objects: paramObjects,
            attributes: propsArray
        };

        _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (getPropResp) {
            console.log(getPropResp);
            var x;
            var propsInfo = {};
            var propsInfoArray = [];

            if (inputData.attributes.length > 0) {
                for (x in inputData.attributes) {

                    if (getPropResp.modelObjects[getPropResp.plain[0]].props[inputData.attributes[x]] != undefined)
                        var sourceUIDs = getPropResp.modelObjects[getPropResp.plain[0]].props[inputData.attributes[x]].dbValues;

                    if (getPropResp.modelObjects[getPropResp.plain[0]].props[inputData.attributes[x]] != undefined)
                        var sourceNames = getPropResp.modelObjects[getPropResp.plain[0]].props[inputData.attributes[x]].uiValues;

                    if (getPropResp.modelObjects[getPropResp.plain[1]].props[inputData.attributes[x]] != undefined)
                        var destUIDs = getPropResp.modelObjects[getPropResp.plain[1]].props[inputData.attributes[x]].dbValues;

                    if (getPropResp.modelObjects[getPropResp.plain[1]].props[inputData.attributes[x]] != undefined)
                        var destNames = getPropResp.modelObjects[getPropResp.plain[1]].props[inputData.attributes[x]].uiValues;

                    if (sourceNames.length > 0 && destNames.length > 0 && sourceUIDs.length > 0 && destUIDs.length > 0) {
                        for (var i = 0; i < sourceNames.length; i++) {
                            console.log("\n" + i + ":" + sourceNames[i]);
                            for (var k = 0; k < destNames.length; k++) {
                                if (sourceNames[i] == destNames[k]) {
                                    var sourceObj = getPropResp.modelObjects[sourceUIDs[i]];
                                    var destObj = getPropResp.modelObjects[destUIDs[k]];
                                    var props = getPropsToGet(sourceObj);
                                    var value = [];
                                    var propsVecVal = [];
                                    for (var j = 0; j < props.length; j++) {
                                        if (sourceObj.props[props[j]] != undefined && destObj.props[props[j]] != undefined) {
                                            if (sourceObj.props[props[j]].dbValues[0] != null && destObj.props[props[j]].dbValues[0] == null) {
                                                propsVecVal.push({
                                                    name: props[j],
                                                    values: sourceObj.props[props[j]].dbValues
                                                })
                                            }
                                        }
                                    }
                                    console.log(propsVecVal);

                                    var propsInfo = {};
                                    propsInfo.object = destObj;
                                    propsInfo.vecNameVal = propsVecVal;
                                    propsInfoArray.push(propsInfo);

                                }
                            }
                        }
                    }
                }

            }
            /*
             * This methods will set the properties
             */
            setPropertiesAction(propsInfoArray, itemUid, itemName);

            console.log("\n End of UpdateProperties");
        });

    }

    export let panelCloseAction = function (ctx) {
        //commented to not to throw error if ctx.selected is undefined.
        //console.log(ctx.selected.uid);
        //console.log(ctx.gmcItemID);

        //updated condition to not throw error if ctx.selected is undefined.

        if (ctx.selected && ctx.selected.uid != undefined && ctx.gmcItemID != undefined) {
            if (ctx.selected.uid != ctx.gmcItemID) {
                _commonUtil.closeTNIpanel();
            }
        }
    }

    export let UpdateProperties = function (ctx) {

        var queryInput = {
            inputCriteria: [{
                queryNames: ["Item Revision..."]
            }
            ]

        }

        _soaSvc.post('Query-2010-04-SavedQuery', 'findSavedQueries', queryInput).then(function (queryResp) {
            console.log(queryResp);
            if (queryResp.savedQueries.length === 1) {

                var queryInput = {
                    query: queryResp.savedQueries[0],
                    entries: ["Item ID", "Type"],
                    values: [ctx.selected.props.item_id.dbValues[0], ctx.selected.type],
                    limit: 0
                }
                _soaSvc.post('Query-2006-03-SavedQuery', 'executeSavedQuery', queryInput).then(function (queryResp) {
                    console.log(queryResp);
                    if (queryResp.nFound === 0) {
                        //create action
                    }
                });
            }
        });
    }

    var getPropsToGet = function (Object) {

        var startswith = 'u4_';
        var startswithCaps = 'U4_';
        var skipValidationRule = 'u4_validation_rule';
        var u4props = [];
        for (var prop in Object.modelType.propertyDescriptorsMap) {
            if (((prop.substring(0, startswith.length) === startswith) || (prop.substring(0, startswithCaps.length) === startswithCaps)) && prop != skipValidationRule) {
                u4props.push(prop);
            }
        }
        return u4props;
    }

    var getRelationProperties = function (itemType, callback) {

        console.log("\n getRelationProperties: " + itemType);

        var typeData = {
            'typeNames': [itemType]
        };

        var propsArray = [];
        _soaSvc.post('Core-2015-10-Session', 'getTypeDescriptions2', typeData).then(function (typeResp) {
            console.log(typeResp);
            for (var l = 0; l < typeResp.types[0].propertyDescriptors.length; l++) {
                var propName = typeResp.types[0].propertyDescriptors[l].name;
                if (propName.startsWith("U4_") && propName.endsWith("Relation")) {
                    propsArray.push(propName);
                }

            }
            callback(propsArray);
        });
        //return propsArray;


    }

    var setPropertiesAction = function (propsInfoArray, itemUid, itemName) {

        var setPropertiesInputData = {
            info: propsInfoArray,
            options: []
        };
        _soaSvc.post('Core-2010-09-DataManagement', 'setProperties', setPropertiesInputData).then(function (setPropertiesResponse) {

            _commonUtil.initiateUnileverWorkflow(itemName, itemUid, "Baseline_Rejected", function () {
                var objectsArray = [];
                for (var l = 0; l < propsInfoArray.length; l++) {
                    objectsArray.push(propsInfoArray[l].object);
                }
                var refreshData = {
                    objects: objectsArray
                }

                _soaSvc.post('Core-2007-01-DataManagement', 'refreshObjects', refreshData).then(function (refreshDataResponse) {
                    console.log(refreshDataResponse)
                    console.log("\n setproperties action");

                });

            });

        });

    }

    export let transferDatasets = function (sourceDestObjs, callback) {

        var prefValues = [];
        var prefName = ["AWC_CompMetaDataChange_DatasetTypeList"];
        var prefScope = "SITE";

        if (prefName && prefScope) {

            var prefInput = {
                preferenceNames: [{
                    scope: prefScope,
                    names: prefName
                }
                ]
            }
            _soaSvc.post('Core-2010-04-Session', 'getPreferences2', prefInput).then(function (prefResponse) {
                prefValues = prefResponse.preferences[0].values;
                getRelatedDatasets(prefValues, sourceDestObjs);
                callback();
            });

        } else
            console.log("\n Something went wrong in the input parameter...");

    }

    var getRelatedDatasets = function (prefValues, sourceDestObjs) {

        var dataTypeLists = getDataTypesList(prefValues);

        var grmInputData = {
            primaryObjects: [sourceDestObjs[0]],
            pref: {
                expItemRev: false,
                info: [{
                    relationName: "",
                    objectTypeNames: dataTypeLists
                }
                ]
            }

        }

        _soaSvc.post('Core-2007-06-DataManagement', 'expandGRMRelationsForPrimary', grmInputData).then(function (grmResponse) {
            //removeRefDatasets(grmResponse,sourceDestObjs,dataTypeLists);
            copyRefDatasets(grmResponse, sourceDestObjs, dataTypeLists);
        });
    }

    var copyRefDatasets = function (grmResponse, sourceDestObjs, dataTypeLists) {

        var createInput = [];
        var saveInput = [];
        var relInput = [];

        for (var k = 0; k < grmResponse.ServiceData.plain.length; k++) {

            if (grmResponse.ServiceData.modelObjects[grmResponse.ServiceData.plain[k]].modelType.parentTypeName === "Dataset") {
                //var datasetName = sourceDestObjs[1].props.item_id.dbValues[0] + "/" + sourceDestObjs[1].props.item_revision_id.dbValues[0];
                var datasetName = sourceDestObjs[0].props.item_id.dbValues[0] + "/" + sourceDestObjs[1].props.item_revision_id.dbValues[0];

                var saveAsIn = {
                    targetObject: grmResponse.ServiceData.modelObjects[grmResponse.ServiceData.plain[k]],
                    inputPropValues: {
                        "object_name": [datasetName]
                    }

                }

                var relIn = {
                    target: sourceDestObjs[1],
                    propertyName: "IMAN_specification",
                    relate: true

                }

                saveInput.push(saveAsIn);
                relInput.push(relIn);

                /*var createData = {
                clientId: "AWClient",
                relationType: prefMap.get(grmResponse.ServiceData.modelObjects[grmResponse.ServiceData.plain[k]].type),
                primaryObject: {
                uid: sourceDestObjs[1].uid,
                type: sourceDestObjs[1].type
                },
                secondaryObject: {
                uid: grmResponse.ServiceData.modelObjects[grmResponse.ServiceData.plain[k]].uid,
                type: grmResponse.ServiceData.modelObjects[grmResponse.ServiceData.plain[k]].type
                }
                }

                createInput.push(createData);*/

            }

        }

        /*var createRelInput = {
        input: createInput
        }

        _soaSvc.post('Core-2006-03-DataManagement', 'createRelations', createRelInput).then(function (createRelResponse) {
        console.log(createRelResponse);
        });*/

        var saveAsInput = {
            iVecSoaSavAsIn: saveInput,
            iVecSoaRelteInfoIn: relInput
        }
        _soaSvc.post('Core-2014-10-DataManagement', 'saveAsObjectsAndRelate', saveAsInput).then(function (saveAsResponse) {
            console.log(saveAsResponse);
        });

    }

    var removeRefDatasets = function (grmResponse, sourceDestObjs, dataTypeLists) {

        var removeInput = [];
        var createInput = [];

        for (var k = 0; k < grmResponse.ServiceData.plain.length; k++) {

            if (grmResponse.ServiceData.modelObjects[grmResponse.ServiceData.plain[k]].modelType.parentTypeName === "Dataset") {

                /*var removeData = {
                clientId: "AWClient",
                relationType: prefMap.get(grmResponse.ServiceData.modelObjects[grmResponse.ServiceData.plain[k]].type),
                primaryObject: {
                uid: sourceDestObjs[0].uid,
                type: sourceDestObjs[0].type
                },
                secondaryObject: {
                uid: grmResponse.ServiceData.modelObjects[grmResponse.ServiceData.plain[k]].uid,
                type: grmResponse.ServiceData.modelObjects[grmResponse.ServiceData.plain[k]].type
                }
                }*/

                var createData = {
                    clientId: "AWClient",
                    relationType: prefMap.get(grmResponse.ServiceData.modelObjects[grmResponse.ServiceData.plain[k]].type),
                    primaryObject: {
                        uid: sourceDestObjs[1].uid,
                        type: sourceDestObjs[1].type
                    },
                    secondaryObject: {
                        uid: grmResponse.ServiceData.modelObjects[grmResponse.ServiceData.plain[k]].uid,
                        type: grmResponse.ServiceData.modelObjects[grmResponse.ServiceData.plain[k]].type
                    }
                }

                //removeInput.push(removeData);
                createInput.push(createData);

            }

        }

        /*var deleteData = {
        input: removeInput
        }*/

        var createRelInput = {
            input: createInput
        }

        _soaSvc.post('Core-2006-03-DataManagement', 'createRelations', createRelInput).then(function (createRelResponse) {
            console.log(createRelResponse);
        });

    }

    var addRefDatasets = function (createInput) {

        var createRelInput = {
            input: createInput
        }

        _soaSvc.post('Core-2006-03-DataManagement', 'createRelations', createRelInput).then(function (createRelResponse) {
            console.log(createRelResponse);
        });
    }

    var getDataTypesList = function (prefValues) {

        var dataTypeList = [];

        for (var i = 0; i < prefValues.length; i++) {
            var valueArr = prefValues[i].split(":");
            dataTypeList.push(valueArr[0]);
            prefMap.set(valueArr[0], valueArr[1]);
        }
        return dataTypeList;
    }

    var copydrawingandattrNX = function (newTechItemRev, srcselectedItemRev) {

        var createDispatcherRequestInput = {
            "inputs": [{
                "providerName": "UNILEVER",
                "serviceName": "ULExportDataAndRunJournal",
                "primaryObjects": newTechItemRev,
                "secondaryObjects": srcselectedItemRev,
                "priority": 3,
            }
            ]

        };

        _soaSvc.post('Core-2008-06-DispatcherManagement', 'createDispatcherRequest', createDispatcherRequestInput).then(function (createDispatcherResponse) {
            console.log(createDispatcherResponse);
        });
    }

    /*export let clearPCRPercentage = function (data) {
        data.pcr_percentageGMC.propertyRequiredText = "";
        data.pcr_percentageGMC.uiValue = "";
        data.pcr_percentageGMC.dbValue = "";
        if (compRevResponse && compRevResponse.props && compRevResponse.props.u4_contains_pcr &&
            compRevResponse.props.u4_pcr_percentage && compRevResponse.props.u4_contains_pcr.dbValues.length > 0) {
            var contains_pcr = compRevResponse.props.u4_contains_pcr.dbValues[0] == "Yes" ? true : false;
            if (data.pcrGMC.dbValue == contains_pcr) {
                data.pcr_percentageGMC.dbValue = compRevResponse.props.u4_pcr_percentage.dbValues[0];
                data.pcr_percentageGMC.uiValue = compRevResponse.props.u4_pcr_percentage.dbValues[0];
            }
        }
        if (data.pcrGMC.dbValue == true && data.matClass.dbValue=="Plastic" ) {
            if (data.matCommodity.dbValue.includes('Recycled') == true) {
                data.pcr_percentageGMC.propertyRequiredText = "Enter Only 100";
                data.pcr_percentageGMC.uiValue = "100";
                data.pcr_percentageGMC.dbValue = "100";
            }
            else {
                data.pcr_percentageGMC.propertyRequiredText = "100 is not allowed";
            }
        }
    };*/
    export let clearPCRPercentage = function (data) {

        // set to empty value    
        //setvmoValue(data,data.pcr_percentageGMC,"data.pcr_percentageGMC","","");

        var requiredText ="";
        var percentageVal = "";
        data.pcrGMC.isEnabled = true;

       // var setvmoValue = function(data,vmo,propname,value,propertyRequiredText)
        if (data.compRevResponse && data.compRevResponse.props && data.compRevResponse.props.u4_contains_pcr &&
            data.compRevResponse.props.u4_pcr_percentage && data.compRevResponse.props.u4_contains_pcr.dbValues.length > 0) 
            {
            var contains_pcr = data.compRevResponse.props.u4_contains_pcr.dbValues[0] == "Yes" ? true : false;
            if (data.pcrGMC.dbValue == contains_pcr) {
                //data.pcr_percentageGMC.dbValue = compRevResponse.props.u4_pcr_percentage.dbValues[0];
                //data.pcr_percentageGMC.uiValue = compRevResponse.props.u4_pcr_percentage.dbValues[0];
                percentageVal = data.compRevResponse.props.u4_pcr_percentage.dbValues[0]
                //setvmoValue(data,data.pcr_percentageGMC,"data.pcr_percentageGMC",currentval,"");
            }

        }
        if (data.matClass.dbValue=="Plastic" ) {
            if (data.matCommodity.dbValue.includes('Recycled') == true) {
                //data.pcr_percentageGMC.propertyRequiredText = "Enter Only 100";
                //data.pcr_percentageGMC.uiValue = "100";
                //data.pcr_percentageGMC.dbValue = "100";
                //setvmoValue(data,data.pcr_percentageGMC,"data.pcr_percentageGMC","100","Enter Only 100");

                percentageVal = "100";
                requiredText = "Enter Only 100";
                data.pcrGMC.dbValue = true;
                data.pcrGMC.isEnabled = false;
            }
            else {
                //data.pcr_percentageGMC.propertyRequiredText = "100 is not allowed";
                //setvmoValue(data,data.pcr_percentageGMC,"data.pcr_percentageGMC","","100 is not allowed");
                //percentageVal = "";
                requiredText = "100 is not allowed";
            }
        }

        if (data.pcrGMC.dbValue == false)
        {
            requiredText = "100 is not allowed";
            percentageVal = "0";
        }

        setvmoValue(data,data.pcr_percentageGMC,"data.pcr_percentageGMC",percentageVal,requiredText);
    }

    export let baseUOMCustomization = function (data, ctx,selValue) {
        console.log("Inside baseUOMCustomization");
        var currentCompClass;

        currentCompClass = data.compClass.dbValue;
        console.log("CurrentCompClass\n",currentCompClass);
        if(currentCompClass === "Flexible Packaging")
            {
                var curBUOMVal = ctx.mselected[0].props.u4_base_uom.dbValues[0];
                console.log("curBUOMVal\n",curBUOMVal);
                data.uomValues.push({
                    propDisplayValue: curBUOMVal,
                    propInternalValue: curBUOMVal,
                    propDisplayDescription: ""
                });
            }
            console.log("Leaving baseUOMCustomization");
    };

    /*var _createListModelObj = function( strings ) {
        var listModels = [];
        for ( var i in strings ) {
            if ( i ) {
                var listModel = _createEmptyListModel();
                var splits = strings[i].split( ',' );
    
                listModel.propDisplayValue = splits[0];
                listModel.propInternalValue = splits[1];
                listModels.push( listModel );
            }
        }
        return listModels;
    };*/


    var _createEmptyListModel = function() {
        return {
            propDisplayValue: '',
            propInternalValue: '',
            propDisplayDescription: '',
            hasChildren: false,
            children: {},
            sel: false
        };
    };

    var setChildEmptyValue = function(data,vmo,propname)
    {
        var vmoClone = _.clone(vmo);                  
        vmoClone.dbValue = "";
        vmoClone.uiValue = "";            
        data.dispatch({path:propname, value:vmoClone});
    }
    var setChildDefaultValue = function(data,vmo,propname,lovvalues,value)
    {
        console.log("loop:setChildDefaultValue" + propname);
        console.log("lovs" + lovvalues);
        console.log("Value" + value);
        var valPresent = false;

        if ( lovvalues!= null)
        {
            
            for (var i in lovvalues) {
                if (lovvalues[i].propInternalValue == value)
                {
                    valPresent = true;
                    break;
                }
            }
            if (valPresent == false)
            {
                value= "";
            }
            console.log("Value1" + value);
        }
        
        var vmoClone = _.clone(vmo);                    
        vmoClone.dbValue = value;
        vmoClone.uiValue = value;
        data.dispatch({path:propname, value:vmoClone});
    }
    var setvmoValue = function(data,vmo,propname,value,propertyRequiredText)
    {
        var vmoClone = _.clone(vmo);                    
        vmoClone.dbValue = value;
        vmoClone.uiValue = value;
        vmoClone.propertyRequiredText=propertyRequiredText;
        data.dispatch({path:propname, value:vmoClone});
    }

    var verifyMultipleItemType = function(data, ctx, queryResponse)
    {
        if (queryResponse.nFound > 0)
        {
            if (ctx.selected.modelType.displayName == data.technology.dbValue)
            {
                return false;
            }
            else
                return true;
        }
    }

export default exports = {
    checkUserPrivilege,
    loadCompClassLov,
    updateCompClass,
    updateDefaultVals,
    loadInitialLovs,
    updateCompCommodity,
    updateMatClass,
    updateMatComm,
    updatedeliForm,
    updatepamFrameType,
    updateTechnology,
    loadCompCommodityLov,
    dataproviderInfo,
    loadDependentLov,
    asyncLoadDependentLov,
    loadDependentLov_indirect,
    loadUomValues,
    onCompCommoditySelected,
    onmatClassSelected,
    onmatCommoditySelected,
    ondeliFormSelected,
    onpamFrameTypeSelected,
    consumerunitTypeUpdate,
    changeButtonVisibilityCheck,
    convertDataToLov,
    resetLov,
    getLOVValueIn,
    metaDataChangePreAction,
    metaDataChangeAction,
    precheckCompMetaDataChange,
    getRelationProp,
    UpdatePackProproties,
    panelCloseAction,
    UpdateProperties,
    transferDatasets,   
    clearPCRPercentage,
    baseUOMCustomization,
    setChildEmptyValue,
    setChildDefaultValue,
    setvmoValue,
    verifyMultipleItemType
};
app.factory('CompMetaDataChange', () => exports );
