// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/* global
 alert
 */



/**
 * Simple Alert service for sample command Handlers
 * This code is used for to "Open PML in New Tab"
 * CR723- Sathish Are/ Sachin Damahe
 * @module js/openPMLinPAMService
 */
import soaService from 'soa/kernel/soaService';
import _msgSvc from 'js/messagingService';
import _pmlSvc from 'js/unileverPMLLovService';
import _cdm from 'soa/kernel/clientDataModel';
import _locNavSvc from 'js/navigationService';
import app from 'app';
import _ from 'lodash';


'use strict';

var exports = {};

/**
 * Dummy alert.
 * @param {String} text - text to display
 */
export let openPMLinPAM = function (ctx) {
    var frameObject = ctx.locationContext.modelObject;
    var frameuid = "";
    if (frameObject) {
        frameuid = frameObject.uid;

    } else if (ctx.pselected) {
        frameObject = ctx.pselected;
        frameuid = frameObject.uid;
    }
    if (frameObject && frameuid != "") {
        var u4_pam_frame_typeResponse = _pmlSvc.loadObjectsandgetProperties([frameuid], ["u4_pam_frame_type"]);
        u4_pam_frame_typeResponse.then(function () {
            var frameSelected = frameObject.props.u4_pam_frame_type.dbValues[0];
            var framepropertypmlmap = {
                "G-BOTTLE-PLASTIC": "u4_pml_type::U4_PNGResinRevision",
                "G-CLOSURE-PLAS": "u4_pml_type::U4_PNGResinRevision",
                "G-TUB-ETC-PLASTIC": "u4_pml_type::U4_PNGResinRevision",
                "G-LID-HOOD-PLSTC": "u4_pml_type::U4_PNGResinRevision",
                "G-INJECTION": "u4_pml_type::U4_PNGResinRevision",
                "G-ARTICLE": "u4_pml_type::U4_PNGResinRevision",
                "G-BUCKET-PLASTIC": "u4_pml_type::U4_PNGResinRevision",
                "G-LID-BUCKET-PLSTC": "u4_pml_type::U4_PNGResinRevision",
                "G-PUMP-VALVE-ACT": "u4_pml_type::U4_PNGResinRevision",
                "G-TOOTHBRUSH": "u4_pml_type::U4_PNGResinRevision",
                "G-VALVE-ACT-DETAIL": "u4_pml_type::U4_PNGResinRevision",
                "G-LABELS-LIT": "u4_type~U4_MaterialDetails2Form|U4_LayerStructureForm::U4_Decoration,U4_Substrate",
                "G-FLEXIBLES": "u4_pml_type,u4_type::U4_PNGResinRevision,U4_SubstrateRevision",
                "G-THERMOFORMS": "u4_pml_type,u4_type::U4_PNGResinRevision,U4_SubstrateRevision",
                "G-COV-MEM-ETC": "u4_pml_type,u4_type::U4_PNGResinRevision,U4_SubstrateRevision",
                "G-BAG-ETC-PREMADE": "u4_type::U4_SubstrateRevision",
                "G-CARTONS": "u4_type::U4_PaperNBoardRevision",
                "G-TUBES": "u4_type::U4_SubstrateRevision",
                "G-CASES-CORR": "u4_type::U4_SubstrateRevision",
                "G-BOARD-FLATBC": "u4_type::U4_PaperNBoardRevision",
                "G-TAPES": "u4_type::U4_SubstrateRevision",
                "G-CONE-SLEEVE": "u4_type::U4_SubstrateRevision"
            };
            var propertyName = framepropertypmlmap[frameSelected].split("::")[0];
            var propertyType = "";
            var propertyToConsider = {};
            if (propertyName.split(",").length > 1) {
                var propertNames = propertyName.split(",");
                var propertyTypes = framepropertypmlmap[frameSelected].split("::")[1];
                propertyToConsider = ctx.selected.props[propertNames[0]];
                propertyType = propertyTypes.split(",")[0];
                if (!propertyToConsider) {
                    propertyToConsider = ctx.selected.props[propertNames[1]];
                    propertyType = propertyTypes.split(",")[1];
                }
            } else {
                var propertyTypeIndex = 0;
                var propertyTypes = framepropertypmlmap[frameSelected].split("::")[1];
                if (propertyName.includes("~") == true) {
                    var propertyObjType = propertyName.split("~")[1];
                    propertyTypeIndex = propertyObjType.split("|").indexOf(ctx.selected.type);
                    propertyName = propertyName.split("~")[0];
                }
                propertyToConsider = ctx.selected.props[propertyName];
                propertyType = propertyTypes.split(",")[propertyTypeIndex];
            }
            if (propertyToConsider && propertyToConsider.dbValues[0] && propertyToConsider.dbValues[0].length > 0) {
                var getItemFromIdInputData = {
                    findList: [{
                        criterias: [{
                            objectType: propertyType,
                            objectName: propertyToConsider.dbValues[0],
                            scope: "WSO_scope_All",
                            owner: "",
                            group: ""
                        }]
                    }]
                };
                soaService.post("Query-2007-06-Finder", "findWorkspaceObjects", getItemFromIdInputData).then(function (response) {
                    if (response.ServiceData && response.ServiceData.plain && response.ServiceData.plain.length > 0) {
                        //  _.forEach(response.ServiceData.plain, function (itemRevisionObjectuid) {
                        var itemRevisionObjectuid = response.ServiceData.plain[0];
                        var revision_listResponse = _pmlSvc.loadObjectsandgetProperties([itemRevisionObjectuid], ["revision_list", "object_name", "release_status_list"]);
                        revision_listResponse.then(function () {
                            if (response.ServiceData.modelObjects[itemRevisionObjectuid].props.object_name.dbValues[0].toLowerCase() == propertyToConsider.dbValues[0].toLowerCase()) {
                                var itemObject = response.ServiceData.modelObjects[itemRevisionObjectuid];
                                var latestindex = itemObject.props.revision_list.dbValues.length;
                                var latestItemRevUId = itemObject.props.revision_list.dbValues[latestindex - 1];
                                var revUids = itemObject.props.revision_list.dbValues;
                                for (var i = revUids.length - 1; i >= 0; i--) {
                                    var revObj = _cdm.getObject(revUids[i]);
                                    if (revObj.props.release_status_list && revObj.props.release_status_list.uiValues[0] && revObj.props.release_status_list.uiValues[0].includes("Released") == true) {
                                        latestItemRevUId = revObj.uid;
                                        break;
                                    }
                                }

                                var action = {
                                    "actionType": "Navigate",
                                    "navigateTo": "com_siemens_splm_clientfx_tcui_xrt_showObject",
                                    "navigationParams": {
                                        "uid": latestItemRevUId
                                    },
                                    "navigateIn": "newTab"
                                };
                                _locNavSvc.navigate(action, action.navigationParams);
                            }
                        });
                        //});
                    } else {
                        _msgSvc.showError("Selected PLM doesn't exist.");
                    }
                });
            } else {
                _msgSvc.showError("PLM doesn't exist.");
            }
        });
    }
};

export default exports = {
    openPMLinPAM
};
app.factory('openPMLinPAMService', () => exports);
