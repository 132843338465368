import app from 'app';
import ngModule from 'angular';
import eventBus from 'js/eventBus';
import ngUtils from 'js/ngUtils';
import browserUtils from 'js/browserUtils';
import 'js/declarativeDataCtxService';
import _soasvc from 'soa/kernel/soaService';
import _messagingSvc from 'js/messagingService';
import _appCtxSvc from 'js/appCtxService';
import AwHttpService from 'js/awHttpService';

import awPromiseService from 'js/awPromiseService';

    'use strict';
    var _$http, exports = {},
        /*_soaSvc = null,*/
        _endPoint = null,
        isRequestCalled = false;
    
    
    /**
     * Clear File Selection
     *      
     */
    export let clearFileSelection = function () {
        _appCtxSvc.ctx.HostedFileNameContext = undefined;
    };


    export let callServiceJS = function (inputData) {
        if (isRequestCalled == false) {
            isRequestCalled = true;
        } else {
            _messagingSvc.showInfo("Request already in-progress..");
            return;
        }
        //inputData.request.url = window.location.protocol+"//"+window.location.hostname+":"+inputData.request.data.Port+inputData.request.url
        //inputData.request.url = AIValidation.getURL(inputData.request);
        inputData.request.url = exports.getURL(inputData.request);
        
        eventBus.publish('progress.start', {
            endPoint: inputData.request.url
        });
        _endPoint = inputData.request.url;
        try {
            const url = inputData.request.url;
            var promise = AwHttpService.instance.post( url, inputData.request.data).
            then(function (response, status, headers, config) {
                    processEnd();
                    //return promise;
                },
                function (data, status, statusText) {
                    processEnd();
                });
        } catch (error2) {
            processEnd();
            alert("error block");
        }
        return promise;
    };
    export let callServiceWithMessageJS = function (inputData) {
		
        var mimeType = 'application/pdf';
        var fileName = '';
        if (isRequestCalled == false) {
            isRequestCalled = true;
        } else {
            _messagingSvc.showInfo("Request already in-progress..");
            return;
        }
		var deferred = awPromiseService.instance.defer();
        //inputData.request.url = window.location.protocol+"//"+window.location.hostname+":"+inputData.request.data.Port+inputData.request.url
        //inputData.request.url = "http://localhost:50455/Report/ReportService.asmx/PackReport"
        //inputData.request.url = AIValidation.getURL(inputData.request);
        inputData.request.url = exports.getURL(inputData.request);
        eventBus.publish('progress.start', {
            endPoint: inputData.request.url
        });
        _endPoint = inputData.request.url;
        try {
              const url = inputData.request.url;
              var promise = AwHttpService.instance.post( url, inputData.request.data).then(function (response, status, headers, config) {
                    processEnd();
                    if (response.data.d.Message != null) {
                        _messagingSvc.showError(response.data.d.Message);
                    }
                    if (response.data.d.messageArray != null) {
                        for (var i = 0, strLen = response.data.d.messageArray.length; i < strLen; i++) {
                            _messagingSvc.showInfo(response.data.d.messageArray[i], true);
                        }
                    }
                    if (response.data.d.errorMessageArray != null) {
                        for (var i = 0, strLen = response.data.d.errorMessageArray.length; i < strLen; i++) {
                            _messagingSvc.showError(response.data.d.errorMessageArray[i]);
                        }
                    }
                    if (response.data.d.FileName1 != null) {
                        fileName = response.data.d.FileName1;
                        mimeType = response.data.d.ContentType1;

                        saveFile(fileName, mimeType, response.data.d.FileBytes1);
                    }
                    if (response.data.d.FileName2 != null) {
                        fileName = response.data.d.FileName2;
                        mimeType = response.data.d.ContentType2;

                        saveFile(fileName, mimeType, response.data.d.FileBytes2);
                    }
                    //return promise;
					deferred.resolve(response);
                },
                function (data, status, statusText) {
                    processEnd();
					deferred.resolve(data);
                });
        } catch (error2) {
            processEnd();
            alert("error block");
        }
		return deferred.promise;
        //return promise;
    };


    function processEnd() {
        eventBus.publish('progress.end', {
            endPoint: _endPoint
        });
        isRequestCalled = false;
    }

    /*function saveFile1 (name, type, data) {
        if (data != null && navigator.msSaveBlob)
            return navigator.msSaveBlob(new Blob([data], { type: type }), name);
        var a = $("<a style='display: none;'/>");
        
    
        var url = window.URL.createObjectURL(new Blob([(data)], {type: type}));
        a.attr("href", url);
        a.attr("download", name);
        $("body").append(a);
        a[0].click();
        window.URL.revokeObjectURL(url);
        a.remove();
    };*/

    function saveFile(name, _type, data) {
        var bytes = new Uint8Array(data);
        var blob = new Blob([bytes], {
            type: _type
        });
        /*if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, name);
        }*/
        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, name);
        } else {
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            //window.open(link.href);
            link.download = name;
            link.click();
            link.remove();
        }
    }

    function str2ab(str) {
        var buf = new ArrayBuffer(str.length); // 2 bytes for each char
        var bufView = new Uint8Array(buf);
        for (var i = 0, strLen = str.length; i < strLen; i++) {
            bufView[i] = str.charCodeAt(i);
        }
        return buf;
    }

    export let callServiceDownloadJS = function (inputData) {
        _appCtxSvc.ctx.HostedFileNameContext = undefined;
        if (isRequestCalled == false) {
            isRequestCalled = true;
        } else {
            _messagingSvc.showInfo("Request already in-progress..");
            return;
        }
        //inputData.request.url = window.location.protocol+"//"+window.location.hostname+":"+inputData.request.data.Port+inputData.request.url
		//inputData.request.url = "http://localhost:50455/Report/ReportService.asmx/TotalPackImpactReport"
        //inputData.request.url = AIValidation.getURL(inputData.request);
        inputData.request.url = exports.getURL(inputData.request);
        var mimeType = 'application/pdf';
        var fileName = '';
        eventBus.publish('progress.start', {
            endPoint: inputData.request.url
        });
        _endPoint = inputData.request.url;
        try {
            const url = inputData.request.url;
            var promise = AwHttpService.instance.post( url, inputData.request.data).
            then(function (response, status, headers, config) {
                    processEnd();

                    if (response.data.d != undefined) {
                        if (response.data.d.Message != null) {
                            _messagingSvc.showError(response.data.d.Message);
                        } else {
                            fileName = response.data.d.FileName;
                            mimeType = response.data.d.ContentType;

                            saveFile(fileName, mimeType, response.data.d.FileBytes);
                            /*if(mimeType=='application/pdf'){
                                // var bytes = new Uint8Array(response.d.FileBytes);
                                var blob=new Blob([response.d.FileBytes], {type: "octet/stream"});
                                var url= window.URL.createObjectURL(blob);
                                window.open(url);
                            }else{
                                saveFile(fileName,mimeType,response.d.FileBytes);                              
                                //saveAs(blob,fileName);                                                
                            }*/
                        }
                    } else {
                        _messagingSvc.showError("Error Occured: Invalid Response Received");
                    }
                    //return promise;
                },
                function (data, status, statusText) {
                    processEnd();
                    _messagingSvc.showError(statusText);
                });
        } catch (error2) {
            processEnd();
            alert("error block");
        }
        return promise;
    };

    export let callPackFrameServiceDownloadJS = function (inputData) {
		
		var deferred = awPromiseService.instance.defer();
		
        _appCtxSvc.ctx.HostedFileNameContext = undefined;
        if (isRequestCalled == false) {
            isRequestCalled = true;
        } else {
            _messagingSvc.showInfo("Request already in-progress..");
            return;
        }
        //inputData.request.url = window.location.protocol+"//"+window.location.hostname+":"+inputData.request.data.Port+inputData.request.url
        //inputData.request.url = AIValidation.getURL(inputData.request);
        inputData.request.url = exports.getURL(inputData.request);
        var mimeType = 'application/pdf';
        var fileName = '';

        eventBus.publish('progress.start', {
            endPoint: inputData.request.url
        });
        _endPoint = inputData.request.url;
        try {
            const url = inputData.request.url;
            var promise = AwHttpService.instance.post( url, inputData.request.data).
            then(function (response, status, headers, config) {
                    processEnd();

                    if (response.data.d != undefined) {
                        if (response.data.d.Message != null) {
                            _messagingSvc.showError(response.data.d.Message);
                        } else {
                            fileName = response.data.d.FileName;
                            mimeType = response.data.d.ContentType;

                            saveFile(fileName, mimeType, response.data.d.FileBytes);

                            if (response.data.d.FileName != null && response.data.d.Message == null) {
                                _messagingSvc.showInfo("Updated the Pack Component properties successfully");
                            } else {
                                _messagingSvc.showError("Failed in updating the properties. View the log file for more details");
                            }
                        }
                    } else {
                        _messagingSvc.showError("Error Occured: Invalid Response Received");
                    }
                    //return promise;
					deferred.resolve(response);
                },
                function (data, status, statusText) {
                    processEnd();
                    _messagingSvc.showError(statusText);
					deferred.resolve(data);
                });
        } catch (error2) {
            processEnd();
            alert("error block");
        }
        return deferred.promise;
		//return promise;
    };
    export let initUOMCombo = function (selectedObj,iData) {
		console.log("Inside initUOMCombo Reveal Method");
		console.log(selectedObj);
		var isDDE = selectedObj.modelType.typeHierarchyArray.indexOf('U4_DDERevision');
		var OBj = {
                uid: selectedObj.uid
            };
			
		if(isDDE>-1){
			var inputData = {
				objects: [OBj],
				attributes: ['u4_units_of_measure']
			};
		}else{
			var inputData = {
				objects: [OBj],
				attributes: ['u4_template_id']
			};
		}
		
		if(isDDE>-1){
			_soasvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (response) {
				console.log(selectedObj.props.u4_units_of_measure);
				console.log('in DDE');							
					iData.isMetric.dbValue = selectedObj.props.u4_units_of_measure.dbValue==='Metric';
			});
		}else{
			_soasvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (response) {				
				console.log(selectedObj.props.u4_template_id);											
				iData.isMetric.dbValue = selectedObj.props.u4_template_id.dbValue==="540000000000";
			});
		}
	}; 
	
	export let initInAsSpec = function (selectedObj,iData) {
		console.log("Inside PAM Report Reveal Method");
		console.log(selectedObj.props.u4_technology.dbValue);
		console.log(iData);
		console.log(iData.preferences);
		
		if (includes(iData.preferences.U4_AssemblyTechnology, selectedObj.props.u4_technology.dbValue)) {
			iData.enableInAsSpec = true;
		}else{
			iData.enableInAsSpec = false;
		}
	};

	function includes(container, value) {
		var returnValue = false;
		var pos = container.indexOf(value);
		if (pos >= 0) {
			returnValue = true;
		}
		return returnValue;
	}

    export let fileInfo = function (fileData) {
        _appCtxSvc.ctx.HostedFileNameContext = {};
        _appCtxSvc.ctx.HostedFileNameContext.filename = fileData.fileName;
        if (fileData && fileData.fileName) {
            if (!_appCtxSvc.ctx.HostedFileNameContext) {
                _appCtxSvc.ctx.HostedFileNameContext = {};
            }
            if (!_appCtxSvc.ctx.HostedFileNameContext.filename) {
                _appCtxSvc.ctx.HostedFileNameContext.filename = fileData.fileName;
            } else {
                _appCtxSvc.ctx.HostedFileNameContext.filename = fileData.fileName;
            }
        }

    };

    export let getURL = function getURL(request) {
        var preFixURL = window.location.protocol + "//" + window.location.hostname;
        var URL = "";
        if (request.data.Port == "0") {
            URL = preFixURL + request.url;
        } else if (request.data.Port.length < 6) {
            URL = preFixURL + ":" + request.data.Port + request.url;
        } else {
            URL = request.data.Port + request.url;
        }
        return URL;
    };


export default exports = {
	clearFileSelection,
	callServiceJS,
	callServiceWithMessageJS,
	callServiceDownloadJS,
	callPackFrameServiceDownloadJS,
	initUOMCombo,
	initInAsSpec,
	fileInfo,
    getURL
};
app.factory('reportService', () => exports );
