// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * Mct list service
 *
 * @module js/MctService
 */
import app from 'app';
import _commUtil from 'js/UnileverCommonUtil';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import _MctUtil from 'js/MctUtil';
import _MctSubSvc from 'js/MctSublocationService';
import $ from 'jquery';
import _lovUtil from 'js/UnileverLovUtil';
import awPromiseService from 'js/awPromiseService';

'use strict';

var exports = {};

var _MctItemandRevision = null;

export let check_PamSelection = function (data) {

    var selectedPams = _MctUtil.getSelectedPAM('pam');

    if (selectedPams) {
        data._canAddMct = true;
    } else {
        data._canAddMct = false;
    }

}


export let emptyAction_pam = function () { }

export let pamloadCurrentUserMCTs = function (data) {

    loadCategoryLOV(data);
    _MctSubSvc.MctAddViewVIEWMODEL = data;

    var deferred = awPromiseService.instance.defer();
    _MctSubSvc.getCurrentUserMCTs(function (MctItemandRevision) {
        _MctItemandRevision = MctItemandRevision;
        var MctItems = [];
        for (var g in MctItemandRevision) {
            if (MctItemandRevision[g].type === 'U4_MOP_List') {
                var MctItem = MctItemandRevision[g];
                var latestRev = _MctSubSvc.getLatestRev(MctItem, MctItemandRevision);
                var pamCount = latestRev.props.U4_MOP_List_Relation.dbValues.length;
                MctItem.props.awp0CellProperties.dbValues.push('PAM Count \\:' + pamCount);
                MctItems.push(MctItem);
            }

        }

        if (MctItems.length === 0) {
            data.addNote.uiValue = 'No MCT found.';
        }

        data.currentUserMcts = MctItems;
        _commUtil.sortComponents(data, 'currentUserMcts', 0, 0);

        deferred.resolve();
    });
    return deferred.promise;
}


var loadCategoryLOV = function (data) {

    var categoryList = [];
    _lovUtil.getLOVvalue('U4_MOP_ListRevision', 'u4_category', 'lov_values', '', null, categoryList, function (serviceData) {
        _appCtxSvc.registerCtx("MCT.categoryList", categoryList);
    });

}


export let PamcreateAndAddAction = async function (data, conditions) {
    let isMctAlreadyAvailable = checkMctAlreadyAvailable(data);
    if (!isMctAlreadyAvailable) {
        data.CreateButtonVisibility = true;
        var MctName = data.MctName.dbValue;
        var MctCategory = data.MctCategory.dbValue;
        var newMctRev = await _MctUtil.createAndAddToMct(MctName, MctCategory, data);
        if (newMctRev) {
            _commUtil.closeTNIpanel();
            openMct(data, newMctRev);
        }
    } else {
        _messagingSvc.showError('MCT already available with this name. Please try with different name.');
    }
    return data.CreateButtonVisibility;
}

let checkMctAlreadyAvailable = function (data) {
    let isMctAlreadyAvailable = false;
    if (_MctItemandRevision) {
        let mctName = data.MctName.dbValue;
        if (mctName) {
            for (let i in _MctItemandRevision) {
                if (_MctItemandRevision[i].type === 'U4_MOP_List') {
                    let mctObjName = _MctItemandRevision[i].props.object_name.dbValues[0];
                    if (mctName === mctObjName) {
                        isMctAlreadyAvailable = true;
                        break;
                    }
                }
            }
        }
    } else {
        _messagingSvc.showError('Data not loaded yet. Please wait some time and try again.');
    }
    return isMctAlreadyAvailable;
}

export let pamaddAction = function (data, conditions) {
    var selectedMctItem = data.dataProviders.MctItemsDataprovider.selectedObjects[0];
    var latestRevMct = _MctSubSvc.getLatestRev(selectedMctItem, _MctItemandRevision);

    _MctUtil.addToMct(latestRevMct, data, function () {
        _commUtil.closeTNIpanel();
        openMct(data, latestRevMct);
    });
}

var openMct = function (data, MctRev) {
    var willOpenMct = data.openOnAdd.dbValue;
    if (willOpenMct) {
        _MctSubSvc.openMct(MctRev.uid);
    }
}

export let openPamMctSublocation = function () {
    _appCtxSvc.registerCtx('ViewModeContext', {
        ViewModeContext: 'SummaryView'
    });
    window.location.href = (window.location.origin + window.location.pathname + '#/' + 'MctSublocation');
}

export let changeToPamSummaryView = function () {
    _appCtxSvc.registerCtx('ViewModeContext', {
        ViewModeContext: 'SummaryView'
    });
    _messagingSvc.showInfo('MCT Operation does not support views other than summary view.');
}

export let pamCtxChange = function () {
    if (_appCtxSvc.ctx.ViewModeContext.ViewModeContext !== 'SummaryView') {
        exports.changeToPamSummaryView();
    }
}

export let moduleServiceNameToInject = 'MctService';
export default exports = {
    check_PamSelection,
    emptyAction_pam,
    pamloadCurrentUserMCTs,
    PamcreateAndAddAction,
    pamaddAction,
    openPamMctSublocation,
    changeToPamSummaryView,
    pamCtxChange,
    moduleServiceNameToInject
};
app.factory('MctService', () => exports);


