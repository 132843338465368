
import _appCtxSvc from 'js/appCtxService';
import _soaSvc from 'soa/kernel/soaService';

'use strict';

var exports = {};
var sheets = [];

export let soaInputData = function (data, ctx) {
   sheets = [];

   if (data.A0.dbValue) {
      sheets.push("A0");
   }
   if (data.A1.dbValue) {
      sheets.push("A1");
   }
   if (data.A2.dbValue) {
      sheets.push("A2");
   }
   if (data.A3.dbValue) {
      sheets.push("A3");
   }
   if (data.A4.dbValue) {
      sheets.push("A4");
   }


   data.sheetsdata = sheets;
   if (ctx.selected.props.release_status_list.dbValues != null) {
      if (ctx.selected.props.release_status_list.dbValues.length != 0) {
         data.hasStatus = "Yes";
      }
      else {
         data.hasStatus = "No";
      }
   }
   else {
      data.hasStatus = "No";
   }
   return;
}

export let moduleServiceNameToInject = 'AddCADDataService';
export default exports = {
   soaInputData,
   moduleServiceNameToInject
};