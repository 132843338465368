// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/* global
 alert
 */

/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/ProductionUpdateRevertService
 */
import app from 'app';
import _soaSvc from 'soa/kernel/soaService';
import eventBus from 'js/eventBus';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import _getPackFromPam from "js/ProductionUpdatePCRService";
import _cdm from 'soa/kernel/clientDataModel';
import awPromiseService from 'js/awPromiseService';

/**
 * Object used to place all the exported API of this module upon.
 */
var exports = {};

export let ProdUpdateRevertAction = function (ctx) {
  var deferred = awPromiseService.instance.defer();
  var selectedPack, selectedPam;
  var selectedObject, packRevObjUID;

  if (_appCtxSvc.ctx.selected.modelType.parentTypeName === "U4_PAMRevision") {
    selectedPam = _appCtxSvc.ctx.selected;
    _getPackFromPam.getPackFromPam(deferred);
    deferred.promise.then(function (packRevObjUID) {
      selectedPack = _cdm.getObject(packRevObjUID);
      selectedObject = selectedPack;

      var inputdata = {
        tSelectedCompRev: {
          uid: selectedObject.uid,
          type: selectedObject.type,
        }
      };
      _soaSvc.post(
        "AWC-2019-06-ProdUpdateRevert",
        "performProdUpdateRevert",
        inputdata)
        .then(function (response) {
          console.log("Response: ", response);
          if (response.message == "")
            _messagingSvc.showInfo("Production Update recall initiated. Please go back to earlier revision now and check.");
          else
            _messagingSvc.showError(response.message);
        });

      var endPt =
        "AWC-2019-06-ProdUpdateRevert" +
        "/" +
        "performProdUpdateRevert";
      eventBus.publish("progress.end", {
        endPoint: endPt,
      });
    });
  }

  else {
    var inputdata = {
      tSelectedCompRev: {
        uid: _appCtxSvc.ctx.selected.uid,
        type: _appCtxSvc.ctx.selected.type,
      }
    };
    _soaSvc.post(
      "AWC-2019-06-ProdUpdateRevert",
      "performProdUpdateRevert",
      inputdata)
      .then(function (response) {
        console.log("Response: ", response);
        if (response.message == "")
          _messagingSvc.showInfo("Production Update recall initiated. Please go back to earlier revision now and check.");
        else
          _messagingSvc.showError(response.message);
      });

    var endPt =
      "AWC-2019-06-ProdUpdateRevert" +
      "/" +
      "performProdUpdateRevert";
    eventBus.publish("progress.end", {
      endPoint: endPt,
    });
  }
};

export let PMLRevert = function (ctx) {
  var inputdata = {
    tSelectedPMLRev: {
      uid: ctx.selected.uid,
      type: ctx.selected.type,
    }
  };
  _soaSvc.post(
    "AWC-2019-06-ProdUpdateRevert",
    "performPMLRevert",
    inputdata
  );
  var endPt =
    "AWC-2019-06-ProdUpdateRevert" +
    "/" +
    "performPMLRevert";
  eventBus.publish("progress.end", {
    endPoint: endPt,
  });
  _messagingSvc.showInfo("PML recall initiated. Please go back to earlier revision now and check.");
};

export let PNPReviseRecallAction = function (ctx) {
  var input = {
    objects: [ctx.selected],
    attributes: ["U4_PnPSpecificationRelation"]
  };
  _soaSvc.post("Core-2006-03-DataManagement", "getProperties", input).then(function (response) {
    if (response && response.modelObjects) {
      console.log("response:", response.modelObjects);
      for (let i in response.modelObjects) {
        if (ctx.selected.uid == response.modelObjects[i].uid) {
          var ddeObj = response.modelObjects[i];

          var matFormUID = ddeObj.props.U4_PnPSpecificationRelation.dbValues[0];
          if (matFormUID) {
            var matFormObj = _cdm.getObject(matFormUID);
            var ecomValue = matFormObj.props.u4_cu_ecom_update.dbValues[0];
            var omniValue = matFormObj.props.u4_omni_channel_ecom_update.dbValues[0];
            if (ecomValue != "LIMS" && omniValue != "LIMS") {
              var inputdata = {
                tSelectedDDERev: {
                  uid: ctx.selected.uid,
                  type: ctx.selected.type,
                }
              };
              _soaSvc.post(
                "AWC-2019-06-ProdUpdateRevert",
                "performPNPReviseRecall",
                inputdata
              );
              var endPt =
                "AWC-2019-06-ProdUpdateRevert" +
                "/" +
                "performPNPReviseRecall";
              eventBus.publish("progress.end", {
                endPoint: endPt,
              });
              _messagingSvc.showInfo("PNP Revise Recall initiated. Please go back to earlier revision now and check.");
            }
            else {
              _messagingSvc.showInfo("PNP having data from LIMS. Could not perform Recall PNP Revise operation");
            }
          }
        }
      }
    }
  });
}

export default exports = {
  ProdUpdateRevertAction,
  PMLRevert,
  PNPReviseRecallAction
};
app.factory('ProductionUpdateRevertService', () => exports);
