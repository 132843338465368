// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/ExportToWebPacService
 */
import app from 'app';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import $ from 'jquery';
import eventBus from 'js/eventBus';
import _appCtxSvc from 'js/appCtxService';
import _tcSessionData from 'js/TcSessionData';
import _messagingSvc from 'js/messagingService';
import _policySvc from 'soa/kernel/propertyPolicyService';
import _commUtil from 'js/UnileverCommonUtil';

    'use strict';

    var exports = {};
    
	/**
     * Validate for precheck for whether proceed or not
     * 
     * 
     */
	export let webpacPrecheck= function (data) {
        
        data.confirm_displaytriggerWebpacPopUp = true;
	}


    /**
     * Dummy alert.
     */
    export let validateComp1 = function( ctx ) {
        //alert( text ); // eslint-disable-line no-alert
        
          /* var inputdata = {            
            compRev: {            
               var uid: _appCtxSvc.ctx.selected.uid,
                type: _appCtxSvc.ctx.selected.type,
            }
          };   */
          
        var puid=  _appCtxSvc.ctx.selected.uid; 
        var ObjStrngValue = _appCtxSvc.ctx.selected.props.object_string.dbValues[0];
        var getPropertiesInputData = {
            objects: [_appCtxSvc.ctx.selected],
            attributes: ['U4_MatlClassRelation','u4_webpaccheck']
        };

        var policy = {
            "types": [{
                "name": "U4_MaterialClassificationForm",
                "properties": [{
                        "name": "u4_pam_frame_type"
                    },
                    {
                        "name": "u4_technology"
                    }
                ]
            }]
        };

        let policyId = _policySvc.register(policy);

        _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', getPropertiesInputData).then(function (getPropertiesResponse) {

            if (policyId)
                _policySvc.unregister(policyId);

                var WebpacCheck = getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]].props.u4_webpaccheck.uiValues[0];
                var FrameName = getPropertiesResponse.modelObjects[getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]].props.U4_MatlClassRelation.dbValues[0]].props.u4_pam_frame_type.dbValues[0];
                if ((['G-BOTTLE-GLASS', 'G-BOTTLE-PLASTIC', 'G-AEROSOLS', 'G-CLOSURE-PLAS', 'G-TUBES', 'G-PUMP-VALVE-ACT', 'G-CARTONS', 'G-CAN-3P-BODY', 'G-CAN-END', 'G-BAG-ETC-PREMADE', 'G-FLEXIBLES', 'G-BUCKET-PLASTIC', 'G-LID-BUCKET-PLSTC', 'G-LID-HOOD-PLSTC', 'G-TOOTHBRUSH'].indexOf(FrameName) !== -1)) {
                    try {
                        if (!_commUtil.compareToIgnoreCase(WebpacCheck, 'true')) {
                            _commUtil.initiateUnileverWorkflow(ObjStrngValue, puid, 'ExportToWebpac Workflow', function (ctx) {
                                // var ExportToMuleSoftSuccessMsg = "Export to Mulesoft Initiated.<br>Export to Mulesoft Initiated on <strong>" + ObjStrngValue + "</strong>.</br>";
                                var ExportToMuleSoftSuccessMsg = "Export to WebPac workflow initiated on <strong>" + ObjStrngValue + "</strong> is successful and sent to the approver.<br><strong> Note: </strong> Specification and 3D data in WebPac will be visible only after approx. 48 hrs time once approver has approved it </br>";
                                _messagingSvc.showInfo(ExportToMuleSoftSuccessMsg, true);
                                //_commonUtil.openNewObject(prodRev.uid);
                            });
                        }
                        else {
                            _messagingSvc.showError('Revised data after PROD update has already flown to Webpac so no need to retrigger.');
                        }
                    }
                    catch (error) {
                        _messagingSvc.showError('Something went wrong while Exporting to WebPac.');

                    }
                }
                else {
                    _messagingSvc.showError('For the Selected frame or specification, export to WebPac functionality is not applicable');
                }
            });

        };



export default exports = {
	validateComp1,
	webpacPrecheck
};
app.factory('ExportToWebPaService', () => exports );