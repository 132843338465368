import _ctx from 'js/appCtxService';
import app from 'app';
/**
 * An OTB SOA call to get the Secondary Object i.e PAM obj based on Relation then opening PAM Obj
 * @param {Object} data ViewModel object
 *
 */

'use strict';

var exports = {};



export let OpenMyProjects = function (data) {
  //Create sublocation URL:
  var myProjectsPath = "#/MyProject";
  var originUrl = window.location.origin + window.location.pathname;
  var myProjectsURL = originUrl + myProjectsPath;

  //open the sublocation URL:
  window.location.href = myProjectsURL;
};

export let CreateNewProject = function (data) {
  var originUrl2 = window.location.origin + window.location.pathname;
  var CreateArg = "#/com.siemens.splm.clientfx.tcui.xrt.showObject?cmdArg=U4_Project&cmdId=Awp0ShowCreateObjectPopup&uid=";
  var homeFolderUID = _ctx.ctx.user.props.home_folder.dbValues[0];
  var createProjectURL = originUrl2 + CreateArg + homeFolderUID;
  if (window.location.href == createProjectURL) {
    window.location.reload();
  }
  else {
    window.open(createProjectURL, "_self");
    window.location.reload();
  }
};

export default exports = {
  OpenMyProjects,
  CreateNewProject
};
app.factory('MyProjectsCommandService', () => exports);

