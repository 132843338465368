/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/RefreshFormService
 */
import cdm from 'soa/kernel/clientDataModel';


export let getFormObjects = function (response) {
    var secondaryForms = [];
    var attachedForm = response.output[0].otherSideObjData[0].otherSideObjects;//U4_CommonForm
    for (var inx = 0; inx < attachedForm.length; inx++) {
        if (attachedForm[inx].modelType.typeHierarchyArray.indexOf('U4_CommonForm') > -1) {
            secondaryForms.push(attachedForm[inx]);
        }
    }
    return secondaryForms;
}
export default {
    getFormObjects
};
