import msgSvc from 'js/messagingService';
import awPromiseService from 'js/awPromiseService';

import app from 'app';
import _soaSvc from 'soa/kernel/soaService';
import eventBus from 'js/eventBus';
import _appCtxSvc from 'js/appCtxService';
import 'js/TcSessionData';
import _policySvc from 'soa/kernel/propertyPolicyService';

    'use strict';

    var exports = {};

    
    //*
     //* In this function, validating the prechecks for Delete/Obsolete Operation
     //* 
     //* @param {Object} data ViewModel object
     //* @return
     //* 
     //*/
    export let preChecks = function( data ) {
        try{
            var policyId = _policySvc.register({

                types: [{ 
                            name:'Item',
                            properties:
                            [{
                                name: 'revision_list'
                                
                            }]
                        },
                        {
                            name:'U4_ProjectRevision',
                            properties:
                            [
                                {
                                    name: 'u4_packaging_developer'                              
                                },
                                {
                                    name: 'u4_packaging_designer'
                                }
                            ]
                        }
                    ]         
                }); 
         
			var deferred = awPromiseService.instance.defer();
            data.cutobs_selectedObjProject      = _appCtxSvc.ctx.pselected.props.object_name.dbValues[0];           
            data.cutobs_isMultipleProjectsExist = undefined;
            data.cutobs_isLoggingUserCompOwner      = false;
            data.cutobs_isProjectAccesstoUser       = false;
            data.cutobs_isLatestRevision            = true;
            data.cutobs_projectNames                = undefined;
            data.cutobs_selectedObj             = _appCtxSvc.ctx.selected;
            data.cutobs_selectedParentObj           = _appCtxSvc.ctx.pselected;
            data.cutobs_ObjStatus                   = _appCtxSvc.ctx.selected.props.release_status_list.uiValue;
            data.cutobs_relationName                = undefined;//contains whether Final Relation or Development Relation
            var selectedObjectString                = _appCtxSvc.ctx.selected.props.object_string.dbValue;
            data.cutobs_pamRevUID                   = _appCtxSvc.ctx.selected.props.U4_PAMSpecification.dbValues[0];
            var packagingDevUsers                   ="";
            var packagingDesignUsers                ="";
            var isLatestPackRevision                    =true;

            if(data.cutobs_ObjStatus == '' && (_appCtxSvc.ctx.selected.props.u4_stage.dbValue === 'Packaging Concept Creation' || _appCtxSvc.ctx.selected.props.u4_stage.dbValue === 'Packaging Refinement'))
            {
                data.cutobs_ObjStatus = undefined;
            }
            else if(data.cutobs_ObjStatus == 'Released')
            {
                data.cutobs_MsgStatus="Obsolete";
            }
            else
            {
                data.cutobs_MsgStatus="Cancelled";
            }
            //For checking logging user is owning user of component or not
            if(_appCtxSvc.ctx.selected.props.owning_user.dbValues[0] == _appCtxSvc.ctx.user.uid)
            {
                
                data.cutobs_isLoggingUserCompOwner  = true;
            }             
            //For checking multiple project for the selected component
            var sProjectNames           = "";
            var iCount                  = 0;
            var inputData = 
            {
                objects: [_appCtxSvc.ctx.selected],
                numLevels:1
            }
            _soaSvc.post("Core-2007-01-DataManagement", "whereReferenced", inputData).then(function (response) {
            _policySvc.unregister(policyId);    
                
                var Outputresponse=response.output["0"];                    
                for(var i = 0 ;i < Outputresponse.info.length; i++)
                    {
                        if(Outputresponse.info[i].referencer.type == "U4_ProjectRevision")
                        {
                            iCount = iCount + 1;
                            sProjectNames=sProjectNames.concat(Outputresponse.info[i].referencer.props.object_name.dbValues[0]);
                            sProjectNames=sProjectNames.concat(",");
                            data.cutobs_relationName = Outputresponse.info[i].relation;
                            //geting Packaging developer and Designer of current selected project
                            if(data.cutobs_selectedObjProject == Outputresponse.info[i].referencer.props.object_name.dbValues[0])
                            {
                                 packagingDesignUsers= Outputresponse.info[i].referencer.props.u4_packaging_designer.dbValues;
                                 packagingDevUsers = Outputresponse.info[i].referencer.props.u4_packaging_developer.dbValues;
                            }
                        }
                        if(Outputresponse.info[i].referencer.modelType.parentTypeName == "U4_Component")
                        {
                            var revisionListSize        = Outputresponse.info[i].referencer.props.revision_list.uiValues.length;
                            data.selectedCompModelObj   = Outputresponse.info[i].referencer;
                            if(Outputresponse.info[i].referencer.props.revision_list.uiValues[revisionListSize-1] != selectedObjectString)//Checking for latest revision or not
                            {
                                isLatestPackRevision = false;
                            }
                        }
                    }
                    sProjectNames = sProjectNames.substring(0, sProjectNames.length-1);//for removing the last character comma ','      
                    if(iCount > 1)
                    {
                        data.cutobs_isMultipleProjectsExist = true;
                    }
                    else
                    {
                        data.cutobs_isMultipleProjectsExist = false;
                    }
                    data.cutobs_projectNames = sProjectNames;
                    
            /*   Checking the Project access to logging user   */
            if(_appCtxSvc.ctx.pselected.props.owning_user.dbValues["0"]==_appCtxSvc.ctx.user.uid)//checking logging user 
            {
                data.cutobs_isProjectAccesstoUser = true;
            }
            else if(data.cutobs_isProjectAccesstoUser == false)
            {
                for(var userIndex = 0;userIndex<packagingDevUsers.length;userIndex++)   //Checking for Packaging Developer
                {
                    if(_appCtxSvc.ctx.userSession.props.user_id.dbValue == packagingDevUsers[userIndex])
                    {
                        data.cutobs_isProjectAccesstoUser = true;
                    }
                }
                for(var userIndex = 0;userIndex<packagingDesignUsers.length;userIndex++)    //Checking for Packaging Designer
                {
                    if(_appCtxSvc.ctx.userSession.props.user_id.dbValue == packagingDesignUsers[userIndex])
                    {
                        data.cutobs_isProjectAccesstoUser = true;
                    }
                }
            }   
            if(isLatestPackRevision ==false && data.cutobs_isProjectAccesstoUser ==true)
            {
                msgSvc.showError("Selected component is not the latest revision, please select the latest revision")
                data.cutobs_isLatestRevision = false;
            }
            //Input for the assignOrRemoveObjects
            data.cutobs_selectedObjTCProjectUID    = _appCtxSvc.ctx.pselected.props.project_list.dbValues[0];
            data.cutobs_ObjectstoRemove = [];
            data.cutobs_ObjectstoRemove.push({uid:data.selectedCompModelObj.uid,type:data.selectedCompModelObj.type})//Current Component Item
            data.cutobs_ObjectstoRemove.push({uid:_appCtxSvc.ctx.selected.uid,type:_appCtxSvc.ctx.selected.type})// Current Component Item revision
            for (var revUIDIndex=0 ; revUIDIndex < (data.selectedCompModelObj.props.revision_list.dbValues.length-1);revUIDIndex ++)//All component revision
            {
                data.cutobs_ObjectstoRemove.push({uid:data.selectedCompModelObj.props.revision_list.dbValues[revUIDIndex],type:_appCtxSvc.ctx.selected.type});
            }
            //Workflow Name based on condition
            if((_appCtxSvc.ctx.selected.props.u4_stage.dbValue === 'Packaging Concept Creation') || (_appCtxSvc.ctx.selected.props.u4_stage.dbValue === 'Packaging Refinement'))
            {
                    data.workflowNameToInitiate = "DeleteObjects"
            }
            else
            {
                    data.workflowNameToInitiate = "Delete / Obsolete"
            }
            deferred.resolve();
            });
            return deferred.promise;
        }
        catch(err)
        {
            msgSvc.showError("Something went wrong during prevalidation check");
        }
    }
    //**
     //* This function used for getting PAM and PAM revision uids, these uids used in 'data.cutobs_ObjectstoRemove' for removing from TC Project
     //* 
     //* @param {Object} data ViewModel object
     //* @return
     //* 
     //*
    export let getPAMData= function (data) {
        try{
            
            var deferred = awPromiseService.instance.defer();
            
            var policyId = _policySvc.register({

                types: [{ 
                            name:'ItemRevision',
                            properties:
                            [{
                                name: 'items_tag',
                                modifiers:
                                [{name:"withProperties",
                                 Value:"true"}]
                            },
                            {
                                name: 'revision_list',
                                modifiers:
                                [{name:"withProperties",
                                 Value:"true"}]
                            }]
                        }
                    ]         
                });
                  var inputData = 
                    {
                        uids: [data.cutobs_pamRevUID]
                    }
                    _soaSvc.post("Core-2007-09-DataManagement", "loadObjects", inputData).then(function (response)
                                        
                    {
                        _policySvc.unregister(policyId);
                        var pamUID  =response.modelObjects[data.cutobs_pamRevUID].props.items_tag.dbValues[0];
                        var pamType = response.modelObjects[data.cutobs_pamRevUID].type;
                        pamType     = pamType.substring(0,pamType.length -8);//Removing the 'Revision' string for PAM type
                        data.cutobs_ObjectstoRemove.push({uid:pamUID,type:pamType}) //PAM Uid                       
                        for (var revUIDIndex=0 ; revUIDIndex < response.modelObjects[data.cutobs_pamRevUID].props.revision_list.dbValues.length;revUIDIndex ++)// PAM Revision uids
                        {
                            data.cutobs_ObjectstoRemove.push({uid:response.modelObjects[data.cutobs_pamRevUID].props.revision_list.dbValues[revUIDIndex],type:response.modelObjects[data.cutobs_pamRevUID].type});
                        }
                        
                deferred.resolve();
                    });
                return deferred.promise;
                }
            catch(err)
            {
                msgSvc.showError("Not able to get PAM uid, unable to remove component from TC Project");
            }
    }
    
     //* For Refresh objects
     //* 
     //* @param {Object} data ViewModel object
     //* @return
     //* 
     //*
    export let refreshObject= function (data) {
        
        eventBus.publish("cdm.relatedModified", {
            relatedModified: [data.cutobs_selectedParentObj]
        });
    }

	export let moduleServiceNameToInject = 'CmdCutObsoleteService';
export default exports = {
	preChecks,
	getPAMData,
	refreshObject,
	moduleServiceNameToInject
};
app.factory('CmdCutObsoleteService', () => exports );
