/* eslint-disable valid-jsdoc */
// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */


import appCtxSvc from 'js/appCtxService';
import _ from 'lodash';
import eventBus from 'js/eventBus';
import _soaSvc from 'soa/kernel/soaService';
import _msgSvc from 'js/messagingService';
import awPromiseService from 'js/awPromiseService';
import _policySvc from 'soa/kernel/propertyPolicyService';
'use strict';

export let getQueryEntry = function () {
    //  var entries = ['Type', 'Release Status', 'Analysis Type'];
    var entries = ['Type', 'Release Status'];
    return entries;
};

export let getQueryValues = function (data, ctx) {
    let itemType = "Sim Tool Revision";
    let releaseStatus = "Sim Tool Released";
    //let simAnalysisType = ctx.selected.props.u5_analysis_type.dbValue;
    // var parameterValues = [itemType, releaseStatus, simAnalysisType];
    var parameterValues = [itemType, releaseStatus];
    return parameterValues;
};

export let getHostName = function (data, ctx) {
    var simHostName = data.personRespData.modelObjects[ctx.user.props.person.dbValue].props.PA7.dbValues[0];
    data.hpcUserName.dbValue = simHostName;
    data.hpcUserName.dispValue = simHostName;
}
export let getVersion = function (data, ctx) {
    var starCCMModelVersion = data.versionRespData.modelObjects[ctx.pselected.props.u5_model_ref.dbValue].props.u5_starccm_version.dbValues[0];

    if (starCCMModelVersion && starCCMModelVersion != undefined) {
        data.startCCMVersion.dbValue = starCCMModelVersion;
        data.startCCMVersion.dispValue = starCCMModelVersion;
        console.log("Model Revision StarCCM version " + starCCMModelVersion);
    }
}
export let getSimFiles = function (ctx, data, subPanelContext) {
    subPanelContext.data.inputSimFiles = [];
    var simFiles = [];
    simFiles = appCtxSvc.getCtx('inputSimFiles');

    subPanelContext.data.inputSimFiles.push({
        propDisplayValue: "",
        propInternalValue: "",
        dispValue: "",
        propDisplayDescription: ""
    });
    subPanelContext.data.simFileNameUIDPair = {};
    _.forEach(data.grmResponse.output[0].relationshipData[0].relationshipObjects, function (eachSecObj) {
        if (eachSecObj.otherSideObject.type == "U5_SimStarcmm") {
            var datasetObjStr = eachSecObj.otherSideObject.props.object_string.dbValues[0];
            var isReselection = false;
            //for avoiding duplicates when user closes pop up and re-select the Sim tool Revision
            for (let index = 0; index < subPanelContext.data.inputSimFiles.length; ++index) {
                if (subPanelContext.data.inputSimFiles[index].propDisplayValue == datasetObjStr) {
                    isReselection = true;
                    break;
                }
            }
            if (!isReselection) {
                subPanelContext.data.inputSimFiles.push({
                    propDisplayValue: datasetObjStr,
                    propInternalValue: datasetObjStr,
                    dispValue: datasetObjStr,
                    propDisplayDescription: ""
                });
            }

            subPanelContext.data.simFileNameUIDPair[datasetObjStr] = eachSecObj.otherSideObject.uid;
        }
    });
    if (simFiles.length > 0) {
        subPanelContext.data.inputSimFiles = subPanelContext.data.inputSimFiles.concat(simFiles);
    }
    if (subPanelContext.data.inputSimFiles.length == 2) {
        data.simFiles.dbValue = subPanelContext.data.inputSimFiles[1].propDisplayValue;
        data.simFiles.displayValues[0] = subPanelContext.data.inputSimFiles[1].propDisplayValue;
        data.simFiles.uiValue = subPanelContext.data.inputSimFiles[1].propDisplayValue;
        data.simFiles.dispValue = subPanelContext.data.inputSimFiles[1].propDisplayValue;
    }
    return subPanelContext;
}

export let createForm = function (data, ctx, subPanelContext) {
    var hpcUserName = data.hpcUserName.dbValue;
    var hpcPassPharse = data.hpcPassPharse.dbValue;
    var hpcCluster = data.hpcCluster.dbValue;
    var hpcCores = data.hpcCores.dbValue;
    var wallTime = data.wallTime.dbValue;
    var startCCMVersion = data.startCCMVersion.dbValue;
    startCCMVersion = startCCMVersion.replace(/(\r\n|\n|\r)/gm, "");
    var licenseServer = data.licenseServer.dbValue;
    var podKey = data.podKey.dbValue;
    var selectedSimFile = data.simFiles.uiValue;
    var selectedTool = subPanelContext.data.dataProviders.performSearch.selectedObjects[0];//selected ootb released revision
    var simDatasetUID = "";
    var analysisObject = null;
    if (appCtxSvc.ctx.pselected === undefined) {
        analysisObject = appCtxSvc.ctx.selected;
    } else {
        analysisObject = appCtxSvc.ctx.pselected;
    }
    var simFileObjectKey = Object.keys(subPanelContext.data.simFileNameUIDPair);
    var meshedSimFileDir = "";
    //if user selects the Main sim file then we are sending dataset UID as soa input for this sim file download
    if (selectedSimFile === simFileObjectKey[0]) {
        simDatasetUID = subPanelContext.data.simFileNameUIDPair[selectedSimFile];
    }
    // if user selects any Meshed SIM File other than the Main sim file then we are sending '-'as soa input because meshed file downloading handled in Hartree batch file
    else {
        simDatasetUID = "-";
        for (let index = 0; index < subPanelContext.data.inputSimFiles.length; ++index) {
            var element = subPanelContext.data.inputSimFiles[index];
            if (element.propDisplayValue === selectedSimFile) {
                meshedSimFileDir = element.propInternalValue;
                break;
            }
        }
    }
    console.log("Entered Values are:" + hpcUserName + hpcPassPharse + hpcCluster + hpcCores + wallTime + startCCMVersion + licenseServer + selectedSimFile);
    if (hpcUserName.length > 0) {

        var info = [];
        info.push({
            clientId: "formCreation",
            formObject: null,
            parentObject: ctx.selected,
            relationName: "IMAN_specification",
            saveDB: true,
            name: hpcUserName,
            description: "Simulation Launch Inputs Form",
            formType: "U5_SimLaunchInputs",
            attributesMap:
            {
                "u5_hpc_user_name": [hpcUserName],
                "u5_hpc_cluster": [hpcCluster],
                "u5_hpc_cores": [hpcCores],
                "u5_wall_time": [wallTime],
                "u5_starcmm_version": [startCCMVersion],
                "u5_license_server": [licenseServer],
                "u5_primary_object": [analysisObject.uid],
                "u5_tool": [selectedTool.uid]
            }
        });
        var inputData = { info };
        //This method ensures the form is created and attached to the specified parent object.
        _soaSvc.post('Core-2007-01-DataManagement', 'createOrUpdateForms', inputData).then(function (soaResponse) {
            var params = [];
            console.log("Entered in success");
            var createdForm = soaResponse.outputs[0].form;
            eventBus.publish('complete', {
                source: 'toolAndInfoPanel'
            });
            var eventData = {
                "relatedModified": [analysisObject],
                "refreshLocationFlag": false
            };
            eventBus.publish("cdm.relatedModified", eventData);
            _msgSvc.showInfo("Simulation Job Triggered Successfully.");
            //custom SOA inputs
            var inputdata = {
                strAnalysisRevUID: analysisObject.uid,
                strToolRevUID: selectedTool.uid,
                strLaunchFormUID: createdForm.uid,
                strSimDatasetUID: simDatasetUID,
                strServerPassPhrase: hpcPassPharse,
                strMeshedSimDirectory: meshedSimFileDir,
                strPodKey: podKey
            };
            _soaSvc.post("Simulation-2021-06-SimExecuteJob", "simTriggerJobSer", inputdata).then(function (customSoaResponse) {
                console.log("Custom SOA Response recieved");
                console.log("Response is -: ", customSoaResponse);
                console.log(customSoaResponse);
            })
        })
            .catch(function (err) {
                _msgSvc.showError(err.message);
            });
    }
    eventBus.publish('aw.complete');
};

/**
 * Check selection. Only allow values from the list, no suggestions.
 * @param {Array} selected - selected list values
 * @param {Boolean} suggested - was the value suggested/typed?
 *
 * @return {Object} - validation response
 */
export let rejectSuggestions = function (selected, suggestion) {
    var valid = true;
    var message = '';

    if (suggestion) {
        valid = false;
        message = 'The value ' + suggestion + ' is not a valid value.';
    }

    return {
        valid: valid,
        message: message
    };
};

/**
 *
 * @param {Object} resp query response
 */
export let processQueryResults = function (resp) {
    var sortedResults;
    if (resp.arrayOfResults[0].numOfObjects > 0) {
        sortedResults = resp.arrayOfResults[0].objects.sort();
    } else {
        sortedResults = resp.arrayOfResults[0].objects;
    }
    return sortedResults;
};

/**
 *
 * @param {Object} resp query response
 */
export let checkSimValidation = function (data) {

    var myCtx = appCtxSvc.ctx;

    var inputdata = {
        strLoginPersonUID: myCtx.user.props.person.dbValue
    };
    var msgObj = {
        msg: ''
    };
    try {
        var deferred = awPromiseService.instance.defer();
        data.isSimValidationPassed = true;
        _soaSvc.postUnchecked("Simulation-2021-06-SimExecuteJob", "simValidation", inputdata).then(
            function (servDataResponse) {
                if (servDataResponse && servDataResponse.partialErrors) {
                    _.forEach(servDataResponse.partialErrors, function (partialError) {
                        getErrorMsgString(partialError.errorValues, msgObj);
                    });
                    _msgSvc.showError(msgObj.msg);
                    data.isSimValidationPassed = false;
                }
                deferred.resolve();
            });
    }
    catch (err) {
        _msgSvc.showError("Something went while checking sim valiation.");
    }
    return deferred.promise;
};

export let checkAttachedForms = function (ctx, data) {
    var deferred = awPromiseService.instance.defer();
    data.isFormValidationPassed = true;


    appCtxSvc.registerCtx('inputSimFiles', []);

    var simFormObjloadData = {
        uids: ctx.pselected.props.IMAN_specification.dbValues
    };

    var policy = {
        'types': [{
            'name': 'U5_SimLaunchInputs',
            'properties': [{
                'name': 'u5_job_status'
            },
            {
                'name': 'u5_finish_time'
            },
            {
                'name': 'u5_meshsim_directory'
            }
            ]
        }]
    };

    var simFiles = [];
    var policyID = _policySvc.register(policy);
    _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', simFormObjloadData).then(function (loadObjResponse) {
        _policySvc.unregister(policyID);
        _.forEach(loadObjResponse.modelObjects, function (eachFormObj) {
            if (eachFormObj.type == "U5_SimLaunchInputs") {
                var jobStatus = eachFormObj.props.u5_job_status.uiValues[0];
                if (jobStatus == "In Progress") {
                    data.isFormValidationPassed = false;
                }
                if (eachFormObj.props.u5_meshsim_directory.uiValues[0] &&
                    eachFormObj.props.u5_meshsim_directory.uiValues[0] != undefined &&
                    eachFormObj.props.u5_meshsim_directory.uiValues[0] != null) {
                    var meshedSimInfo = eachFormObj.props.u5_meshsim_directory.uiValues[0];

                    var splittedStr = meshedSimInfo.split("/");
                    if (splittedStr[2] && splittedStr[2] != undefined && splittedStr[2] != null) {

                        simFiles.push({
                            propDisplayValue: splittedStr[2],
                            propInternalValue: meshedSimInfo,
                            propDisplayDescription: ""
                        });
                    }
                }
            }
            appCtxSvc.updateCtx('inputSimFiles', simFiles);
        });
        deferred.resolve();

    });

    return deferred.promise;
};

var getErrorMsgString = function (messages, msgObj) {
    _.forEach(messages, function (object) {
        if (msgObj.msg.length > 0) {
            msgObj.msg += '<BR/>';
        }
        //Performing String operation for getting only error message without errorcode
        let tempStr = object.message;
        let commaIndexOf = tempStr.indexOf(", ");
        let msgLength = tempStr.length;
        msgObj.msg += tempStr.substring((commaIndexOf + 3), (msgLength - 1));
    });
};

export let emptyPodKeyValue = function (data) {
    data.dispatch({ path: 'data.podKey.dbValue', value: "" });
}

export let updateExcel = async function (ctx, data) {

    data.enableSubmitButton = "";
    data.dispatch({ path: 'data.enableSubmitButton', value: true });
    // var selectedSimRunInputProps = {};
    // var inputData = {
    //     primaryObjects: [ctx.pselected],
    //     pref:
    //     {
    //         expItemRev: false,
    //         returnRelations: false,
    //         info:
    //             [{
    //                 relationTypeName: "IMAN_specification",
    //                 otherSideObjectTypes: []
    //             }]
    //     }
    // }
    // await _soaSvc.post('Core-2007-09-DataManagement', 'expandGRMRelationsForPrimary', inputData).then(function (response) {
    //     console.log(" objects:", response);
    //     var mAnalysisHForm = _.filter(response.ServiceData.modelObjects, { 'type': 'U5_MAnalysisHForm' });
    //     var mAnalysisForm = response.ServiceData.modelObjects[ctx.selected.uid];
    //     var property = [];

    // for (var prop in mAnalysisHForm[0].props) {
    //     if (_.startsWith(prop, 'u5_p')) //custom relations always starts with u5_p
    //         //property.push(mAnalysisHForm[0].props[prop]);
    //         property.push({
    //             name: prop,
    //             values: mAnalysisHForm[0].props[prop]
    //         })
    //     if (_.startsWith(prop, 'u5_file')) //custom relations always starts with u5_file
    //         //property.push(mAnalysisHForm[0].props[prop]);
    //         property.push({
    //             name: prop,
    //             values: mAnalysisHForm[0].props[prop]
    //         })
    // }

    //     _.forEach(property, function (hProp) {
    //         _.forEach(mAnalysisForm.props, function (props) {
    //             if (hProp.values.dbValues[0] != null && hProp.values.dbValues[0] != '') {
    //                 if (props.dbValues[0] != null && props.dbValues[0] != '') {
    //                     if (hProp.name === props.propertyDescriptor.name) {
    //                         selectedSimRunInputProps[hProp.values.dbValues[0]] = props.dbValues[0];
    //                     }
    //                 }
    //             }
    //         });
    //     });
    //     console.log(selectedSimRunInputProps);   
    // });
    var selectedMAnalysisFormUIDs = [];
    for (var i = 0; i < ctx.mselected.length; i++) {
        if (ctx.mselected[i].type == "U5_MAnalysisForm")
            selectedMAnalysisFormUIDs.push(ctx.mselected[i].uid);
    }
    console.log("selected MAnalysisFormUIDs:", selectedMAnalysisFormUIDs);
    var excelUpdateInput = {
        mAnalysisUID: ctx.pselected.uid,
        mAnalysisFormUID: selectedMAnalysisFormUIDs
    }
    await _soaSvc.post('Simulation-2023-06-MAnalysisSimExecuteJob', 'mAnalysisExcelUpdate', excelUpdateInput).then(function (response) {
        console.log(" objects:", response);
    })
    return;
}

export let mAnalysisSimJob = async function (data, ctx, subPanelContext) {
    console.log("Inside Mass Analysis Sim Job")
    var hpcUserName = data.hpcUserName.dbValue;
    var hpcPassPharse = data.hpcPassPharse.dbValue;
    var hpcCluster = data.hpcCluster.dbValue;
    var hpcCores = data.hpcCores.dbValue;
    var wallTime = data.wallTime.dbValue;
    var startCCMVersion = data.startCCMVersion.dbValue;
    startCCMVersion = startCCMVersion.replace(/(\r\n|\n|\r)/gm, "");
    var licenseServer = data.licenseServer.dbValue;
    var podKey = data.podKey.dbValue;
    var selectedSimFile = data.simFiles.uiValue;
    var selectedTool = subPanelContext.data.dataProviders.performSearch.selectedObjects[0];//selected ootb released revision
    var simDatasetUID = "";
    var mAnalysisObject = null;
    if (appCtxSvc.ctx.pselected === undefined) {
        mAnalysisObject = appCtxSvc.ctx.pselected;
    } else {
        mAnalysisObject = appCtxSvc.ctx.pselected;
    }
    var simFileObjectKey = Object.keys(subPanelContext.data.simFileNameUIDPair);
    var meshedSimFileDir = "";
    //if user selects the Main sim file then we are sending dataset UID as soa input for this sim file download
    if (selectedSimFile === simFileObjectKey[0]) {
        simDatasetUID = subPanelContext.data.simFileNameUIDPair[selectedSimFile];
    }
    // if user selects any Meshed SIM File other than the Main sim file then we are sending '-'as soa input because meshed file downloading handled in Hartree batch file
    else {
        simDatasetUID = "-";
        for (let index = 0; index < subPanelContext.data.inputSimFiles.length; ++index) {
            var element = subPanelContext.data.inputSimFiles[index];
            if (element.propDisplayValue === selectedSimFile) {
                meshedSimFileDir = element.propInternalValue;
                break;
            }
        }
    }
    console.log("Entered Values are:" + hpcUserName + hpcPassPharse + hpcCluster + hpcCores + wallTime + startCCMVersion + licenseServer + selectedSimFile);

    /* eslint-disable no-await-in-loop */
    for (var i = 0; i < ctx.mselected.length; i++) {
        if (ctx.mselected[i].type == "U5_MAnalysisForm") {
			var analysisModObj = null;
            if (hpcUserName.length > 0) {
				
				// Get analysis Object from analysisform
				/*var inputData = {
					objects: [{
						uid: ctx.mselected[i].uid,
						type: ctx.mselected[i].type
					}],
					attributes: ['u5_analysis_ref']
				};
				
				var analysisresponse =  await _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData);
				if (analysisresponse) {
					analysisModObj = analysisresponse.modelObjects[analysisresponse.plain[0]];
					
				}*/
				if (ctx.mselected[i].props.u5_analysis_ref.dbValues && ctx.mselected[i].props.u5_analysis_ref.dbValues[0])
				{
					var analysisModObjuid = ctx.mselected[i].props.u5_analysis_ref.dbValues[0];
					
					var loadObjectsInputData = { uids: [analysisModObjuid]};

					//to load the objects from uid
					var loadObjectsResponse = await _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', loadObjectsInputData);
					{
						analysisModObj = loadObjectsResponse.modelObjects[loadObjectsResponse.plain[0]];
					}
									
				}
				
                var info = [];
                info.push({
                    clientId: "formCreation",
                    formObject: null,
                    parentObject: analysisModObj,
                    relationName: "IMAN_specification",
                    saveDB: true,
                    name: "Results",
                    description: "Simulation Launch Inputs Form",
                    formType: "U5_SimLaunchInputs",
                    attributesMap:
                    {
                        "u5_hpc_user_name": [hpcUserName],
                        "u5_hpc_cluster": [hpcCluster],
                        "u5_hpc_cores": [hpcCores],
                        "u5_wall_time": [wallTime],
                        "u5_starcmm_version": [startCCMVersion],
                        "u5_license_server": [licenseServer],
                        "u5_primary_object": [analysisModObj.uid],
                        "u5_tool": [selectedTool.uid]
                    }
                });
                var inputData = { info };
                var createdForm  = "";
                //This method ensures the form is created and attached to the specified parent object.
                await _soaSvc.post('Core-2007-01-DataManagement', 'createOrUpdateForms', inputData).then(function (soaResponse) {
                    var params = [];
                    console.log("Entered in success");
                    createdForm = soaResponse.outputs[0].form;

                    var setPropertiesInputData = {
                        info: [{
                            object: ctx.mselected[i],
                            vecNameVal: [{
                                name: 'u5_simjob_ref',
                                values: [createdForm.uid]
                            }]
                        }],
                        options: []
                    };
                    _soaSvc.post('Core-2010-09-DataManagement', 'setProperties', setPropertiesInputData).then(function (setPropertiesResponse) {
                    });
                })

                    // eventBus.publish('complete', {
                    //     source: 'toolAndInfoPanel'
                    // });
                    // var eventData = {
                    //     "relatedModified": [mAnalysisObject],
                    //     "refreshLocationFlag": true
                    // };
                    // eventBus.publish("cdm.relatedModified", eventData);
                    // _msgSvc.showInfo("Simulation Job Triggered Successfully.");
                    //custom SOA inputs
                    var inputdata = {
                        //strMAnalysisRevUID: mAnalysisObject.uid,
						strMAnalysisRevUID: analysisModObj.uid,
                        strToolRevUID: selectedTool.uid,
                        strLaunchFormUID: createdForm.uid,
                        strSimDatasetUID: simDatasetUID,
                        strServerPassPhrase: hpcPassPharse,
                        strMeshedSimDirectory: meshedSimFileDir,
                        strPodKey: podKey,
                        mAnalysisFormUID: ctx.mselected[i].uid
                    };
                    await _soaSvc.post('Simulation-2023-06-MAnalysisSimExecuteJob', 'mAnalysisSimTriggerJobSer', inputdata).then(function (customSoaResponse) {
                        console.log("Custom SOA Response recieved");
                        console.log("Response is -: ", customSoaResponse);
                        console.log(customSoaResponse);
                    })
                //})
                    .catch(function (err) {
                        _msgSvc.showError(err.message);
                    });
            }
        }
    }
    /* eslint-disable no-await-in-loop */
    eventBus.publish('complete', {
        source: 'toolAndInfoPanel'
    });
    var eventData = {
        "relatedModified": [mAnalysisObject],
        "refreshLocationFlag": true
    };
    eventBus.publish("cdm.relatedModified", eventData);
    _msgSvc.showInfo("Simulation Job Triggered Successfully.");
    eventBus.publish('aw.complete');
};

export default {
    getQueryEntry,
    getQueryValues,
    processQueryResults,
    createForm,
    rejectSuggestions,
    checkSimValidation,
    checkAttachedForms,
    getHostName,
    getVersion,
    getSimFiles,
    emptyPodKeyValue,
    updateExcel,
    mAnalysisSimJob
};
