// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */
import app from 'app';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import _queryUtil from 'js/UnileverQueryUtil';
import _commUtil from 'js/UnileverCommonUtil';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import awPromiseService from 'js/awPromiseService';
import _policySvc from 'soa/kernel/propertyPolicyService';
import $ from 'jquery';

/**
 * Mct list service
 *
 * @module js/Mct_PnP_SublocationService
 */

'use strict';

var exports = {};



export let MctAddViewVIEWMODEL = null;
var MctSublocationVIEWMODEL = null;
//var conditions = null;



export let openMctPnpSublocation = function () {

    var MctRev = getSelectedMct();

    if (MctRev) {
        exports.openMct(MctRev.uid);
    }

}

export let openMct = function (MctRevUid) {


    sessionStorage._Mct_PnP_NotOpenedThroughTile = 'true'; // if you store a boolean to localStorage or sessionStorage, it turns to string. so stored as a string
    sessionStorage._Mct_PnP_Rev_UID = MctRevUid;

    _appCtxSvc.registerCtx('ViewModeContext', {
        ViewModeContext: 'SummaryView'
    });

    window.location.href = (window.location.origin + window.location.pathname + '#/' + 'Mct_PnP_Sublocation');

}

var getSelectedMct = function () {

    var MctRev = null;
    if ((_appCtxSvc.ctx.pselected && (_appCtxSvc.ctx.pselected.type === 'U4_MCT_PnPRevision')))
        MctRev = _appCtxSvc.ctx.pselected;

    if ((_appCtxSvc.ctx.selected && (_appCtxSvc.ctx.selected.type === 'U4_MCT_PnPRevision')))
        MctRev = _appCtxSvc.ctx.selected;

    if (!MctRev) {
        _messagingSvc.showInfo('Please Open/Select MCT Revision.');
    }
    return MctRev;
}

export let changeToPnpSummaryView = function () {


    _appCtxSvc.registerCtx('ViewModeContext', {
        ViewModeContext: 'SummaryView'
    });
    _messagingSvc.showInfo('MCT Operation does not support views other than summary view.');
}

export let pnpCtxChange = function () {

    if (_appCtxSvc.ctx.ViewModeContext.ViewModeContext !== 'SummaryView' && (_appCtxSvc.ctx.locationContext) && (_appCtxSvc.ctx.locationContext['ActiveWorkspace:SubLocation'] === 'Mct_PnP_Sublocation')) {
        exports.changeToPnpSummaryView();
    }
}

export let reveal = function (data) {

    var customconditions = {
        showFirstPage: false,
        showSecondPage: false,
        loadMCT: true
    };
    _appCtxSvc.registerCtx('MctSublocation.customconditions', customconditions);

    var willOpenSecondPage = false;
    // open first page if it is triggered from Mct tile
    // if sublocation is triggered programmatically open second page
    if (sessionStorage._Mct_PnP_NotOpenedThroughTile === 'true') {
        willOpenSecondPage = true;
        sessionStorage._Mct_PnP_NotOpenedThroughTile = 'false';
    }


    MctSublocationVIEWMODEL = data;
    //conditions = _conditions;
    _appCtxSvc.registerCtx('MctSublocation.MctSublocationdata', data);


    // var deferred = awPromiseService.instance.defer();

    //MctSublocationVIEWMODEL.pnpObjs = [];
    //MctSublocationVIEWMODEL.pnpTemplateObj = [];
    //MctSublocationVIEWMODEL.MctObjectInArray = [];

    var pnpObjs = [];
    _appCtxSvc.registerCtx('MctSublocation.pnpObjs', pnpObjs);

    var pnpTemplateObj = [];
    _appCtxSvc.registerCtx('MctSublocation.pnpTemplateObj', pnpTemplateObj);

    var MctObjectInArray = [];
    _appCtxSvc.registerCtx('MctSublocation.MctObjectInArray', MctObjectInArray);



    loadFirstPage(function () {
        if (sessionStorage._Mct_PnP_Rev_UID && willOpenSecondPage) {
            var MctRev = {
                uid: sessionStorage._Mct_PnP_Rev_UID
            };

            loadSecondPage(MctRev, function () {
                setPage('second');
                //deferred.resolve();
            });
        } else {
            setPage('first');
            //deferred.resolve();
        }

    });


    // return deferred.promise;

}

var loadFirstPage = function (callback) {

    var MctLatestRevsDataprovider = _appCtxSvc.getCtx("MctSublocation.MctSublocationdata.dataProviders[MctLatestRevsDataprovider]");

    exports.getCurrentUserMCTs(function (MctItemandRevision) {

        var MctLatestRevs = [];
        for (var g in MctItemandRevision) {
            if (MctItemandRevision[g].type === 'U4_MCT_PnP') {
                var MctItem = MctItemandRevision[g];
                var latestRev = exports.getLatestRev(MctItem, MctItemandRevision);
                exports.setPnPCountToRev(latestRev);
                MctLatestRevs.push(latestRev);
            }

        }

        if (MctLatestRevs.length === 0) {
            _messagingSvc.showInfo('No MCT found.');
        }

        var currentUserMcts = MctLatestRevs;
        _appCtxSvc.registerCtx("MctSublocation.currentUserMcts", currentUserMcts);

        var MctLatestRevsDataprovider = MctSublocationVIEWMODEL.dataProviders.MctLatestRevsDataprovider;
        var vmo = MctLatestRevsDataprovider.viewModelCollection;

        vmo.loadedVMObjects = currentUserMcts;
        MctLatestRevsDataprovider.update(vmo.loadedVMObjects);
        _appCtxSvc.updateCtx("MctSublocation.MctSublocationdata.dataProviders[MctLatestRevsDataprovider]", MctLatestRevsDataprovider);

        //MctSublocationVIEWMODEL.currentUserMcts = MctLatestRevs;
        // _commUtil.sortComponents(MctSublocationVIEWMODEL, 'currentUserMcts', 0, 0);
        callback();

    });
}

var loadSecondPage = function (latestRevMct, callback) {


    var customCondition = _appCtxSvc.getCtx("MctSublocation.customconditions");
    var pnpObjs = _appCtxSvc.getCtx("MctSublocation.pnpObjs");
    var pnpTemplateObj = _appCtxSvc.getCtx("MctSublocation.pnpTemplateObj");
    var MctObjectInArray = _appCtxSvc.getCtx("MctSublocation.MctObjectInArray");

    loadMct(MctSublocationVIEWMODEL, latestRevMct, function () {

        MctSublocationVIEWMODEL.dataProviders.pamDataprovider.update(pnpObjs, pnpObjs.length);

        MctSublocationVIEWMODEL.dataProviders.pamTemplateDataprovider.update(pnpTemplateObj, pnpTemplateObj.length);

        MctSublocationVIEWMODEL.dataProviders.MctDataprovider.update(MctObjectInArray, MctObjectInArray.length);

        MctObjectInArray = [];
        _appCtxSvc.updateCtx("MctSublocation.MctObjectInArray", MctObjectInArray);

        MctSublocationVIEWMODEL._isSecondPageLoadedOnce = true;
        MctSublocationVIEWMODEL._isSecondPageLoadedMctRev = latestRevMct;
        callback();

    });
}

var loadMct = function (data, MctRev, callback) {

    var pnpObjs = _appCtxSvc.getCtx("MctSublocation.pnpObjs");
    var pnpTemplateObj = _appCtxSvc.getCtx("MctSublocation.pnpTemplateObj");
    var MctObjectInArray = _appCtxSvc.getCtx("MctSublocation.MctObjectInArray");


    var inputData = {
        objects: [MctRev],
        attributes: ['U4_MCT_PnP_Temp_Relation', 'U4_MCT_PnP_Relation']
    };


    _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (getPropertiesResponse) {
        var MctRev = getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]];

        var pnpUids = MctRev.props.U4_MCT_PnP_Relation.dbValues;

        var pnpTemplateUid = MctRev.props.U4_MCT_PnP_Temp_Relation.dbValues[0];

        //data.pnpObjs = [];
        //data.pnpTemplateObj = [];
        //data.MctObjectInArray = [];

        for (var i in pnpUids) {

            //data.pnpObjs.push(getPropertiesResponse.modelObjects[pnpUids[i]]);
            pnpObjs.push(getPropertiesResponse.modelObjects[pnpUids[i]]);

        }
        // _commUtil.sortComponents(data, 'pnpObjs', 0, 0);

        var pamTemplate1 = getPropertiesResponse.modelObjects[pnpTemplateUid];
        pnpTemplateObj.push(pamTemplate1);
        //data.pnpTemplateObj.push(pamTemplate1);
        //_commUtil.sortComponents(data, 'pnpTemplateObj', 0, 0);

        _appCtxSvc.updateCtx("MctSublocation.pnpTemplateObj", pnpTemplateObj);
        var pamTemplateDataprovider = MctSublocationVIEWMODEL.dataProviders.pamTemplateDataprovider;
        var vmo = pamTemplateDataprovider.viewModelCollection;
        vmo.loadedVMObjects = pnpTemplateObj;

        pamTemplateDataprovider.update(vmo.loadedVMObjects);
        _appCtxSvc.updateCtx("MctSublocation.pnpObjs", pnpObjs);

        pnpTemplateObj = [];
        _appCtxSvc.updateCtx("MctSublocation.pnpTemplateObj", pnpTemplateObj);


        //exports.setTotalPnPCountToRev(MctRev);
        MctObjectInArray.push(MctRev);
        callback();

    });

}

export let emptyAction = function () { }


/**
 * goto previous page
 */
export let Mct_PnP_PreviousCommandAction = function () {

    var customconditions = _appCtxSvc.getCtx('MctSublocation.customconditions');
    if (customconditions.showSecondPage) {
        setPage('first');
    }
}

/**
 *  goto next page
 */
export let Mct_PnP_NextCommandAction = function () {

    var customconditions = _appCtxSvc.getCtx('MctSublocation.customconditions');

    if (customconditions.showFirstPage) {

        var selectedMctRevs = MctSublocationVIEWMODEL.dataProviders.MctLatestRevsDataprovider.selectedObjects;
        if (MctSublocationVIEWMODEL._isSecondPageLoadedOnce || (selectedMctRevs.length === 1)) {
            var latestRevMct = (selectedMctRevs.length === 1) ? selectedMctRevs[0] : MctSublocationVIEWMODEL._isSecondPageLoadedMctRev;

            if (selectedMctRevs.length > 0) {
                loadSecondPage(latestRevMct, function () {
                    sessionStorage._Mct_PnP_Rev_UID = latestRevMct.uid;
                    setPage('second');
                });

            }
            else {
                //setPage('second');
                _messagingSvc.showInfo('Please select a MCT to proceed.');
            }



        } else {
            _messagingSvc.showInfo('Please select a MCT to proceed.');
        }


    }


}




export let getCurrentUserMCTs = function (callback) {

    var entries = ['Type', 'Owning User'];
    var values = ['MCT PnP', _appCtxSvc.ctx.userSession.props.user.displayValues[0]];

    var policy = {
        'types': [{
            'name': 'U4_MCT_PnP',
            'properties': [{
                'name': 'revision_list',
                'modifiers': [{
                    'name': 'withProperties',
                    'Value': 'true'
                }]
            }, {
                'name': 'object_name'
            }]
        }, {
            'name': 'U4_MCT_PnPRevision',
            'properties': [{
                'name': 'U4_MCT_PnP_Relation'
            },
            {
                'name': 'u4_mctPnPType'
            }]
        }]
    };
    _queryUtil.executeQuery('Item...', entries, values, policy, true, function (queryOutput) {

        callback(queryOutput);

    });
}

export let getLatestRev = function (item, mObjs) {
    var latestRevUid = item.props.revision_list.dbValues[item.props.revision_list.dbValues.length - 1];
    for (var h in mObjs) {
        if (mObjs[h].uid === latestRevUid) {
            return mObjs[h];
        }
    }
}

export let setPnPCountToRev = function (rev) {

    var pnpCount = rev.props.U4_MCT_PnP_Relation.dbValues.length;
    pnpCount = pnpCount + '';
    _commUtil.addCustomPropertiesToModelObj(rev, {
        key: 'PnP Count',
        value: pnpCount
    });
    // rev.props.awp0CellProperties.dbValues.push('PnP Count \\:' + pnpCount);

}

/* export let setTotalPnPCountToRev = function (rev) {

     var pnpCount = rev.props.U4_MCT_PnP_Relation.dbValues.length;
     pnpCount = pnpCount + '';
     _commUtil.addCustomPropertiesToModelObj(rev, {
         key: 'Total PnP Count',
         value: pnpCount
     });

     //rev.props.awp0CellProperties.dbValues.push('Total PnP Count \\:' + pnpCount);

 }*/

var setPage = function (page, willOpenSecondPage) {

    var customconditions = _appCtxSvc.getCtx('MctSublocation.customconditions');

    if (page === 'first') {
        customconditions.showFirstPage = true;
        customconditions.showSecondPage = false;
    }

    if (page === 'second') {
        customconditions.showFirstPage = false;
        customconditions.showSecondPage = true;
    }

    _appCtxSvc.updateCtx('MctSublocation.customconditions', customconditions);
}

/**
 * 
 * sort components
 * 
 * @param {Integer} index to decide sort by ID or Name
 * @param {Integer} order to decide sort ascending or descending
 */
var sortComponents = function (index, order, data) {

    var currentDataproviderInfo = getDataprovider();

    _commUtil.sortDataProviderObjects(currentDataproviderInfo.data, currentDataproviderInfo.dataproviderName, index, order);

    data.dataProviders[currentDataproviderInfo.dataproviderName].viewModelCollection.loadedVMObjects = currentDataproviderInfo.data.dataProviders[currentDataproviderInfo.dataproviderName].viewModelCollection.loadedVMObjects;
    data.dataProviders[currentDataproviderInfo.dataproviderName].update(data.dataProviders[currentDataproviderInfo.dataproviderName].viewModelCollection.loadedVMObjects);
}

var getDataprovider = function () {

    var customconditions = _appCtxSvc.getCtx('MctSublocation.customconditions');

    if (_appCtxSvc.ctx.sublocation.historyNameToken === 'Mct_PnP_Sublocation') {

        if (customconditions.showSecondPage) {

            var obj = {};
            obj.data = MctSublocationVIEWMODEL;

            if (MctSublocationVIEWMODEL.Mct_tab[0].selectedTab)
                obj.dataproviderName = 'pamTemplateDataprovider';

            /*  if (MctSublocationVIEWMODEL.Mct_tab[1].selectedTab)
                obj.dataproviderName = 'pamDataprovider';
*/

            return obj;

        }

        if (customconditions.showFirstPage) {

            var obj = {};
            obj.data = MctSublocationVIEWMODEL;
            obj.dataproviderName = 'MctLatestRevsDataprovider';

            return obj;

        }

    } else {

        var obj = {};
        obj.data = exports.MctAddViewVIEWMODEL;
        obj.dataproviderName = 'MctItemsDataprovider';

        return obj;

    }
}


/**
 * sort by ID in ascending order
 */
export let sortByIDAscendMct_PnP_CommandAction = function (data) {
    sortComponents(1, 0, data);
}

/**
 * sort by ID in descending order
 */
export let sortByIDDescendMct_PnP_CommandAction = function (data) {

    sortComponents(1, 1, data);
}

/**
 * sort by name in ascending order
 */
export let sortByNameAscendMct_PnP_CommandAction = function (data) {

    sortComponents(0, 0, data);
}

/**
 * sort by name in descending order
 */

export let sortByNameDescendMct_PnP_CommandAction = function (data) {

    sortComponents(0, 1, data);

}

export let updateCountAfterPnPremoval = function (removeChildrenResponse, MctRev) {
    var currentPnpsUid = removeChildrenResponse.modelObjects[MctRev.uid].props.U4_MCT_PnP_Relation.dbValues;
    MctSublocationVIEWMODEL.dataProviders.MctDataprovider.viewModelCollection.loadedVMObjects[0].props._awc_customCellProperties.dbValues[0]['PnP Count'].value = '' + currentPnpsUid.length;
    //MctSublocationVIEWMODEL.dataProviders.MctDataprovider.viewModelCollection.loadedVMObjects[0].cellProperties['Total PnP Count '].value = '' + currentPnpsUid.length;
}
export let moduleServiceNameToInject = 'Mct_PnP_SublocationService';
export default exports = {
    MctAddViewVIEWMODEL,
    openMctPnpSublocation,
    openMct,
    changeToPnpSummaryView,
    pnpCtxChange,
    reveal,
    emptyAction,
    Mct_PnP_PreviousCommandAction,
    Mct_PnP_NextCommandAction,
    getCurrentUserMCTs,
    getLatestRev,
    setPnPCountToRev,
    sortByIDAscendMct_PnP_CommandAction,
    sortByIDDescendMct_PnP_CommandAction,
    sortByNameAscendMct_PnP_CommandAction,
    sortByNameDescendMct_PnP_CommandAction,
    updateCountAfterPnPremoval,
    moduleServiceNameToInject
};
app.factory('Mct_PnP_SublocationService', () => exports);
