import msgSvc from 'js/messagingService';
import awPromiseService from 'js/awPromiseService';
import app from 'app';
import _soaSvc from 'soa/kernel/soaService';
import eventBus from 'js/eventBus';
import _appCtxSvc from 'js/appCtxService';
import 'js/TcSessionData';
import _policySvc from 'soa/kernel/propertyPolicyService';

    'use strict';

    var exports = {};
    
    
    /**
     * In this function, validating the prechecks for dataset Delete Operation
     * 
     * @param {Object} data ViewModel object
     * @return
     * 
     */
    export let datasetDeletePrechecks = function (data) 
    {
      try {
            var deferred = awPromiseService.instance.defer();
            data.delele_isProjectAccesstoUser   = false;
            data.delele_displayDeletePopUp      = true;
            data.delete_selectObjString         = _appCtxSvc.ctx.selected.props.object_string.dbValue;
            data.delete_selectObjType           = _appCtxSvc.ctx.selected.type;
            data.delete_selectObjUID            = _appCtxSvc.ctx.selected.uid;
            data.delete_selectedParentObj       = _appCtxSvc.ctx.pselected;
            data.delete_RelationName            = "";
            var selectObjStatus                 = '';
            if(_appCtxSvc.ctx.selected.type==='Web Link')
            {
                selectObjStatus                 = '';
            }
            else
            {
                selectObjStatus                 = _appCtxSvc.ctx.selected.props.release_status_list.uiValue;
            }
            //For checking whether logging user as privilege access or not
            if(_appCtxSvc.ctx.pselected.props.is_modifiable.dbValues[0] == "0")
            {
				//For Checking dataset status
				if (selectObjStatus != '') 
				{
					msgSvc.showError("You do not have access to delete the dataset as the component is already released.");
					data.delele_displayDeletePopUp = false;
					return;
				}
				else
				{
					msgSvc.showError("You do not have the access to this project to make any changes. Please open the project to which this component is attached and click on Project Subscription icon to get the access.");
					data.delele_displayDeletePopUp = false;
					return;
				}				
                
            }
            //For Checking dataset status
            if (selectObjStatus != '') {
                msgSvc.showError("Selected dataset has a status, unable to delete");
                data.delele_displayDeletePopUp = false;
                return;
            }
            var inputData = 
			{
				objects: [_appCtxSvc.ctx.selected],
				numLevels:1
			}
			_soaSvc.post("Core-2007-01-DataManagement", "whereReferenced", inputData).then(function (response) {
                var Outputresponse  =   response.output["0"];
                var iNoOfRefs       =    Outputresponse.info.length;
                if(iNoOfRefs != 1)
                {
                    data.delele_displayDeletePopUp = false;
                    msgSvc.showError("Selected dataset has reference to other objects, unable to delete");
                }
                else{
                    data.delete_RefType=Outputresponse.info["0"].referencer.type;
                    data.delete_RefUid=Outputresponse.info["0"].referencer.uid;
                    data.delete_RelationName=Outputresponse.info["0"].relation;
                }
                deferred.resolve();
			});
            return deferred.promise;
        }
        catch (err) {
            msgSvc.showError("Unable to able to delete,contact administrator");
        }
    }
    /**
     * For Refresh objects
     * 
     * @param {Object} data ViewModel object
     * @return
     * 
     */
	export let refreshObject= function (data) {
		
        eventBus.publish("cdm.relatedModified", {
			relatedModified: [data.delete_selectedParentObj]
		});
	}


   
export default exports = {
	datasetDeletePrechecks,
	refreshObject
};
app.factory('UnileverDatasetDeleteService', () => exports );
