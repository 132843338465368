// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
define
 */

/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/AIValidationService
 */
import app from 'app';
import ngModule from 'angular';
import eventBus from 'js/eventBus';
import browserUtils from 'js/browserUtils';
import declUtils from 'js/declUtils';
import 'js/declarativeDataCtxService';
import _soaSvc from 'soa/kernel/soaService';
import _messagingSvc from 'js/messagingService';
import _appCtxSvc from 'js/appCtxService';
import _editHandlerService from 'js/editHandlerService';
import AwHttpService from 'js/awHttpService';
import awPromiseService from 'js/awPromiseService';


'use strict';

var _$http, exports = {},
    /*_soaSvc = null,*/
    _endPoint = null,
    isRequestCalled = false;
var oldTabSelection;

/**
 * Dummy alert.
 */
export let alertSaveSuccess = function (eventData) {
    console.log(eventData);
};

export let dataSetUploadHandler = function (eventData) {
    console.log("inside Dataset upload function stage out");
    console.log("addded1");
    console.log("addded2");
    console.log("addded3");
    console.log(eventData);
    console.log(eventData.createdObject.type);
    console.log(eventData.createdObject.uid);
    //console.log(ctx);
};

export let captureTab = function (ctx) {
    //console.log(ctx)
    console.log('Inside Capture Old Tab');
    //oldTabSelection = ctx.xrtPageContext.primaryXrtPageID;
    try {
        if (ctx.xrtPageContext)
            oldTabSelection = ctx.xrtPageContext.primaryXrtPageID;
    } catch (err) {
        return;
    }
    console.log(oldTabSelection);
};
export let MLOperations = function (ctx, data, subCtx) {
    //console.log(ctx)
    console.log('Inside MLOperations call');



    var typeHierarchy = ctx.selected.modelType.typeHierarchyArray
    if (ctx.mselected.length === 1) {
        var selObj = ctx.selected.props;
        var request = {}
        request.url = "/ValidationPnP/ReportService.asmx/";
        request.method = "POST";
        //request.headers= {};
        //request.headers.Content-type="application/json; charset=utf-8";
        request.contentType = "application/json; charset=utf-8";
        request.dataType = "json";

        var rData = {};
        rData.ItemID = selObj.item_id.dbValues[0];
        rData.Revision = selObj.item_revision_id.dbValues[0];
        rData.CurrentUser = ctx.user.props.userid.dbValues[0];
        rData.Port = ctx.preferences.AWC_U4_ReportService_Port[0];
        rData.SelectedTab = subCtx.pageContext.primaryActiveTabId;
        request.data = rData;
        var types = ['U4_PAMRevision', 'U4_ComponentRevision', 'U4_PnPSpecRevision'];
        var urlArr = [
            '/Validation/ReportService.asmx/PAMAIValidation',
            '/Validation/ReportService.asmx/PackAIValidation',
            '/ValidationPnP/ReportService.asmx/PnPAIValidation'];
        var url = ""
        for (var inx = 0; inx < types.length; inx++) {
            if (typeHierarchy.includes(types[inx])) {
                url = urlArr[inx];
                break;
            }
        }

        request.url = url;
        if (url !== "") {
            callServiceWithMessageJS(ctx, request);
        }
    }

    console.log(request);
};

export let callServiceWithMessageJS = function (ctx, request) {
    //alert( ctx );
    console.log(request);
    //request.data.type=request.vData.eventData.createdObjs[0].type;
    //request.data.uid=request.vData.eventData.createdObjs[0].uid;
    if (request.data.type === undefined && request.vData !== undefined && request.vData.eventMap["cdm.relatedModified"]) {
        request.data.type = request.vData.eventMap["cdm.relatedModified"].createdObjects[0].type;
        request.data.uid = request.vData.eventMap["cdm.relatedModified"].createdObjects[0].uid;
    }
    var mimeType = 'application/pdf';
    var fileName = '';
    var retMsg = "ML Validation not enabled, Returning";
    var allowedPAMTabs = ["Component Properties",
        "Materials",
        "Package Details"
    ];
    var allowedPackTabs = ["Environmental",
        "Structured Properties"
    ];
    var allowedPnPTabs = [
        "Configuration",
        "~GS1~"
    ];

    var allowedFrames = [
        "G-CASES-CORR",
        "G-FLEXIBLES",
        "G-LABELS-LIT",
        //"G-PAM-COPACK",
        "G-BOTTLE-PLASTIC",
        "G-CARTONS",
        "G-TUBES",
        "G-CLOSURE-PLAS",
        "G-BAG-ETC-PREMADE",
        "G-INJECTION",
        // ML 1.2
        //"G-VALVE-ACT-DETAIL",
        //"G-AEROSOLS",
        "G-TOOTHBRUSH",
        //"G-ARTICLE",
        "G-PUMP-VALVE-ACT",
        "G-THERMOFORMS",
        //ML-2
        "G-VALVE-ACT-DETAIL",
        "G-BOARD-FLATBC",
        "G-LID-HOOD-PLSTC",
        "G-ADHESIVES-INKS",
        "G-TUB-ETC-PLASTIC",
        //"G-TAPES",
        "G-AEROSOLS",
        "G-BOTTLE-GLASS",
        "G-BUCKET-PLASTIC",
        "G-COV-MEM-ETC",
        "G-LID-BUCKET-PLSTC",
        //ML-3
        "G-CAN-3P-BODY",
        //"G-CAN-END",
        "G-CLOSURE-METAL",
        "G-CONE-SLEEVE",
        //"G-PALLETS",
        //"G-RESINS-ADDITS",
        "G-STICK-ETC-WOOD",
        //"G-SUNDRIES",
        "G-THREAD",
        "G-WIRE",
        "G-TEABAG-MAT",
        "G-TUB-ETC-BOARD"

    ];
    var allowedTechnlogoy = [
        "Cases/Corrugate - Flat components and all Other",
        "Cases/Corrugate - RSC",
        "Cases/Corrugate - RSC w/Tear or Perf",
        "Cases/Corrugate - Hood (Tray)",
        "Cases/Corrugate - Tray (for Hood or Shrink)",
        "Cases/Corrugate - Wrap Around",
        "Cases/Corrugate - Assembly",
        "Flexible Film - Tetra Brick (reel-fed)",
        "Flexible Film - Thermoformed",
        "Flexible Film -  Multilayer Barrier Low",
        "Flexible Film - Monolayer",
        "Flexible Film - Multilayer Barrier High/Med",
        "Flexible Film - Shrink",
        "Flexible Film - Stretch",
        "Flexible Film - Assembly",
        "Labels and Literature - All Other",
        "Labels and Literature - Shrink Sleeve",
        "Labels and Literature - Heat Transfer",
        "Labels and Literature - In Mould",
        "Labels and Literature - Self Adhesive",
        "3PM - All",
        "Plastic Bottle or Jar - Threaded",
        "Plastic Bottle or Jar - Snap-on",
        "Plastic Bottle or Jar - Open (no specific neck finish)",
        "Plastic Bottle or Jar - Snap-on Thread Off",
        "Plastic Bottle or Jar - Assembly",
        "Cartons - (Containers) includes CombiBloc",
        "Cartons - Other Cartonboard",
        "Cartons - Assembly",
        "Tube - Extruded Snap-On",
        "Tube - Extruded Threaded",
        "Tube - Laminate Snap-On",
        "Tube - Laminate Threaded",
        "Tube - Bayonet",
        "Tube - Aluminium",
        "Tube - Assembly",
        "Plastic Closure - Other",
        "Plastic Closure - Push-pull",
        "Plastic Closure - Snap-on",
        "Plastic Closure - Snap-on Flip Top",
        "Plastic Closure - Snap-on Thread Off",
        "Plastic Closure - Threaded",
        "Plastic Closure - Threaded Flip Top",
        "Plastic Closure - Twist Lock",
        "Plastic Closure - Disc Top Threaded",
        "Plastic Closure - Disc Top Snap-On",
        "Plastic Closure - Assembly",
        "Premade Bag - All Other",
        "Premade Bag - Doy/Stand-up/Gusseted/Big-bag",
        "Premade Bag - Film Multilayer Barrier High/Med",
        "Premade Bag - Hot-Fill /Retort",
        "Injection Moulding - All Other",
        "Injection Moulding - Assembly",
        "Injection Moulding - Threaded",
        "Injection Moulding - Sub-Component",
        "Injection Moulding - Snap-on",
        "Molded Components",
        //ML 1.2
        //"Actuator Only",
        //"Actuator/Valve Assembly",
        //"Aerosol Container - All Other Metal",
        //"Aerosol Container - Aluminium",
        "Anchor Less",
        "Article/Item - usually not pkg, e.g. Seals",
        "Non-aerosol Pump - Airless",
        "Non-aerosol Pump - All Other",
        "Non-aerosol Pump - Assembly",
        "Non-aerosol Pump - Fine mist spray (finger act)",
        "Non-aerosol Pump - Lotion",
        "Non-aerosol Pump - Sub-Component",
        "Non-aerosol Pump - Trigger Spray",
        "Staple",
        "Thermoforms",
        "Thermoforms - Assembly",
        //"Valve - Bag-on",
        //"Valve - Female",
        //"Valve - Male",
        //"Valve - Metered Dose",
        //"Valve - Plastic",
        //"Valve - Sub-Component",
        //"Valve - Tilt",
        //ML-2
        "Actuator Only",
        "Valve - Bag-on",
        "Valve - Female",
        "Valve - Male",
        "Valve - Metered Dose",
        "Valve - Plastic",
        "Valve - Tilt",
        "Valve - Sub-Component",
        "Actuator/Valve Assembly",
        "Board and Flat Board Components",
        "Plastic Lids and Hoods",
        "Plastic Lids and Hoods - Assembly",
        "Adhesives, Inks, Solvents - All",
        "Plastic Tub, Cup, Tray - Injection Moulded",
        "Plastic Tub, Cup, Tray - Thermoformed",
        "Plastic Tub, Cup, Tray - Assembly",
        "Tapes",
        "Aerosol Container - All Other Metal",
        "Aerosol Container - Aluminium",
        "Glass Bottle or Jar - Snap-on",
        "Glass Bottle or Jar - Threaded/Helix",
        "Glass Bottle or Jar - Open (no specific neck finish)",
        "Glass Bottle or Jar - Assembly",
        "Plastic Buckets",
        "Plastic Bucket - Assembly",
        "Coverleaf, Membrane and Pre-cut Flex - All Other",
        "Coverleaf, Membrane, Pre-cut Flex - Aluminium",
        "Coverleaf, Membrane, Pre-cut Flex - Plastic",
        "Lid for Plastic Bucket - Thermoformed",
        "Lid for Plastic Bucket - Injection Moulded",
        //ML-3
        "Metal Can - 2 Piece",
        "Metal Can - 3 Piece",
        "Metal Can - All Other",
        "Metal Can End - All Other",
        "Metal Can End - Easy Opening",
        "Wooden Sticks Spoon Fork",
        "Rivets, Handles and Other Sundries",
        "Teabag materials",
        "Metal Closures - All Other",
        "Metal Closures - Foods Twist-Off",
        "Cone Sleeves",
        "Cartonboard - Assembly",
        "Cartonboard - All Other",
        "Cartonboard - Tub",
        "Cartonboard - Lid",
        "Pallets",
        "Polymeric Resins and Additives",
        "Threads",
        "Metal Wires"
    ];
    var allowedDSType = [
        "U4_StructuredPDF",
        "U4_PrintPDF",
        "U4_EPS",
    ];

    var objectTypeStr;
    var selTab;
    if (oldTabSelection !== undefined) {
        selTab = oldTabSelection;
        oldTabSelection = undefined;
    } else {
        selTab = request.data.SelectedTab;
    }

    request.data.SelectedTab = selTab;
    var pType = "";

    if (includes(request.url, "PAM")) {
        console.log("PAM ML Validation");
        if (!includes(allowedPAMTabs, selTab)) {
            return;
        }
        try {
            if (ctx.selected.props.u4_pam_frame_type.dbValues[0] !== undefined) {
                objectTypeStr = ctx.selected.props.u4_pam_frame_type.dbValues[0];
            }
        } catch (err) {
            return;
        }
        if (objectTypeStr === undefined) {
            return;
        }
        if (!includes(allowedFrames, ctx.selected.props.u4_pam_frame_type.dbValues[0])) {
            console.log(retMsg);
            return;
        }
    } else if (includes(request.url, "PnP")) {
        console.log("PnP ML Validation");
        if (!includes(allowedPnPTabs, selTab)) {
            return;
        }
    } else if (includes(request.url, "Pack")) {
        console.log("Pack ML Validation");
        if (!includes(allowedPackTabs, selTab)) {
            return;
        }
        try {
            if (ctx.selected.props.object_type.uiValues !== undefined) {
                objectTypeStr = ctx.selected.props.object_type.uiValues[0];
            } else if (ctx.selected.props.object_type.displayValues !== undefined) {
                objectTypeStr = ctx.selected.props.object_type.displayValues[0];
            }
        } catch (err) {
            return;
        }
        if (objectTypeStr === undefined) {
            return;
        }
        if (!includes(allowedTechnlogoy, objectTypeStr)) {
            console.log(retMsg);
            return;
        }
    } else if (includes(request.url, "PDF")) {
        if (((ctx.pselected !== undefined && ctx.pselected.modelType.typeHierarchyArray.indexOf('U4_ComponentRevision') > -1)
            || (ctx.selected !== undefined && ctx.selected.modelType.typeHierarchyArray.indexOf('U4_ComponentRevision') > -1))
            && includes(allowedDSType, request.data.type)) {
            pType = "PACK";

        } else if (((ctx.pselected !== undefined && ctx.pselected.modelType.typeHierarchyArray.indexOf('U4_DDERevision') > -1)
            || (ctx.selected !== undefined && ctx.selected.modelType.typeHierarchyArray.indexOf('U4_DDERevision') > -1))
            && includes(allowedDSType, request.data.type)) {
            pType = "PNP";
        }
        if (pType === "") {
            return;
        } else {
            request.data.pType = pType;
            console.log(request.data.pType);
        }

    }

    if (isRequestCalled == false) {
        isRequestCalled = true;
    } else {
        _messagingSvc.showInfo("Request already in-progress..", true);
        return;
    }
    //request.url = window.location.protocol+"//"+window.location.hostname+":"+request.data.Port+request.url
    request.url = exports.getURL(request);
    //alert( request.url );
    //_$http = ngModule.element(document).injector().get("$http");
    eventBus.publish('progress.start', {
        endPoint: request.url
    });
    _endPoint = request.url;
    try {
        // var promise = _$http(request).
        //     then(function (response, status, headers, config) {
        //     processEnd();
        const url = request.url;
        var deferred = awPromiseService.instance.defer();
        var promise = AwHttpService.instance.post(url, request.data).
            then(function (response, status, headers, config) {
                processEnd();
                if (response.data.d.Message != null) {
                    _messagingSvc.showError(response.data.d.Message);
                }
                if (response.data.d.messageArray != null) {
                    for (var i = 0, strLen = response.data.d.messageArray.length; i < strLen; i++) {
                        if (includes(response.data.d.messageArray[i], "No Anomaly detected")) {
                            return;
                        }
                        _messagingSvc.showInfo(response.data.d.messageArray[i], true);
                    }
                }
                if (response.data.d.errorMessageArray != null) {
                    for (var i = 0, strLen = response.data.d.errorMessageArray.length; i < strLen; i++) {
                        _messagingSvc.showError(response.data.d.errorMessageArray[i]);
                    }
                }
                if (response.data.d.FileName1 != null) {
                    fileName = response.data.d.FileName1;
                    mimeType = response.data.d.ContentType1;

                    //saveFile(fileName,mimeType,response.d.FileBytes1);
                }
                if (response.data.d.FileName2 != null) {
                    fileName = response.data.d.FileName2;
                    mimeType = response.data.d.ContentType2;

                    //saveFile(fileName,mimeType,response.data.d.FileBytes2);
                }

                deferred.resolve(response);
            },
                function (data, status, statusText) {
                    processEnd();
                    deferred.resolve(data);

                    //     //return promise;;
                    // },
                    //         function (data, status, statusText) {
                    //     processEnd();
                });
    } catch (error2) {
        processEnd();
        alert("error block");
    }
    return promise;
};

export let getURL = function getURL(request) {
    var preFixURL = window.location.protocol + "//" + window.location.hostname;
    var URL = "";
    if (request.data.Port == "0") {
        URL = preFixURL + request.url;
    } else if (request.data.Port.length < 6) {
        URL = preFixURL + ":" + request.data.Port + request.url;
    } else {
        URL = request.data.Port + request.url;
    }
    return URL;
};

export let getWaitValue = function getWaitValue() {
    return 15000;
};

function includes(container, value) {
    var returnValue = false;
    var pos = container.indexOf(value);
    if (pos >= 0) {
        returnValue = true;
    }
    return returnValue;
}
/**
 * NOTE:If any table section is added in the corresponding stylesheets then the table dataProviders need to be updated.
 * get the Package Details table details and Material Details table details and check the Type.If the selected type is other then Description should not be empty,if its empty throw error message.
 * this validation is available for the following frame types:G-CASES-CORR,G-FLEXIBLES,G-LABELS-LIT,G-CARTONS,G-TUBES,G-BAG-ETC-PREMADE,G-TAPES,G-COV-MEM-ETC,G-CONE-SLEEVE,G-AEROSOLS.
 * @param {ctx} ctx-context
 * @param {data} data
 */
export let getPackorMatTableDetails = function (ctx, data, subPanelCtx) {
    data.stopSaveAction = true;
    data.tabName = subPanelCtx.pageContext.primaryActiveTabId;
    var table = _editHandlerService.getActiveEditHandler().getDataSource().getDeclViewModel(); //get the table details
    //var selectedType = subPanelCtx.selectedData.selected.type;
    //var tabs = subPanelCtx.showObjectContext.subLocationTabs;
    var selectedTab = "";
    var formsVMO = "";
    var iDescNotFilled = 0;
    var packProp = "";
    if (subPanelCtx.pageContext.primaryActiveTabId &&
        (subPanelCtx.showObjectContext && subPanelCtx.showObjectContext.objectSetInfo && subPanelCtx.showObjectContext.objectSetInfo.firstPage)
        && ctx.preferences.U4_PAMTabNames_ForLOV_OtherValidation && ctx.pselected && ctx.pselected.props.u4_pam_frame_type
        && ctx.preferences.U4_PAMFrameNames_ForLOV_OtherValidation && ctx.preferences.U4_PAMPropertyName_ForLOV_OtherValidation) {
        selectedTab = subPanelCtx.pageContext.primaryActiveTabId;
        formsVMO = subPanelCtx.showObjectContext.objectSetInfo.firstPage;
        var pref = ctx.preferences.U4_PAMTabNames_ForLOV_OtherValidation; //read the preference U4_PAMTabNames.
        var packDetailsIndex = pref[0].split("||");
        var matDetailsIndex = pref[1].split("||");

        if ((selectedTab == packDetailsIndex[1] || selectedTab == matDetailsIndex[1]) &&
            (ctx.preferences.U4_PAMFrameNames_ForLOV_OtherValidation.includes(ctx.pselected.props.u4_pam_frame_type.dbValues[0]) == true)) {
            for (var iCnt = 0; iCnt < formsVMO.length; iCnt++) {
                if (formsVMO[iCnt].props.u4_description && formsVMO[iCnt].props.u4_property && formsVMO[iCnt].props.u4_type) {
                    if (ctx.preferences.U4_PAMPropertyName_ForLOV_OtherValidation.includes(formsVMO[iCnt].props.u4_property.dbValues[0]) == true) {
                        packProp = formsVMO[iCnt].props.u4_property.dbValues[0]; //get the u4_property name
                        var packType = formsVMO[iCnt].props.u4_type.dbValues[0]; //get the u4_type property values from the table
                        var packDesc = formsVMO[iCnt].props.u4_description.dbValue; //get the u4_description property values from the table
                        if (packType == "Other") { //if the selected type is Other, then the Description should not be empty
                            if (packDesc == null || packDesc == undefined || packDesc == "") {
                                data.stopSaveAction = false; //make the stopSaveAction to false,which will not allow to save the values
                                iDescNotFilled++;
                                break;
                            }
                        }
                    }
                }
            }
        }
    }
    if (packDetailsIndex && selectedTab == packDetailsIndex[1] && iDescNotFilled > 0) {
        _messagingSvc.showError("You have selected 'Other' as an option for " + packProp + " type property. It is mandatory to provide more details under description field.");
    }

    if (matDetailsIndex && selectedTab == matDetailsIndex[1] && iDescNotFilled > 0) {
        _messagingSvc.showError("You have selected 'Other' as an option for " + packProp + " type property. It is mandatory to provide more details under description field.");
    }

    /*if (ctx.preferences.U4_PAMTabNames_ForLOV_OtherValidation && tabs) {
        var pref = ctx.preferences.U4_PAMTabNames_ForLOV_OtherValidation; //read the preference U4_PAMTabNames.
        var packDetailsIndex = pref[0].split("||");
        var matDetailsIndex = pref[1].split("||"); //pref contains 2entries.1st index holds package details and 2nd index holds Material details.
        console.log("tab Index" + packDetailsIndex[0] + "tab Name" + packDetailsIndex[1]);
        console.log("tab Index" + matDetailsIndex[0] + "tab Name" + matDetailsIndex[1]);
        let findPackTypeCnt = 0;
        let packProp;
        let findMatTypeCnt = 0;
        let matProp;
        //for (var tabCount = 0; tabCount < table._pages.length; tabCount++) {
            //if ((table._pages[tabCount].displayTitle == packDetailsIndex[1] || table._pages[tabCount].displayTitle == matDetailsIndex[1]) && ctx.preferences.U4_PAMFrameNames_ForLOV_OtherValidation && ctx.pselected) {
            for (var tabCount = 0; tabCount < tabs.length; tabCount++) {
                if ((tabs[tabCount].page.displayTitle == packDetailsIndex[1] || tabs[tabCount].page.displayTitle == matDetailsIndex[1]) && ctx.preferences.U4_PAMFrameNames_ForLOV_OtherValidation && ctx.pselected) {

                //if (table.dataProviders.ObjectSet_7_Provider && ctx.pselected.props.u4_pam_frame_type && (ctx.preferences.U4_PAMFrameNames_ForLOV_OtherValidation.includes(ctx.pselected.props.u4_pam_frame_type.dbValues[0]) == true) && table._pages[tabCount].selected == true && table._pages[tabCount].displayTitle == packDetailsIndex[1]) { //if Package Details tab is selected
                if (table.dataProviders.ObjectSet_7_Provider && ctx.pselected.props.u4_pam_frame_type && (ctx.preferences.U4_PAMFrameNames_ForLOV_OtherValidation.includes(ctx.pselected.props.u4_pam_frame_type.dbValues[0]) == true) && table._pages[tabCount].selected == true && table._pages[tabCount].displayTitle == packDetailsIndex[1]) { //if Package Details tab is selected
                    var packageTable = table.dataProviders.ObjectSet_7_Provider.viewModelCollection.loadedVMObjects; //get the VM Objects
                    var packVMObjLen = table.dataProviders.ObjectSet_7_Provider.viewModelCollection.loadedVMObjects.length; //get the length of the table
                    //processPackageTable(packageTable, packVMObjLen, ctx, data, packProp, findPackTypeCnt);
                    for (var iCnt = 0; iCnt < packVMObjLen; iCnt++) { //loop it until the table length
                        if (ctx.preferences.U4_PAMPropertyName_ForLOV_OtherValidation.includes(packageTable[iCnt].props.u4_property.dbValues[0]) == true) {
                            packProp = packageTable[iCnt].props.u4_property.dbValues[0]; //get the u4_property name
                            var packType = packageTable[iCnt].props.u4_type.dbValues[0]; //get the u4_type property values from the table
                            var packDesc = packageTable[iCnt].props.u4_description.dbValues[0]; //get the u4_description property values from the table

                            if (packType == "Other") { //if the selected type is Other, then the Description should not be empty
                                if (packDesc == null || packDesc == undefined || packDesc == "") {
                                    data.stopSaveAction = false; //make the stopSaveAction to false,which will not allow to save the values
                                    findPackTypeCnt++;
                                    break;
                                }
                            }
                        }
                    }
                } else if (table.vmo.type == "U4_GConeSleeveRevision" && table.dataProviders.ObjectSet_11_Provider && ctx.pselected.props.u4_pam_frame_type && (ctx.preferences.U4_PAMFrameNames_ForLOV_OtherValidation.includes(ctx.pselected.props.u4_pam_frame_type.dbValues[0]) == true) && table._pages[tabCount].selected == true && table._pages[tabCount].displayTitle == matDetailsIndex[1]) {
                    // package details tab is working //ObjectSet_11_Provider material table properties
                    var materialTable = table.dataProviders.ObjectSet_11_Provider.viewModelCollection.loadedVMObjects; //get the VM Objects
                    var matVMObjLen = table.dataProviders.ObjectSet_11_Provider.viewModelCollection.loadedVMObjects.length; //get the length of the table
                    //processMaterialTable(materialTable, matVMObjLen, ctx, data, matProp, findMatTypeCnt);
                    for (var iCount = 0; iCount < matVMObjLen; iCount++) { //loop it until the table length
                        if (ctx.preferences.U4_PAMPropertyName_ForLOV_OtherValidation.includes(materialTable[iCount].props.u4_property.dbValues[0]) == true) {
                            matProp = materialTable[iCount].props.u4_property.dbValues[0]; //get the u4_property name
                            var matType = materialTable[iCount].props.u4_type.dbValues[0]; //get the u4_type property values from the table
                            var matDesc = materialTable[iCount].props.u4_description.dbValues[0]; //get the u4_description property values from the table
                            if (matType == "Other") { //if the selected type is Other, then the Description should not be empty
                                if (matDesc == null || matDesc == undefined || matDesc == "") {
                                    data.stopSaveAction = false; //make the stopSaveAction to false,which will not allow to save the values
                                    findMatTypeCnt++;
                                    break;
                                }
                            }
                        }
                    }
                } else if (table.dataProviders.ObjectSet_12_Provider && ctx.pselected.props.u4_pam_frame_type && (ctx.preferences.U4_PAMFrameNames_ForLOV_OtherValidation.includes(ctx.pselected.props.u4_pam_frame_type.dbValues[0]) == true) && table._pages[tabCount].selected == true && table._pages[tabCount].displayTitle == matDetailsIndex[1]) { //if Materials tab is selected
                    var materialTable = table.dataProviders.ObjectSet_12_Provider.viewModelCollection.loadedVMObjects; //get the VM Objects
                    var matVMObjLen = table.dataProviders.ObjectSet_12_Provider.viewModelCollection.loadedVMObjects.length; //get the length of the table
                    // processMaterialTable(materialTable, matVMObjLen, ctx, data, matProp, findMatTypeCnt);
                    for (var iCount = 0; iCount < matVMObjLen; iCount++) { //loop it until the table length
                        if (ctx.preferences.U4_PAMPropertyName_ForLOV_OtherValidation.includes(materialTable[iCount].props.u4_property.dbValues[0]) == true) {
                            matProp = materialTable[iCount].props.u4_property.dbValues[0]; //get the u4_property name
                            var matType = materialTable[iCount].props.u4_type.dbValues[0]; //get the u4_type property values from the table
                            var matDesc = materialTable[iCount].props.u4_description.dbValues[0]; //get the u4_description property values from the table
                            if (matType == "Other") { //if the selected type is Other, then the Description should not be empty
                                if (matDesc == null || matDesc == undefined || matDesc == "") {
                                    data.stopSaveAction = false; //make the stopSaveAction to false,which will not allow to save the values
                                    findMatTypeCnt++;
                                    break;
                                }
                            }
                        }
                    }

                } else if ((table.vmo.type == "U4_GTapesRevision" || table.vmo.type == "U4_GLabelRevision" || table.vmo.type == "U4_GCoverleafRevision") && table.dataProviders.ObjectSet_8_Provider && ctx.pselected.props.u4_pam_frame_type && (ctx.preferences.U4_PAMFrameNames_ForLOV_OtherValidation.includes(ctx.pselected.props.u4_pam_frame_type.dbValues[0]) == true) && table._pages[tabCount].selected == true && table._pages[tabCount].displayTitle == packDetailsIndex[1]) {
                    //ObjectSet_8_Provider package details tab only ,no material table properties
                    var packageTable = table.dataProviders.ObjectSet_8_Provider.viewModelCollection.loadedVMObjects; //get the VM Objects
                    var packVMObjLen = table.dataProviders.ObjectSet_8_Provider.viewModelCollection.loadedVMObjects.length; //get the length of the table
                    //processPackageTable(packageTable, packVMObjLen, ctx, data, packProp, findPackTypeCnt);
                    for (var iCnt = 0; iCnt < packVMObjLen; iCnt++) { //loop it until the table length
                        if (ctx.preferences.U4_PAMPropertyName_ForLOV_OtherValidation.includes(packageTable[iCnt].props.u4_property.dbValues[0]) == true) {
                            packProp = packageTable[iCnt].props.u4_property.dbValues[0]; //get the u4_property name
                            var packType = packageTable[iCnt].props.u4_type.dbValues[0]; //get the u4_type property values from the table
                            var packDesc = packageTable[iCnt].props.u4_description.dbValues[0]; //get the u4_description property values from the table

                            if (packType == "Other") { //if the selected type is Other, then the Description should not be empty
                                if (packDesc == null || packDesc == undefined || packDesc == "") {
                                    data.stopSaveAction = false; //make the stopSaveAction to false,which will not allow to save the values
                                    findPackTypeCnt++;
                                    break;
                                }
                            }
                        }
                    }
                } else if (table.vmo.type == "U4_GLabelRevision" && table.dataProviders.ObjectSet_14_Provider && ctx.pselected.props.u4_pam_frame_type && (ctx.preferences.U4_PAMFrameNames_ForLOV_OtherValidation.includes(ctx.pselected.props.u4_pam_frame_type.dbValues[0]) == true) && table._pages[tabCount].selected == true && table._pages[tabCount].displayTitle == matDetailsIndex[1]) {
                    //ObjectSet_8_Provider package details tab //ObjectSet_14_Provider material table properties
                    var materialTable = table.dataProviders.ObjectSet_14_Provider.viewModelCollection.loadedVMObjects; //get the VM Objects
                    var matVMObjLen = table.dataProviders.ObjectSet_14_Provider.viewModelCollection.loadedVMObjects.length; //get the length of the table
                    // processMaterialTable(materialTable, matVMObjLen, ctx, data, matProp, findMatTypeCnt);
                    for (var iCount = 0; iCount < matVMObjLen; iCount++) { //loop it until the table length
                        if (ctx.preferences.U4_PAMPropertyName_ForLOV_OtherValidation.includes(materialTable[iCount].props.u4_property.dbValues[0]) == true) {
                            matProp = materialTable[iCount].props.u4_property.dbValues[0]; //get the u4_property name
                            var matType = materialTable[iCount].props.u4_type.dbValues[0]; //get the u4_type property values from the table
                            var matDesc = materialTable[iCount].props.u4_description.dbValues[0]; //get the u4_description property values from the table
                            if (matType == "Other") { //if the selected type is Other, then the Description should not be empty
                                if (matDesc == null || matDesc == undefined || matDesc == "") {
                                    data.stopSaveAction = false; //make the stopSaveAction to false,which will not allow to save the values
                                    findMatTypeCnt++;
                                    break;
                                }
                            }
                        }
                    }
                } else if (table.vmo.type == "U4_GCoverleafRevision" && table.dataProviders.ObjectSet_13_Provider && ctx.pselected.props.u4_pam_frame_type && (ctx.preferences.U4_PAMFrameNames_ForLOV_OtherValidation.includes(ctx.pselected.props.u4_pam_frame_type.dbValues[0]) == true) && table._pages[tabCount].selected == true && table._pages[tabCount].displayTitle == matDetailsIndex[1]) {
                    //ObjectSet_8_Provider package details tab //ObjectSet_13_Provider material table properties
                    var materialTable = table.dataProviders.ObjectSet_13_Provider.viewModelCollection.loadedVMObjects; //get the VM Objects
                    var matVMObjLen = table.dataProviders.ObjectSet_13_Provider.viewModelCollection.loadedVMObjects.length; //get the length of the table
                    // processMaterialTable(materialTable, matVMObjLen, ctx, data, matProp, findMatTypeCnt);
                    for (var iCount = 0; iCount < matVMObjLen; iCount++) { //loop it until the table length
                        if (ctx.preferences.U4_PAMPropertyName_ForLOV_OtherValidation.includes(materialTable[iCount].props.u4_property.dbValues[0]) == true) {
                            matProp = materialTable[iCount].props.u4_property.dbValues[0]; //get the u4_property name
                            var matType = materialTable[iCount].props.u4_type.dbValues[0]; //get the u4_type property values from the table
                            var matDesc = materialTable[iCount].props.u4_description.dbValues[0]; //get the u4_description property values from the table
                            if (matType == "Other") { //if the selected type is Other, then the Description should not be empty
                                if (matDesc == null || matDesc == undefined || matDesc == "") {
                                    data.stopSaveAction = false; //make the stopSaveAction to false,which will not allow to save the values
                                    findMatTypeCnt++;
                                    break;
                                }
                            }
                        }
                    }
                }

            }
        }

        if (findPackTypeCnt > 0) {
            _messagingSvc.showError("You have selected 'Other' as an option for " + packProp + " type property. It is mandatory to provide more details under description field.");
        }

        if (findMatTypeCnt > 0) {
            _messagingSvc.showError("You have selected 'Other' as an option for " + matProp + " type property. It is mandatory to provide more details under description field.");
        }
    }*/

};

function processEnd() {
    eventBus.publish('progress.end', {
        endPoint: _endPoint
    });
    isRequestCalled = false;
}

export default exports = {
    alertSaveSuccess,
    dataSetUploadHandler,
    captureTab,
    callServiceWithMessageJS,
    getURL,
    MLOperations,
    getWaitValue,
    getPackorMatTableDetails
};
app.factory('AIValidationService', () => exports);
