import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import browserUtils from 'js/browserUtils';
import awPromiseService from 'js/awPromiseService';

'use strict';

var exports = {};
/*
   Populate the list of check Boxes and lov drop down
*/
export let checkIfDatasetisAdded = function (data, parameters) {
    var fileName;
    var validFile;
    var fileExt;
    var isFileSelected;
    var fileNameNoExt;
    var formData;
    fileName = parameters.fileName;
    validFile = parameters.validFile;
    fileExt = parameters.fileExt;
    fileNameNoExt = parameters.fileNameNoExt;
    formData = parameters.formData;
    console.log("filename", parameters.fileName);

    if (fileName != undefined) {
        isFileSelected = "Yes";
    } else {

        isFileSelected = "No";
    }
    return {
        "isFileSelected": isFileSelected,
        "fileName": fileName,
        "validFile": validFile,
        "fileExt": fileExt,
        "fileNameNoExt": fileNameNoExt,
        "formData": formData
    }
}

export let populateUIData = function (data) {
    data.isFileSelected = "No";
    data.ExportDataset.propertyRequiredText = "Required";
    data.ExportReason.propertyRequiredText = "Required";
    data.Supplier.propertyRequiredText = "Required";
    data.Comments.propertyRequiredText = "Required";
}

export let priGetDatasetsInfo = async function (currentctx, data) {
    data.priobjects = undefined;
    data.datasetsResults = undefined;
    data.totalFound = undefined;

    var input = {
        selObj: currentctx.uid
    }

    var response = await _soaSvc.post('AWC-2016-10-ExportData', 'populateExportDataUI', input);
    if (response) {
        for (var i = 0; i < response.exportReasonLOV.length; i++) {
            data.ExportReasonlist.dbValue.push({
                propDisplayValue: response.exportReasonLOV[i],
                propDisplayDescription: "",
                dispValue: "",
                propInternalValue: response.exportReasonLOV[i]
            })
        }
        var inputparentDatasets = {
            objects: response.parentDatasets,
            attributes: ["object_name", "object_type"]
        };
        var priObjNew = [];
        var response1 = await _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputparentDatasets);
        if (response.parentDatasets != null) {
            for (var j = 0; j < response.parentDatasets.length; j++) {
                priObjNew.push(response1.modelObjects[response.parentDatasets[j].uid]);
            }
            data.datasetsResults = priObjNew;
            data.totalFound = priObjNew.length;
        }

        var inputchildDatasets = {
            objects: response.childDatasets,
            attributes: ["object_name", "object_type"]
        };

        var response2 = await _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputchildDatasets);
        var dataSetName = "";
        var secObjs = [];
        if (response.childDatasets != null) {
            for (var j = 0; j < response.childDatasets.length; j++) {

                dataSetName = response2.modelObjects[response.childDatasets[j].uid].props.object_string.dbValues[0] + " (" + response2.modelObjects[response.childDatasets[j].uid].type + ")";

                secObjs.push({
                    secModelObject: response2.modelObjects[response.childDatasets[j].uid],
                    secObjectName: dataSetName
                })
            }
        }
        data.secObjects.dbValue = secObjs;
    }
}

export let getDataSetInfo = function (ctx, data) {
    data.fmsUrl = browserUtils.getBaseURL() + "fms/fmsupload/"
}


export let exportDataInputs = function (ctx, data) {
    var str = "";
    if (data.allDatasetsTobeDownloaded != null) {
        if (data.createdObject) {
            data.allDatasetsTobeDownloaded.push({
                uid: data.createdObject.uid,
                type: data.createdObject.type
            });
            console.log(data.allDatasetsTobeDownloaded)
        }
    }
}

export let areAllthePropertiesSet = function (ctx, data, conditions) {
    var selectDatasets = [];
    var parentDatasets = [];
    if (data.dataProviders.priDatasetsProvider.selectedObjects &&
        data.dataProviders.priDatasetsProvider.selectedObjects.length > 0) {
        for (var index = 0; index < data.dataProviders.priDatasetsProvider.selectedObjects.length; index++) {
            var aModelDs = {
                uid: data.dataProviders.priDatasetsProvider.selectedObjects[index].uid,
                type: data.dataProviders.priDatasetsProvider.selectedObjects[index].type
            };
            parentDatasets.push(aModelDs);
            selectDatasets.push(aModelDs);
        }
    }

    data.selectedDatsetsParent = parentDatasets;
    if (data.secObjects.dbValue != null) {
        for (var j = 0; j < data.secObjects.dbValue.length; j++) {
            selectDatasets.push({
                uid: data.secObjects.dbValue[j].secModelObject.uid,
                type: data.secObjects.dbValue[j].secModelObject.type
            });
        }
    }
    data.allDatasetsTobeDownloaded = selectDatasets;
    data.areAllMandProperties = "Yes";
    return data.allDatasetsTobeDownloaded;
}

export let ValidateCheckBoxSelection = function (data) {
    data.isOkPressed.dbValue = false;
    var parentDatasets = [];

    if (data.dataProviders.priDatasetsProvider.selectedObjects &&
        data.dataProviders.priDatasetsProvider.selectedObjects.length > 0) {
        for (var index = 0; index < data.dataProviders.priDatasetsProvider.selectedObjects.length; index++) {
            var aModelDs = {
                uid: data.dataProviders.priDatasetsProvider.selectedObjects[index].uid,
                type: data.dataProviders.priDatasetsProvider.selectedObjects[index].type
            };
            parentDatasets.push(aModelDs);
        }
    }

    data.selectedDatsetsParent = parentDatasets;
    if (data.selectedDatsetsParent.length == 0) {
        data.check = "No";
    } else {
        data.check = "Yes";
    }
    return {
        "check": data.check,
        "isOkPressed": data.isOkPressed.dbValue
    }

}

export let getOutputDataErrorMessage = function (data, ctx) {
    console.log("*********Inside the getOutputDataErrorMessage************");
    console.log(data);
    console.log(ctx);
    console.log(data.status);
    console.log("*********Inside the getOutputDataErrorMessage************");
}

export let moduleServiceNameToInject = 'ExportDataJs';
export default exports = {
    checkIfDatasetisAdded,
    populateUIData,
    priGetDatasetsInfo,
    getDataSetInfo,
    exportDataInputs,
    areAllthePropertiesSet,
    ValidateCheckBoxSelection,
    getOutputDataErrorMessage,
    moduleServiceNameToInject
};