import _soaSvc from 'soa/kernel/soaService';
import _msgSvc from 'js/messagingService';
import _appCtxSvc from 'js/appCtxService';
import app from 'app';

/**
 * An OTB SOA call to get the Secondary Objects i.e PnP Obj based on Relation then opening PnP Obj
 * @param {Object} data ViewModel object
 *
 */

    'use strict';

    var exports = {};
    
    export let getPnPFromDDE = function( data) {
        var inputData = {
            primaryObjects:[_appCtxSvc.ctx.selected],
            pref:
                {
                expItemRev: true,
                returnRelations: false,
                info:
                [{
                relationTypeName: "U4_PnPSpecificationRelation",
                otherSideObjectTypes: []
                }]
                }
        }
        _soaSvc.post("Core-2007-09-DataManagement","expandGRMRelationsForPrimary",inputData).then(function(response)
        {

            if(response.output[0].relationshipData[0].relationshipObjects.length === 0)
            {
                _msgSvc.showInfo("For selected DDE, PnP doesn't exists");
            }
            else
            {
                var pnpRevObjUID = response.output[0].relationshipData[0].relationshipObjects[0].otherSideObject.uid;
                if (pnpRevObjUID) 
                {
                    var newURLwithNewObject = window.location.origin + window.location.pathname + '#/com.siemens.splm.clientfx.tcui.xrt.showObject?uid=' + pnpRevObjUID;
                    window.location.href = newURLwithNewObject;
                }
            }
        }).catch(function(error){
            _msgSvc.showError("ERROR:Unable to get PnP from DDE due to:\t"+_msgSvc.getSOAErrorMessage(error));            

        });
      
    };
export let moduleServiceNameToInject = 'UnileverDDEToPnPService';
export default exports = {
	getPnPFromDDE,
	moduleServiceNameToInject
};
app.factory('UnileverDDEToPnPService', () => exports );