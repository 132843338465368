import _msgSvc from 'js/messagingService';
import _appCtxSvc from 'js/appCtxService';
import _soaSvc from 'soa/kernel/soaService';

/**
 * An OTB SOA call to get the Secondary Object i.e PAM obj based on Relation then opening PAM Obj
 * @param {Object} data ViewModel object
 *
 */
import app from 'app';
    'use strict';

    var exports = {};

    export let getPAMFromPACK = function( data) {
        var inputData = {
            primaryObjects:[_appCtxSvc.ctx.selected],
            pref:
                {
                expItemRev: true,
                returnRelations: false,
                info:
                [{
                relationTypeName: "U4_PAMSpecification",
                otherSideObjectTypes: []
                }]
                }
        }
        _soaSvc.post("Core-2007-09-DataManagement","expandGRMRelationsForPrimary",inputData).then(function(response)
        {

            if(response.output[0].relationshipData[0].relationshipObjects.length === 0)
            {
                _msgSvc.showError("For selected component, PAM doesn't exist.");
            }
            else
            {
                var pamRevObjUID = response.output[0].relationshipData[0].relationshipObjects[0].otherSideObject.uid;
                if (pamRevObjUID) 
                {
                    var newURLwithNewObject = window.location.origin + window.location.pathname + 
                    '#/com.siemens.splm.clientfx.tcui.xrt.showObject?uid=' + pamRevObjUID;
                    window.location.href = newURLwithNewObject;
                }
            }
        }).catch(function(error){
            _msgSvc.showError("ERROR:Unable to get PAM from PACK due to:\t"+_msgSvc.getSOAErrorMessage(error));            

        });
      
    };
export let moduleServiceNameToInject = 'UnileverPackToPAMService';
export default exports = {
	getPAMFromPACK,
	moduleServiceNameToInject
};
app.factory('UnileverPackToPAMService', () => exports );