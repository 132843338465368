import app from 'app';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import eventBus from 'js/eventBus';
import _commonUtil from 'js/UnileverCommonUtil';
import _lovUtil from 'js/UnileverLovUtil';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import $ from 'jquery';
import awPromiseService from 'js/awPromiseService';
'use strict';

var exports = {};

var technology_description = null;
var requiredText = 'Required';
var noLOVtext = 'Select a value for the property ';

/**
 *   
 * checks whether current user has privilege for selected project.
 * Selected Project should be in projects of current user to allow to create pack component.
 * 
 * 
 * @param {Object} data ViewModel object from module.json
 *  
 */
export let checkUserPrivilege = function (data) {
    data.privileged = undefined;
    data.projectsAssigned = undefined;
    data.projectName = _appCtxSvc.ctx.selected.props.object_string.dbValues[0];
    data.projectsAssigned = _appCtxSvc.ctx.selected.props.project_list.dbValues[0];
    data.modifiablePack = undefined;

    if (_appCtxSvc.ctx.selected.props.is_modifiable.uiValues[0] === 'False') {
        data.modifiablePack = false;
        data.privileged = false;
    }
    // query response has projects of current user
    var projectList__ = data.executeProjectQueryResponse[0].projectsInfo;
    //  _commonUtil.logOnConsole('length ' + projectList__.length);
    for (var i in projectList__) {
        if (_appCtxSvc.ctx.selected.props.object_string.dbValues[0] === (
            'Project ' + (projectList__[i].project.props.object_string.dbValues[0]))) {
            // set user has privilege
            data.privileged = true;
            _commonUtil.logOnConsole('User has Privilege to create component on this Project.');
            break;
        }
    }

};

/**
 *   
 * Loads Component Class LOV.
 * Called on Click of Create Component command
 * 
 * @param {Object} data ViewModel object
 * @return 
 */
export let loadCompClassLov =  async function (data) {

    var inputData = {
        lovName: 'U4_ComponentClassLOV'
    };
    var response = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
    if (response) {
        data.compClassLOVValues = exports.convertDataToLov(response.lovpair); // set new lov
        data.compClass.dbValue = data.compClassLOVValues[0]; // set to empty
        _commonUtil.logOnConsole('Component Class loaded.');
        data.dispatch({ path: 'data.compClassLOVValues', value: data.compClassLOVValues });
    }


    /* Fetching Base UOM LOV value */

    var baseUOMLOVValues = [];
    _lovUtil.getLOVvalue('U4_ComponentRevision', 'u4_base_uom', 'lov_values', 'lov_value_descriptions', null, baseUOMLOVValues, function (serviceData) {
    data.dispatch({path:'data.uomValues', value:baseUOMLOVValues});
        console.log('Type of change loaded');
    });
};

/**
 *   
 * Loads 'Component Commodity', 'Shape / Feature', 'CU Declared Weight/Volume', 'Manufacturing Processes' LOV.
 * 
 * Called on selection of 'Component Class'
 * 
 * @param {Object} data ViewModel object
 * @return 
 */
export let onCompClassSelected = async function (data) {

    var currentCompClass = data.compClass.dbValue;
    var newUOMVal = undefined;

    if (currentCompClass && currentCompClass.length > 0) {
        
        var lovName1 = exports.getLOVValueIn(currentCompClass, data.compClassLOVValues);

        if (lovName1) {
            //get comp commodity lov
            var inputData = {
                lovName: lovName1
            };
            var responsecc = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
            if (responsecc) {
                data.compCommodityLOVValues = exports.convertDataToLov(responsecc.lovpair); // set new lov
                //data.compCommodity.dbValue = data.compCommodityLOVValues[0]; // set to empty
                data.dispatch({ path: 'data.compCommodityLOVValues', value: data.compCommodityLOVValues });
                setEmptyValue(data, data.compCommodity,"data.compCommodity");
                _commonUtil.logOnConsole('Component Commodity updated.');
            }
        }
        //get shape lov
        var inputData = {
            selectedValue: currentCompClass,
            softLinkLov: 'U4_CompClassShapeLOV'
        };
        var responseShape = await _soaSvc.post('AWC-2016-10-Create', 'getVIDLSubLovNLov', inputData);
        if (responseShape) {
            data.shapeLOVValues = exports.convertDataToLov(responseShape.lovpair); // set new lov
            //data.shape.dbValue = data.shapeLOVValues[0]; // set to empty
            data.dispatch({ path: 'data.shapeLOVValues', value: data.shapeLOVValues });
            setEmptyValue(data, data.shape, 'data.shape');
            _commonUtil.logOnConsole('Shape / Feature updated.');
        }


        //get weight vol lov
        var inputData = {
            selectedValue: currentCompClass,
            softLinkLov: 'U4_CompClassWVLOV'
        };
        var responseWt = await _soaSvc.post('AWC-2016-10-Create', 'getVIDLSubLovNLov', inputData);
        if (responseWt) {
            data.cuWeightVolumeLOVValues = exports.convertDataToLov(responseWt.lovpair); // set new lov
            //data.cuWeightVolume.dbValue = data.cuWeightVolumeLOVValues[0]; // set to empty
            data.dispatch({ path: 'data.cuWeightVolumeLOVValues', value: data.cuWeightVolumeLOVValues });
            setEmptyValue(data, data.cuWeightVolume, 'data.cuWeightVolume');            
            _commonUtil.logOnConsole('CU Declared Weight/Volume updated.');
        }

        //get mfg process lov
        var inputData = {
            selectedValue: currentCompClass,
            softLinkLov: 'U4_CompClassMfgProcessLOV'
        };
        var responseMfg = await _soaSvc.post('AWC-2016-10-Create', 'getVIDLSubLovNLov', inputData);
        if (responseMfg) {
            data.manufacProcessessLOVValues = exports.convertDataToLov(responseMfg.lovpair); // set new lov
            //data.manufacProcessess.dbValue = data.manufacProcessessLOVValues[0]; // set to empty
            data.dispatch({ path: 'data.manufacProcessessLOVValues', value: data.manufacProcessessLOVValues});
            setEmptyValue(data, data.manufacProcessess, 'data.manufacProcessess'); 
            _commonUtil.logOnConsole('Manufacturing Processes updated.', data.manufacProcessess.dbValue);
            console.log('Manufacturing Processes updated.', data.manufacProcessess.dbValue);
        }



    } else {

        // reset dependent properties 
        data.rriClassification.dbValue = '';
        setEmptyValue(data, data.compCommodity,"data.compCommodity");
        setEmptyValue(data, data.shape, 'data.shape');
        setEmptyValue(data, data.cuWeightVolume, 'data.cuWeightVolume');
        setEmptyValue(data, data.manufacProcessess, 'data.manufacProcessess'); 
        data.dispatch({ path: 'data.compCommodityLOVValues', value: undefined});
        data.dispatch({ path: 'data.shapeLOVValues', value: undefined});
        data.dispatch({ path: 'data.cuWeightVolumeLOVValues', value: undefined});
        data.dispatch({ path: 'data.manufacProcessessLOVValues', value: undefined});
    }

    // reset dependent properties
    setEmptyValue(data, data.matClass, 'data.matClass');
    setEmptyValue(data, data.matCommodity, 'data.matCommodity');
    setEmptyValue(data, data.deliForm, 'data.deliForm');
    setEmptyValue(data, data.pamFrameType, 'data.pamFrameType');
    setEmptyValue(data, data.technology, 'data.technology');
    setEmptyValue(data, data.baseDiffUOM, 'data.baseDiffUOM');
    data.dispatch({ path: 'data.matClassLOVValues', value: undefined});
    data.dispatch({ path: 'data.matCommodityLOVValues', value: undefined});
    data.dispatch({ path: 'data.deliFormLOVValues', value: undefined});
    data.dispatch({ path: 'data.pamFrameTypeLOVValues', value: undefined});
    data.dispatch({ path: 'data.technologyLOVValues', value: undefined});
    return;
};

/**
 *   
 * Loads 'Material Class' LOV.
 * 
 * Called on selection of 'Component Commodity'
 * 
 * @param {Object} data ViewModel object
 * @return 
 */
export let onCompCommoditySelected = async function (data) {

    var currentCompCommodity = data.compCommodity.dbValue;
    var currentCompClass = data.compClass.dbValue;
    
    if (currentCompCommodity && currentCompCommodity.length > 0) {

        var lovName1 = exports.getLOVValueIn(currentCompCommodity, data.compCommodityLOVValues);

        if (lovName1) {

            var inputData = {
                lovName: lovName1
            };
            var responsemc = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
            if (responsemc) {
                data.matClassLOVValues = exports.convertDataToLov(responsemc.lovpair); // set new lov
                //data.matClass.dbValue = data.matClassLOVValues[0]; // set to empty
                data.dispatch({ path: 'data.matClassLOVValues', value: data.matClassLOVValues });
                setEmptyValue(data, data.matClass, 'data.matClass');
                _commonUtil.logOnConsole('Material Class updated.');
            }           

        }

    } else {

        var compCommStr = '\"Component Commodity\".';

        //exports.resetLov(data, 'matClass');
        setEmptyValue(data, data.matClass, 'data.matClass');
        data.dispatch({ path: 'data.matClassLOVValues', value: undefined});
        data.rriClassification.dbValue = '';

        data.matClass.lovNoValsText = noLOVtext + compCommStr;
        data.matCommodity.lovNoValsText = noLOVtext + compCommStr;
        data.deliForm.lovNoValsText = noLOVtext + compCommStr;
        data.pamFrameType.lovNoValsText = noLOVtext + compCommStr;
        data.technology.lovNoValsText = noLOVtext + compCommStr;


    }

    setEmptyValue(data, data.matCommodity, 'data.matCommodity');
    setEmptyValue(data, data.deliForm, 'data.deliForm');
    setEmptyValue(data, data.pamFrameType, 'data.pamFrameType');
    setEmptyValue(data, data.technology, 'data.technology');
    data.dispatch({ path: 'data.matCommodityLOVValues', value: undefined});
    data.dispatch({ path: 'data.deliFormLOVValues', value: undefined});
    data.dispatch({ path: 'data.pamFrameTypeLOVValues', value: undefined});
    data.dispatch({ path: 'data.technologyLOVValues', value: undefined});
    return;
};

/**
 *   
 * Loads 'Material Commodity' LOV.
 * 
 * Called on selection of 'Material Class'
 * 
 * @param {Object} data ViewModel object
 * @return 
 */
export let onmatClassSelected = async function (data) {

    var currentCompCommodity = data.compCommodity.dbValue;
    var currentCompClass = data.compClass.dbValue;
    var currentmatClass = data.matClass.dbValue;

    if (currentmatClass && currentmatClass.length > 0) {

        var lovName1 = exports.getLOVValueIn(currentmatClass, data.matClassLOVValues);

        if (lovName1) {

            var inputData = {
                lovName: lovName1
            };
            var responsemc = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
            if (responsemc) {
                data.matCommodityLOVValues = exports.convertDataToLov(responsemc.lovpair); // set new lov
                //data.matCommodity.dbValue = data.matCommodityLOVValues[0]; // set to empty                
                data.dispatch({ path: 'data.matCommodityLOVValues', value: data.matCommodityLOVValues });
                setEmptyValue(data, data.matCommodity, 'data.matCommodity');
                _commonUtil.logOnConsole('Material Commodity updated.');
            }
            //get mfg process lov
            var inputData = {
                selectedValue: currentCompClass + "_" + currentCompCommodity + "_" + currentmatClass,
                softLinkLov: 'U4_CompClassMfgProcessLOV'
            };
            var response = await _soaSvc.post('AWC-2016-10-Create', 'getVIDLSubLovNLov', inputData);
                if (response && response.lovpair && response.lovpair.length > 9 && response.lovpair[0].lovvalue == "Blow and Blow" && response.lovpair[9].lovvalue == "Press and Blow") 
                {
                    inputData.selectedValue = currentCompClass;
                    var response1 = await _soaSvc.post('AWC-2016-10-Create', 'getVIDLSubLovNLov', inputData);
                    if (response1) {
                        data.manufacProcessessLOVValues = exports.convertDataToLov(response1.lovpair); // set new lov
                        //data.manufacProcessess.dbValue = data.manufacProcessessLOVValues[0]; // set to empty
                        data.dispatch({ path: 'data.manufacProcessessLOVValues', value: data.manufacProcessessLOVValues });
                        setEmptyValue(data, data.manufacProcessess, 'data.manufacProcessess');
                        _commonUtil.logOnConsole('Manufacturing Processes updated with Component Class.', data.manufacProcessess.dbValue);
                        console.log('Manufacturing Processes updated with Component Class.', data.manufacProcessessLOVValues.dbValue);
                    }

                }
                else {
                    data.manufacProcessessLOVValues = exports.convertDataToLov(response.lovpair); // set new lov
                    //data.manufacProcessess.dbValue = data.manufacProcessessLOVValues[0]; // set to empty
                    data.dispatch({ path: 'data.manufacProcessessLOVValues', value: data.manufacProcessessLOVValues });
                    setEmptyValue(data, data.manufacProcessess, 'data.manufacProcessess');
                    _commonUtil.logOnConsole('Manufacturing Processes updated with the combination of GMC.', data.manufacProcessess.dbValue);
                    console.log('Manufacturing Processes updated with the combination of GMC.', data.manufacProcessessLOVValues.dbValue);
                }

        }

    } else {

        var matClassStr = '\"Material Class\".';

        //exports.resetLov(data, 'matCommodity');
        setEmptyValue(data, data.matCommodity, 'data.matCommodity');
        data.dispatch({ path: 'data.matCommodityLOVValues', value: undefined});
        data.rriClassification.dbValue = '';

        data.matCommodity.lovNoValsText = noLOVtext + matClassStr;
        data.deliForm.lovNoValsText = noLOVtext + matClassStr;
        data.pamFrameType.lovNoValsText = noLOVtext + matClassStr;
        data.technology.lovNoValsText = noLOVtext + matClassStr;
        data.manufacProcessess.lovNoValsText = noLOVtext + matClassStr;
    }


    setEmptyValue(data, data.deliForm, 'data.deliForm');
    setEmptyValue(data, data.pamFrameType, 'data.pamFrameType');
    setEmptyValue(data, data.technology, 'data.technology');   
    data.dispatch({ path: 'data.deliFormLOVValues', value: undefined});
    data.dispatch({ path: 'data.pamFrameTypeLOVValues', value: undefined});
    data.dispatch({ path: 'data.technologyLOVValues', value: undefined});

    return;
};

/**
 *   
 * Loads 'Delivered Form' LOV.
 * 
 * Called on selection of 'Material Commodity'
 * 
 * @param {Object} data ViewModel object
 * @return 
 */
export let onmatCommoditySelected = async function (data) {

    if (data.pcr && data.pcr_percentage) {
        data.pcr.dbValue = false;
        data.pcr.uiValue = false;
        data.pcr_percentage.dbValue = "0";
        data.pcr_percentage.uiValue = "0";
        data.pcr_percentage.propertyRequiredText = "";
    }
    var currentmatCommodity = data.matCommodity.dbValue;
    var currentmatClass = data.matClass.dbValue;
    var currentCompCommodity = data.compCommodity.dbValue;
    var currentCompClass = data.compClass.dbValue;

    if (currentmatCommodity && currentmatCommodity.length > 0) {

        var lovName1 = exports.getLOVValueIn(currentmatCommodity, data.matCommodityLOVValues);

        if (lovName1) {

            var inputData = {
                lovName: lovName1
            };
            var response = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
                if (response) {
                    data.deliFormLOVValues = exports.convertDataToLov(response.lovpair); // set new lov
                    //data.deliForm.dbValue = data.deliFormLOVValues[0]; // set to empty                    
                    data.dispatch({ path: 'data.deliFormLOVValues', value: data.deliFormLOVValues });
                    setEmptyValue(data, data.deliForm, 'data.deliForm');
                    _commonUtil.logOnConsole('Delivered Form updated.');
                }

            exports.rriClassificationUpdate(data);
        }
    } else {

        var matCommStr = '\"Material Commodity\".';
        setEmptyValue(data, data.deliForm, 'data.deliForm');
        data.dispatch({ path: 'data.deliFormLOVValues', value: undefined});
        data.rriClassification.dbValue = '';

        data.deliForm.lovNoValsText = noLOVtext + matCommStr;
        data.pamFrameType.lovNoValsText = noLOVtext + matCommStr;
        data.technology.lovNoValsText = noLOVtext + matCommStr;

    }
    console.log("Data UOM Value: ", data.uomValues);
    setEmptyValue(data, data.pamFrameType, 'data.pamFrameType');
    setEmptyValue(data, data.technology, 'data.technology');    
    data.dispatch({ path: 'data.pamFrameTypeLOVValues', value: undefined});
    data.dispatch({ path: 'data.technologyLOVValues', value: undefined});    
    return;
};

/**
*   
* Gets RRI Value furing component creation
* 
* Called on selection of material commodity on component create panel
* 
* @param {Object} data ViewModel object
* @return 
*/

export let rriClassificationUpdate = async function (data) {
    //alert( text ); // eslint-disable-line no-alert

    var inputdata = {
        componentClass: data.compClass.dbValue,
        componentCommodity: data.compCommodity.dbValue,
        materialClass: data.matClass.dbValue,
        materialCommodity: data.matCommodity.dbValue
    }
    var response = await _soaSvc.post('AWC-2016-10-Create', 'getRRIComponentCreate', inputdata);
    if (response) {
        data.rriClassification.dbValue = response.out;
        var newRRIVal = _.clone(data.rriClassification);
        newRRIVal.uiValue = response.out;
        newRRIVal.dbValue = response.out;
        data.dispatch({ path: 'data.rriClassification', value: newRRIVal });
    }
    return;
};

/**
 *   
 * Loads 'PAM Frame Type' LOV.
 * 
 * Called on selection of 'Delivered Form'
 * 
 * @param {Object} data ViewModel object
 * @return 
 */
export let ondeliFormSelected = async function (data) {

    var currentdeliForm = data.deliForm.dbValue;
    var currentCompCommodity = data.compCommodity.dbValue;
    var currentCompClass = data.compClass.dbValue;
    var currentmatClass = data.matClass.dbValue;
    var currentmatCommodity = data.matCommodity.dbValue;
    var uomValues = [];
    /*data.uomValues.push({
        propDisplayValue: "",
        propInternalValue: "",
        propDisplayDescription: ""
    });*/
    setEmptyValue(data, data.baseDiffUOM, 'data.baseDiffUOM');
    if (currentdeliForm && currentdeliForm.length > 0 && currentdeliForm != ' ') {

        var lovName1 = exports.getLOVValueIn(currentdeliForm, data.deliFormLOVValues);
        //setEmptyValue(data, data.baseDiffUOM, 'data.baseDiffUOM');

        if (lovName1) {

            var inputData = {
                lovName: lovName1
            };
            var response = await  _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
            if (response) {
                data.pamFrameTypeLOVValues = exports.convertDataToLov(response.lovpair); // set new lov
                //data.pamFrameType.dbValue = data.pamFrameTypeLOVValues[0]; // set to empty
                data.dispatch({ path: 'data.pamFrameTypeLOVValues', value: data.pamFrameTypeLOVValues});
                setEmptyValue(data, data.pamFrameType, 'data.pamFrameType');
                _commonUtil.logOnConsole('PAM Frame Type updated.');
            }

            var uombasedonClassNCom = 'U4_UOM_basedon_Class_Commodity';
            if (_commonUtil.isPreferencesAvailable([uombasedonClassNCom], true)) {
                var base_uom_values = _appCtxSvc.ctx.preferences[uombasedonClassNCom];
                if (base_uom_values) {
                    for (var inx = 0; inx < base_uom_values.length; inx++) {
                        var str = base_uom_values[inx].trim().split(':');
                        if (str.length == 2)
                            var classncomValues = str[0].trim().split('#');

                        if (classncomValues.length == 3) {
                            if (currentCompClass == classncomValues[0] && currentCompCommodity == classncomValues[1] && currentmatClass == classncomValues[2]) {
                                var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                uomValues.push({
                                    propDisplayValue: onlyUOM[0],
                                    propInternalValue: onlyUOM[0],
                                    propDisplayDescription: propDesc[1]
                                });

                            }
                        }
                        else if (classncomValues.length == 2) {
                            if (currentCompClass == classncomValues[0] && currentCompCommodity == classncomValues[1]) {
                                var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                uomValues.push({
                                    propDisplayValue: onlyUOM[0],
                                    propInternalValue: onlyUOM[0],
                                    propDisplayDescription: propDesc[1]
                                });

                            }
                        }
                        else if (classncomValues.length == 4) {
                            if (currentCompClass == classncomValues[0] && currentCompCommodity == classncomValues[1] && currentmatClass == classncomValues[2] && currentmatCommodity == classncomValues[3]) {
                                var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                uomValues.push({
                                    propDisplayValue: onlyUOM[0],
                                    propInternalValue: onlyUOM[0],
                                    propDisplayDescription: propDesc[1]
                                });

                            }
                        }
                        else if (classncomValues.length == 1 && currentCompClass == str[0]) {
                            var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                            var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                            uomValues.push({
                                propDisplayValue: onlyUOM[0],
                                propInternalValue: onlyUOM[0],
                                propDisplayDescription: propDesc[1]
                            });
                        }

                    }
                    //data.dispatch({ path: 'data.uomValues', value: uomValues });

                    if (uomValues.length == 1) {
                        const newUOMVal = _.clone(data.baseDiffUOM);
                        newUOMVal.uiValue = uomValues[0].propDisplayValue;
                        newUOMVal.dbValue = uomValues[0].propDisplayValue;
                        data.dispatch({ path: 'data.baseDiffUOM', value: newUOMVal });
                        data.dispatch({ path: 'data.uomValues', value: uomValues });
                    }
                    else if (uomValues.length > 1){
                        uomValues.unshift({
                            propDisplayValue: "",
                            propInternalValue: "",
                            propDisplayDescription: ""
                        });
                        setEmptyValue(data, data.baseDiffUOM, 'data.baseDiffUOM');
                        data.dispatch({ path: 'data.uomValues', value: uomValues });
                    }
                }
            }
            if (uomValues.length == 0) {
                var baseUOMLOVValues = [];
                _lovUtil.getLOVvalue('U4_ComponentRevision', 'u4_base_uom', 'lov_values', 'lov_value_descriptions', null, baseUOMLOVValues, function (serviceData) {
                data.dispatch({path:'data.uomValues', value:baseUOMLOVValues});
                    console.log('Type of change loaded');
                });
            }
        }

    } else {

        var delFormStr = '\"Delivered Form\".';
        setEmptyValue(data, data.pamFrameType, 'data.pamFrameType');
        data.dispatch({ path: 'data.pamFrameTypeLOVValues', value: undefined});
        data.pamFrameType.lovNoValsText = noLOVtext + delFormStr;
        data.technology.lovNoValsText = noLOVtext + delFormStr;

    }
    setEmptyValue(data, data.technology, 'data.technology');
    data.dispatch({ path: 'data.technologyLOVValues', value: undefined});
    return;
};

/**
 *   
 * Loads 'Technology' LOV.
 * 
 * Called on selection of 'PAM Frame Type'
 * 
 * @param {Object} data ViewModel object
 * @return 
 */
export let onpamFrameTypeSelected = async function (data) {

    if (data.matClass.dbValue == "Plastic" && data.matCommodity.dbValue.includes('Recycled') == true) {
        data.pcr.dbValue = true;
        data.pcr.uiValue = true;
        data.pcr_percentage.propertyRequiredText = "Enter Only 100";
        data.pcr_percentage.uiValue = "100";
        data.pcr_percentage.dbValue = "100";
        data.pcr.isEnabled = false;
    }
    else if (data.pcr && data.pcr_percentage) {
        data.pcr.dbValue = false;
        data.pcr.uiValue = false;
        data.pcr_percentage.dbValue = "0";
        data.pcr_percentage.uiValue = "0";
        data.pcr_percentage.propertyRequiredText = "";
        data.pcr.isEnabled = true;
    }

    var currentpamFrameType = data.pamFrameType.dbValue;
    if (currentpamFrameType && currentpamFrameType.length > 0) {

        var lovName1 = exports.getLOVValueIn(currentpamFrameType, data.pamFrameTypeLOVValues);

        if (lovName1) {

            var inputData = {
                lovName: lovName1
            };
            var response = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
            if (response) {
                data.technologyLOVValues = exports.convertDataToLov(response.lovpair); // set new lov
                //data.technology.dbValue = data.technologyLOVValues[0]; // set to empty
                data.dispatch({ path: 'data.technologyLOVValues', value: data.technologyLOVValues });
                setEmptyValue(data, data.technology, 'data.technology');
                _commonUtil.logOnConsole('Technology updated.');
            }

        }
    } else {

        var pamframeStr = '\"PAM Frame Type\".';

        //exports.resetLov(data, 'data.technology');
        setEmptyValue(data, data.technology, 'data.technology');
        data.dispatch({ path: 'data.technologyLOVValues', value: undefined});
        data.technology.lovNoValsText = noLOVtext + pamframeStr;
    }
    return;
};

/**
 * 
 * Called on selection of 'Technology'
 * 
 * set consumerUnit based on 'Technology' and 'PAM Frame Type'
 * 
 * @param {Object} data  ViewModel object
 * @param {Object} ctx ctx Object
 * 
 * @return
 */
export let consumerunitTypeUpdate = function (data, ctx) {
    var prefValue = [];
    var prefValue2 = [];
    var story = [];
    var frame = data.pamFrameType.dbValue;
    var technoValue = data.technology.dbValue;

    if (technoValue && technoValue.length > 0) {

        var prefName1 = 'U4_GMCPackComponentType';
        var prefName2 = 'UL_primary_packaging_list';

        prefValue = ctx.preferences[prefName1];
        prefValue2 = ctx.preferences[prefName2];

        if (_commonUtil.isPreferencesAvailable([prefName1, prefName2], true)) {

            for (var i = 0; i < prefValue.length; i++) {
                story = prefValue[i].split('#');

                if ((story.length == 3) && (story[0] == frame) && (story[1] == technoValue)) {
                    //   _commonUtil.logOnConsole('found the respective technovalue');
                    var techFromPref = story[2];
                }
            }
            let found = false;
            for (var j = 0; j < prefValue2.length; j++) {
                if (prefValue2[j] == techFromPref) {
                    found = true;
                    break;
                }

            }

            data.consumerUnit.dbValue = found;


        }
    }

    showTechnologyInfo(technoValue);

    return;
}

/**
 *   
 * converts LOV string values into objects 
 * 
 * @param {Object} lovPair LOV name and Dependent LOV realname pair
 * @return {Array} array lov objects
 */
export let convertDataToLov = function (lovPair) {


    var lov = [];
    lov.push({
        propDisplayValue: '',
        propDisplayDescription: '',
        propInternalValue: '',
        propDep: ''
    });
    for (var i in lovPair) {
        // sub LOV will be stored in 'propDep' property
        var val__ = lovPair[i].lovvalue;
        var subLov__ = lovPair[i].lovname;
        lov.push({
            propDisplayValue: val__,
            propDisplayDescription: '',
            propInternalValue: val__,
            propDep: subLov__
        });

    }

    return lov;
};

/**
 *   
 * Resets property and respective LOV to empty.
 * 
 * @param {Object} data ViewModel object
 * @param {String} propString property to reset
 * 
 * @return
 */
export let resetLov = function (data, propString) {

    data[(propString + 'LOVValues')] = [''];
    data[propString].dbValue = '';
    data[propString].displayValues = undefined;
    data[propString].uiValue = undefined;
    data[(propString + 'LOVValues')] = [];

    /* const newEmptyVal = _.clone(data);
    newEmptyVal.uiValue = "";
    newEmptyVal.dbValue = ""; */
    //data.dispatch({ path: propString, value: newEmptyVal });
    return;
};

/**
 *   
 * Finds selected LOV's dependent LOV
 * 
 * @param {String} selected selected LOV value
 * @param {Array} lovArray LOV array
 * 
 * @return {String} Dependent LOV
 */
export let getLOVValueIn = function (selected, lovArray) {

    for (var i in lovArray) {

        if (lovArray[i].propDisplayValue === selected) {
            //   _commonUtil.logOnConsole('Value matched >> ' + lovArray[i].propDep);
            return lovArray[i].propDep;
        }
    }
    //   _commonUtil.logOnConsole('Match not found');
    return;
};


/**
 *   
 * Shows information about selected technology as notification based on preference
 * 
 * @param {String} technoValue selected technology
 * 
 *
 */
var showTechnologyInfo = function (technoValue) {

    if (technoValue) {
        var techDescPref = 'U4_TechnologyDescription';
        if (technology_description == undefined && _commonUtil.isPreferencesAvailable([techDescPref], true)) {
            technology_description = {};
            var technology_description_values = _appCtxSvc.ctx.preferences[techDescPref];
            if (technology_description_values) {
                for (var inx = 0; inx < technology_description_values.length; inx++) {
                    var str = technology_description_values[inx].trim().split('#');
                    if (str.length == 2)
                        technology_description[str[0]] = str[1];
                }
            }

        }
        if (technology_description && technology_description[technoValue] != undefined) {
            var desc_value = technology_description[technoValue];
            if (desc_value)
                _messagingSvc.showError(desc_value);
        }

    }

}

/**
 *   
 * Check selected commodity is present in a preference
 * 
 * @param {String} commodity selected commodity
 * @param {Array} prefValue preference value
 *
 */
var updateFormPropertyValue = function (commodity, prefValue) {

    if (prefValue) {
        for (var i = 0; i < prefValue.length; i++) {

            if (prefValue[i]) {

                var val = prefValue[i].trim().split('#');

                if (val.length == 2)
                    if (commodity.toUpperCase() === val[0].toUpperCase())
                        return val[1];
            }
        }
    }
    return '';
}

/**
 *   
 * Check selected frame and technology is present in a preference
 * 
 * @param {String} frame selected frame
 * @param {String} tech selected technology
 * @param {Array} prefValue preference value
 * @return value from preference after evaluation with technology and frame value
 *
 */
var getDesignComponentType = function (frame, tech, prefValue) {

    for (var i = 0; i < prefValue.length; i++) {
        var str = prefValue[i].trim().split('#');

        if (str && str.length == 3 && (str[0].toUpperCase() === frame.toUpperCase()) && (str[1].toUpperCase() === tech.toUpperCase()))
            return str[2];
    }

    return undefined;
}

/**
 *   
 * Validates properties selected with preference values
 * 
 * @param {Object} data ViewModel object
 * @param {Object} ctx ctx Object
 * @return true allows to proceed pack component creation
 *
 */
var createPackPreCheck = function (data, ctx) {

    var compAbbPref = 'U4_GMC_Component_Abbreviation';
    var compMatPref = 'U4_GMC_Material_Abbreviation';
    var packCompTypePref = 'U4_GMCPackComponentType';

    if (_commonUtil.isPreferencesAvailable([compAbbPref, compMatPref, packCompTypePref], true)) {

        var cDesc = updateFormPropertyValue(data.compCommodity.dbValue, ctx.preferences[compAbbPref]);
        var mDesc = updateFormPropertyValue(data.matCommodity.dbValue, ctx.preferences[compMatPref]);

        if (cDesc && mDesc && cDesc.length != 0 && mDesc.length != 0) {

            var frame = data.pamFrameType.dbValue;
            var tech = data.technology.dbValue;

            var compType = getDesignComponentType(frame, tech, ctx.preferences[packCompTypePref]);

            if (compType && compType.length >= 1) {
                return true;
            } else {
                var str = '\nFor selected Pam Frame Type ( ' + frame + ' ) & Technology ( ' + tech + ' )';
                _messagingSvc.showError('Pack Component Type Missing in Preference.' + str);
                return false;
            }

        } else {
            if (mDesc.length == 0)
                _messagingSvc.showError('Material Description Value is Missing in Preference <U4_GMC_Material_Abbreviation>.');
            else
                _messagingSvc.showError('Component Description Value is Missing in Preference <U4_GMC_Component_Abbreviation>.');

            return false;
        }

    } else {
        return false;
    }


}

/**
 *   
 * Creates new Pack component with selected properties using custom SOA
 * 
 * @param {Object} data ViewModel object
 * @param {Object} ctx ctx Object
 * @param {Object} conditions condition Object
 * 
 *
 */
export let createPackAction = function (data, ctx, conditions) {
    try {
        if (createPackPreCheck(data, ctx)) {

            //   Disables create button once it is clicked. Not allowing user to click again.
            data.disableCreate = true;
            if (conditions.isDesignNameVisible === false) {
                data.designname.dbValue = "";
            }
            var inputData = {
                'selectedObj': {
                    'uid': ctx.selected.uid,
                    'type': ctx.selected.type
                },
                'leadSize': data.leadSize.dbValue,
                'packProps': {
                    'packProps': {
                        'u4_component_class': data.compClass.dbValue,
                        'u4_component_class_shape': data.compClass.dbValue,
                        'u4_component_class_mfgpro': data.compClass.dbValue,
                        'u4_component_class_wv': data.compClass.dbValue,
                        'u4_component_commodity': data.compCommodity.dbValue,
                        'u4_material_class': data.matClass.dbValue,
                        'u4_material_commodity': data.matCommodity.dbValue,
                        'u4_cu_weight_volume': data.cuWeightVolume.dbValue,
                        'u4_manufacturing_processes': data.manufacProcessess.dbValue,
                        'u4_shape': data.shape.dbValue,
                        'u4_delivered_form': data.deliForm.dbValue,
                        'u4_pam_frame_type': data.pamFrameType.dbValue,
                        'u4_technology': data.technology.dbValue,
                        'u4_size': data.size.dbValue,
                        'u4_unique_descriptor': data.uniqDescriptor.dbValue,
                        'u4_base_uom': data.baseDiffUOM.dbValue,
                        'object_desc': data.objDesc.dbValue,
                        'u4_reason_for_issue': data.reasonforissue.dbValue,
                        'u4_consumer_unit': data.consumerUnit.dbValue.toString(),
                        'u4_component_type': data.componentType.dbValue,
                        'u4_design_name': data.designname.dbValue
                    }
                }
            };
            if (conditions.isPDFPrintSectionVisible == true) {
                inputData.packProps.packProps["u4_is_printed_material"] = data.pdf_print.dbValue == true ? "Yes" : "No";
            } else {
                inputData.packProps.packProps["u4_is_printed_material"] = "";
            }
            if (conditions.isPCRSectionVisible == true) {
                inputData.packProps.packProps["u4_contains_pcr"] = data.pcr.dbValue == true ? "Yes" : "No";
                inputData.packProps.packProps["u4_pcr_percentage"] = data.pcr_percentage.dbValue =="" ? "0" :data.pcr_percentage.dbValue;
            } else {
                inputData.packProps.packProps["u4_contains_pcr"] = "";
                inputData.packProps.packProps["u4_pcr_percentage"] = "";
            }
            _commonUtil.logOnConsole('\nProperties of new pack component item : ' + JSON.stringify(inputData.packProps.packProps));
            _soaSvc.post('AWC-2016-10-Create', 'createPackComp', inputData).then(function (response) {

                try {

                    data.newCreatedItem = response.modelObjects[response.created[0]].props.object_string.dbValues[0];
                    //_commonUtil.closeTNIpanel();
                    delete ctx.addObjectPanelInfo;
                    _messagingSvc.reportNotyMessage(data, data._internal.messages, 'createCompSuccessMsg');
                    eventBus.publish("unilever.closePopup");

                    var objs = _commonUtil.getSelectedObjsToRefresh(ctx);
                    _commonUtil.refreshObjectsInUI(objs);

                } catch (error) {
                    _messagingSvc.showError('create Pack Component failed.');
                }

            });
        }
    } catch (error) {
        _messagingSvc.showError('Something went wrong in pack component create action.');
        data.disableCreate = false;
    }
    return data.disableCreate;
}

export let clearPCRPercentage = function (data) {
    data.pcr_percentage.propertyRequiredText = "";
    data.pcr_percentage.uiValue = "";
    data.pcr_percentage.dbValue = "";

    if(data.pcr.dbValue == true){
        const newPCRPercentVal = _.clone(data.pcr_percentage);
        data.dispatch({ path: 'data.pcr_percentage', value: newPCRPercentVal });
    }else if(data.pcr.dbValue == false){
        data.pcr_percentage.uiValue = "0";
        data.pcr_percentage.dbValue = "0";
        const newPCRPercentVal = _.clone(data.pcr_percentage);
        data.dispatch({ path: 'data.pcr_percentage', value: newPCRPercentVal });
    }
   
    if (data.pcr.dbValue == true && data.matClass.dbValue == "Plastic") {
        if (data.matCommodity.dbValue.includes('Recycled') == true) {
            data.pcr_percentage.propertyRequiredText = "Enter Only 100";
            data.pcr_percentage.uiValue = "100";
            data.pcr_percentage.dbValue = "100";
            const newPCRPercentVal = _.clone(data.pcr_percentage);
            data.dispatch({ path: 'data.pcr_percentage', value: newPCRPercentVal });
        }
        else {
            data.pcr_percentage.propertyRequiredText = "100 is not allowed";
            const newPCRPercentVal = _.clone(data.pcr_percentage);
            data.dispatch({ path: 'data.pcr_percentage', value: newPCRPercentVal });
        }
    }
}

export let closePanelJsAction = function (data) {
    data.valuesEntered = false;
    var totalVal = [];
    var Val = $("#CreatePackCompPanel");

    if (Val && Val[0] && Val[0].id === "CreatePackCompPanel") {
        $('#CreatePackCompPanel').find('.sw-property-val').each(function (idx, textarea) {
            if (textarea.value != "") {
                //  console.log(textarea.value);
                totalVal.push(1);
            }
        });
    }
    else if (_appCtxSvc.ctx.addObject && _appCtxSvc.ctx.addObject.includedTypes == "U4_DDE") {
        console.log("_appCtxSvc.ctx.addObject.includedTypes", _appCtxSvc.ctx.addObject.includedTypes);
        $('details.aw-panelSection').find("input[type !='radio'].sw-property-val").each(function (idx, selectbox) {
            var fieldLstVal = $(selectbox).val().trim();
            if (fieldLstVal != " " && fieldLstVal.length != 0) {
                totalVal.push(fieldLstVal);
            }
        });
    }
    /*  else {
         $('.sw-section').find('.sw-property-val').each(function (idx, textarea) {
             // console.log(textarea.value);
             totalVal.push(1);
         });
     } */

    if (totalVal.length > 0) {
        data.valuesEntered = true;
    }

    console.log(data.valuesEntered);
    return data;
}

export let EnableDisableCreatebutton = function (data) {
    data.disableCreate = false;
    return data.disableCreate;
}

export let callAlert = function (data) {
    console.log("Entered in callAlert");
}

export let loadUOMClassLov = function (data) {
    data.baseUOMLOVValues = [];
    if (data.listUOMVals.lovValues.length > 0) {
        _lovUtil.pushLovValuesToContainer(data.baseUOMLOVValues, data.listUOMVals, "lov_values", 'lov_value_descriptions');
        console.log('Type of change loaded');
    }
}

var setEmptyValue = function(data,vmo,propname)
{
    var vmoClone = _.clone(vmo);                  
    vmoClone.dbValue = "";
    vmoClone.uiValue = "";            
    data.dispatch({path:propname, value:vmoClone});
}

export default exports = {
    checkUserPrivilege,
    loadCompClassLov,
    onCompClassSelected,
    onCompCommoditySelected,
    onmatClassSelected,
    onmatCommoditySelected,
    rriClassificationUpdate,
    ondeliFormSelected,
    onpamFrameTypeSelected,
    consumerunitTypeUpdate,
    convertDataToLov,
    resetLov,
    getLOVValueIn,
    createPackAction,
    clearPCRPercentage,
    closePanelJsAction,
    EnableDisableCreatebutton,
	callAlert,
	loadUOMClassLov,
    setEmptyValue
};
app.factory('CreatePackCompView', () => exports);

