// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/UnileverExportToSAPService
 */
import app from 'app';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import $ from 'jquery';
import eventBus from 'js/eventBus';
import _appCtxSvc from 'js/appCtxService';
import _tcSessionData from 'js/TcSessionData';
import _messagingSvc from 'js/messagingService';



    'use strict';

    var exports = {};

    /**
     * Dummy alert.
     */
    export let validateComp1 = function (ctx) {
        //alert( text ); // eslint-disable-line no-alert

        var inputdata = {
            compRev: {
                uid: _appCtxSvc.ctx.selected.uid,
                type: _appCtxSvc.ctx.selected.type
            }
        };
        _soaSvc.post('AWC-2016-10-ExportToSAP', 'validateComp', inputdata).then(function (response) {
			if (response)
			{
				if(response.validbool==false)
				{
					_messagingSvc.showError(response.validError);	
				}
				else if(response.validbool==true && response.validint==0)
				{
					_messagingSvc.showInfo("Export to SAP Initiated");
				}
			}
		});
        /*_soaSvc.post('AWC-2016-10-ExportToSAP', 'validateComp', inputdata);
        var endPt = 'AWC-2016-10-ExportToSAP' + '/' + 'validateComp';
        eventBus.publish('progress.end', {
            endPoint: endPt
        });
        _messagingSvc.showInfo("Export to SAP Initiated");*/
    };

    export let validateComp2 = function (ctx, eventData) {
        //alert( text ); // eslint-disable-line no-alert

        var inputdata = {
            compRev: {
                uid: _appCtxSvc.ctx.selected.uid,
                type: _appCtxSvc.ctx.selected.type
            }
        };

        console.log(eventData);
        console.log("handler called");
        var wfName;
        if (eventData.eventMap) {
            //wfName = eventData.eventMap.submissionSuccessful.createChangeData.workflowTemplates.displayValues[0];
            wfName = eventData.eventMap.submissionSuccessful.createChangeData.subPanelContext.submitPanelState.processTemplate.props.template_name.dbValues[0]

		} else {
           //wfName = eventData.workflowTemplates.displayValues[0];
			wfName = ctx.selected.props.release_status_list.uiValues[0];

        }
        console.log(wfName);

        if (wfName && wfName === 'Obsolete' || wfName === 'Pilot Lock Approve' || wfName === 'Product Design Lock Approve' || wfName === 'Retired') {
            /*_soaSvc.post('AWC-2016-10-ExportToSAP', 'validateComp', inputdata).then(function (response) {
			if (response)
			{
				if(response.validbool==false)
				{
					_messagingSvc.showError(response.validError);	
				}
				else if(response.validbool==true && response.validint==0)
				{
					_messagingSvc.showInfo("Export to SAP Initiated");
				}
			}
		});*/
            _soaSvc.post('AWC-2016-10-ExportToSAP', 'validateComp', inputdata);
            var endPt = 'AWC-2016-10-ExportToSAP' + '/' + 'validateComp';
            eventBus.publish('progress.end', {
                endPoint: endPt
            });
        }
        else if (wfName === 'PNP Revise' && ctx.pselected) {
            eventBus.publish('cdm.relatedModified', {
                "relatedModified": [
                    ctx.pselected
                ]
            });
        }
        //_messagingSvc.showInfo("Export to SAP Initiated");
    };

	export default exports = {
	validateComp1,
	validateComp2
};
app.factory('UnileverExportToSAPService', () => exports );