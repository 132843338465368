import envRepUtil from 'js/EnvReportUtil';
import app from 'app';
import _ from 'lodash';
import $ from 'jquery';
import eventBus from 'js/eventBus';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import _commonUtil from 'js/UnileverCommonUtil';

        'use strict';

        var exports = {};

        var totalPackImpactRepData;
        var conditions;

        var packCaptions = {
            packoneCaption: "Pack 1",
            packtwoCaption: "Pack 2",
            packthreeCaption: "Pack 3",
            packfourCaption: "Pack 4",
            packfiveCaption: "Pack 5"
        };




        /**
         *   
         * @param {Object} data ViewModel object
         */
        export let intializePage = function (data) {

            totalPackImpactRepData = data;
            data.NumbOfConsUnitPerSecoundary.propertyRequiredText = 'Required';
            var customConditions = {
                "showFirstPage": false,
                "showSecondPage": false,
                "showThirdPage": false,
                "showNextCommand": false,
                "showPreviousCommand": false,
                "showGenRepCommand": false,
                "showSortCompCommand": false,
                "showPackTextBox": false,
                "showPackPanel": false,
				"showpack1Data":false,
				"showpack2Data":false,
				"showpack3Data":false,
				"showpack4Data":false,
				"showpack5Data":false,
            };
            _appCtxSvc.registerCtx("TotalPackImpact.customConditions", customConditions);
            _appCtxSvc.registerCtx("TotalPackImpact.TotalPackImpactData", data);
			setPage(1);

            /**
             * This function is referred in html file. Will be called always whenever any UI 
             *  interaction happens. since this get called many number of times within a small period of time and we
             * are setting values, we have handled as it setting values should happen only at required time. 
             */
             var setTextBoxValue = function () {


                // decides whether hash texbox be visible or not
                if (data.packoneCompDataprovider && data.packoneCompDataprovider.selectedObjects) {

                    if (data.packoneCompDataprovider.selectedObjects.length == 1) {
                        if (!customConditions.showPackTextBox)
                            customConditions.showPackTextBox = true;
                    } else {
                        if (customConditions)
                            customConditions.showPackTextBox = false;
                    }
                }

                if (customConditions.showPackTextBox && data.packoneCompDataprovider && data.packoneCompDataprovider.selectedObjects[0]) {
                    // set value to hash textbox is only needed when user changes the selecton
                    if (!data.packoneCompDataprovider._previousselectedObjHashKey || (data.packoneCompDataprovider._previousselectedObjHashKey !== data.packoneCompDataprovider.selectedObjects[0]['$$hashKey'])) {
                        // if selection changes get hash value of selected component and set to hash textbox
                        data.packHashTextbox.dbValue = data.packoneCompDataprovider.selectedObjects[0].cellProperties['# '].value;
                        // setting focus to textbox programmatically
                        setFocusToHashTextbox('data.packHashTextbox');
                        data.packoneCompDataprovider._previousselectedObjHashKey = data.packoneCompDataprovider.selectedObjects[0]['$$hashKey'];
                    }
                    // if user changes value in hash textbox, it gets updated to component cell. only if nex value is different than previous value
                    if (!data.packHashTextbox._previousdbValue || (data.packHashTextbox._previousdbValue !== data.packHashTextbox.dbValue)) {
                        // set textbox value to component cell
                        data.packoneCompDataprovider.selectedObjects[0].cellProperties['# '].value = data.packHashTextbox.dbValue;
                        data.packHashTextbox._previousdbValue = data.packHashTextbox.dbValue;
                    }
                }
            }
			_appCtxSvc.registerCtx("TotalPackImpact.setTextBoxValue", setTextBoxValue);
			_appCtxSvc.updateCtx("TotalPackImpact.customConditions", customConditions);

        }

        /**
         * @param viewmodelIDofTextbox viewmodel of the textbox which has been picked from viewmodel.json
         * 
         * sets focus to Hash Textbox, once user has selected a component in pack panel.
         * Iterates through all elements and finds the wanted element by its viewmodel id and takes input tag and sets focus
         */
        var setFocusToHashTextbox = function (viewmodelIDofTextbox) {

            /**
             * recursively finds input tag by inside textbox
             * 
             * @param {*} tag element tag of textbox
             * @returns input tag
             */
            var findInputTag = function (tag) {

                if (tag.nodeName === 'INPUT') {

                    return tag;
                }

                if (tag.children && tag.children.length > 0) {
                    for (var i = 0; i < tag.children.length; ++i) {
                        var itag = findInputTag(tag.children[i]);
                        if (itag)
                            return itag;
                    }
                }
            }

            // iterate through all elements
            var all = document.getElementsByTagName('*');
            for (var i = 0; i < all.length; i++) {

                try {
                    if (all[i].getAttribute('prop') === viewmodelIDofTextbox) {

                        var inputtag = findInputTag(all[i]);

                        if (inputtag) {
                            if (document.activeElement !== inputtag) {

                                var timerVariable = setTimeout(() => {
                                    if (document.activeElement !== inputtag) {
                                        inputtag.focus();
                                        clearTimeout(timerVariable);
                                    }
                                }, 150);
                            }

                        }

                    }

                } catch (error) {
                    console.log('error');
                }
            }

        }

        /**
         * 
         *  Get called on the click of previous command
         * 
         */
        export let previousCommandAction = function () {

            goPreviousPage();

            return;
        }

        /**
         * 
         *  Switches to previous page.
         * 
         */
        var goPreviousPage = function () {
			var customConditions = _appCtxSvc.getCtx("TotalPackImpact.customConditions");
            let flag = false;
            if (customConditions.showSecondPage) {
                setPage(1);
                flag = true;
            }
            if (!flag && customConditions.showThirdPage) {
                setPage(2);
            }
			_appCtxSvc.updateCtx("TotalPackImpact.customConditions", customConditions);
        }


        /**
         * 
         * Get called on the click of next command
         * 
         */
        export let nextCommandAction = function (ctx) {
		var customConditions = _appCtxSvc.getCtx("TotalPackImpact.customConditions");
            if (validateNextPage()) {

                goNextPage(ctx);

            }
        }

        /**
         * 
         * validates whether can switch to next page
         * 
         */
        var validateNextPage = function () {

		var customConditions = _appCtxSvc.getCtx("TotalPackImpact.customConditions");
            if (customConditions.showFirstPage) {

                var packOneSelectionCount = totalPackImpactRepData.dataProviders.packoneCompDataprovider.viewModelCollection.loadedVMObjects.length;
                var packTw0SelectionCount = totalPackImpactRepData.dataProviders.packtwoCompDataprovider.viewModelCollection.loadedVMObjects.length;
                var packThreeSelectionCount = totalPackImpactRepData.dataProviders.packthreeCompDataprovider.viewModelCollection.loadedVMObjects.length;
                var packFourSelectionCount = totalPackImpactRepData.dataProviders.packfourCompDataprovider.viewModelCollection.loadedVMObjects.length;
                var packFiveSelectionCount = totalPackImpactRepData.dataProviders.packfiveCompDataprovider.viewModelCollection.loadedVMObjects.length;

                if (!(packOneSelectionCount !== 0 || packTw0SelectionCount !== 0 || packThreeSelectionCount !== 0 || packFourSelectionCount !== 0 || packFiveSelectionCount !== 0)) {
                    _messagingSvc.showError('Please select at least one component in a pack for correct Total Pack Impact report generation.');
                    return false;
                }
            }

            
            return true;
        }

        /**
         * 
         *  Switches to next page.
         * 
         */
        var goNextPage = function (ctx) {

		var customConditions = _appCtxSvc.getCtx("TotalPackImpact.customConditions");

            let flag = false;
            if (customConditions.showFirstPage) {
                setPage(2);
                flag = true;
            }
            if (!flag && customConditions.showSecondPage) {
                setPage(3);
            }


        }

        /**
         * 
         *  Generates total pack impact report based on selected objects.
         * 
         * .net service gets called with appropriate parameter.
         * 
         */
        export let genRepCommandAction = function (data) {
			var TotalPackImpactData = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData");
			var customConditions = _appCtxSvc.getCtx("TotalPackImpact.customConditions");
            if (data.NumbOfConsUnitPerSecoundary.dbValue) {
                if (_commonUtil.isPreferencesAvailable(['AWC_U4_ReportService_Port'], true)) {
                    
                    envRepUtil.getProjectInfo(function (projInfo) {


                        var projItemid = projInfo.item_id;
                        var projrevid = projInfo.item_revision_id;
                        var desc = data.description.dbValue;
                        var saveToTc = data.saveToTC.dbValue;
                        var userId = _appCtxSvc.ctx.userSession.props.user_id.dbValue;

                        var selectedRelevantComps = envRepUtil.getModelObjectsFromDataprovider(TotalPackImpactData, 'relavantCompSelectedDataprovider', false);
                        var selectedReferenceComps = envRepUtil.getModelObjectsFromDataprovider(TotalPackImpactData, 'referenceCompSelectedDataprovider', false);

                        var packOneLabel = TotalPackImpactData.packoneLabelTextbox.dbValue;
                        var packTwoLabel = TotalPackImpactData.packtwoLabelTextbox.dbValue;
                        var packThreeLabel = TotalPackImpactData.packthreeLabelTextbox.dbValue;
                        var packFourLabel = TotalPackImpactData.packfourLabelTextbox.dbValue;
                        var packFiveLabel = TotalPackImpactData.packfiveLabelTextbox.dbValue;

                        var selectedPackOneComps = envRepUtil.getModelObjectsFromDataprovider(TotalPackImpactData, 'packoneCompDataprovider', true);
                        var selectedPackTwoComps = envRepUtil.getModelObjectsFromDataprovider(TotalPackImpactData, 'packtwoCompDataprovider', true);
                        var selectedPackThreeComps = envRepUtil.getModelObjectsFromDataprovider(TotalPackImpactData, 'packthreeCompDataprovider', true);
                        var selectedPackFourComps = envRepUtil.getModelObjectsFromDataprovider(TotalPackImpactData, 'packfourCompDataprovider', true);
                        var selectedPackFiveComps = envRepUtil.getModelObjectsFromDataprovider(TotalPackImpactData, 'packfiveCompDataprovider', true);

                        var dataToExport = {
                            'objectData': {
                                'ProjectItemId': projItemid,
                                'ProjectRevisionId': projrevid,
                                'RelevantComponents': selectedRelevantComps,
                                'ReferenceComponents': selectedReferenceComps,
                                'NumberOfConsumerUnitsPerSecondary': data.NumbOfConsUnitPerSecoundary.dbValue,
                                'PackOne': {
                                    Components: selectedPackOneComps,
                                    Label: packOneLabel
                                },
                                'PackTwo': {
                                    Components: selectedPackTwoComps,
                                    Label: packTwoLabel
                                },
                                'PackThree': {
                                    Components: selectedPackThreeComps,
                                    Label: packThreeLabel
                                },
                                'PackFour': {
                                    Components: selectedPackFourComps,
                                    Label: packFourLabel
                                },
                                'PackFive': {
                                    Components: selectedPackFiveComps,
                                    Label: packFiveLabel
                                },
                                'Description': desc,
                                'SaveReport': saveToTc,
                                'InitUser': userId
                            },
                            'Port': _appCtxSvc.ctx.preferences.AWC_U4_ReportService_Port[0]
                        };
                        customConditions.showGenRepCommand = false;
						_appCtxSvc.updateCtx("TotalPackImpact.customConditions",customConditions);
                        envRepUtil.generateReport(dataToExport);

                    });

                }
            } else
                _messagingSvc.showError('Please fill in the number of primary component per secondary pack to enable the calculation to be performed.');

        }

        /*
         * 
         * @param {Integer} page page number to switch
         */
        var setPage = function (page) {
			var customConditions = _appCtxSvc.getCtx("TotalPackImpact.customConditions");
            var TotalPackImpactData = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData");
			if (page) {
                customConditions.showFirstPage = (page == 1);
                customConditions.showSecondPage = (page == 2);
                customConditions.showThirdPage = (page == 3);
            }
            changeBreadCrumbState();
            customConditions.showPreviousCommand = !customConditions.showFirstPage;
            customConditions.showNextCommand = !customConditions.showThirdPage;
            customConditions.showGenRepCommand = customConditions.showThirdPage;
            customConditions.showSortCompCommand = customConditions.showFirstPage || customConditions.showSecondPage;
			_appCtxSvc.updateCtx("TotalPackImpact.customConditions", customConditions);
			if(page==3)
			{
				var relSelectedObjs = TotalPackImpactData.dataProviders['relavantCompDataprovider'].selectedObjects;
				var vmo = TotalPackImpactData.dataProviders['relavantCompSelectedDataprovider'].viewModelCollection;
				vmo.loadedVMObjects = relSelectedObjs;
				TotalPackImpactData.dataProviders['relavantCompSelectedDataprovider'].update(vmo.loadedVMObjects);
				var refSelectedObjs = TotalPackImpactData.dataProviders['referenceCompDataprovider'].selectedObjects;
				var vmo = TotalPackImpactData.dataProviders['referenceCompSelectedDataprovider'].viewModelCollection;
				vmo.loadedVMObjects = refSelectedObjs;
				TotalPackImpactData.dataProviders['referenceCompSelectedDataprovider'].update(vmo.loadedVMObjects);
				updateCountOnCaption();
				updateCountOnCaptionForPack();
			}
			_appCtxSvc.updateCtx("TotalPackImpact.TotalPackImpactData",TotalPackImpactData);
		}

        /**
         * sets breadcrumb state based on pages state
         */
        var changeBreadCrumbState = function () {
			var customConditions = _appCtxSvc.getCtx("TotalPackImpact.customConditions");
            totalPackImpactRepData.bc_provider.crumbs[0].selectedCrumb = customConditions.showFirstPage;
            totalPackImpactRepData.bc_provider.crumbs[1].selectedCrumb = customConditions.showSecondPage;
            totalPackImpactRepData.bc_provider.crumbs[2].selectedCrumb = customConditions.showThirdPage;
        }

        /**
         * sort by ID in ascending order
         */
        export let sortByIDAscendCommandAction = function (data) {
            sortComponents(1, 0,data);
        }

        /**
         * sort by ID in descending order
         */
        export let sortByIDDescendCommandAction = function (data) {

            sortComponents(1, 1,data);
        }

        /**
         * sort by name in ascending order
         */
        export let sortByNameAscendCommandAction = function (data) {
            sortComponents(0, 0,data);
        }

        /**
         * sort by name in descending order
         */
        export let sortByNameDescendCommandAction = function (data) {
			sortComponents(0, 1,data);
        }
		export let selectPackComponents = function(data,dataProvider)
		{
			var customConditions = _appCtxSvc.getCtx("TotalPackImpact.customConditions");
			if(data.dataProviders[dataProvider].selectedObjects.length>0){
				
				customConditions.showPackTextBox = true;
				
			}
			else
			{
				customConditions.showPackTextBox = false;
			}
			_appCtxSvc.updateCtx("TotalPackImpact.customConditions",customConditions);
		}
        /**
         * 
         * sort components
         * 
         * @param {Integer} index to decide sort by ID or Name
         * @param {Integer} order to decide sort ascending or descending
         */
        var sortComponents = function (index, order,data) {
			var customConditions = _appCtxSvc.getCtx("TotalPackImpact.customConditions");
			var TotalPackImpactData = data;
            if (customConditions.showFirstPage) {
                _commonUtil.sortDataProviderObjects(TotalPackImpactData, 'relavantCompDataprovider', index, order);

            }
            if (customConditions.showSecondPage) {
                _commonUtil.sortDataProviderObjects(TotalPackImpactData, 'referenceCompDataprovider', index, order);
            }
			data.dataProviders['relavantCompDataprovider'].viewModelCollection.loadedVMobjects = TotalPackImpactData.dataProviders['relavantCompDataprovider'].viewModelCollection.loadedVMObjects;
			data.dataProviders['relavantCompDataprovider'].update(data.dataProviders['relavantCompDataprovider'].viewModelCollection.loadedVMobjects);
			data.dataProviders['referenceCompDataprovider'].viewModelCollection.loadedVMobjects = TotalPackImpactData.dataProviders['referenceCompDataprovider'].viewModelCollection.loadedVMObjects;
			data.dataProviders['referenceCompDataprovider'].update(data.dataProviders['referenceCompDataprovider'].viewModelCollection.loadedVMobjects);

        }

        /**
         * 
         * Get called on the click of Pack One command
         * 
         */
        export let packoneCommandAction = function () {

            setPackPage('packone');

        }
        /**
         * 
         * Get called on the click of Pack Two command
         * 
         */
        export let packtwoCommandAction = function () {

            setPackPage('packtwo');
        }
        /**
         * 
         * Get called on the click of Pack Three command
         * 
         */
        export let packthreeCommandAction = function () {

            setPackPage('packthree');
        }
        /**
         * 
         * Get called on the click of Pack Four command
         * 
         */
        export let packfourCommandAction = function () {

            setPackPage('packfour');
        }
        /**
         * 
         * Get called on the click of Pack Five command
         * 
         */
        export let packfiveCommandAction = function () {

            setPackPage('packfive');
        }
        /**
         * 
         * Based on the selection of pack type, we set appropriate objects to data.packdata.
         * By changing objects in data.packdata, UI changes.
         * 
         * @param {String} pageid
         */
        var setPackPage = function (page) {
			var packPage = page;
			_appCtxSvc.registerCtx("TotalPackImpact.TotalPackImpactData.packPage", packPage);
			var customConditions = _appCtxSvc.getCtx("TotalPackImpact.customConditions");
			var TotalPackImpactData = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData");
            if (!TotalPackImpactData.packdata)
                TotalPackImpactData.packdata = {};


            // showing and hidind pack panel
            if (TotalPackImpactData.packdata.lastOpenedPackPage !== page) {
                customConditions.showPackPanel = true;
				customConditions.showPackTextBox = false;
            } else {
                customConditions.showPackPanel = !TotalPackImpactData.packdata.lastOpenedState;
            }
            TotalPackImpactData.packdata.lastOpenedPackPage = page;
            TotalPackImpactData.packdata.lastOpenedState = customConditions.showPackPanel;
			if(TotalPackImpactData.packPage === 'packone')
			{
				TotalPackImpactData.packLabelTextbox = TotalPackImpactData.packoneLabelTextbox;
				customConditions.showpack1Data = true;
				customConditions.showpack2Data = customConditions.showpack3Data = customConditions.showpack4Data = customConditions.showpack5Data = false;
			}
			else if(TotalPackImpactData.packPage === 'packtwo'){
				TotalPackImpactData.packLabelTextbox = TotalPackImpactData.packtwoLabelTextbox;
				customConditions.showpack2Data = true;
				customConditions.showpack1Data = customConditions.showpack3Data = customConditions.showpack4Data = customConditions.showpack5Data = false;
			}
			else if(TotalPackImpactData.packPage === 'packthree'){
				TotalPackImpactData.packLabelTextbox = TotalPackImpactData.packthreeLabelTextbox;
				customConditions.showpack3Data = true;
				customConditions.showpack1Data = customConditions.showpack2Data = customConditions.showpack4Data = customConditions.showpack5Data = false;
			}
			else if(TotalPackImpactData.packPage === 'packfour'){
				TotalPackImpactData.packLabelTextbox = TotalPackImpactData.packfourLabelTextbox;
				customConditions.showpack4Data = true;
				customConditions.showpack1Data = customConditions.showpack2Data = customConditions.showpack3Data = customConditions.showpack5Data = false;
			}
			else if(TotalPackImpactData.packPage === 'packfive'){
				TotalPackImpactData.packLabelTextbox = TotalPackImpactData.packfiveLabelTextbox;
				customConditions.showpack5Data = true;
				customConditions.showpack1Data = customConditions.showpack2Data = customConditions.showpack3Data = customConditions.showpack4Data = false;
			}
			_appCtxSvc.updateCtx("TotalPackImpact.customConditions",customConditions);
			_appCtxSvc.updateCtx("TotalPackImpact.TotalPackImpactData",TotalPackImpactData);
			
        }
        /**
         * adds selected component to opend pack panel
         */
        export let addCompAction = function () {
			//var TotalPackImpactData = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData");
			//getcurrentPackDataProvider();
            //eventBus.publish((TotalPackImpactData.packdata.currentPackDataProvider.name + 'Event'), TotalPackImpactData);
			var TotalPackImpactData = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData");
			var SelectedComp=_appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData.dataProviders[relavantCompDataprovider]");
			var relSelectedObjs = SelectedComp.selectedObjects;
			var isPresent = false;
			if(TotalPackImpactData.packPage === 'packone')
			{
				var PackOneCompDataProvider=_appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData.dataProviders[packoneCompDataprovider]");
				var vmo = PackOneCompDataProvider.viewModelCollection;
				if(vmo.loadedVMObjects)
				{
					if(vmo.loadedVMObjects.length>0)
					{
						for(let relObj = 0;relObj<relSelectedObjs.length;relObj++)
						{
							for(let i=0;i<vmo.loadedVMObjects.length;i++)
							{
								if(relSelectedObjs[relObj].uid == vmo.loadedVMObjects[i].uid)
									{
										isPresent = true;
										break;
									}
								else
								{
									continue;
								}
							}
							if(	isPresent == false)	
							{
								relSelectedObjs[relObj].cellProperties.HashProp = {key: '#', value: '1'};
								vmo.loadedVMObjects.push(relSelectedObjs[relObj]);
							}
							isPresent = false;
						}
					}
					else
					{
						for(let relObj = 0;relObj<relSelectedObjs.length;relObj++)
						{
								relSelectedObjs[relObj].cellProperties.HashProp = {key: '#', value: '1'};
								vmo.loadedVMObjects.push(relSelectedObjs[relObj]);
						}
					}
				}	
				else
				{
					vmo.loadedVMObjects = relSelectedObjs;
				}
				PackOneCompDataProvider.update(vmo.loadedVMObjects);
				//setHashValue(PackOneCompDataProvider,TotalPackImpactData.PackOneComponents);
			}
			else if(TotalPackImpactData.packPage === 'packtwo'){
				var PackTwoCompDataProvider=_appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData.dataProviders[packtwoCompDataprovider]");
				var vmo = PackTwoCompDataProvider.viewModelCollection;
				if(vmo.loadedVMObjects)
				{
					if(vmo.loadedVMObjects.length>0)
					{
						for(let relObj = 0;relObj<relSelectedObjs.length;relObj++)
						{
							for(let i=0;i<vmo.loadedVMObjects.length;i++)
							{
								if(relSelectedObjs[relObj].uid == vmo.loadedVMObjects[i].uid)
									{
										isPresent = true;
										break;
									}
								else
								{
									continue;
								}
							}
							if(	isPresent == false)	
							{
								relSelectedObjs[relObj].cellProperties.HashProp = {key: '#', value: '1'};
								vmo.loadedVMObjects.push(relSelectedObjs[relObj]);
							}
							isPresent = false;
						}
					}
					else
					{
						for(let relObj = 0;relObj<relSelectedObjs.length;relObj++)
						{
								relSelectedObjs[relObj].cellProperties.HashProp = {key: '#', value: '1'};
								vmo.loadedVMObjects.push(relSelectedObjs[relObj]);
						}
					}
				}	
				else
				{
					vmo.loadedVMObjects = relSelectedObjs;
				}
				PackTwoCompDataProvider.update(vmo.loadedVMObjects);
			}
			else if(TotalPackImpactData.packPage === 'packthree'){
				var PackThreeCompDataProvider=_appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData.dataProviders[packthreeCompDataprovider]");
				var vmo = PackThreeCompDataProvider.viewModelCollection;
				if(vmo.loadedVMObjects)
				{
					if(vmo.loadedVMObjects.length>0)
					{
						for(let relObj = 0;relObj<relSelectedObjs.length;relObj++)
						{
							for(let i=0;i<vmo.loadedVMObjects.length;i++)
							{
								if(relSelectedObjs[relObj].uid == vmo.loadedVMObjects[i].uid)
									{
										isPresent = true;
										break;
									}
								else
								{
									continue;
								}
							}
							if(	isPresent == false)	
							{
								relSelectedObjs[relObj].cellProperties.HashProp = {key: '#', value: '1'};
								vmo.loadedVMObjects.push(relSelectedObjs[relObj]);
							}
							isPresent = false;
						}
					}
					else
					{
						for(let relObj = 0;relObj<relSelectedObjs.length;relObj++)
						{
								relSelectedObjs[relObj].cellProperties.HashProp = {key: '#', value: '1'};
								vmo.loadedVMObjects.push(relSelectedObjs[relObj]);
						}
					}
				}	
				else
				{
					vmo.loadedVMObjects = relSelectedObjs;
				}
				PackThreeCompDataProvider.update(vmo.loadedVMObjects);
			}
			else if(TotalPackImpactData.packPage === 'packfour'){
				var PackFourCompDataProvider=_appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData.dataProviders[packfourCompDataprovider]");
				var vmo = PackFourCompDataProvider.viewModelCollection;
				if(vmo.loadedVMObjects)
				{
					if(vmo.loadedVMObjects.length>0)
					{
						for(let relObj = 0;relObj<relSelectedObjs.length;relObj++)
						{
							for(let i=0;i<vmo.loadedVMObjects.length;i++)
							{
								if(relSelectedObjs[relObj].uid == vmo.loadedVMObjects[i].uid)
									{
										isPresent = true;
										break;
									}
								else
								{
									continue;
								}
							}
							if(	isPresent == false)	
							{
								relSelectedObjs[relObj].cellProperties.HashProp = {key: '#', value: '1'};
								vmo.loadedVMObjects.push(relSelectedObjs[relObj]);
							}
							isPresent = false;
						}
					}
					else
					{
						for(let relObj = 0;relObj<relSelectedObjs.length;relObj++)
						{
								relSelectedObjs[relObj].cellProperties.HashProp = {key: '#', value: '1'};
								vmo.loadedVMObjects.push(relSelectedObjs[relObj]);
						}
					}
				}	
				else
				{
					vmo.loadedVMObjects = relSelectedObjs;
				}
				PackFourCompDataProvider.update(vmo.loadedVMObjects);
			}
			else if(TotalPackImpactData.packPage === 'packfive'){
				var PackFiveCompDataProvider=_appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData.dataProviders[packfiveCompDataprovider]");
				var vmo = PackFiveCompDataProvider.viewModelCollection;
				if(vmo.loadedVMObjects)
				{
					if(vmo.loadedVMObjects.length>0)
					{
						for(let relObj = 0;relObj<relSelectedObjs.length;relObj++)
						{
							for(let i=0;i<vmo.loadedVMObjects.length;i++)
							{
								if(relSelectedObjs[relObj].uid == vmo.loadedVMObjects[i].uid)
									{
										isPresent = true;
										break;
									}
								else
								{
									continue;
								}
							}
							if(	isPresent == false)	
							{
								relSelectedObjs[relObj].cellProperties.HashProp = {key: '#', value: '1'};
								vmo.loadedVMObjects.push(relSelectedObjs[relObj]);
							}
							isPresent = false;
						}
					}
					else
					{
						for(let relObj = 0;relObj<relSelectedObjs.length;relObj++)
						{
								relSelectedObjs[relObj].cellProperties.HashProp = {key: '#', value: '1'};
								vmo.loadedVMObjects.push(relSelectedObjs[relObj]);
						}
					}
				}	
				else
				{
					vmo.loadedVMObjects = relSelectedObjs;
				}
				PackFiveCompDataProvider.update(vmo.loadedVMObjects);
			}
			updateCountOnCaptionForPack();

        }

        /**
         * removes selected component from opend pack panel
         */
        export let removeCompAction = function () {
			var TotalPackImpactData = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData");
			if(TotalPackImpactData.packPage === 'packone')
			{
				var selectedDataProvider = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData.dataProviders[packoneCompDataprovider]");
				var PackOneComponents = selectedDataProvider.viewModelCollection.loadedVMObjects;
				var selectedObjs = selectedDataProvider.selectedObjects;
				for (var selObj in selectedObjs) {
                for (var obj in PackOneComponents) {
                    if (PackOneComponents[obj].uid === selectedObjs[selObj].uid) {
                        //remove object
                        PackOneComponents.splice(PackOneComponents.indexOf(PackOneComponents[obj]), 1);
						}
					}
				}
				selectedDataProvider.update(PackOneComponents,PackOneComponents.length);
				//setHashValue(packoneCompDataprovider,PackOneComponents);
			}
			else if(TotalPackImpactData.packPage === 'packtwo'){
				var selectedDataProvider = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData.dataProviders[packtwoCompDataprovider]");
				var PackTwoComponents = selectedDataProvider.viewModelCollection.loadedVMObjects;
				var selectedObjs = selectedDataProvider.selectedObjects;
				for (var selObj in selectedObjs) {
                for (var obj in PackTwoComponents) {
                    if (PackTwoComponents[obj].uid === selectedObjs[selObj].uid) {
                        //remove object
                        PackTwoComponents.splice(PackTwoComponents.indexOf(PackTwoComponents[obj]), 1);
						}
					}
				}
				selectedDataProvider.update(PackTwoComponents,PackTwoComponents.length);
			}
			else if(TotalPackImpactData.packPage === 'packthree'){
				var selectedDataProvider = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData.dataProviders[packthreeCompDataprovider]");
				var PackThreeComponents = selectedDataProvider.viewModelCollection.loadedVMObjects;
				var selectedObjs = selectedDataProvider.selectedObjects;
				for (var selObj in selectedObjs) {
                for (var obj in PackThreeComponents) {
                    if (PackThreeComponents[obj].uid === selectedObjs[selObj].uid) {
                        //remove object
                        PackThreeComponents.splice(PackThreeComponents.indexOf(PackThreeComponents[obj]), 1);
						}
					}
				}
				selectedDataProvider.update(PackThreeComponents,PackThreeComponents.length);
			}
			else if(TotalPackImpactData.packPage === 'packfour'){
				var selectedDataProvider = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData.dataProviders[packfourCompDataprovider]");
				var PackFourComponents = selectedDataProvider.viewModelCollection.loadedVMObjects;
				var selectedObjs = selectedDataProvider.selectedObjects;
				for (var selObj in selectedObjs) {
                for (var obj in PackFourComponents) {
                    if (PackFourComponents[obj].uid === selectedObjs[selObj].uid) {
                        //remove object
                        PackFourComponents.splice(PackFourComponents.indexOf(PackFourComponents[obj]), 1);
						}
					}
				}
				selectedDataProvider.update(PackFourComponents,PackFourComponents.length);
			}
			else if(TotalPackImpactData.packPage === 'packfive'){
				var selectedDataProvider = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData.dataProviders[packfiveCompDataprovider]");
				var PackFiveComponents = selectedDataProvider.viewModelCollection.loadedVMObjects;
				var selectedObjs = selectedDataProvider.selectedObjects;
				for (var selObj in selectedObjs) {
                for (var obj in PackFiveComponents) {
                    if (PackFiveComponents[obj].uid === selectedObjs[selObj].uid) {
                        //remove object
                        PackFiveComponents.splice(PackFiveComponents.indexOf(PackFiveComponents[obj]), 1);
						}
					}
				}
				selectedDataProvider.update(PackFiveComponents,PackFiveComponents.length);
			}
            // set hashvalue which was already set by user 
            //setHashValue(totalPackImpactRepData[compArray]);
            //update components count to tab caption in third page
            updateCountOnCaptionForPack();
        }

        /**
         * get selected components from relavant comp dataprovider and push into array to build dataprovider for selected pack
         */
        export let getPackComponents = function (param) {
			var TotalPackImpactData = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData");
            if (!TotalPackImpactData[param])
                TotalPackImpactData[param] = [];

            var containsCheck = function (arr, element) {
                for (var i = 0; i < arr.length; i++) {
                    if (arr[i].uid === element.uid) {
                        return true;
                    }
                }
                return false;
            }

            var relSelectedObjs = TotalPackImpactData.dataProviders.relavantCompDataprovider.selectedObjects;
            if (relSelectedObjs.length > 0) {
                for (var i in relSelectedObjs) {
                    for (var j in TotalPackImpactData.relevantComponents) {
                        if (TotalPackImpactData.relevantComponents[j].uid === relSelectedObjs[i].uid) {
                            if (!containsCheck(TotalPackImpactData[param], TotalPackImpactData.relevantComponents[j])) {
                                // set default hash value
                                TotalPackImpactData.relevantComponents[j].props.awp0CellProperties.dbValues.push('# \\:1');
                                TotalPackImpactData[param].push(TotalPackImpactData.relevantComponents[j]);
                            }
                            break;
                        }

                    }
                }

            }
            // set hashvalue which was already set by user 
            setHashValue(TotalPackImpactData[param]);

            //update components count to tab caption in third page
            //updateCountOnCaptionForPack();
        }

        /**
         * sets already entered hash value from viewmodel object to model object.
         * @param {*} compArray component to set hash value
         */
        let setHashValue = function (dataproviderName,compArray) {

            //let dataproviderName = totalPackImpactRepData.packdata.currentPackDataProvider.name;
			var TotalPackImpactData = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData");		
            _.each(TotalPackImpactData.dataProviders[dataproviderName].viewModelCollection.loadedVMObjects, function (vmObj, index) {

                _.each(compArray, function (modelObj, index) {
                    if (vmObj.uid === modelObj.uid) {
                        let hashValue = vmObj.cellProperties['# '].value;

                        //remove hash value
                        _.remove(modelObj.props.awp0CellProperties.dbValues, function (str) {
                            return _.startsWith(str, '# ');
                        });

                        //put new hash value
                        modelObj.props.awp0CellProperties.dbValues.push('# \\:' + hashValue);
                    }
                });
            });

        }

        /**
         * add customer unit information to cell
         */
        export let addConsumerUnitToCell = function () {

            //  check preference
            if (_commonUtil.isPreferencesAvailable(['UL_primary_packaging_list'], true)) {

                var objs = totalPackImpactRepData.dataProviders.relavantCompDataprovider.viewModelCollection.loadedVMObjects;
                for (var i in objs) {

                    var consUnit = null;

                    if (objs[i].props.u4_consumer_unit) {
                        consUnit = (objs[i].props.u4_consumer_unit.dbValues[0] === '1') ? 'Primary' : null;
                    }

                    if (consUnit === null) {
                        var itemType = objs[i].type.substring(0, objs[i].type.length - 8);
                        var contain = _.includes(_appCtxSvc.ctx.preferences.UL_primary_packaging_list, itemType);
                        consUnit = (contain) ? 'Primary' : 'Secondary';
                    }

                    objs[i].cellProperties.consUnit = {
                        key: 'Consumer Unit',
                        value: consUnit
                    };
                }
            }


        }

        /**
         * 
         * appends component count on Third page caption for pack
         */
        var updateCountOnCaptionForPack = function () {
			var TotalPackImpactData = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData");
            var packOneCompsCount = (TotalPackImpactData.dataProviders['packoneCompDataprovider'].viewModelCollection.loadedVMObjects) ? TotalPackImpactData.dataProviders['packoneCompDataprovider'].viewModelCollection.loadedVMObjects.length : 0;
            var packTwoCompsCount = (TotalPackImpactData.dataProviders['packtwoCompDataprovider'].viewModelCollection.loadedVMObjects) ? TotalPackImpactData.dataProviders['packtwoCompDataprovider'].viewModelCollection.loadedVMObjects.length : 0;
            var packThreeCompsCount = (TotalPackImpactData.dataProviders['packthreeCompDataprovider'].viewModelCollection.loadedVMObjects) ? TotalPackImpactData.dataProviders['packthreeCompDataprovider'].viewModelCollection.loadedVMObjects.length : 0;
            var packFourCompsCount = (TotalPackImpactData.dataProviders['packfourCompDataprovider'].viewModelCollection.loadedVMObjects) ? TotalPackImpactData.dataProviders['packfourCompDataprovider'].viewModelCollection.loadedVMObjects.length : 0;
            var packFiveCompsCount = (TotalPackImpactData.dataProviders['packfiveCompDataprovider'].viewModelCollection.loadedVMObjects) ? TotalPackImpactData.dataProviders['packfiveCompDataprovider'].viewModelCollection.loadedVMObjects.length : 0;

            totalPackImpactRepData.packCompsTabModels[0].name = 'Pack1(' + packOneCompsCount + ')';
            totalPackImpactRepData.packCompsTabModels[1].name = 'Pack2(' + packTwoCompsCount + ')';
            totalPackImpactRepData.packCompsTabModels[2].name = 'Pack3(' + packThreeCompsCount + ')';
            totalPackImpactRepData.packCompsTabModels[3].name = 'Pack4(' + packFourCompsCount + ')';
            totalPackImpactRepData.packCompsTabModels[4].name = 'Pack5(' + packFiveCompsCount + ')';


        }

        /**
         * 
         * appends component count on Third page caption
         */
        export let updateCountOnCaption = function () {
			var TotalPackImpactData = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData");
            var relObjsCount = (TotalPackImpactData.dataProviders['relavantCompSelectedDataprovider'].viewModelCollection.loadedVMObjects) ? TotalPackImpactData.dataProviders['relavantCompSelectedDataprovider'].viewModelCollection.loadedVMObjects.length : 0;
            var refObjsCount = (TotalPackImpactData.dataProviders['referenceCompSelectedDataprovider'].viewModelCollection.loadedVMObjects) ? TotalPackImpactData.dataProviders['referenceCompSelectedDataprovider'].viewModelCollection.loadedVMObjects.length : 0;

            TotalPackImpactData.relCompTabModel[0].name = 'Relevant Components(' + relObjsCount + ')';
            TotalPackImpactData.refCompTabModel[0].name = 'Reference Components(' + refObjsCount + ')';

        }
		export let UpdateHash = function(data)
		{
			var packPage = _appCtxSvc.getCtx("TotalPackImpact.TotalPackImpactData.packPage");
			if(packPage === 'packone')
			{
				
				var  selectedobjects = data.dataProviders['packoneCompDataprovider'].selectedObjects;
				selectedobjects[0].cellProperties.HashProp.value=data.packHashTextbox.uiValue;
				
				var vmo = data.dataProviders['packoneCompDataprovider'].viewModelCollection;
				var loadedVMObjects = vmo.loadedVMObjects;
				vmo.loadedVMObjects = [];
				data.dataProviders['packoneCompDataprovider'].update(vmo.loadedVMObjects );
				
				vmo.loadedVMObjects = loadedVMObjects;
				data.dataProviders['packoneCompDataprovider'].update(vmo.loadedVMObjects );
				data.dataProviders['packoneCompDataprovider'].selectionModel.setSelection(selectedobjects);
			}
			else if(packPage === 'packtwo')
			{
				var  selectedobjects = data.dataProviders['packtwoCompDataprovider'].selectedObjects;
				selectedobjects[0].cellProperties.HashProp.value=data.packHashTextbox.uiValue;
				
				var vmo = data.dataProviders['packtwoCompDataprovider'].viewModelCollection;
				var loadedVMObjects = vmo.loadedVMObjects;
				vmo.loadedVMObjects = [];
				data.dataProviders['packtwoCompDataprovider'].update(vmo.loadedVMObjects );
				
				vmo.loadedVMObjects = loadedVMObjects;
				data.dataProviders['packtwoCompDataprovider'].update(vmo.loadedVMObjects );
				data.dataProviders['packtwoCompDataprovider'].selectionModel.setSelection(selectedobjects);
			}
			else if(packPage === 'packthree')
			{
				var  selectedobjects = data.dataProviders['packthreeCompDataprovider'].selectedObjects;
				selectedobjects[0].cellProperties.HashProp.value=data.packHashTextbox.uiValue;
				
				var vmo = data.dataProviders['packthreeCompDataprovider'].viewModelCollection;
				var loadedVMObjects = vmo.loadedVMObjects;
				vmo.loadedVMObjects = [];
				data.dataProviders['packthreeCompDataprovider'].update(vmo.loadedVMObjects );
				
				vmo.loadedVMObjects = loadedVMObjects;
				data.dataProviders['packthreeCompDataprovider'].update(vmo.loadedVMObjects );
				data.dataProviders['packthreeCompDataprovider'].selectionModel.setSelection(selectedobjects);
			}
			else if(packPage === 'packfour')
			{
				var  selectedobjects = data.dataProviders['packfourCompDataprovider'].selectedObjects;
				selectedobjects[0].cellProperties.HashProp.value=data.packHashTextbox.uiValue;
				
				var vmo = data.dataProviders['packfourCompDataprovider'].viewModelCollection;
				var loadedVMObjects = vmo.loadedVMObjects;
				vmo.loadedVMObjects = [];
				data.dataProviders['packfourCompDataprovider'].update(vmo.loadedVMObjects );
				
				vmo.loadedVMObjects = loadedVMObjects;
				data.dataProviders['packfourCompDataprovider'].update(vmo.loadedVMObjects );
				data.dataProviders['packfourCompDataprovider'].selectionModel.setSelection(selectedobjects);
			}
			else if(packPage === 'packfive')
			{
				var  selectedobjects = data.dataProviders['packfiveCompDataprovider'].selectedObjects;
				selectedobjects[0].cellProperties.HashProp.value=data.packHashTextbox.uiValue;
				
				var vmo = data.dataProviders['packfiveCompDataprovider'].viewModelCollection;
				var loadedVMObjects = vmo.loadedVMObjects;
				vmo.loadedVMObjects = [];
				data.dataProviders['packfiveCompDataprovider'].update(vmo.loadedVMObjects );
				
				vmo.loadedVMObjects = loadedVMObjects;
				data.dataProviders['packfiveCompDataprovider'].update(vmo.loadedVMObjects );
				data.dataProviders['packfiveCompDataprovider'].selectionModel.setSelection(selectedobjects);
				
			}
			
		}


export let moduleServiceNameToInject = 'TotalPackImpactReportTableService';
export default exports = {
	intializePage,
	previousCommandAction,
	nextCommandAction,
	genRepCommandAction,
	sortByIDAscendCommandAction,
	sortByIDDescendCommandAction,
	sortByNameAscendCommandAction,
	sortByNameDescendCommandAction,
	packoneCommandAction,
	packtwoCommandAction,
	packthreeCommandAction,
	packfourCommandAction,
	packfiveCommandAction,
	addCompAction,
	removeCompAction,
	getPackComponents,
	addConsumerUnitToCell,
	updateCountOnCaption,
	moduleServiceNameToInject,
	selectPackComponents,
	UpdateHash
};
app.factory('TotalPackImpactReportTableService', () => exports );