import app from 'app';
import _ from 'lodash';
import eventBus from 'js/eventBus';
import _appCtxSvc from "js/appCtxService";
import _soaSvc from 'soa/kernel/soaService';
import _cdm from 'soa/kernel/clientDataModel';
import _dms from 'soa/dataManagementService';
import _popUpSvc from 'js/popupService';
import _commandPanelService from 'js/commandPanel.service';
import $ from 'jquery';
import awPromiseService from 'js/awPromiseService';
import _notySvc from 'js/NotyModule';
import _msgSvc from 'js/messagingService';
var exports = {};
var unSelect;


export let getReleasedPCRs = function (ctx, data) {
    if (ctx.selected && ctx.selected.props.object_string) {
        var ProductionUpdatePCRCaption = data.i18n.ProductionUpdateTitle + " - " + ctx.selected.props.object_string.uiValues[0];
    }
    //var deferred = awPromiseService.instance.defer();
    //var deferred = awPromiseService.instance.defer();
    _appCtxSvc.registerCtx("ProductionUpdatePCRCaption",ProductionUpdatePCRCaption);
    if (ctx.PCRData && ctx.PCRData.releasedPCRs.length > 1) {
        return({
            "searchResults": ctx.PCRData.releasedPCRs,
            "totalFound": ctx.PCRData.releasedPCRs.length
        });
    }
    //return deferred.promise;
};

export let getOtherProjectPCRs = function (ctx) {
    //var deferred = awPromiseService.instance.defer();
    // var deferred = awPromiseService.instance.defer();
    if (ctx.PCRData && ctx.PCRData.otherProjectPCRs.length > 0) {
        return({
            "searchResults": ctx.PCRData.otherProjectPCRs,
            "totalFound": ctx.PCRData.otherProjectPCRs.length
        });
    }
//     return deferred.promise;
};

export let getInProgressPCRs = function (ctx) {
    //var deferred = awPromiseService.instance.defer();
    // var deferred = awPromiseService.instance.defer();
    if (ctx.PCRData && ctx.PCRData.inProcessPCRs.length > 0) {
        return({
            "searchResults": ctx.PCRData.inProcessPCRs,
            "totalFound": ctx.PCRData.inProcessPCRs.length
        });
    }
    // return deferred.promise;
};

export let getReleasedPCRs1 = function (data, deferred, selectedPack) {
    console.log("getReleasedPCRs1");
    data.releasedPCRs = [];
    data.inProcessPCRs = [];
    data.otherProjectPCRs = [];
    data.relatedObjects = [];
    var operationName = "expandGRMRelationsForSecondary";
    var selectedObject = selectedPack;
    var InputData = {
        secondaryObjects: [selectedObject],
        pref: {
            expItemRev: true,
            returnRelations: true,
            info: [{
                relationTypeName: "U4_PCRSpecification",
                otherSideObjectTypes: []
            }]
        }
    };
    _soaSvc.post('Core-2007-09-DataManagement', operationName, InputData).then(function (SecObjectResponse) {
        console.log(SecObjectResponse);
        operationName = "expandGRMRelationsForPrimary";
        delete InputData.secondaryObjects;
        InputData.primaryObjects = [SecObjectResponse.ServiceData.modelObjects[SecObjectResponse.ServiceData.plain[0]]];
        _soaSvc.post('Core-2007-09-DataManagement', operationName, InputData).then(function (primaryObjResponse) {
            if (primaryObjResponse.ServiceData.plain.length > 1) {
                createPCRCheckList(data, primaryObjResponse, deferred);
            } else {
                deferred.resolve();
            }
        });
    });
    return deferred.promise;
};

function createPCRCheckList(data, response, deferred) {
    console.log("createPCRCheckList");
    var selectedObject = _appCtxSvc.ctx.selected;
    var current_project_list = [];
    if (selectedObject.props.project_list) {
        current_project_list = selectedObject.props.project_list.dbValues;
    }
    createandAddCheckBox(data.releasedPCRs, "Select All", undefined, true);
    // createandAddCheckBox(data.releasedPCRs, selectedObject.props.object_string.dbValues[0], selectedObject);
    var projectuids = [];
    _.forEach(response.ServiceData.modelObjects, function (modelObject) {
        if (response.ServiceData.plain.includes(modelObject.uid) == true) {
            if (modelObject.type == "U4_PCRSpecification") {
                data.relatedObjects.push({
                    "uid": modelObject.uid,
                    "type": modelObject.type
                });
            } else {
                var objectproject_list = modelObject.props.project_list.dbValues;
                // var similarprojectuids = _.intersection(current_project_list, objectproject_list);
                // if (similarprojectuids.length > 0) {
                projectuids = _.union(objectproject_list, projectuids);
                // }
            }
        }
    });
    var project_ProjectTeammap = {};
    var project_teamResponse = exports.loadObjectsandgetProperties(projectuids, ["project_team"]);
    project_teamResponse.then(function () {
        _.forEach(projectuids, function (projectuid) {
            var projectObject = _cdm.getObject(projectuid);
            var crntTeamuid = projectObject.props.project_team.dbValues[0];
            project_ProjectTeammap[crntTeamuid] = projectObject.uid;
        });
        var projectTeamUids = _.keys(project_ProjectTeammap);
        var projectswithDeveloperAccess = {};
        var projectswithNoDeveloperAccess = {};
        var project_membersResponse = exports.loadObjectsandgetProperties(projectTeamUids, ["project_members"]);
        project_membersResponse.then(function () {
            _.forEach(projectTeamUids, function (projectTeamUid) {
                var projectTeamObj = _cdm.getObject(projectTeamUid);
                var project_members = projectTeamObj.props.project_members.uiValues;
                var currentuser = _appCtxSvc.ctx.user.props.object_string.dbValue;
                _.forEach(project_members, function (project_member) {
                    var temp = project_member.split("/");
                    var project_uid = project_ProjectTeammap[projectTeamUid];
                    if (temp[1] == 'Packaging_Developer' && temp[2] == currentuser) {
                        projectswithDeveloperAccess[project_uid] = projectTeamObj.props.object_string.uiValues[0];
                    } else {
                        projectswithNoDeveloperAccess[project_uid] = projectTeamObj.props.object_string.uiValues[0];
                    }
                });
            });
            createPCRCheckListwithfilteredData(data, response, deferred, projectswithDeveloperAccess, projectswithNoDeveloperAccess);
        });
    });
}

function createPCRCheckListwithfilteredData(data, response, deferred, projectswithDeveloperAccess, projectswithNoDeveloperAccess) {
    console.log("createPCRCheckListwithfilteredData");
    var projectswithDeveloperAccessUids = _.keys(projectswithDeveloperAccess);
    var projectswithNoDeveloperAccessUids = _.keys(projectswithNoDeveloperAccess);
    var componentMap = {};
    _.forEach(response.ServiceData.modelObjects, function (modelObject) {
        if (response.ServiceData.plain.includes(modelObject.uid) == true && modelObject.type != "U4_PCRSpecification") {
            var similarprojectuids = _.intersection(projectswithDeveloperAccessUids, modelObject.props.project_list.dbValues);
            var notSimilarProjects = _.intersection(projectswithNoDeveloperAccessUids, modelObject.props.project_list.dbValues);
            if (similarprojectuids.length > 0) {
                if (modelObject.props.release_status_list.uiValues[0] === 'Released') {
                    createandAddCheckBox(data.releasedPCRs, modelObject.props.object_string.dbValues[0], modelObject, true);
                } else {
                    createandAddCheckBox(data.inProcessPCRs, modelObject.props.object_string.dbValues[0], modelObject, false);
                }
            } else if (notSimilarProjects.length > 0) {
                _.forEach(notSimilarProjects, function (notSimilarProject) {
                    if (!componentMap[notSimilarProject]) {
                        componentMap[notSimilarProject] = [modelObject]
                    } else {
                        componentMap[notSimilarProject].push(modelObject);
                    }
                });
            }
        }
    });
    _.forEach(componentMap, function (components, projectUid) {
        createandAddCheckBox(data.otherProjectPCRs, projectswithNoDeveloperAccess[projectUid], null, false, true);
        _.forEach(components, function (component) {
            createandAddCheckBox(data.otherProjectPCRs, component.props.object_string.dbValues[0], component, false);
        });
    });
    deferred.resolve();
}

function createandAddCheckBox(ReferenceArray, checkBoxString, modelObject, isEnabled, isProject) {
    console.log("createandAddCheckBox");
    var checkBoxElement = {
        "propertyDisplayName": checkBoxString,
        "type": "BOOLEAN",
        "isEditable": true,
        "isEnabled": isEnabled,
        "dbValue": false,
        "dispValue": checkBoxString,
        "propertyLabelDisplay": "PROPERTY_LABEL_AT_RIGHT",
        "isProject": isProject == true
    };
    if (modelObject) {
        checkBoxElement.modelObject = {
            "uid": modelObject.uid,
            "type": modelObject.type
        }
    }
    ReferenceArray.push(checkBoxElement);
}

export let ObjectSelected = function (ctx, dataProvider, ItemSelected) {
    console.log("ObjectSelected");
    // ctx.PCRData.pcrObjectsSelected = [];
    // ctx.PCRData.pcrObjectsSelectedUIds = [];
    var pcrObjectsSelected = [];
    var pcrObjectsSelectedUIds = [];
    _appCtxSvc.registerCtx("PCRData.pcrObjectsSelected", pcrObjectsSelected);
    _appCtxSvc.registerCtx("PCRData.pcrObjectsSelectedUIds", pcrObjectsSelectedUIds);
    var vmc = dataProvider.getViewModelCollection();
    var CheckBoxes = vmc.getLoadedViewModelObjects();
    var selectAll = false;
    // _.forEach(CheckBoxes, function (CheckBox) {
    for (var m = 0; m < CheckBoxes.length; m++) {
        CheckBoxes[m].dbValue = false;
    }
    for (var i = 0; i < CheckBoxes.length; i++) {
        for (var j = 0; j < dataProvider.selectedObjects.length; j++) {
            if (CheckBoxes[i].propertyDisplayName === dataProvider.selectedObjects[j].propertyDisplayName) {
                if (dataProvider.selectedObjects[j] && dataProvider.selectedObjects[j].propertyDisplayName == "Select All") {
                    if(CheckBoxes[i].selected == true){
                    dataProvider.selectedObjects = [];
                    dataProvider.selectedObjects.push(CheckBoxes[0]);
                    for (var k = 0; k < CheckBoxes.length; k++) {
                        if (CheckBoxes[k].modelObject) {
                            //CheckBoxes[k].selected = true;
                            dataProvider.selectedObjects.push(CheckBoxes[k]);
                            pcrObjectsSelected.push(CheckBoxes[k].modelObject);
                            pcrObjectsSelectedUIds.push(CheckBoxes[k].modelObject.uid);
                        }
                    }
                    
                    //selectedObject = [];
                    selectAll = true;
                    unSelect = true;
                }
                }
                else {
                    CheckBoxes[i].dbValue = true;
                }
            }
            if (selectAll == true) {
                break;
            }
            else if (CheckBoxes[i].dbValue == true && CheckBoxes[i].modelObject) {
                pcrObjectsSelected.push(CheckBoxes[i].modelObject);
                pcrObjectsSelectedUIds.push(CheckBoxes[i].modelObject.uid);
                break;
            }
        }
        if (selectAll == true) {
            var selectedObject = dataProvider.selectedObjects;
            dataProvider.selectionModel.setSelection(selectedObject);
            break;
        }
        if(unSelect == true && CheckBoxes[0].selected == false){
            var unSelectedObject = [];
            for (var k = 0; k < CheckBoxes.length; k++) {
                if (CheckBoxes[k].modelObject) {
                    //CheckBoxes[k].selected = true;
                    unSelectedObject.push(CheckBoxes[k]);
                }
            }
            dataProvider.selectionModel.removeFromSelection(unSelectedObject);
            dataProvider.selectedObjects = [];
            unSelect = false;
        }
    };
    _appCtxSvc.updateCtx("PCRData.pcrObjectsSelected", pcrObjectsSelected);
    _appCtxSvc.updateCtx("PCRData.pcrObjectsSelectedUIds", pcrObjectsSelectedUIds);
}

export let loadObjectsandgetProperties = function (totalObjectsUidArray, propertyArray) {
    console.log("ObjectSelected");
    var notLoadedObjectsUidArray = [];

    _.forEach(totalObjectsUidArray, function (objectUid) {
        if (_cdm.getObject(objectUid) == null) {
            notLoadedObjectsUidArray.push(objectUid);
        }
    });

    if (notLoadedObjectsUidArray.length > 0) {
        return _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', {
            uids: notLoadedObjectsUidArray
        }).then(function () {
            return _dms.getProperties(totalObjectsUidArray, propertyArray);
        });
    } else {
        return _dms.getProperties(totalObjectsUidArray, propertyArray);
    }
}

export let navigateToProductionUpdatePanelNO = function (ctx) {
    ctx.PCRData.considerSelection = false;
    eventBus.publish("awPopup.close");
    _commandPanelService.activateCommandPanel("ProductionUpdate", 'aw_toolsAndInfo', null);
}

export let navigateToProductionUpdatePanelYes = function (ctx) {
    ctx.PCRData.considerSelection = true;
    eventBus.publish("awPopup.close", {
        "popupId": "ProductionUpdatePCR"
    });
    var objectsSelected = ctx.PCRData.pcrObjectsSelected;
    if (objectsSelected && objectsSelected.length > 1) {
        exports.unlockObjectsandOpenPanel(ctx);
    } else {
        _commandPanelService.activateCommandPanel("ProductionUpdate", 'aw_toolsAndInfo', null);
    }
}
export let unlockObjectsandOpenPanel = function (ctx) {
    var objectsToUnlock = ctx.PCRData.pcrObjectsSelected;
    var input = {
        objects: objectsToUnlock,
        attributes: ["items_tag"]
    };
    var fnlObjects = [];
    _soaSvc.post("Core-2006-03-DataManagement", "getProperties", input).then(function (response) {
        for (var i in objectsToUnlock) {
            if (response.modelObjects[objectsToUnlock[i].uid]) {
                fnlObjects.push({
                    uid: objectsToUnlock[i].uid,
                    type: objectsToUnlock[i].type
                });
                var pcrObject = response.modelObjects[objectsToUnlock[i].uid];
                fnlObjects.push({
                    uid: pcrObject.props.items_tag.dbValues[0],
                    type: pcrObject.type.split("Revision")[0]
                });
            }
        }
        if (ctx.PCRData.relatedObjects && ctx.PCRData.relatedObjects.length > 0) {
            fnlObjects = fnlObjects.concat(ctx.PCRData.relatedObjects);
        }
        var lockInput = {
            objects: fnlObjects,
            lockObjects: false,
        };
        _soaSvc.post("Core-2012-10-DataManagement", "refreshObjects2", lockInput).then(function () {
            _commandPanelService.activateCommandPanel("ProductionUpdate", 'aw_toolsAndInfo', null);
        });
    });
}

export let activateProductionUpdate = function (ctx, selectedObject) {
    var pcrData = {};
    _appCtxSvc.updateCtx("PCRData", pcrData);
    if (ctx.selected && ctx.selected.props.object_string) {
        var ProductionUpdatePCRCaption = "Production Update" + " - " + ctx.selected.props.object_string.uiValues[0];
    }
    if (selectedObject && ctx.preferences.U4_PCR_Technology_Types &&
        ctx.preferences.U4_PCR_Technology_Types.length > 0) {
        var componentType = selectedObject.props.object_type.uiValues[0];
        if (ctx.preferences.U4_PCR_Technology_Types.includes(componentType) == true) {
            var mainPromise = awPromiseService.instance.defer();
            exports.getReleasedPCRs1(pcrData, mainPromise, selectedObject);
            mainPromise.promise.then(function () {
                if (pcrData.releasedPCRs.length > 0 || pcrData.inProcessPCRs.length > 0 || pcrData.otherProjectPCRs.length) {

                    var popupData = {

                        declView: 'ProductionUpdatePCR',
                        options: {
                            whenParentScrolls: 'close',
                            placement: 'right',
                            autoFocus: true,
                            clickOutsideToClose: true,
                            forceCloseOthers: false,
                            hasCloseButton: true,
                            width: "1300px",
                            height: "1000px"
                        }

                    };
                    popupData.caption = ProductionUpdatePCRCaption;
                    _popUpSvc.show(popupData);
                } else {
                    _commandPanelService.activateCommandPanel("ProductionUpdate", 'aw_toolsAndInfo', null);
                }
            });
        } else {
            _commandPanelService.activateCommandPanel("ProductionUpdate", 'aw_toolsAndInfo', null);
        }
    } else {
        _commandPanelService.activateCommandPanel("ProductionUpdate", 'aw_toolsAndInfo', null);
    }
}
export let preCheckProductionUpdate = function (ctx) {
    var deferred = awPromiseService.instance.defer();
    var packRevObjUID, pamRevObjUID;
    var selectedObject;
    var selectedPack, selectedPam;
    if (ctx.selected.modelType.parentTypeName === "U4_PAMRevision") {
        selectedPam = ctx.selected;
        exports.getPackFromPam(deferred);
        deferred.promise.then(function (packRevObjUID) {
            selectedPack = _cdm.getObject(packRevObjUID);
            selectedObject = selectedPack;

            console.log("All Workflows: ", _appCtxSvc.ctx.selected.props.fnd0AllWorkflows);
            if (_appCtxSvc.ctx.selected.props.fnd0AllWorkflows.uiValues[0].includes("Production Change Process - MCT")) {
                _msgSvc.showInfo("Component(s) is already a part of another change.<br>Please initiate Production Change Process workflow on the change object attached to the Related Changes section</br>");
            }
            else {
                var projects = [];
                if (selectedObject.props.project_list) {
                    projects = selectedObject.props.project_list.uiValues;
                }
                var mainPromise = awPromiseService.instance.defer();
                var pamData = {};
                exports.getAssociatedPAMs(selectedPack, selectedPam, pamData, mainPromise);
                mainPromise.promise.then(function () {
                    if (projects.length > 1 && pamData.itemId.length >= 1) {
                        var message = "This specification are used in below projects,</br>";
                        _.forEach(projects, function (project, index) {
                            var num = index + 1;
                            message = message + "  " + num + "). " + project + "</br>";
                        });
                        message = message + "</br>Please make sure you have aligned other users of this specification on the changes before revising it.</br>";

                        message = message + "</br>This Specification is used in below assemblies,</br>";

                        _.forEach(pamData.itemId, function (pam, index) {
                            var num1 = index + 1;
                            message = message + "  " + num1 + "). " + pam.ID + "</br>";
                        });

                        message = message + "</br>Please make sure to update assembly specifications also.</br>";
                    }
                    else if (projects.length > 1) {
                        var message = "This specification are used in below projects,</br>";
                        _.forEach(projects, function (project, index) {
                            var num = index + 1;
                            message = message + "  " + num + "). " + project + "</br>";
                        });
                        message = message + "</br>Please make sure you have aligned other users of this specification on the changes before revising it.</br>";
                    }

                    else if (pamData.itemId.length >= 1) {
                        var message = "This Specification is used in below assemblies,</br>";
                        _.forEach(pamData.itemId, function (pam, index) {
                            var num = index + 1;
                            message = message + "  " + num + "). " + pam.ID + "</br>";
                        });
                        message = message + "</br>Please make sure to update assembly specifications also.</br>";
                    }

                    if (!ctx.activeToolsAndInfoCommand || (ctx.activeToolsAndInfoCommand && ctx.activeToolsAndInfoCommand.commandId != "ProductionUpdate")) {
                        if (projects.length > 1 || pamData.itemId.length >= 1) {
                            var buttonArray = [];
                            buttonArray.push(createButton("Proceed", function ($noty) {
                                $noty.close();
                                exports.activateProductionUpdate(ctx, selectedObject);
                            }));
                            buttonArray.push(createButton("Discard", function ($noty) {
                                eventBus.publish("complete", {
                                    "source": "toolAndInfoPanel"
                                });
                                $noty.close();
                            }));
                            _notySvc.showWarning(message, buttonArray);
                        } else {
                            exports.activateProductionUpdate(ctx, selectedObject);
                        }
                    } else {
                        exports.activateProductionUpdate(ctx, selectedObject);
                    }

                });
            }
        });
    }

    else if (ctx.selected.modelType.parentTypeName === "U4_ComponentRevision") {
        selectedPack = ctx.selected;
        exports.getPAMFromPACK(deferred)
        deferred.promise.then(function (pamRevObjUID) {
            selectedPam = _cdm.getObject(pamRevObjUID);
            selectedObject = selectedPack;

            var projects = [];
            if (selectedObject.props.project_list) {
                projects = selectedObject.props.project_list.uiValues;
            }

            var mainPromise = awPromiseService.instance.defer();
            var pamData = {};
            exports.getAssociatedPAMs(selectedPack, selectedPam, pamData, mainPromise);
            mainPromise.promise.then(function () {
                if (projects.length > 1 && pamData.itemId.length >= 1) {
                    var message = "This specification are used in below projects,</br>";
                    _.forEach(projects, function (project, index) {
                        var num = index + 1;
                        message = message + "  " + num + "). " + project + "</br>";
                    });
                    message = message + "</br>Please make sure you have aligned other users of this specification on the changes before revising it.</br>";

                    message = message + "</br>This Specification is used in below assemblies,</br>";

                    _.forEach(pamData.itemId, function (pam, index) {
                        var num1 = index + 1;
                        message = message + "  " + num1 + "). " + pam.ID + "</br>";
                    });

                    message = message + "</br>Please make sure to update assembly specifications also.</br>";
                }
                else if (projects.length > 1) {
                    var message = "This specification are used in below projects,</br>";
                    _.forEach(projects, function (project, index) {
                        var num = index + 1;
                        message = message + "  " + num + "). " + project + "</br>";
                    });
                    message = message + "</br>Please make sure you have aligned other users of this specification on the changes before revising it.</br>";
                }

                else if (pamData.itemId.length >= 1) {
                    var message = "This Specification is used in below assemblies,</br>";
                    _.forEach(pamData.itemId, function (pam, index) {
                        var num = index + 1;
                        message = message + "  " + num + "). " + pam.ID + "</br>";
                    });
                    message = message + "</br>Please make sure to update assembly specifications also.</br>";
                }

                if (!ctx.activeToolsAndInfoCommand || (ctx.activeToolsAndInfoCommand && ctx.activeToolsAndInfoCommand.commandId != "ProductionUpdate")) {
                    if (projects.length > 1 || pamData.itemId.length >= 1) {
                        var buttonArray = [];
                        buttonArray.push(createButton("Proceed", function ($noty) {
                            $noty.close();
                            exports.activateProductionUpdate(ctx, selectedObject);
                        }));
                        buttonArray.push(createButton("Discard", function ($noty) {
                            eventBus.publish("complete", {
                                "source": "toolAndInfoPanel"
                            });
                            $noty.close();
                        }));
                        _notySvc.showWarning(message, buttonArray);
                    } else {
                        exports.activateProductionUpdate(ctx, selectedObject);
                    }
                } else {
                    exports.activateProductionUpdate(ctx, selectedObject);
                }
            });
        });
    }
}
export let createButton = function (label, callback) {
    return {
        addClass: 'btn btn-notify',
        text: label,
        onClick: callback
    };
}
export let clearPCRPercentage = function (data, _ctx) {
    data.pcr_percentagePD.propertyRequiredText = "";
    data.pcr_percentagePD.uiValue = "";
    data.pcr_percentagePD.dbValue = "";

    if (_ctx.selected && _ctx.selected.props && _ctx.selected.props.u4_material_commodity && _ctx.selected.props.u4_material_class) {
        var matcommProperty = _ctx.selected.props.u4_material_commodity;
        var matClassProperty = _ctx.selected.props.u4_material_class;

        if (matClassProperty.dbValues.length > 0 && matcommProperty.dbValues.length > 0) {
            var matclassValidation = matClassProperty.dbValues[0] == "Plastic";
            var validation = matcommProperty.dbValues[0].includes('Recycled') == true;
            if (matclassValidation == true && validation == true) {
                data.pcr_percentagePD.propertyRequiredText = "Enter Only 100";
                data.pcr_percentagePD.uiValue = "100";
                data.pcr_percentagePD.dbValue = "100";
            } else if (matclassValidation == true) {
                data.pcr_percentagePD.propertyRequiredText = "100 is not allowed";
            }
        }

    }
};

export let getAssociatedPAMs = function (selectedPack, selectedPAM, data, deferred) {
    data.itemId = [];
    var operationName = "expandGRMRelationsForSecondary";
    var selectedObject = selectedPAM;
    var InputData = {
        secondaryObjects: [selectedObject],
        pref: {
            expItemRev: false,
            returnRelations: false,
            info: [{
                relationTypeName: "U4_AssociatedSpecRelation",
                otherSideObjectTypes: []
            }]
        }
    };
    _soaSvc.post('Core-2007-09-DataManagement', operationName, InputData).then(function (SecObjectResponse) {
        _.forEach(SecObjectResponse.ServiceData.modelObjects, function (modelobject) {
            if (SecObjectResponse.ServiceData.plain.includes(modelobject.uid) == true && modelobject.uid != selectedObject.uid) {
                data.itemId.push({
                    "ID": modelobject.props.item_id.dbValues[0]
                });
            }
        });
        deferred.resolve();
    });
    return deferred.promise;
};

export let getPackFromPam = function (deferred) {
    var packRevObjUID;
    var InputData = {
        secondaryObjects: [_appCtxSvc.ctx.selected],
        pref:
        {
            expItemRev: false,
            returnRelations: false,
            info:
                [{
                    relationTypeName: "U4_PAMSpecification",
                    otherSideObjectTypes: []
                }]
        }
    };
    _soaSvc.post('Core-2007-09-DataManagement', 'expandGRMRelationsForSecondary', InputData).then(function (response) {
        packRevObjUID = response.output[0].relationshipData[0].relationshipObjects[0].otherSideObject.uid;
        _appCtxSvc.registerCtx("packRevObjUID",packRevObjUID);
        deferred.resolve(packRevObjUID);
    });
    return deferred.promise;
}

export let getPAMFromPACK = function (deferred) {
    var pamRevObjUID
    var inputData = {
        primaryObjects: [_appCtxSvc.ctx.selected],
        pref:
        {
            expItemRev: false,
            returnRelations: false,
            info:
                [{
                    relationTypeName: "U4_PAMSpecification",
                    otherSideObjectTypes: []
                }]
        }
    }
    _soaSvc.post('Core-2007-09-DataManagement', 'expandGRMRelationsForPrimary', inputData).then(function (response) {
        pamRevObjUID = response.output[0].relationshipData[0].relationshipObjects[0].otherSideObject.uid;
        deferred.resolve(pamRevObjUID);
    });
    return deferred.promise;
};

export default exports = {
    getReleasedPCRs,
    getOtherProjectPCRs,
    getInProgressPCRs,
    getReleasedPCRs1,
    ObjectSelected,
    loadObjectsandgetProperties,
    navigateToProductionUpdatePanelNO,
    navigateToProductionUpdatePanelYes,
    unlockObjectsandOpenPanel,
    activateProductionUpdate,
    preCheckProductionUpdate,
    createButton,
    clearPCRPercentage,
    getAssociatedPAMs,
    getPackFromPam,
    getPAMFromPACK
};
app.factory('ProductionUpdatePCRService', () => exports);