import _policySvc from 'soa/kernel/propertyPolicyService';
import awPromiseService from 'js/awPromiseService';
import app from 'app';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import $ from 'jquery';
import eventBus from 'js/eventBus';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import _compImpactRep from 'js/ComponentImpactReportTableService';
import _totalPackImpactRep from 'js/TotalPackImpactReportTableService';
import _envPackMatImpactRep from 'js/EnvPackagingMaterialReportTableService';
import _commonUtil from 'js/UnileverCommonUtil';
import reportService from 'js/reportService';
import localStrg from 'js/localStorage';

        'use strict';

        var exports = {};

        

        let defaultSublocationName = 'ComponentImpactReport';
        let projUid = 'projUid';




		export let loadrelavantCompSelectedData=function(data)
			{
				let relavantCompSelectedData = data.dataProviders.relavantCompDataprovider.selectedObjects;
				if( relavantCompSelectedData && relavantCompSelectedData.length > 0 ) {
					_appCtxSvc.registerCtx("ComponentImpact.relavantCompSelectedData",relavantCompSelectedData);
				}
			};
		export let loadreferenceCompSelectedData=function(data)
			{
				let referenceCompSelectedData = data.dataProviders.referenceCompDataprovider.selectedObjects;
				if( referenceCompSelectedData && referenceCompSelectedData.length > 0 ) {
					_appCtxSvc.registerCtx("ComponentImpact.referenceCompSelectedData",referenceCompSelectedData);
				}
			};			
        /**
         * 
         * opens mir management location with selected project
         * 
         * 
         */
        export let openMirManagementReport = function () {
            if (_commonUtil.isPreferencesAvailable(['UL_MIR_PhaseRollOut_OrganizationGroups'], true)) {
                if (isEligibleToOpenMirReport()) {
                    _appCtxSvc.registerCtx('_EnvReport_ProjectRev_ItemId', null);
                    _appCtxSvc.registerCtx('_EnvReport_ProjectRev_RevId', null);
                    _appCtxSvc.registerCtx('ViewModeContext', {
                        ViewModeContext: 'ListView'
                    });
					
                    window.location.href = (window.location.origin + window.location.pathname + '#/' + defaultSublocationName + '?' + projUid + '=' + _appCtxSvc.ctx.selected.uid);
                } else {
                    _messagingSvc.showError('Environmental Report Generation is not enabled for your current login Group or the Project is not part Phase rollout.');
                }
            }
			var selectedProject = _appCtxSvc.ctx.selected.uid;
			_appCtxSvc.registerCtx("selectedProject",selectedProject);

        };

        /**
         * 
         * checks user group or project is eligible to generate report comparing with preference
         * 
         * 
         */
        var isEligibleToOpenMirReport = function () {

            var eligible = false;


            var phaserollout_groups = _appCtxSvc.ctx.preferences.UL_MIR_PhaseRollOut_OrganizationGroups;
            var currentGroup = _appCtxSvc.ctx.userSession.props.group_name.dbValues[0];


            //checks user group is eligible
            if (phaserollout_groups && currentGroup) {
                for (var index = 0; index < phaserollout_groups.length; index++) {
                    if (currentGroup === phaserollout_groups[index])
                        eligible = true;
                }
            }
            return eligible;
        }


        export let loadRelevantComponents = function (data, policy) {
			var selectedProject = _appCtxSvc.getCtx("selectedProject");
			console.log(selectedProject);
            var deferred = awPromiseService.instance.defer();

            var noCompAvailable = function () {
                data['relevantComponents'] = [];
                _messagingSvc.showError('No Components under Project.');
                deferred.resolve();
            }

            var projectNotSelected = function () {
                data['relevantComponents'] = [];
                _messagingSvc.showError('Something goes wrong. Please check whether project revision exist or not.');
                deferred.resolve();
            }
            let selectedProjRevUid = _commonUtil.getParametersFromURL(_appCtxSvc.ctx.sublocation.nameToken)[projUid];
            if (!selectedProjRevUid) {
                appendProjInfoToUrl();
                selectedProjRevUid = _commonUtil.getParametersFromURL(_appCtxSvc.ctx.sublocation.nameToken)[projUid];
            }
			else{
				localStrg.publish('ProjectUid',selectedProjRevUid); 
			}
			const ProjectUid = localStrg.get('ProjectUid');
		console.log(ProjectUid);
            if (!ProjectUid) {
                projectNotSelected();
            } else {
				
                var inputData = {
                    projectRev: {
                        uid: ProjectUid,
                        type: 'U4_ProjectRevision'
                    }
                };

                _soaSvc.post('AWC-2016-10-EnvReportChanges', 'latestCompsUnderProject', inputData).then(function (response) {
	
                    if (response && response.latestItemRevs) {
                        var latestRevs = [];
                        for (var obj in response.latestItemRevs) {
                            if (response.latestItemRevs[obj].uid && (response.latestItemRevs[obj].uid !== 'AAAAAAAAAAAAAA'))
                                latestRevs.push(response.latestItemRevs[obj].uid);
							
                        }

                        if (latestRevs.length === 0) {

                            noCompAvailable();
                        } else {

                            var policyID = null;
                            if (policy)
                                policyID = _policySvc.register(policy);
                            try {
                                var loadObjectsInputData = {
                                    uids: latestRevs
                                };
                                //to load the objects from uid
                                _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', loadObjectsInputData).then(function (loadObjectsResponse) {
                                    if (policy && policyID)
                                        _policySvc.unregister(policyID);

                                    var outputArray = [];
                                    for (var i in latestRevs) {

                                        var uid = latestRevs[i];
                                        var compRev = loadObjectsResponse.modelObjects[uid];

                                        if (_.endsWith(compRev.type, 'Revision') && compRev.type !== 'U4_DDERevision' && compRev.type !== 'U4_CADComponentRevision') {
                                            outputArray.push(compRev);
                                        }

                                    }

                                    if (outputArray.length === 0) {

                                        noCompAvailable();

                                    } else {

                                        data['relevantComponents'] = outputArray;
                                        data.bc_provider.crumbs[0].displayName = 'Relevant Components(' + outputArray.length + ')';
                                        _commonUtil.sortComponents(data, 'relevantComponents', 0, 0);
                                        deferred.resolve();

                                    }

                                });
                            } catch (error) {
                                _policySvc.unregister(policyID);
                            }
                        }
                    } else {
                        noCompAvailable();
                    }
                });
            }

            return deferred.promise;

        }



        export let getSelectedComponents = function (data, param1, param2, param3, props) {

            data[param3] = [];
            var relSelectedObjs = data.dataProviders[param1].selectedObjects;
			
        }

        export let getProjectInfo = function (callback) {

            //let selectedProjRevUid = _commonUtil.getParametersFromURL(_appCtxSvc.ctx.sublocation.nameToken)[projUid];
			const ProjectUid = localStrg.get('ProjectUid');
            if (_appCtxSvc.ctx._EnvReport_ProjectRev_ItemId && _appCtxSvc.ctx._EnvReport_ProjectRev_RevId) {
                callback({
                    item_id: _appCtxSvc.ctx._EnvReport_ProjectRev_ItemId,
                    item_revision_id: _appCtxSvc.ctx._EnvReport_ProjectRev_RevId
                });
            } else {

                var inputData = {
                    objects: [{
                        uid: ProjectUid,
                        type: 'U4_ProjectRevision'
                    }],
                    attributes: ['item_id', 'item_revision_id']
                };

                _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (response) {
					console.log("response");
					console.log(response);
                    var itemid = response.modelObjects[ProjectUid].props.item_id.dbValues[0];
                    var revid = response.modelObjects[ProjectUid].props.item_revision_id.dbValues[0];

                    _appCtxSvc.ctx._EnvReport_ProjectRev_ItemId = itemid;
                    _appCtxSvc.ctx._EnvReport_ProjectRev_RevId = revid;

                    callback({
                        item_id: _appCtxSvc.ctx._EnvReport_ProjectRev_ItemId,
                        item_revision_id: _appCtxSvc.ctx._EnvReport_ProjectRev_RevId
                    });

                });
            }

        }

        export let generateReport = function (dataToReport) {

            var inputData = {

                'request': {
                    'method': 'POST',
                    'url': '/Report/ReportService.asmx/' + currentReport(),
                    'headers': {
                        'Content-type': 'application/json; charset=utf-8'
                    },
                    'contentType': 'application/json; charset=utf-8',
                    'dataType': 'json',
                    'data': dataToReport
                }

            };

            
            reportService.callServiceDownloadJS(inputData);

        }

        var currentReport = function () {

            if (_appCtxSvc.ctx.sublocation.historyNameToken === 'ComponentImpactReport') {
                return 'ComponentImpactReport';
            } else if (_appCtxSvc.ctx.sublocation.historyNameToken === 'TotalPackImpactReport') {
                return 'TotalPackImpactReport';
            } else if (_appCtxSvc.ctx.sublocation.historyNameToken === 'EnvPackagingMaterialReport') {
                return 'EnvironmentPackageMaterialReport';
            }

        }

        export let getModelObjectsFromDataprovider = function (data, dataproviderName, takeHash) {
            var vmObjs = data.dataProviders[dataproviderName].viewModelCollection.loadedVMObjects;
            var selectedComps = [];

            for (var t = 0; t < vmObjs.length; ++t) {
                var obj = {
                    ItemID: vmObjs[t].props.item_id.dbValues[0],
                    Rev: vmObjs[t].props.item_revision_id.dbValues[0]
                };

                if (takeHash) {
                    var hashValue = vmObjs[t].cellProperties['# '];
                    if (hashValue) {
                        obj.Hash = hashValue.value;
                    }
                }
                selectedComps.push(obj);
            }

            return selectedComps;
        }


        export let previousCommandAction = function (ctx) {
            getCurrentReportJSobj().previousCommandAction(ctx);

        }

        export let nextCommandAction = function (ctx,data) {
            getCurrentReportJSobj().nextCommandAction(ctx,data);
        }

        export let genRepCommandAction = function () {

            //getCurrentReportJSobj().genRepCommandAction();
			eventBus.publish("genRepCommandAction");
        }

        export let sortByIDAscendCommandAction = function () {

			getCurrentReportJSobj();
            eventBus.publish("sortByIDAscendCommandAction");
        }

        export let sortByIDDescendCommandAction = function () {
			getCurrentReportJSobj();
            eventBus.publish("sortByIDDescendCommandAction");
        }

        export let sortByNameAscendCommandAction = function () {
			getCurrentReportJSobj();
            eventBus.publish("sortByNameAscendCommandAction");
        }

        export let sortByNameDescendCommandAction = function () {
			getCurrentReportJSobj();
            eventBus.publish("sortByNameDescendCommandAction");

        }

        var getCurrentReportJSobj = function () {

            if (_appCtxSvc.ctx.sublocation.historyNameToken === 'ComponentImpactReport') {
                return _compImpactRep;
            } else if (_appCtxSvc.ctx.sublocation.historyNameToken === 'TotalPackImpactReport') {
                return _totalPackImpactRep;
            } else if (_appCtxSvc.ctx.sublocation.historyNameToken === 'EnvPackagingMaterialReport') {
                return _envPackMatImpactRep;
            }
        }

        export let ctxChange = function () {

            if ((_appCtxSvc.ctx.ViewModeContext.ViewModeContext !== 'ListView') && (_appCtxSvc.ctx.locationContext) && (_appCtxSvc.ctx.locationContext['ActiveWorkspace:Location'] === 'MIRReports')) {
                exports.changeToListView();
            }
        }

        export let changeToListView = function () {


            _appCtxSvc.registerCtx('ViewModeContext', {
                ViewModeContext: 'ListView'
            });
            _messagingSvc.showInfo('Environmental Report does not support views other than list view.');
        }

        export let storeProjectUid = function () {
            if (_appCtxSvc.ctx.sublocation) {
                let selectedProjRevUid = _commonUtil.getParametersFromURL(_appCtxSvc.ctx.sublocation.nameToken)[projUid];
                if (selectedProjRevUid)
                    sessionStorage.selectedProjRevUid = selectedProjRevUid;
            }
        }

        let appendProjInfoToUrl = function () {

            let selectedProjRevUid = sessionStorage.selectedProjRevUid;
            if (selectedProjRevUid) {
                window.location.href = window.location.href + '?' + projUid + '=' + selectedProjRevUid;
            }


        }

        /*
    clears breadcrumb title for mct sublocation
    */
        export let clearBreadCrumbText = function () {
            //send location name
            _commonUtil.clearBreadCrumbText('MIRReports');
        }

			export let moduleServiceNameToInject = 'EnvReportUtil';
			
			export default exports = {
				openMirManagementReport,
				loadRelevantComponents,
				getSelectedComponents,
				getProjectInfo,
				generateReport,
				getModelObjectsFromDataprovider,
				previousCommandAction,
				nextCommandAction,
				genRepCommandAction,
				sortByIDAscendCommandAction,
				sortByIDDescendCommandAction,
				sortByNameAscendCommandAction,
				sortByNameDescendCommandAction,
				ctxChange,
				changeToListView,
				storeProjectUid,
				clearBreadCrumbText,
				moduleServiceNameToInject,
				loadrelavantCompSelectedData,
				loadreferenceCompSelectedData
			};
			app.factory('EnvReportUtil', () => exports );
   