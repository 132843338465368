import _soaSVC from 'soa/kernel/soaService';
import _msgSvc from 'js/messagingService';
import _appCtxSvc from 'js/appCtxService';
import app from 'app';
/**
 * An OTB SOA call to get the Primary Object i.e DDE obj based on Relation then opening DDE Obj
 * @param {Object} data ViewModel object
 *
 */

    'use strict';

   var exports = {};

    export let getDDEFromPnP = function( data ) {

        var InputData = {
                secondaryObjects: [_appCtxSvc.ctx.selected],
                pref:
                {
                expItemRev: true,
                returnRelations: true,
                info:
                [{
                relationTypeName: "U4_PnPSpecificationRelation",
                otherSideObjectTypes: []
                }]
                }

        };

        _soaSVC.post('Core-2007-09-DataManagement','expandGRMRelationsForSecondary',InputData).then(function(response)
        {
            if(response.output[0].relationshipData[0].relationshipObjects.length === 0)
            {
                _msgSvc.showError("For selected component, DDE  doesn't exists");// This case never happen,but handling native case.
            }
            var ddeRevObjUID = response.output[0].relationshipData[0].relationshipObjects[0].otherSideObject.uid;
            if(ddeRevObjUID)
            {
                var newURLwithNewObject = window.location.origin + window.location.pathname + 
                '#/com.siemens.splm.clientfx.tcui.xrt.showObject?uid=' + ddeRevObjUID;
                window.location.href = newURLwithNewObject;
            }
        }).catch(function(error){
            _msgSvc.showError("ERROR:Unable to get DDE from PnP due to:\t"+_msgSvc.getSOAErrorMessage(error));
        });
    };

export let moduleServiceNameToInject = 'UnileverPnPToDDEService';
export default exports = {
	getDDEFromPnP,
	moduleServiceNameToInject
};
app.factory('UnileverPnPToDDEService', () => exports );  