import _messagingSvc from 'js/messagingService';
import app from 'app';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import eventBus from 'js/eventBus';
import _lovUtil from 'js/UnileverLovUtil';
import _policySvc from 'soa/kernel/propertyPolicyService';
import _uwPropertyService from 'js/uwPropertyService';
import _pmlLovService from 'js/unileverPMLLovService';
import awPromiseService from 'js/awPromiseService';
import _appCtxService from "js/appCtxService";


'use strict';
var exports = {};
var isDataBeingLoaded = false;
var isLoadobjectedlov = false;
//var _lovService = null;

export let loadLayerFormDataRender = function () {
	// FOR ISSUE - RenderFn is not defined
};

var LOVDataValidationResult = function LOVDataValidationResult() {
	var self = this;
	self.updatedPropValueMap = {};
	self.updatedPropDisplayValueMap = {};
	self.setViewModelObject = function (vmObj) {
		self.viewModelObj = vmObj;
	};
	self.addUpdatedPropertyValue = function (propName, propValues, propDisplayValues) {
		self.updatedPropValueMap[propName] = propValues;
		self.updatedPropDisplayValueMap[propName] = propDisplayValues;
	};
	self.setValid = function (valid) {
		self.valid = valid;
	};
	self.setError = function (error) {
		self.error = error;
	};
};

/* export let loadLayerFormData = function (data, ctx, props, editState,context) {
} */
export let loadLayerFormData = function (data, editState) {
	if (_appCtxService.ctx && _appCtxService.ctx.xrtSummaryContextObject) {
		var frameSelected = _appCtxService.ctx.xrtSummaryContextObject;
		var _declViewModel;
		if (data.subPanelContext && data.subPanelContext.showObjectContext && data.subPanelContext.showObjectContext.objectSetInfo) {
			_declViewModel = data.subPanelContext.showObjectContext.objectSetInfo.firstPage;
		}
		if (_declViewModel) {
			_.forEach(_declViewModel, function (dataProvider) {
				if (dataProvider != null) {
					_.forEach(dataProvider.props, function (col) {
						if (col.propertyName === "u4_type" && col.srcObjectTypeName === "U4_LayerStructureForm") {
							var loadedViewModelObjects = dataProvider;
							isLoadobjectedlov = false;
							ValidateRowAndPopulateLov(frameSelected, loadedViewModelObjects, dataProvider, editState, _appCtxService.ctx);
						}

						if (col.propertyName === "u4_pml_type") {
							var loadedViewModelObjects = dataProvider;
							//_.forEach(loadedViewModelObjects, function (viewModelObj) {
							var property = loadedViewModelObjects.props[col.propertyName];
							if (property.dbValues.length == 0 || property.dbValues[0] == "-" || property.dbValues[0] == null || property.dbValues[0] == "") {
								enableProperties(loadedViewModelObjects, ["u4_manufacturer"], true);
								enableProperties(loadedViewModelObjects, ["u4_grade_reference"], true);
							}
							else {
								enableProperties(loadedViewModelObjects, ["u4_manufacturer"], false);
								enableProperties(loadedViewModelObjects, ["u4_grade_reference"], false);
							}
							//_uwPropertyService.triggerDigestCycle();
							if (!property.propApi) {
								property.propApi = {};
							}
							property.propApi.fireValueChangeEvent = function () {
								if (property.newValue == "-" || property.newValue == "") {
									enableProperties(loadedViewModelObjects, ["u4_manufacturer"], true);
									enableProperties(loadedViewModelObjects, ["u4_grade_reference"], true);
								}
								else {
									enableProperties(loadedViewModelObjects, ["u4_manufacturer"], false);
									enableProperties(loadedViewModelObjects, ["u4_grade_reference"], false);
								}
								//_uwPropertyService.triggerDigestCycle();
							};
							//});

						}
					});
				}
			});
		}
		//}
	}
}

async function ValidateRowAndPopulateLov(frameSelected, selViewModelObj, dataProvider, editState, ctx) {
	let existingTypeValue = selViewModelObj.props.u4_type.dbValue;
	let existingTypeProperty = selViewModelObj.props.u4_type;
	isDataBeingLoaded = true;
	var inputData = {
		"inputLayerForm": {
			"uid": selViewModelObj.props.awp0Secondary.dbValue,
			"type": selViewModelObj.type
		}
	};
	await _soaSvc.post('AWC-2017-12-UpdateProps', 'updatePropsPreCheck', inputData).then(function (response) {
		selViewModelObj.preChkint = response.preChkint;
		if (response.preChkint == 1 || response.preChkint == 0) {
			if (isLoadobjectedlov == false) {
				isLoadobjectedlov = true;
				//loadU4_type(frameSelected, existingTypeProperty, selViewModelObj, function () {
					ctx.layerStructureLov = {};
					ctx.layerStructureLov.lovValues = existingTypeProperty.lovValues;
					updateValuesAndEditableState(existingTypeProperty, dataProvider, selViewModelObj, existingTypeValue, editState);
				//});
			} else if (ctx.layerStructureLov && ctx.layerStructureLov.lovValues) {
				existingTypeProperty.lovValues = ctx.layerStructureLov.lovValues;
				updateValuesAndEditableState(existingTypeProperty, dataProvider, selViewModelObj, existingTypeValue, editState);

			}
		} else if (response.preChkint == 2) {//g-label-lit will come into this loop
			enableProperties(selViewModelObj, ['u4_type', 'u4_target', 'u4_min', 'u4_max', 'u4_colour', 'u4_comment'], false);
		}

		exports.initNativeCellLovApi(existingTypeProperty, selViewModelObj, dataProvider);
	}, function (error) {
		_messagingSvc.showInfo(error + "");
	});
}

function updateValuesAndEditableState(existingTypeProperty, dataProvider, selViewModelObj, existingTypeValue, editState) {
	var makeEditable = false;
	isDataBeingLoaded = false;
	if (existingTypeProperty && existingTypeProperty.lovValues && existingTypeProperty.lovValues.length > 0) {
		if (existingTypeValue !== null) {
			for (var inx = 0; inx < existingTypeProperty.lovValues.length; inx++) {
				if (existingTypeProperty.lovValues[inx].propDisplayValue == existingTypeValue) {
					if (existingTypeProperty.lovValues[inx]._type === 'U4_Substrate') {
						makeEditable = false;
					} else {
						makeEditable = true;
					}
					break;
				}
			}
		} else {
			makeEditable = true;
		}
		enableProperties(selViewModelObj, ['u4_target', 'u4_min', 'u4_max'], makeEditable);
	} else {
		console.log("Entering in else of updateValuesAndEditableState");
		enableProperties(selViewModelObj, ['u4_target', 'u4_min', 'u4_max', 'u4_colour', 'u4_comment'], false);
		//_messagingSvc.showInfo('No Value found.');
	}
}

function valiatetypeValueandProceed(viewProp, viewModelObj, dataProvider) {
	//var loadedObjects = dataProvider.getViewModelCollection().getLoadedViewModelObjects();
	/* var activeEditHandler = _editHandlerService.getActiveEditHandler();
	var dataPro = activeEditHandler.getDataSource().getDeclViewModel().getAtomicData().dpRef.current.dataProviders;
	var loadedObjects = dataPro[1](); */
	var loadedObjects = dataProvider;
	var editedObjectindex = _.findIndex(loadedObjects, function (loadedObject) {
		return loadedObject.uid == viewModelObj.uid;
	});
	var editedObject = loadedObjects[editedObjectindex];
	if (editedObjectindex > 0)
		var previousObject = loadedObjects[editedObjectindex - 1];
	if (editedObjectindex < loadedObjects.length - 1)
		var nextObject = loadedObjects[editedObjectindex + 1];


	var isError = false;
	if (previousObject && editedObject.props.u4_no.dbValues[0] == previousObject.props.u4_no.dbValues[0]) {
		/*if(viewProp.uiValue == null || viewProp.uiValue == "" || viewProp.uiValue == " ")
		{
			enableProperties(previousObject, ['u4_type', 'u4_target', 'u4_min', 'u4_max'], true, dataProvider);
		}
		else{
		enableProperties(previousObject, ['u4_type', 'u4_target', 'u4_min', 'u4_max'], false, dataProvider);
		}*/
		var prevTypeValue = previousObject.props[viewProp.propertyName].uiValue;
		if (prevTypeValue != null && prevTypeValue != "" && prevTypeValue != " " && viewProp.uiValue != prevTypeValue) {
			isError = true;
		}
	}
	if (nextObject && editedObject && editedObject.props.u4_no.dbValues[0] == nextObject.props.u4_no.dbValues[0]) {
		var nxtTypeValue = nextObject.props[viewProp.propertyName].uiValue;
		/*if(viewProp.uiValue == null || viewProp.uiValue == ""|| viewProp.uiValue == " ")
		{
			enableProperties(nextObject, ['u4_type', 'u4_target', 'u4_min', 'u4_max'], true, dataProvider);
		}
		else{
		enableProperties(nextObject, ['u4_type', 'u4_target', 'u4_min', 'u4_max'], false, dataProvider);
		}*/
		if (nxtTypeValue != null && nxtTypeValue != "" && nxtTypeValue != " " && viewProp.uiValue != nxtTypeValue) {
			isError = true;
		}
	}
	if (isError == true) {
		_uwPropertyService.setError(viewProp, "Error");
		return false;
	}
	else {
		return true;
	}

}

export let initNativeCellLovApi = function (viewProp, viewModelObj, dataProvider) {
	// //To make the property as lov and override the default getInitialValues
	// //and validateLOVValueSelections
	viewProp.hasLov = true;
	viewProp.lovApi = {};
	viewProp.lovApi.operationName = "EDIT";
	viewProp.renderingHint = "CustomSOAComponent";
};

export let validateLOVValueSelections = function (lovEntries, viewProp, viewModelObj, dataProvider) {
	var validateLOVValueDeferred = awPromiseService.instance.defer();
	var validationResult = new LOVDataValidationResult();
	validationResult.setViewModelObject(viewModelObj);
	if (viewProp.lovList && viewProp.lovList.includes(lovEntries[0].propInternalValue) == true) {
		validationResult.setValid(true);
	} else {
		validationResult.setValid(false);
	}
	validationResult.setValid(valiatetypeValueandProceed(viewProp, viewModelObj, dataProvider));
	if (!validationResult.valid) {
		var errorValue = lovEntries[0].propDisplayValue;
		var editedProperty = viewProp.propertyDisplayName;
		var propertyType = viewProp.type;
		var message = 'The value "{{0}}" is not a valid value for the property "{{1}}" of type "{{2}}".'
		message = message.replace("{{0}}", errorValue);
		message = message.replace("{{1}}", editedProperty);
		message = message.replace("{{2}}", propertyType);
		validationResult.setError(message);
		validateLOVValueDeferred.resolve(validationResult);
	} else {
		validateLOVValueDeferred.resolve(validationResult);
	}
	return validateLOVValueDeferred.promise;
}

function enableProperties(viewModelObject, list, enable) {
	if (list && viewModelObject) {
		for (var i in list) {
			var propertykey = list[i];
			if (viewModelObject.props && viewModelObject.props[propertykey]) {
				viewModelObject.props[propertykey].isEditable = enable;
				//	viewModelObject.props[propertykey].isEditing = enable;
				viewModelObject.props[propertykey].isPropertyModifiable = enable;
				_uwPropertyService.setEditable(viewModelObject.props[propertykey], enable);
				_uwPropertyService.setIsEditable(viewModelObject.props[propertykey], enable);
				_uwPropertyService.setEditState(viewModelObject.props[propertykey], enable, true);
			}
		}
	}
}

var loadU4_type = function (frameSelected, selectedProperty, viewModelObject, callback) {
	selectedProperty.lovValues = [];
	selectedProperty.lovValues.push({
		propDisplayValue: "",
		propDisplayDescription: "",
		propInternalValue: "",
		hasChildren: false,
		children: {},
		sel: false
	});
	if (frameSelected.type === 'U4_GCartonsRevision') {
		var inputData = {
			lovname: "U4_PaperNBoardLSTypeLOV"
		};
		_soaSvc.post('AWC-2016-10-PNP', 'getLOVValues', inputData).then(function (response) {
			for (let i = 0; i < response.lovvalues.length; i++) {
				selectedProperty.lovValues.push({
					propDisplayValue: response.lovvalues[i],
					propDisplayDescription: '',
					propInternalValue: response.lovvalues[i],
					hasChildren: false,
					children: {},
					sel: false
				});
			}
			callback();
		});
	} else {
		getU4_typeLovValues(viewModelObject, selectedProperty, function () {
			callback();
		});
	}
}

var getU4_typeLovValues = function (viewModelObject, selectedProperty, callback) {
	var selectedObject = {
		"uid": viewModelObject.props.awp0Secondary.dbValue,
		"type": "U4_LayerStructureForm"
	};
	_lovUtil.getLOVvalue('U4_LayerStructureForm', 'u4_type', 'object_name', '', selectedObject, selectedProperty.lovValues, function (serviceData) {
		if (selectedProperty.lovValues.length > 0) {
			let _uids = [];
			for (let i = 0; i < selectedProperty.lovValues.length; ++i) {
				if (selectedProperty.lovValues[i].uid)
					_uids.push(selectedProperty.lovValues[i].uid);
			}
			var inputData = {
				uids: _uids
			};
			_soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', inputData).then(function (response) {
				for (let i = 0; i < selectedProperty.lovValues.length; ++i) {
					if (selectedProperty.lovValues[i].uid && response.modelObjects[selectedProperty.lovValues[i].uid] && response.modelObjects[selectedProperty.lovValues[i].uid].type)
						selectedProperty.lovValues[i]._type = response.modelObjects[selectedProperty.lovValues[i].uid].type;
				}
				callback();
			});
		} else {
			callback();
		}
	});
}

/**

 * Function will be called on updation of type property.

 * Called on the click of update command

 *

 * @param {Object} data ViewModel object

 * @param {Object} ctx ctx Object

 * @return

 *

 */
export let onValueInTypeProperty = function (props, data, ctx, editState) {
	if (props.name === "u4_type") {
		var selectedProperty = data.dataProviders.methodDataProvider.viewModelCollection.loadedVMObjects;
		var viewModelObject = data.ctx.selected;
		var u4_typevalue = props.vmo.props;
		var selectedPMLuid = null;
		for (var inx = 0; inx < selectedProperty.length; inx++) {
			if (selectedProperty[inx].selected == true) {
				selectedPMLuid = selectedProperty[inx].dbValue;
				break;
			}
		}

		if (selectedPMLuid) {
			isDataBeingLoaded = true;
			getPmlLatestRevInfo(viewModelObject, selectedPMLuid, function (info) {

				isDataBeingLoaded = false;
				var propertykeyValuePair = {
					"u4_target": (info.u4_target) ? info.u4_target : '',
					"u4_min": (info.u4_min) ? info.u4_min : '',
					"u4_max": (info.u4_max) ? info.u4_max : ''
				};
				enableProperties(props.vmo, ['u4_target', 'u4_min', 'u4_max'], false);
				exports.UpdatePropertywithnewValue(propertykeyValuePair, props.vmo);
			});
		}
		else {
			var propertykeyValuePair = {
				"u4_target": '',
				"u4_min": '',
				"u4_max": ''
			};
			exports.UpdatePropertywithnewValue(propertykeyValuePair, props.vmo);
			enableProperties(props.vmo, ['u4_target', 'u4_min', 'u4_max'], true);
		}
	}
	else if (props.name === "u4_pml_type") {
		enableProperties(props.vmo, ['u4_manufacturer', 'u4_grade_reference'], false);
	}
}

export let layerFormStartEdit = function (commandContext, ctx) {
	exports.loadLayerFormData(ctx);
}

export let UpdatePropertywithnewValue = function (keyValuePair, ctx) {
	var updatedProps = {};
	updatedProps[ctx.uid] = _.keys(keyValuePair);
	_.forEach(keyValuePair, function (value, key) {
		if (ctx.props[key]) {
			ctx.props[key].uiValue = value;
			ctx.props[key].dbValue = value;
			_uwPropertyService.setValue(ctx.props[key], value);
		}
	});
	eventBus.publish('viewModelObject.propsUpdated', updatedProps);
};

var getPmlLatestRevInfo = function (viewModelObject, pmlLatestRev, callback) {
	let result = {};
	var policy = {
		types: [{
			'name': 'U4_SubstrateRevision',
			'properties': [{
				'name': 'U4_PMLCompPropertyRelation',
				'modifiers': [{
					'name': 'withProperties',
					'Value': 'true'
				}]
			}]
		},
		{
			'name': 'U4_PMLCompPropertyForm',
			'properties': [{
				'name': 'u4_property'
			},
			{
				'name': 'u4_sequence_no'
			},
			{
				'name': 'u4_target'
			},
			{
				'name': 'u4_min'
			},
			{
				'name': 'u4_max'
			}
			]
		}
		]
	};
	var policyID = null;
	if (policy) {
		policyID = _policySvc.register(policy);
	}
	var loadObjectsInputData = {
		uids: [pmlLatestRev]
	};

	_soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', loadObjectsInputData).then(function (loadObjectsResponse) {
		if (policy && policyID)
			_policySvc.unregister(policyID);
		if (loadObjectsResponse && (loadObjectsResponse.modelObjects[pmlLatestRev].type === 'U4_SubstrateRevision')) {
			let reqUOM = 'Thickness';
			let layerFormObjName = viewModelObject.cellHeader1;
			if (layerFormObjName == 'Layer_No. 2' || layerFormObjName == 'Layer_No. 4' || layerFormObjName == 'Layer_No. 6' ||
				layerFormObjName == 'Layer_No. 8' || layerFormObjName == 'Layer_No. 10' || layerFormObjName == 'Layer_No. 12' || layerFormObjName == 'Layer_No. 14' || layerFormObjName == 'Layer_No. 16' || layerFormObjName == 'Layer_No. 18') {
				reqUOM = 'Grammage';
			}
			let pmlFormSize = loadObjectsResponse.modelObjects[pmlLatestRev].props.U4_PMLCompPropertyRelation.dbValues.length;
			for (let i = 0; i < pmlFormSize; i++) {
				let pmlCompPropFormUid = loadObjectsResponse.modelObjects[pmlLatestRev].props.U4_PMLCompPropertyRelation.dbValues[i];
				if (pmlCompPropFormUid && loadObjectsResponse.modelObjects[pmlCompPropFormUid].props.u4_property && loadObjectsResponse.modelObjects[pmlCompPropFormUid].props.u4_property.dbValues[0] == reqUOM) {
					result.u4_target = loadObjectsResponse.modelObjects[pmlCompPropFormUid].props.u4_target.uiValues[0];
					result.u4_min = loadObjectsResponse.modelObjects[pmlCompPropFormUid].props.u4_min.uiValues[0];
					result.u4_max = loadObjectsResponse.modelObjects[pmlCompPropFormUid].props.u4_max.uiValues[0];
					result.u4_target = truncateFloat(result.u4_target);
					result.u4_min = truncateFloat(result.u4_min);
					result.u4_max = truncateFloat(result.u4_max);
				}
			}
			callback(result);
		}

	});
}
let truncateFloat = function (param) {
	if (param.indexOf('e+') !== -1) {
		param = parseFloat(param) + '';
	}
	return param;
}

let removeConditionalEntries = function (ctx, lovEntries, deferred) {
	var entriesToRemove = ["<7> Other Plastic"];
	var frameTypePromise = awPromiseService.instance.defer();
	/*below call works for only one object type*/
	_pmlLovService.loadRelatedObjectsWithProperties(frameTypePromise, ctx.pselected, "U4_MatlClassRelation", ["U4_MaterialClassificationForm"], ["u4_pam_frame_type"]);
	frameTypePromise.promise.then(function (relatedObject) {
		if (relatedObject) {
			var frameSelected = relatedObject.props.u4_pam_frame_type;
			var componentClassProp = ctx.pselected.props.u4_component_class;
			if (frameSelected && componentClassProp && ctx.preferences.U4_Validate_FrameType &&
				ctx.preferences.U4_Validate_FrameType.includes(frameSelected.dbValues[0]) == true) {
				var remove = true;
				var componentClassValue = componentClassProp.dbValues[0];
				if (frameSelected.dbValues[0] == "G-PAM-COPACK") {
					remove = false;
					if (componentClassValue == "Flexible Packaging" || componentClassValue == "Tube") {
						remove = true;
					}
				}
				if (remove == true) {
					_.remove(lovEntries, function (lovEntry) {
						return entriesToRemove.includes(lovEntry.propInternalValue);
					});
				}
			}
			deferred.resolve(lovEntries);
		} else {
			deferred.resolve(lovEntries);
		}
	});
}

export default exports = {
	loadLayerFormData,
	initNativeCellLovApi,
	validateLOVValueSelections,
	onValueInTypeProperty,
	layerFormStartEdit,
	UpdatePropertywithnewValue,
	loadLayerFormDataRender
};
