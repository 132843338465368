import _messagingSvc from 'js/messagingService';
import app from 'app';
import _ from 'lodash';
import _appCtxService from "js/appCtxService";
import _soaSvc from "soa/kernel/soaService";
import eventBus from 'js/eventBus';
import _lovUtil from 'js/UnileverLovUtil';
import _policySvc from 'soa/kernel/propertyPolicyService';
import _uwPropertyService from 'js/uwPropertyService';
import _editHandlerService from 'js/editHandlerService';
import _pmlLovService from 'js/unileverPMLLovService';
import awPromiseService from 'js/awPromiseService';


'use strict';
var exports = {};
var isDataBeingLoaded = false;

export let loadMaterialFormDataRender = function () {
	// FOR ISSUE - RenderFn is not defined
};

var LOVDataValidationResult = function LOVDataValidationResult() {
	var self = this;
	self.updatedPropValueMap = {};
	self.updatedPropDisplayValueMap = {};
	self.setViewModelObject = function (vmObj) {
		self.viewModelObj = vmObj;
	};
	self.addUpdatedPropertyValue = function (propName, propValues, propDisplayValues) {
		self.updatedPropValueMap[propName] = propValues;
		self.updatedPropDisplayValueMap[propName] = propDisplayValues;
	};
	self.setValid = function (valid) {
		self.valid = valid;
	};
	self.setError = function (error) {
		self.error = error;
	};
};


export let loadMaterialFormData = function (data, editState) {
	if (_appCtxService.ctx && _appCtxService.ctx.xrtSummaryContextObject) {
		var frameSelected = _appCtxService.ctx.xrtSummaryContextObject;
		//if (_editHandlerService.getActiveEditHandler() && _editHandlerService.getActiveEditHandler().getDataSource() && _editHandlerService.getActiveEditHandler().getDataSource().getSourceObject()) {
		//var _declViewModel = data.subPanelContext.showObjectContext.objectSetInfo.firstPage;
		var _declViewModel;
		if (data.subPanelContext && data.subPanelContext.showObjectContext && data.subPanelContext.showObjectContext.objectSetInfo) {
			_declViewModel = data.subPanelContext.showObjectContext.objectSetInfo.firstPage;
		}
		if (_declViewModel) {
			_.forEach(_declViewModel, function (dataProvider) {
				if (dataProvider != null) {
					if (dataProvider.props.object_name.dbValue.includes("Board Grade_Appr. Board")) {
						_.forEach(dataProvider.props, function (col) {
							if (col.propertyName == "u4_type") {

								//if (dataProvider.validSourceTypes && dataProvider.validSourceTypes.includes("U4_MaterialPropertiesForm") == true) {
								var loadedViewModelObjects = dataProvider;
								//_.forEach(loadedViewModelObjects, function (viewModelObj) {
								let existingTypeProperty = loadedViewModelObjects.props.u4_type;
								ValidateRowAndPopulateLov(frameSelected, loadedViewModelObjects, dataProvider, editState, _appCtxService.ctx);
								//});
								//}
							}
						});
					}
				}
			});
		}
		//}
	}
}

export let ValidateRowAndPopulateLov = async function (frameSelected, selViewModelObj, dataProvider, editState, ctx) {
	let existingTypeValue = selViewModelObj.props.u4_type.dbValue;
	let existingPropertyValue = selViewModelObj.props.u4_property.dbValue;
	let existingTypeProperty = selViewModelObj.props.u4_type;
	isDataBeingLoaded = true;
	var inputData = {
		"inputLayerForm": {
			"uid": selViewModelObj.props.awp0Secondary.dbValue,
			"type": selViewModelObj.type
		}
	};
	if (ctx.layerStructureLov && ctx.layerStructureLov[existingPropertyValue]) {
		existingTypeProperty.lovValues = ctx.layerStructureLov[existingPropertyValue].lovValues;
		updateValuesAndEditableState(existingTypeProperty, dataProvider, selViewModelObj, existingTypeValue, existingPropertyValue, editState);
	} else {
		await loadU4_type(frameSelected, existingTypeProperty, existingPropertyValue, selViewModelObj);
		ctx.layerStructureLov = {};
		ctx.layerStructureLov[existingPropertyValue] = [];
		ctx.layerStructureLov[existingPropertyValue].lovValues = existingTypeProperty.lovValues;
		updateValuesAndEditableState(existingTypeProperty, dataProvider, selViewModelObj, existingTypeValue, editState);
		//});
	}
	exports.initNativeCellLovApi(existingTypeProperty, selViewModelObj, dataProvider);
}

function updateValuesAndEditableState(existingTypeProperty, dataProvider, selViewModelObj, existingTypeValue, existingPropertyValue, editState) {
	var makeEditable = false;
	isDataBeingLoaded = false;
	if (existingTypeProperty.lovValues.length > 0) {
		if (existingTypeValue !== null) {
			for (var inx = 0; inx < existingTypeProperty.lovValues.length; inx++) {
				if (existingTypeProperty.lovValues[inx].propDisplayValue == existingTypeValue) {
					if (existingPropertyValue === 'Board Grade') {
						makeEditable = false;
					} else {
						makeEditable = true;
					}
					break;
				}
			}
		} else {
			makeEditable = true;
		}
		enableProperties(selViewModelObj, ['u4_trade_name', 'u4_value'], makeEditable);
	} else {
		enableProperties(selViewModelObj, ['u4_trade_name', 'u4_value', 'u4_comment'], false);
		_messagingSvc.showInfo('No Value found.');
	}
}
export let initNativeCellLovApi = function (viewProp, viewModelObj, dataProvider) {
	//To make the property as lov and override the default getInitialValues
	//and validateLOVValueSelections
	viewProp.hasLov = true;
	viewProp.lovApi = {};
	viewProp.lovApi.operationName = "EDIT";
	viewProp.renderingHint = "CustomLOVComponent";
};

export let validateLOVValueSelections = function (lovEntries, viewProp, viewModelObj) {
	var validateLOVValueDeferred = awPromiseService.instance.defer();
	var validationResult = new LOVDataValidationResult();
	validationResult.setViewModelObject(viewModelObj);
	if (viewProp.lovList && viewProp.lovList.includes(lovEntries[0].propInternalValue) == true) {
		validationResult.setValid(true);
	} else {
		validationResult.setValid(false);
	}
	if (!validationResult.valid) {
		var errorValue = lovEntries[0].propDisplayValue;
		var editedProperty = viewProp.propertyDisplayName;
		var propertyType = viewProp.type;
		var message = 'The value "{{0}}" is not a valid value for the property "{{1}}" of type "{{2}}".'
		message = message.replace("{{0}}", errorValue);
		message = message.replace("{{1}}", editedProperty);
		message = message.replace("{{2}}", propertyType);
		validationResult.setError(message);
		validateLOVValueDeferred.resolve(validationResult);
	} else {
		validateLOVValueDeferred.resolve(validationResult);
	}
	return validateLOVValueDeferred.promise;
}

function enableProperties(viewModelObject, list, enable) {
	if (list && viewModelObject) {
		for (var i in list) {
			var propertykey = list[i];
			if (viewModelObject.props && viewModelObject.props[propertykey]) {
				viewModelObject.props[propertykey].isEditable = enable;
				//	viewModelObject.props[propertykey].isEditing = enable;
				viewModelObject.props[propertykey].isPropertyModifiable = enable;
				_uwPropertyService.setEditable(viewModelObject.props[propertykey], enable);
				_uwPropertyService.setIsEditable(viewModelObject.props[propertykey], enable);
				_uwPropertyService.setEditState(viewModelObject.props[propertykey], enable, true);
			}
		}
	}
	//_uwPropertyService.triggerDigestCycle();
}

export let loadU4_type = async function (frameSelected, selectedProperty, existingPropertyValue, viewModelObject, callback) {
	selectedProperty.lovValues = [];
	//var deferred = awPromiseService.instance.defer();
	if (existingPropertyValue === 'Board Grade') {
		var request = {
			selectedForm: {
				uid: viewModelObject.props.awp0Secondary.dbValue,
				type: "U4_MaterialPropertiesForm"
			}
		};
		await _soaSvc.post('AWC-2017-12-AwcCommon', 'getBrdGradeLOV', request).then(function (response) {
			console.log("response", response);
			pushLovValuesToContainer(selectedProperty.lovValues, response.boardGradeList);
		});
	}
	else {
		var object_name = 'lov_values';
		var selectedObject = {
			"uid": viewModelObject.props.awp0Secondary.dbValue,
			"type": "U4_MaterialPropertiesForm"
		};
		console.log("This is the problem");
		_lovUtil.getLOVvalue('U4_MaterialPropertiesForm', 'u4_type', object_name, '', selectedObject, selectedProperty.lovValues, function (serviceData) {
		});
	}
	//return deferred.promise;
}

export let pushLovValuesToContainer = function (container, lovValues) {
	container.push({
		propDisplayValue: '',
		propDisplayDescription: '',
		propInternalValue: '',
		uid: ''
	});
	for (var i in lovValues) {
		if (lovValues[i]) {
			let arr = lovValues[i].split('|||');
			let value = arr[0];
			let uid = (arr[1] === '') ? (undefined) : (arr[1]);
			if (value) {
				container.push({
					propDisplayValue: value,
					propDisplayDescription: '',
					propInternalValue: value,
					uid: uid,
					lovRowValue: {
						uid: uid
					}
				});
			}
		}

	}
}

//export let typeChange = function (viewModelObject, selectedProperty, dataProvider) {   //dataProviders.methodDataProvider.viewModelCollection.loadedVMObjects[14].lovRowValue
export let typeChange = async function (props, data, ctx, editState) {
	;
	var viewModelObject = data.vmo;
	var selectedProperty = props.vmo.props.u4_type;
	if ((viewModelObject.props.u4_property.dbValues[0] === 'Board Grade')) {
		var u4_typevalue = data.vmo.props.u4_type.dbValue;

		if (u4_typevalue.replace(' ', '').length !== 0) {
			var selectedPMLuid = null;
			for (var inx = 0; inx < selectedProperty.lovValues.length; inx++) {
				if (selectedProperty.lovValues[inx].propDisplayValue == u4_typevalue) {
					selectedPMLuid = selectedProperty.lovValues[inx].lovRowValue.uid;
					break;
				}
			}
			//selectedPMLuid='B5mRrmDeZSnp8A';
			if (selectedPMLuid) {
				var inputData = {
					inputItem: {
						uid: selectedPMLuid
					}
				};
				isDataBeingLoaded = true;
				await _soaSvc.post('AWC-2017-12-UpdateProps', 'getLatestRevisionFromItem', inputData).then(function (response) {
					//   console.log(response);
					isDataBeingLoaded = false;
					var pmlLatestRev = response.latestRevObject;
					isDataBeingLoaded = true;
					getPmlLatestRevInfo(pmlLatestRev, function (info) {
						console.log("info", info);
						isDataBeingLoaded = false;
						var propertykeyValuePair = {
							"u4_trade_name": (info.u4_trade_name) ? info.u4_trade_name : '',
							"u4_value": (info.u4_grammage) ? info.u4_grammage : ''
						};
						enableProperties(data.vmo, ['u4_trade_name', 'u4_value'], false);
						UpdatePropertywithnewValue(data.vmo, propertykeyValuePair);
					});

				});
			} else {
				var propertykeyValuePair = {
					"u4_trade_name": '',
					"u4_value": ''
				};
				enableProperties(data.vmo, ['u4_trade_name', 'u4_value'], true);
				UpdatePropertywithnewValue(data.vmo, propertykeyValuePair);
			}
		} else {
			var propertykeyValuePair = {
				"u4_trade_name": '',
				"u4_value": ''
			};
			UpdatePropertywithnewValue(data.vmo, propertykeyValuePair);
			enableProperties(data.vmo, ['u4_trade_name', 'u4_value'], true);
		}
	}
}

export let UpdatePropertywithnewValue = function (ctx, keyValuePair) {
	//var viewModelCollection = dataProvider.getViewModelCollection();
	//var loadedVMOs = viewModelCollection.getLoadedViewModelObjects();
	var updatedProps = {};
	//_.forEach(loadedVMOs, function (vmo, index) {
	//	if (vmo.uid == viewmodelObject.uid) {
	updatedProps[ctx.uid] = _.keys(keyValuePair);
	console.log("keyValuePair", keyValuePair);
	_.forEach(keyValuePair, function (value, key) {
		if (ctx.props[key]) {
			ctx.props[key].uiValue = value;
			ctx.props[key].dbValue = value;
			_uwPropertyService.setValue(ctx.props[key], value);
		}
	});
	//}
	//});
	eventBus.publish('viewModelObject.propsUpdated', updatedProps);

};

export let getPmlLatestRevInfo = function (pmlLatestRev, callback) {

	if (pmlLatestRev) {
		var req = {
			primaryObjects: [pmlLatestRev],
			pref: {
				expItemRev: true,
				info: [{
					relationName: "U4_PMLMatlPropRelation",
					objectTypeNames: ["U4_PMLMatlPropForm"]
				}]
			}
		};
		_soaSvc.post("Core-2007-06-DataManagement", "expandGRMRelationsForPrimary", req).then(function (response) {
			var matuid = undefined;
			if (response.ServiceData && response.ServiceData.plain.length == 2) matuid = response.ServiceData.plain[1];
			if (matuid) {
				var matobject = response.ServiceData.modelObjects[matuid];

				var policy = {
					'types': [{
						'name': 'BusinessObject',
						'properties': [{
							'name': 'u4_trade_name'
						}, {
							'name': 'u4_grammage'
						}]
					}]
				};
				var policyID = null;
				if (policy) {
					policyID = _policySvc.register(policy);
				}
				var loadObjectsInputData = {
					uids: [matobject.uid]
				};
				//to load the objects from uid
				_soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', loadObjectsInputData).then(function (loadObjectsResponse) {

					if (policy && policyID)
						_policySvc.unregister(policyID);

					if (loadObjectsResponse) {
						console.log("loadObjectsResponse", loadObjectsResponse);
						var pmlRev1 = loadObjectsResponse.modelObjects[loadObjectsResponse.plain[0]];
						var gradeValue = pmlRev1.props.u4_trade_name.dbValues[0];
						var grammageValue = pmlRev1.props.u4_grammage.dbValues[0];
						// var gradeValue = "NULL";
						// var grammageValue = "NULL";
						if (!_.isEmpty(grammageValue)) {
							if (grammageValue.indexOf('e+') !== -1) {
								grammageValue = parseFloat(grammageValue) + '';
							}

							callback({
								'u4_trade_name': gradeValue,
								'u4_grammage': grammageValue
							});
						}
					}
				});
			}
		});
	}
}

export let materialFormStartEdit = function (commandContext, ctx) {
	// For Audit Issue
}

export let moduleServiceNameToInject = 'unileverMaterialPropertiesLovService';
export default exports = {
	loadMaterialFormData,
	loadMaterialFormDataRender,
	initNativeCellLovApi,
	validateLOVValueSelections,
	typeChange,
	UpdatePropertywithnewValue,
	ValidateRowAndPopulateLov,
	moduleServiceNameToInject,
	loadU4_type,
	pushLovValuesToContainer,
	getPmlLatestRevInfo,
	materialFormStartEdit

};
app.factory('unileverMaterialPropertiesLovService', () => exports);