// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * Mct list service
 *
 * @module js/MctSublocationService
 */
import app from 'app';
import _ from 'lodash';
import $ from 'jquery';
import _soaSvc from 'soa/kernel/soaService';
import _commUtil from 'js/UnileverCommonUtil';
import _queryUtil from 'js/UnileverQueryUtil';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import _policySvc from 'soa/kernel/propertyPolicyService';
import awPromiseService from 'js/awPromiseService';


'use strict';

var exports = {};


export let MctAddViewVIEWMODEL = null;
var MctSublocationVIEWMODEL = null;



export let openPamMctSublocation = function () {

    var MctRev = getSelectedMct();

    if (MctRev) {
        exports.openMct(MctRev.uid);
    }

}

export let openMct = function (MctRevUid) {
    sessionStorage._MctNotOpenedThroughTile = 'true'; // if you store a boolean to localStorage or sessionStorage, it turns to string. so stored as a string
    sessionStorage._MctRev_UID = MctRevUid;
    _appCtxSvc.registerCtx('ViewModeContext', {
        ViewModeContext: 'SummaryView'
    });
    window.location.href = (window.location.origin + window.location.pathname + '#/' + 'MctSublocation');
}

var getSelectedMct = function () {

    var MctRev = null;
    if ((_appCtxSvc.ctx.pselected && (_appCtxSvc.ctx.pselected.type === 'U4_MOP_ListRevision')))
        MctRev = _appCtxSvc.ctx.pselected;

    if ((_appCtxSvc.ctx.selected && (_appCtxSvc.ctx.selected.type === 'U4_MOP_ListRevision')))
        MctRev = _appCtxSvc.ctx.selected;

    if (!MctRev) {
        _messagingSvc.showInfo('Please Open/Select MCT Revision.');
    }

    return MctRev;
}

export let changeToPamSummaryView = function () {

    _appCtxSvc.registerCtx('ViewModeContext', {
        ViewModeContext: 'SummaryView'
    });
    _messagingSvc.showInfo('MCT Operation does not support views other than summary view.');

}

export let pamCtxChange = function () {

    if ((_appCtxSvc.ctx.ViewModeContext.ViewModeContext !== 'SummaryView') && (_appCtxSvc.ctx.locationContext) && (_appCtxSvc.ctx.locationContext['ActiveWorkspace:SubLocation'] === 'MctSublocation')) {
        exports.changeToPamSummaryView();
    }

}

export let selectionChange = function (data, subPanelContext) {

}

export let selectionChangeMctDataprovider = function (dataProvider, subPanelContext) {

    let newSelectionData = { ...subPanelContext.selectionModel.selectionData.getValue() }

    newSelectionData.selected = dataProvider.vmCollectionObj.vmCollection.loadedVMObjects[0];
    subPanelContext.selectionModel.selectionData.update(newSelectionData);
    //dataProvider.selectionModel.setSelection(dataProvider.vmCollectionObj.vmCollection.loadedVMObjects[0]);
}


export let reveal = function (data) {
    var customconditions = {
        showFirstPage: false,
        showSecondPage: false,
        loadMCT: true
    };
    _appCtxSvc.registerCtx('MctSublocation.customconditions', customconditions);

    //console.log(data);

    var willOpenSecondPage = false;
    // open first page if it is triggered from Mct tile
    // if sublocation is triggered programmatically open second page
    if (sessionStorage._MctNotOpenedThroughTile === 'true') {
        willOpenSecondPage = true;
        sessionStorage._MctNotOpenedThroughTile = 'false';
    }


    MctSublocationVIEWMODEL = data;
    _appCtxSvc.registerCtx('MctSublocation.MctSublocationdata', data);


    //var deferred = awPromiseService.instance.defer();
    var pamObjs = [];
    _appCtxSvc.registerCtx('MctSublocation.pamObjs', pamObjs);

    var pamTemplateObjs = [];
    _appCtxSvc.registerCtx('MctSublocation.pamTemplateObjs', pamTemplateObjs);

    var MctObjectInArray = [];
    _appCtxSvc.registerCtx('MctSublocation.MctObjectInArray', MctObjectInArray);

    loadFirstPage(function () {
        if (sessionStorage._MctRev_UID && willOpenSecondPage) {
            var MctRev = {
                uid: sessionStorage._MctRev_UID
            };

            loadSecondPage(MctRev, function () {
                setPage('second');
                //deferred.resolve();
            });
        } else {
            setPage('first');
            //deferred.resolve();
        }

    });


    //setPage('first')
    //  return deferred.promise;

}

var loadFirstPage = function (callback) {
    var MctLatestRevsDataprovider = _appCtxSvc.getCtx("MctSublocation.MctSublocationdata.dataProviders[MctLatestRevsDataprovider]");
    exports.getCurrentUserMCTs(function (MctItemandRevision) {

        var MctLatestRevs = [];
        for (var g in MctItemandRevision) {
            if (MctItemandRevision[g].type === 'U4_MOP_List') {
                var MctItem = MctItemandRevision[g];
                var latestRev = exports.getLatestRev(MctItem, MctItemandRevision);
                exports.setPamCountToRev(latestRev);
                MctLatestRevs.push(latestRev);
            }

        }

        if (MctLatestRevs.length === 0) {
            _messagingSvc.showInfo('No MCT found.');
        }

        var currentUserMcts = MctLatestRevs;
        _appCtxSvc.registerCtx("MctSublocation.currentUserMcts", currentUserMcts);

        var MctLatestRevsDataprovider = MctSublocationVIEWMODEL.dataProviders.MctLatestRevsDataprovider;
        var vmo = MctLatestRevsDataprovider.viewModelCollection;
        vmo.loadedVMObjects = currentUserMcts;
        MctLatestRevsDataprovider.update(vmo.loadedVMObjects);
        _appCtxSvc.updateCtx("MctSublocation.MctSublocationdata.dataProviders[MctLatestRevsDataprovider]", MctLatestRevsDataprovider);
        //_commUtil.sortComponents(MctSublocationVIEWMODEL, 'currentUserMcts', 0, 0);
        callback();

    });


}

var loadSecondPage = function (latestRevMct, callback) {
    var customCondition = _appCtxSvc.getCtx("MctSublocation.customconditions");
    var pamObjs = _appCtxSvc.getCtx("MctSublocation.pamObjs");
    var pamTemplateObjs = _appCtxSvc.getCtx("MctSublocation.pamTemplateObjs");
    var MctObjectInArray = _appCtxSvc.getCtx("MctSublocation.MctObjectInArray");

    loadMct(MctSublocationVIEWMODEL, latestRevMct, function () {

        MctSublocationVIEWMODEL.dataProviders.pamDataprovider.update(pamObjs, pamObjs.length);

        MctSublocationVIEWMODEL.dataProviders.pamTemplateDataprovider.update(pamTemplateObjs, pamTemplateObjs.length);

        MctSublocationVIEWMODEL.dataProviders.MctDataprovider.update(MctObjectInArray, MctObjectInArray.length);

        MctObjectInArray = [];
        _appCtxSvc.updateCtx("MctSublocation.MctObjectInArray", MctObjectInArray);

        MctSublocationVIEWMODEL._isSecondPageLoadedOnce = true;
        MctSublocationVIEWMODEL._isSecondPageLoadedMctRev = latestRevMct;
        callback();


    });

}

var loadMct = function (data, MctRev, callback) {
    var pamObjs = _appCtxSvc.getCtx("MctSublocation.pamObjs");
    var pamTemplateObjs = _appCtxSvc.getCtx("MctSublocation.pamTemplateObjs");
    var MctObjectInArray = _appCtxSvc.getCtx("MctSublocation.MctObjectInArray");

    var policy = {
        'types': [{
            'name': 'BusinessObject',
            'properties': [{
                'name': 'u4_template_id'
            }]
        }]
    };
    var policyID = null;
    if (policy)
        policyID = _policySvc.register(policy);

    var inputData = {
        objects: [MctRev],
        attributes: ['U4_MOP_Templates', 'U4_MOP_List_Relation']
    };


    _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData).then(function (getPropertiesResponse) {

        console.log(getPropertiesResponse);

        if (policy && policyID)
            _policySvc.unregister(policyID);

        var MctRev = getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]];

        var pamUids = MctRev.props.U4_MOP_List_Relation.dbValues;

        var pamTemplateUids = MctRev.props.U4_MOP_Templates.dbValues;

        //console.log(pamTemplateUids);

        //pamObjs = [];
        //  pamTemplateObjs = [];
        // MctObjectInArray = [];


        for (var i in pamUids) {
            //data.pamObjs.push(getPropertiesResponse.modelObjects[pamUids[i]]);
            pamObjs.push(getPropertiesResponse.modelObjects[pamUids[i]]);

        }


        // _commUtil.sortComponents(data, 'pamObjs', 0, 0);

        for (const i of pamTemplateUids) {

            const pamTemplate1 = getPropertiesResponse.modelObjects[i];
            setPamCountOnPamTemplate(pamTemplate1, pamObjs);
            pamTemplateObjs.push(pamTemplate1);
            //newUidSet.add(i);

        }

        _appCtxSvc.updateCtx("MctSublocation.pamTemplateObjs", pamTemplateObjs);
        var pamTemplateDataprovider = MctSublocationVIEWMODEL.dataProviders.pamTemplateDataprovider;
        var vmo = pamTemplateDataprovider.viewModelCollection;
        vmo.loadedVMObjects = pamTemplateObjs;
        pamTemplateDataprovider.update(vmo.loadedVMObjects);
        _appCtxSvc.updateCtx("MctSublocation.pamObjs", pamObjs);

        pamTemplateObjs = [];
        _appCtxSvc.updateCtx("MctSublocation.pamTemplateObjs", pamTemplateObjs);

        exports.setTotalPamCountToRev(MctRev);
        MctObjectInArray.push(MctRev);
        callback();

    });


}

var setPamCountOnPamTemplate = function (pamTemplate, pamObjs) {

    //   props.u4_template_id.dbValues[0]
    var pamCount1 = 0;
    if (pamObjs.length > 0) {
        for (let index = 0; index < pamObjs.length; index++) {
            const element = pamObjs[index];
            if (element.props.u4_template_id && pamTemplate.props.u4_template_id && element.props.u4_template_id.dbValues[0] === pamTemplate.props.u4_template_id.dbValues[0]) {
                pamCount1++;
            }
        }
    }
    pamCount1 = pamCount1 + '';
    _commUtil.addCustomPropertiesToModelObj(pamTemplate, {
        key: 'PAM Count',
        value: pamCount1
    });
    //   pamTemplate.props.awp0CellProperties.dbValues.push('PAM Count \\:' + pamCount1);


}

export let emptyAction = function () { }



/**
 * goto previous page
 */
export let MctPreviousCommandAction = function () {
    var customconditions = _appCtxSvc.getCtx('MctSublocation.customconditions');
    if (customconditions.showSecondPage) {
        setPage('first');
    }
}

/**
 *  goto next page
 */
export let MctNextCommandAction = function () {
    var customconditions = _appCtxSvc.getCtx('MctSublocation.customconditions');

    if (customconditions.showFirstPage) {

        var selectedMctRevs = MctSublocationVIEWMODEL.dataProviders.MctLatestRevsDataprovider.selectedObjects;
        if (MctSublocationVIEWMODEL._isSecondPageLoadedOnce || (selectedMctRevs.length === 1)) {
            var latestRevMct = (selectedMctRevs.length === 1) ? selectedMctRevs[0] : MctSublocationVIEWMODEL._isSecondPageLoadedMctRev;
            if (selectedMctRevs.length > 0) {
                loadSecondPage(latestRevMct, function () {
                    sessionStorage._MctRev_UID = latestRevMct.uid;
                    setPage('second');
                });
            }
            else {
                //MctSublocationVIEWMODEL.dataProviders.MctLatestRevsDataprovider.selectedObjects.clear();
                //selectedMctRevs.length = 0;
                //vmo.loadedVMObjects = [];

                //setPage('second');

                _messagingSvc.showInfo('Please select a MCT to proceed.');
            }


        }
        else {
            _messagingSvc.showInfo('Please select a MCT to proceed.');
        }

    }

}
export let getCurrentUserMCTs = function (callback) {

    var entries = ['Type', 'Owning User'];
    var values = ['MCT List', _appCtxSvc.ctx.userSession.props.user.displayValues[0]];

    var policy = {
        'types': [{
            'name': 'U4_MOP_List',
            'properties': [{
                'name': 'revision_list',
                'modifiers': [{
                    'name': 'withProperties',
                    'Value': 'true'
                }]
            }, {
                'name': 'object_name'
            }]
        }, {
            'name': 'U4_MOP_ListRevision',
            'properties': [{
                'name': 'U4_MOP_List_Relation'
            }]
        }]
    };
    _queryUtil.executeQuery('Item...', entries, values, policy, true, function (queryOutput) {

        callback(queryOutput);

    });


}

export let getLatestRev = function (item, mObjs) {


    var latestRevUid = item.props.revision_list.dbValues[item.props.revision_list.dbValues.length - 1];
    for (var h in mObjs) {
        if (mObjs[h].uid === latestRevUid) {
            return mObjs[h];
        }
    }


}

export let setPamCountToRev = function (rev) {



    var pamCount = rev.props.U4_MOP_List_Relation.dbValues.length + '';
    _commUtil.addCustomPropertiesToModelObj(rev, {
        key: 'PAM Count',
        value: pamCount
    });
    //rev.props.awp0CellProperties.dbValues.push('PAM Count \\:' + pamCount);


}

export let setTotalPamCountToRev = function (rev) {


    // modelobject already has custom property set in first page for mct revision, so clearing that.
    rev.props._awc_customCellProperties = null;

    var pamCount = rev.props.U4_MOP_List_Relation.dbValues.length + '';
    _commUtil.addCustomPropertiesToModelObj(rev, {
        key: 'Total PAM Count',
        value: pamCount
    });
    //  rev.props.awp0CellProperties.dbValues.push('Total PAM Count \\:' + pamCount);


}

var setPage = function (page, willOpenSecondPage) {
    var customconditions = _appCtxSvc.getCtx('MctSublocation.customconditions');

    if (page === 'first') {
        customconditions.showFirstPage = true;
        customconditions.showSecondPage = false;
    }

    if (page === 'second') {
        customconditions.showFirstPage = false;
        customconditions.showSecondPage = true;
    }
    _appCtxSvc.updateCtx('MctSublocation.customconditions', customconditions);
}

/**
 * 
 * sort components
 * 
 * @param {Integer} index to decide sort by ID or Name
 * @param {Integer} order to decide sort ascending or descending
 */
var sortComponents = function (index, order, data) {


    var currentDataproviderInfo = getDataprovider();
    //console.log(currentDataproviderInfo);
    _commUtil.sortDataProviderObjects(currentDataproviderInfo.data, currentDataproviderInfo.dataproviderName, index, order);
    //console.log("sort");
    //console.log(data.dataProviders);

    data.dataProviders[currentDataproviderInfo.dataproviderName].viewModelCollection.loadedVMObjects = currentDataproviderInfo.data.dataProviders[currentDataproviderInfo.dataproviderName].viewModelCollection.loadedVMObjects;
    data.dataProviders[currentDataproviderInfo.dataproviderName].update(data.dataProviders[currentDataproviderInfo.dataproviderName].viewModelCollection.loadedVMObjects);
}

var getDataprovider = function () {
    var customconditions = _appCtxSvc.getCtx('MctSublocation.customconditions');
    if (_appCtxSvc.ctx.sublocation.historyNameToken === 'MctSublocation') {

        if (customconditions.showSecondPage) {

            var obj = {};
            obj.data = MctSublocationVIEWMODEL;

            if (MctSublocationVIEWMODEL.Mct_tab[0].selectedTab)
                obj.dataproviderName = 'pamTemplateDataprovider';

            /*  if (MctSublocationVIEWMODEL.Mct_tab[1].selectedTab)
                obj.dataproviderName = 'pamDataprovider';
*/

            return obj;

        }

        if (customconditions.showFirstPage) {

            var obj = {};
            obj.data = MctSublocationVIEWMODEL;
            obj.dataproviderName = 'MctLatestRevsDataprovider';

            return obj;

        }

    } else {

        var obj = {};
        obj.data = exports.MctAddViewVIEWMODEL;
        obj.dataproviderName = 'MctItemsDataprovider';

        return obj;

    }
}


/**
 * sort by ID in ascending order
 */
export let sortByIDAscendMctCommandAction = function (data) {

    sortComponents(1, 0, data);

}

/**
 * sort by ID in descending order
 */
export let sortByIDDescendMctCommandAction = function (data) {

    //console.log(data);
    sortComponents(1, 1, data);

}

/**
 * sort by name in ascending order
 */
export let sortByNameAscendMctCommandAction = function (data) {

    sortComponents(0, 0, data);
}

/**
 * sort by name in descending order
 */
export let sortByNameDescendMctCommandAction = function (data) {

    sortComponents(0, 1, data);

}


export let updateCountAfterPAMremoval = function (removeChildrenResponse, MctRev) {


    var currentPamsUid = removeChildrenResponse.modelObjects[MctRev.uid].props.U4_MOP_List_Relation.dbValues;

    var pamCount_based_on_tech = [];
    for (var _pamUid in currentPamsUid) {
        var _techName = removeChildrenResponse.modelObjects[currentPamsUid[_pamUid]].props.u4_technology.dbValues[0];
        var obj = _.find(pamCount_based_on_tech, {
            'techName': _techName
        });
        if (obj) {
            obj.count = obj.count + 1;
        } else {
            pamCount_based_on_tech.push({
                techName: _techName,
                count: 1
            });
        }
    }

    for (var dpItem in MctSublocationVIEWMODEL.dataProviders.pamTemplateDataprovider.viewModelCollection.loadedVMObjects) {
        var techName1 = MctSublocationVIEWMODEL.dataProviders.pamTemplateDataprovider.viewModelCollection.loadedVMObjects[dpItem].cellHeader1;
        var obj = _.find(pamCount_based_on_tech, {
            'techName': techName1
        });
        if (obj) {
            MctSublocationVIEWMODEL.dataProviders.pamTemplateDataprovider.viewModelCollection.loadedVMObjects[dpItem].props._awc_customCellProperties.dbValues[0]['PAM Count'].value = '' + obj.count;
        } else {
            MctSublocationVIEWMODEL.dataProviders.pamTemplateDataprovider.viewModelCollection.loadedVMObjects[dpItem].props._awc_customCellProperties.dbValues[0]['PAM Count'].value = '0';
        }
    }

    MctSublocationVIEWMODEL.dataProviders.MctDataprovider.viewModelCollection.loadedVMObjects[0].props._awc_customCellProperties.dbValues[0]['Total PAM Count'].value = '' + currentPamsUid.length;


}

export let moduleServiceNameToInject = 'MctSublocationService';



export default exports = {
    MctAddViewVIEWMODEL,
    openPamMctSublocation,
    openMct,
    changeToPamSummaryView,
    pamCtxChange,
    reveal,
    emptyAction,
    MctPreviousCommandAction,
    MctNextCommandAction,
    getCurrentUserMCTs,
    getLatestRev,
    setPamCountToRev,
    setTotalPamCountToRev,
    sortByIDAscendMctCommandAction,
    sortByIDDescendMctCommandAction,
    sortByNameAscendMctCommandAction,
    sortByNameDescendMctCommandAction,
    updateCountAfterPAMremoval,
    moduleServiceNameToInject,
    selectionChange,
    selectionChangeMctDataprovider


};
app.factory('MctSublocationService', () => exports);