// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
define
 */

/**
 * Mct list service
 *
 * @module js/valueExtAddService
 */
import app from 'app';
import _ from 'lodash';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import _queryUtil from 'js/UnileverQueryUtil';
import _soaSvc from 'soa/kernel/soaService';
import $ from 'jquery';
import awPromiseService from 'js/awPromiseService';
import _commUtil from 'js/UnileverCommonUtil';
import _policySvc from 'soa/kernel/propertyPolicyService';
'use strict';

var exports = {};

var _ValueExtItemandRevision = null;
var _SelectedComp = "";
const inputDataPerPacket = 500;

export let checkPamSelection = function (data, ctx) {
    console.log("Entering checkPamSelection\n");
    var selectedPams = getSelectedPAM().selectedObjects;
    var selectedPnPs = getSelectedPNP().selectedObjects;
    var selectedPmls = getSelectedPML().selectedObjects;
    data._pamSelected = false;
    data._pnpSelected = false;
    data._pmlSelected = false;
    data._selectedPams = selectedPams;
    ctx._rcButtonStatus = "";
    // data.radioVEType.dbValue = "";
    // data.radioVEType.isEditable = true;
    if (ctx.mselected && ctx.mselected.length > 0 && selectedPams.length == 0 && selectedPnPs.length == 0 && selectedPmls.length == 0) {
        delete ctx._canAddValueExt;
        delete data._canAddValueExt;
        _messagingSvc.showError('No valid objects found in selection.Select PAM(s)/PnP(s)/PML(s) Revision and try again');
        return;
    }
    if ((selectedPams.length > 0 && selectedPnPs.length > 0) || (selectedPams.length > 0 && selectedPmls.length > 0) || (selectedPnPs.length > 0 && selectedPmls.length > 0)) {
        data._canAddValueExt = true;
        ctx._canAddValueExt = false;
    } else if ((selectedPams !== undefined) && selectedPams.length > 0) {
        data._canAddValueExt = true;
        ctx._canAddValueExt = true;

        data._pamSelected = true;
        ctx._rcButtonStatus = "PAM";
    } else if ((selectedPnPs !== undefined) && selectedPnPs.length > 0) {
        data._canAddValueExt = true;
        ctx._canAddValueExt = true;

        data._pnpSelected = true;
        ctx._rcButtonStatus = "PNP";
    } else if ((selectedPmls !== undefined) && selectedPmls.length > 0) {
        data._canAddValueExt = true;
        ctx._canAddValueExt = true;

        data._pmlSelected = true;
        ctx._rcButtonStatus = "PML";
    } else if (_appCtxSvc.ctx.sublocation.nameToken === 'com.siemens.splm.client.search:SearchResultsSubLocation') {
        ctx._anyCtegory = false;
        data._anyCtegory = false;
        if (ctx.search.filterString && ctx.search.filterString.split("Category=").length > 1 && ctx.search.filterString.split("Category=")[1].split(",").length > 1 && (ctx.search.filterString.split("Category=")[1].includes("PAM Specs") == true || ctx.search.filterString.split("Category=")[1].includes("PnP") == true || ctx.search.filterString.split("Category=")[1].includes("PML (PNG)") == true)) {
            ctx._canAddValueExt = false;
            data._canAddValueExt = true;
            ctx._rcButtonStatus = "";
        } else if (ctx.search.filterString && ctx.search.filterString.includes('Category=PAM Specs') == true && (ctx.searchPreFilters.catPrefilters.selectedCategory === 'PAM Specs' || ctx.searchPreFilters.catPrefilters.propDisplayName === 'Any Category')) {
            ctx._canAddValueExt = true;
            data._canAddValueExt = true;
            ctx._rcButtonStatus = "PAM";
        } else if (ctx.search.filterString && ctx.search.filterString.includes('Category=PnP') == true && (ctx.searchPreFilters.catPrefilters.selectedCategory === 'PnP' || ctx.searchPreFilters.catPrefilters.propDisplayName === 'Any Category')) {
            ctx._canAddValueExt = true;
            data._canAddValueExt = true;
            ctx._rcButtonStatus = "PNP";
        } else if (ctx.search.filterString && ctx.search.filterString.includes('Category=PML (PNG)') == true && (ctx.searchPreFilters.catPrefilters.selectedCategory === 'PML (PNG)' || ctx.searchPreFilters.catPrefilters.propDisplayName === 'Any Category')) {
            ctx._canAddValueExt = true;
            data._canAddValueExt = true;
            ctx._rcButtonStatus = "PML";

        } else {
            delete ctx._canAddValueExt;
            delete data._canAddValueExt;
            _messagingSvc.showError('Select any or combination of PAM Specs/PnP/PML (PNG) in category filter and try again');
            return;
        }

        /* else if (ctx.searchPreFilters.catPrefilters.propDisplayName === 'Any Category' && ctx.searchSearch.searchFilterString === 'POM_application_object.owning_user=~Categorization.category=') {
        ctx._anyCtegory = true;
        data._anyCtegory = true;
        ctx._canAddValueExt = false;
        data._canAddValueExt = false;
 
        } */

    } else if (_appCtxSvc.ctx.sublocation.nameToken === 'com.siemens.splm.client.search:AdvancedSearchSubLocation') {
        data._canAddValueExt = false;
        ctx._canAddValueExt = false;
    } else {
        data._canAddValueExt = false;
        ctx._canAddValueExt = false;
    }

    _SelectedComp = ctx._rcButtonStatus;

    _appCtxSvc.registerCtx('_canAddValueExt', '');
    _appCtxSvc.updateCtx('_canAddValueExt', ctx._canAddValueExt);
    _appCtxSvc.registerCtx('_dataAddValueExt', '');
    _appCtxSvc.updateCtx('_dataAddValueExt', data._canAddValueExt);
    console.log("Leaving checkPamSelection\n");
}

export let emptyAction = function () { };

export let loadCurrentUserValueExts = function (data, ctx) {
    var deferred = awPromiseService.instance.defer();
    console.log("Entering loadCurrentUserValueExts\n");
    if (ctx._canAddValueExt == false && ctx.search) {
        var selectedpamsLength = getSelectedPAM().selectedObjects.length;
        var selectedPnPsLength = getSelectedPNP().selectedObjects.length;
        var selectedPMlsLength = getSelectedPML().selectedObjects.length;
        var filterString = _appCtxSvc.ctx.search.filterString;
        if (selectedpamsLength == 0 && (filterString && filterString.includes('PAM Specs') == false)) {
            data.radioVEType.dbValue = data.radioVEType.dbValue == "PAM" ? "" : data.radioVEType.dbValue;
        }
        if (selectedPnPsLength == 0 && (filterString && filterString.includes('PnP') == false)) {
            data.radioVEType.dbValue = data.radioVEType.dbValue == "PNP" ? "" : data.radioVEType.dbValue;
        }
        if (selectedPMlsLength == 0 && (filterString && filterString.includes('PML (PNG)') == false)) {
            data.radioVEType.dbValue = data.radioVEType.dbValue == "PML" ? "" : data.radioVEType.dbValue;
        }
    }
    exports.loadCurrentUserValueExts1(data, deferred);
    console.log("Leaving loadCurrentUserValueExts\n");
    return deferred.promise;
}
export let loadCurrentUserValueExts1 = function (data, deferred) {
    console.log("Entering loadCurrentUserValueExts1\n");
    exports.selectionChanged(data);

    getCurrentUserValueExts(function (ValueExtItemandRevision) {
        _ValueExtItemandRevision = ValueExtItemandRevision;
        var valueExtItems = [];
        var valueExtItemsPAM = [];
        var valueExtItemsPNP = [];
        var valueExtItemsPML = [];

        for (var g in ValueExtItemandRevision) {
            if (ValueExtItemandRevision[g].type === 'U4_ValueExt') {
                var valueExtItem = ValueExtItemandRevision[g];
                var latestRev = getLatestRev(valueExtItem, ValueExtItemandRevision);
                //var pamOrPNPCount = latestRev.props.U4_ValueExtRelation.dbValues.length;

                valueExtItems.push(valueExtItem);
                if (latestRev.props.u4_ve_type.dbValues[0] === null || latestRev.props.u4_ve_type.dbValues[0] === 'PAM') {
                    valueExtItemsPAM.push(valueExtItem);
                    //valueExtItem.props.awp0CellProperties.dbValues.push('PAM Count \\:' + pamOrPNPCount);
                } else if (latestRev.props.u4_ve_type.dbValues[0] === 'PNP') {
                    valueExtItemsPNP.push(valueExtItem);
                    //valueExtItem.props.awp0CellProperties.dbValues.push('PNP Count \\:' + pamOrPNPCount);
                } else if (latestRev.props.u4_ve_type.dbValues[0] === 'PML') {
                    valueExtItemsPML.push(valueExtItem);
                    //valueExtItem.props.awp0CellProperties.dbValues.push('PML Count \\:' + pamOrPNPCount);
                }
            }
        }

        if (valueExtItems.length === 0) {
            data.addNote.uiValue = 'No Value Extractor found.';
        } else if (valueExtItemsPAM.length === 0 && data.radioVEType.dbValue == "PAM") {
            data.addNote.uiValue = 'No Value Extractor found for PAM';
        } else if (valueExtItemsPNP.length === 0 && data.radioVEType.dbValue == "PNP") {
            data.addNote.uiValue = 'No Value Extractor found for PNP';
        } else if (valueExtItemsPML.length === 0 && data.radioVEType.dbValue == "PML") {
            data.addNote.uiValue = 'No Value Extractor found for PML';
        }
        _appCtxSvc.registerCtx('currentUserValueExts', "");
        if (data.radioVEType.dbValue == "PAM" || _SelectedComp == "PAM") {
            data.currentUserValueExts = valueExtItemsPAM;
        } else if (data.radioVEType.dbValue == "PNP" || _SelectedComp == "PNP") {
            data.currentUserValueExts = valueExtItemsPNP;
        } else if (data.radioVEType.dbValue == "PML" || _SelectedComp == "PML") {
            data.currentUserValueExts = valueExtItemsPML;
        }
        data.currentUserValueExtsPAM = valueExtItemsPAM;
        data.currentUserValueExtsPNP = valueExtItemsPNP;
        data.currentUserValueExtsPML = valueExtItemsPML;

        //data.currentUserValueExts = valueExtItems;

        if (data.currentUserValueExts) {
            _commUtil.sortComponents(data, 'currentUserValueExts', 0, 0);
        }
        var currentUserValueExts = data.currentUserValueExts;
        //_appCtxSvc.updateCtx('currentUserValueExts', currentUserValueExts);
        if (data.currentUserValueExts != undefined) {
            data.dataProviders.ValueExtItemsDataprovider.update(data.currentUserValueExts, data.currentUserValueExts.length);
        }
        deferred.resolve();
    });
    console.log("Leaving loadCurrentUserValueExts1\n");
}

export let createAndAddAction = function (data, conditions) {

    let isValueExtAlreadyAvailable = checkValueExtAlreadyAvailable(data);

    if (!isValueExtAlreadyAvailable) {
        conditions.disableButton = false;
        var ValueExtName = data.ValueExtName.dbValue;
        createAndaddToValueExt(ValueExtName, data, function (newValueExtRev) {
            _commUtil.closeTNIpanel();
            openValueExtRev(data, newValueExtRev);
        });
    } else {
        _messagingSvc.showError('Value Extractor already available with this name. Please try with different name.');
    }
}

let checkValueExtAlreadyAvailable = function (data) {
    console.log("Entering checkValueExtAlreadyAvailable\n");
    let isValueExtAlreadyAvailable = false;

    if (_ValueExtItemandRevision) {

        let valueExtName = data.ValueExtName.dbValue;

        if (valueExtName) {

            for (let i in _ValueExtItemandRevision) {

                if (_ValueExtItemandRevision[i].type === 'U4_ValueExt') {

                    let valueExtObjName = _ValueExtItemandRevision[i].props.object_name.dbValues[0];

                    if (valueExtName === valueExtObjName) {
                        isValueExtAlreadyAvailable = true;
                        break;
                    }
                }
            }
        }
    } else {

        _messagingSvc.showError('Data not loaded yet. Please wait some time and try again.');
    }
    console.log("Leaving checkValueExtAlreadyAvailable\n");
    return isValueExtAlreadyAvailable;
}

export let addAction = function (data, conditions) {
    var selectedValueExtItem = data.dataProviders.ValueExtItemsDataprovider.selectedObjects[0];
    var latestRevValueExt = getLatestRev(selectedValueExtItem, _ValueExtItemandRevision);
    addToValueExt(latestRevValueExt, data, function () {
        _commUtil.closeTNIpanel();

        openValueExtRev(data, latestRevValueExt);
    });
}

export let typeSelectionButtonAction = function (data) {
    //var selectedValueExtItem = data.dataProviders.ValueExtItemsDataprovider.selectedObjects[0];
    console.log("Entering typeSelectionButtonAction\n");
    var deferred = awPromiseService.instance.defer();
    _appCtxSvc.registerCtx('currentUserValueExts', "");
    data.currentUserValueExts = [];
    if (data.radioVEType.dbValue == "PAM" || _SelectedComp == "PAM") {

        data.currentUserValueExts = data.currentUserValueExtsPAM;
    } else if (data.radioVEType.dbValue == "PNP" || _SelectedComp == "PNP") {
        //data.currentUserValueExts = [];
        data.currentUserValueExts = data.currentUserValueExtsPNP;
    } else if (data.radioVEType.dbValue == "PML" || _SelectedComp == "PML") {
        // data.currentUserValueExts = [];
        data.currentUserValueExts = data.currentUserValueExtsPML;
    }
    console.log("Selection of VE Type ");
    console.log("Leaving typeSelectionButtonAction\n");
    //_commUtil.sortComponents(data, 'currentUserValueExts', 0, 0);
    var currentUserValueExts = data.currentUserValueExts;
    _appCtxSvc.updateCtx('currentUserValueExts', currentUserValueExts);

    deferred.resolve();

    return deferred.promise;
}

export let selectionChanged = function (data) {
    console.log("Entering selectionChanged\n");
    console.log("_appCtxSvc.ctx.ViewModeContext.ViewModeContext and _appCtxSvc.ctx.locationContext", _appCtxSvc.ctx.ViewModeContext.ViewModeContext, _appCtxSvc.ctx.locationContext);
    if (_appCtxSvc.ctx.ViewModeContext.ViewModeContext.includes('SummaryView') && (_appCtxSvc.ctx.locationContext) && (_appCtxSvc.ctx.locationContext['ActiveWorkspace:SubLocation'] === 'teamcenter.search.search')) {        var selectedpamsLength = getSelectedPAM().selectedObjects.length;
        var selectedPnPsLength = getSelectedPNP().selectedObjects.length;
        var selectedPMlsLength = getSelectedPML().selectedObjects.length;
        data.radioVEType.dbValue = "";
        data.radioVEType.isEditable = true;

        if (selectedpamsLength > 0 || selectedPnPsLength > 0 || selectedPMlsLength > 0) {
            //if (selectedpamsLength >= selectedPnPsLength && selectedpamsLength >= selectedPMlsLength) {
            if (data.radioVEType.uiValue == "PAM" && selectedpamsLength >= selectedPnPsLength && selectedpamsLength >= selectedPMlsLength) {

                data.radioVEType.dbValue = "PAM";
                data.radioVEType.isEditable = true;
                data.dispatch({ path: 'data.radioVEType.isRequired', value: true });
            }
            if (data.radioVEType.uiValue == "PNP" && selectedPnPsLength >= selectedpamsLength && selectedPnPsLength >= selectedPMlsLength) {
                //if (selectedPnPsLength >= selectedpamsLength && selectedPnPsLength >= selectedPMlsLength) {

                data.radioVEType.dbValue = "PNP";
                data.radioVEType.isEditable = true;
                data.dispatch({ path: 'data.radioVEType.isRequired', value: true });

            }
            if (data.radioVEType.uiValue == "PML" && selectedPMlsLength >= selectedpamsLength && selectedPMlsLength >= selectedPnPsLength) {
                //if (selectedPMlsLength >= selectedpamsLength && selectedPMlsLength >= selectedPnPsLength) {
                data.radioVEType.dbValue = "PML";
                data.radioVEType.isEditable = true;
                data.dispatch({ path: 'data.radioVEType.isRequired', value: true });

            }
        } else if (_appCtxSvc.ctx) {
            if (_appCtxSvc.ctx.search.filterString.split("Category=")[1].includes("PAM Specs") == true && _appCtxSvc.ctx.search.filterString.split("Category=")[1].includes("PnP") == false && _appCtxSvc.ctx.search.filterString.split("Category=")[1].includes("PML (PNG)") == false) {
                data.radioVEType.dbValue = "PAM";
                data.radioVEType.isEditable = false;
            }
            if (_appCtxSvc.ctx.search.filterString.split("Category=")[1].includes("PAM Specs") == false && _appCtxSvc.ctx.search.filterString.split("Category=")[1].includes("PnP") == true && _appCtxSvc.ctx.search.filterString.split("Category=")[1].includes("PML (PNG)") == false) {
                data.radioVEType.dbValue = "PNP";
                data.radioVEType.isEditable = false;
            }
            if (_appCtxSvc.ctx.search.filterString.split("Category=")[1].includes("PAM Specs") == false && _appCtxSvc.ctx.search.filterString.split("Category=")[1].includes("PnP") == false && _appCtxSvc.ctx.search.filterString.split("Category=")[1].includes("PML (PNG)") == true) {
                data.radioVEType.dbValue = "PML";
                data.radioVEType.isEditable = false;
            }
        }
        console.log("Click on PNP");

    }
    console.log("Leaving selectionChanged\n");
}

var openValueExtRev = function (data, ValueExtRev) {
    var willOpenValueExt = data.openOnAdd.dbValue;

    if (willOpenValueExt) {

        window.location.href = (window.location.origin + window.location.pathname + '#/' + 'com.siemens.splm.clientfx.tcui.xrt.showObject?uid=' + ValueExtRev.uid);
    }
}

var getCurrentUserValueExts = function (callback) {
    console.log("Entering getCurrentUserValueExts\n");
    var entries = ['Type', 'Owning User'];
    var values = ['Value Extractor', _appCtxSvc.ctx.userSession.props.user.displayValues[0]];
    var policy = {
        'types': [{
            'name': 'U4_ValueExt',
            'properties': [{
                'name': 'revision_list',
                'modifiers': [{
                    'name': 'withProperties',
                    'Value': 'true'
                }
                ]
            }, {
                'name': 'object_name'
            }
            ]
        }, {
            'name': 'U4_ValueExtRevision',
            'properties': [
                {
                    'name': 'u4_ve_type'
                }
            ]
        }
        ]
    };

    let policyId = _policySvc.register(policy);
    _queryUtil.executeQuery('Item...', entries, values, policy, true, function (queryOutput) {
        if (policyId)
            _policySvc.unregister(policyId);
        callback(queryOutput);
    });
    console.log("Leaving getCurrentUserValueExts\n");
}

var getLatestRev = function (item, mObjs) {
    var latestRevUid = item.props.revision_list.dbValues[item.props.revision_list.dbValues.length - 1];

    for (var h in mObjs) {
        if (mObjs[h].uid === latestRevUid) {
            return mObjs[h];
        }
    }
}

var getSelectedPAM = function (selectedObjects, referenceArray) {
    var selectedObjs = _appCtxSvc.ctx.mselected;
    var selectedObjUids = [];
    _.forEach(selectedObjs, function (selectedObj) {
        selectedObjUids.push(selectedObj.uid);
    });
    if (selectedObjects) {
        selectedObjs = selectedObjects;
    }
    if (referenceArray && referenceArray.length > 0) {
        selectedObjUids = referenceArray;
    }
    var selectedPam = [];
    var pamTemplateSelectionCount = 0;

    if (selectedObjUids.length > 0) {
        _.forEach(selectedObjs, function (selectedObj) {
            if (selectedObjUids.includes(selectedObj.uid) == true) {
                var isValidPam = true;

                if (!(selectedObj.modelType.typeHierarchyArray.indexOf('U4_PAMRevision') !== -1)) {
                    isValidPam = false; // not a pam
                }

                if ((!selectedObj.props.u4_technology) || (!selectedObj.props.u4_technology.dbValues[0]) || (_.trim(selectedObj.props.u4_technology.dbValues[0]).length === 0)) {
                    isValidPam = false; // not a pam template

                    pamTemplateSelectionCount++;
                }

                if (selectedObj.props.object_string.dbValues[0].substring(0, 1) === 'T') {
                    isValidPam = false; // not a mct pam template

                    pamTemplateSelectionCount++;
                }

                if (isValidPam) {
                    selectedPam.push({
                        uid: selectedObj.uid,
                        type: selectedObj.type
                    });
                }
            }
        });
        /*if (pamTemplateSelectionCount === selectedObjs.length) {
        _messagingSvc.showError('Only PAM Template(s) found in the selection. Please change the selection.');
        return;
        }*/
    } else {
        console.log('\n ******* Going for all at a time ******** \n');
    }

    /*if (selectedPam.length === 0) {
    _messagingSvc.showError('No valid PAM\'s are found in selection. Please change the selection.');
 
    return;
    } */

    return {
        "selectedObjects": selectedPam,
        "selectionCount": pamTemplateSelectionCount
    };
}

var getSelectedPNP = function (selectedObjects, referenceArray) {
    var selectedObjs = _appCtxSvc.ctx.mselected;
    var selectedObjUids = [];
    _.forEach(selectedObjs, function (selectedObj) {
        selectedObjUids.push(selectedObj.uid);
    });
    if (selectedObjects) {
        selectedObjs = selectedObjects;
    }
    if (referenceArray && referenceArray.length > 0) {
        selectedObjUids = referenceArray;
    }
    var selectedPnP = [];
    var pnpTemplateSelectionCount = 0;

    if (selectedObjUids.length > 0) {
        _.forEach(selectedObjs, function (selectedObj) {
            if (selectedObjUids.includes(selectedObj.uid) == true) {
                var isValidPnP = true;

                if (!(selectedObj.modelType.typeHierarchyArray.indexOf('U4_PnPSpecRevision') !== -1)) {
                    isValidPnP = false; // not a pam
                }

                if ((!selectedObj.props.item_id) || (selectedObj.props.item_id.dbValues[0] === '540000000000') || (selectedObj.props.item_id.dbValues[0] === '540000000002')) {
                    isValidPnP = false; // not a pam template

                    pnpTemplateSelectionCount++;
                }

                if (selectedObj.props.object_string.dbValues[0].substring(0, 1) === 'T') {
                    isValidPnP = false; // not a mct pam template

                    pnpTemplateSelectionCount++;
                }

                if (isValidPnP) {
                    selectedPnP.push({
                        uid: selectedObj.uid,
                        type: selectedObj.type
                    });
                }
            }
        });
        /*if (pnpTemplateSelectionCount === selectedObjs.length) {
        _messagingSvc.showError('Only PAM Template(s) found in the selection. Please change the selection.');
        return;
        }*/
    } else {
        console.log('\n ******* Going for all at a time ******** \n');
    }

    /*if (selectedPam.length === 0) {
    _messagingSvc.showError('No valid PAM\'s are found in selection. Please change the selection.');
 
    return;
    } */

    return {
        "selectedObjects": selectedPnP,
        "selectionCount": pnpTemplateSelectionCount
    };
}

var getSelectedPML = function (selectedObjects, referenceArray) {
    var selectedObjs = _appCtxSvc.ctx.mselected;
    var selectedObjUids = [];
    _.forEach(selectedObjs, function (selectedObj) {
        selectedObjUids.push(selectedObj.uid);
    });
    if (selectedObjects) {
        selectedObjs = selectedObjects;
    }
    if (referenceArray && referenceArray.length > 0) {
        selectedObjUids = referenceArray;
    }
    var selectedPML = [];
    var pmlTemplateSelectionCount = 0;
    if (selectedObjUids.length > 0) {
        _.forEach(selectedObjs, function (selectedObj) {
            if (selectedObjUids.includes(selectedObj.uid) == true) {
                var isValidPML = false;
                if (selectedObj.modelType.typeHierarchyArray.indexOf('U4_PMLRevision') !== -1) {
                    isValidPML = true; // is a PML Object
                }
                if (_appCtxSvc.ctx.preferences.U4_PML_Template && _appCtxSvc.ctx.preferences.U4_PML_Template.length > 0) {
                    var pref_values = _appCtxSvc.ctx.preferences.U4_PML_Template;
                    _.forEach(pref_values, function (pref_value) {
                        if (selectedObj.props.item_id && pref_value.includes(selectedObj.props.item_id.dbValues[0]) == true) {
                            isValidPML = false;
                            pmlTemplateSelectionCount++;
                        }
                    });
                }
                if (isValidPML) {
                    selectedPML.push({
                        uid: selectedObj.uid,
                        type: selectedObj.type
                    });
                }
            }
        });
    } else {
        console.log('\n ******* Going for all at a time ******** \n');
    }
    return {
        "selectedObjects": selectedPML,
        "selectionCount": pmlTemplateSelectionCount
    };
}

var getAllPAMorPNP = function (sResult, data, callback) {
    var selectedObjs = sResult.searchResults;
    var selectedObjUids = [];
    _.forEach(selectedObjs, function (selectedObj) {
        selectedObjUids.push(selectedObj.uid);
    });

    if (selectedObjUids.length > 0) {
        if (data.radioVEType.dbValue == "PAM" || _SelectedComp == "PAM") {
            var getPropertiesInputData = {
                objects: selectedObjs,
                attributes: ['u4_technology', 'object_string']
            };
            _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', getPropertiesInputData).then(function (getPropertiesResponse) {
                var PamSelected = getSelectedPAM(getPropertiesResponse.modelObjects, selectedObjUids);
                if (PamSelected.selectedObjects.length === 0) {
                    _messagingSvc.showError('No valid PAM found in the selection. Please change the selection.');
                    return;
                } else if (PamSelected.selectionCount === selectedObjs.length) {
                    _messagingSvc.showError('Only PAM Template(s) found in the selection. Please change the selection.');
                    return;
                }
                callback(PamSelected.selectedObjects);
            });
        } else if (data.radioVEType.dbValue == "PNP" || _SelectedComp == "PNP") {
            var getPropertiesInputData = {
                objects: selectedObjs,
                attributes: ['object_string']
            };
            _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', getPropertiesInputData).then(function (getPropertiesResponse) {
                var PnPSelected = getSelectedPNP(getPropertiesResponse.modelObjects, selectedObjUids);
                if (PnPSelected.selectedObjects.length === 0) {
                    _messagingSvc.showError('No valid PNP found in the selection. Please change the selection.');
                    return;
                } else if (PnPSelected.selectionCount === selectedObjs.length) {
                    _messagingSvc.showError('Only PNP Template(s) found in the selection. Please change the selection.');
                    return;
                }
                callback(PnPSelected.selectedObjects);
            });
        } else if (data.radioVEType.dbValue == "PML" || _SelectedComp == "PML") {
            var getPropertiesInputData = {
                objects: selectedObjs,
                attributes: ['object_string']
            };
            _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', getPropertiesInputData).then(function (getPropertiesResponse) {
                var PMLSelected = getSelectedPML(getPropertiesResponse.modelObjects, selectedObjUids);
                if (PMLSelected.selectedObjects.length === 0) {
                    _messagingSvc.showError('No valid PML found in the selection. Please change the selection.');
                    return;
                } else if (PMLSelected.selectionCount === selectedObjs.length) {
                    _messagingSvc.showError('Only PML Template(s) found in the selection. Please change the selection.');
                    return;
                }
                callback(PMLSelected.selectedObjects);
            });
        }
    } else {
        console.log('\n ******* Going for all at a time ******** \n');
    }

    /*if (selectedPam.length === 0) {
    _messagingSvc.showError('No valid PAM\'s are found in selection. Please change the selection.');
 
    return;
    } */
    //return selectedPam;
}

var getAllPAMAdvancedSearch = function getAllPAMAdvancedSearch(sResult, data, callback) {

    var selectedObjs = sResult.ServiceData.plain;
    var resultObj = Object.values(sResult.ServiceData.modelObjects);

    if (selectedObjs.length > 0) {
        if (data.radioVEType.dbValue == "PAM" || _SelectedComp == "PAM") {
            var getPropertiesInputData = {
                objects: resultObj,
                attributes: ['u4_technology', 'object_string']
            };
            _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', getPropertiesInputData).then(function (getPropertiesResponse) {
                var pamSelected = getSelectedPAM(getPropertiesResponse.modelObjects, selectedObjs);
                if (pamSelected.selectedObjects.length === 0) {
                    _messagingSvc.showError('No valid PAM found in the selection. Please change the selection.');
                    return;
                } else if (pamSelected.selectionCount === selectedObjs.length) {
                    _messagingSvc.showError('Only PAM Template(s) found in the selection. Please change the selection.');
                    return;
                }
                callback(pamSelected.selectedObjects);
            });
        } else if (data.radioVEType.dbValue == "PNP" || _SelectedComp == "PNP") {
            var getPropertiesInputData = {
                objects: resultObj,
                attributes: ['object_string']
            };
            _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', getPropertiesInputData).then(function (getPropertiesResponse) {
                var PnPSelected = getSelectedPNP(getPropertiesResponse.modelObjects, selectedObjs);
                if (PnPSelected.selectedObjects.length === 0) {
                    _messagingSvc.showError('No valid PNP found in the selection. Please change the selection.');
                    return;
                } else if (PnPSelected.selectionCount === selectedObjs.length) {
                    _messagingSvc.showError('Only PNP Template(s) found in the selection. Please change the selection.');
                    return;
                }
                callback(PnPSelected.selectedObjects);
            });
        } else if (data.radioVEType.dbValue == "PML" || _SelectedComp == "PML") {
            var getPropertiesInputData = {
                objects: resultObj,
                attributes: ['object_string']
            };
            _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', getPropertiesInputData).then(function (getPropertiesResponse) {
                var PMLSelected = getSelectedPML(getPropertiesResponse.modelObjects, selectedObjs);
                if (PMLSelected.selectedObjects.length === 0) {
                    _messagingSvc.showError('No valid PML found in the selection. Please change the selection.');
                    return;
                } else if (PMLSelected.selectionCount === selectedObjs.length) {
                    _messagingSvc.showError('Only PML Template(s) found in the selection. Please change the selection.');
                    return;
                }
                callback(PMLSelected.selectedObjects);
            });
        }
    } else {
        console.log('\n ******* Going for all at a time ******** \n');
    }
}

var createAndaddToValueExt = function (valueExtName, data, callback) {
    var selectedPAMorPNP = [];
    if (data.radioVEType.dbValue == "PAM" || _SelectedComp == "PAM") {
        selectedPAMorPNP = getSelectedPAM().selectedObjects;
    } else if (data.radioVEType.dbValue == "PNP" || _SelectedComp == "PNP") {
        selectedPAMorPNP = getSelectedPNP().selectedObjects;
    } else if (data.radioVEType.dbValue == "PML" || _SelectedComp == "PML") {
        selectedPAMorPNP = getSelectedPML().selectedObjects;
    }

    if (selectedPAMorPNP.length > 0) {
        createValueExt(valueExtName, data, function (newValueExtRev) {
            _commUtil.getCurrentUserNewstuffFolder(function (newstuffFolder) {
                var addChildrenInputData = {
                    inputData: [{
                        parentObj: newstuffFolder,
                        childrenObj: [newValueExtRev],
                        propertyName: ''
                    }
                    ]
                };

                _soaSvc.post('Core-2014-10-DataManagement', 'addChildren', addChildrenInputData).then(function (addChildrenResponse) {
                    var packetCount = Math.ceil(selectedPAMorPNP.length / inputDataPerPacket);
                    addPamRecursively(data, selectedPAMorPNP, 0, packetCount, newValueExtRev, 'DD_CREATE', 0, callback);
                });
            });
        });
    } else {
        var searchInputData;
        if (_appCtxSvc.ctx.sublocation.nameToken == 'com.siemens.splm.client.search:SearchResultsSubLocation') {

            searchInputData = {
                searchInput: {
                    providerName: _appCtxSvc.ctx.search.provider,
                    searchCriteria: {
                        searchString: _appCtxSvc.ctx.search.criteria.searchString
                    },
                    startIndex: 0,
                    maxToReturn: -1,
                    maxToLoad: -1,
                    searchFilterMap: _appCtxSvc.ctx.search.activeFilterMap,
                    searchFilterFieldSortType: _appCtxSvc.ctx.search.sortType,
                    attributesToInflate: ['object_id', 'object_name']
                }
            };
            _soaSvc.post('Query-2014-11-Finder', 'performSearch', searchInputData).then(function (searchInputResponse) {
                console.log('\n Search Done');
                console.log(searchInputResponse);
                getAllPAMorPNP(searchInputResponse, data, function (selectedPamAll) {
                    if (selectedPamAll.length > 0) {
                        createValueExt(valueExtName, data, function (newValueExtRev) {
                            _commUtil.getCurrentUserNewstuffFolder(function (newstuffFolder) {
                                var addChildrenInputData = {
                                    inputData: [{
                                        parentObj: newstuffFolder,
                                        childrenObj: [newValueExtRev],
                                        propertyName: ''
                                    }
                                    ]
                                };

                                _soaSvc.post('Core-2014-10-DataManagement', 'addChildren', addChildrenInputData).then(function (addChildrenResponse) {
                                    var packetCount = Math.ceil(selectedPamAll.length / inputDataPerPacket);
                                    addPamRecursively(data, selectedPamAll, 0, packetCount, newValueExtRev, 'DD_CREATE', 0, callback);
                                });
                            });
                        });
                    } else {
                        _messagingSvc.showError('No valid PAM found in the search . Please change the filter.');
                        return;
                    }
                });
            });
        } else if (_appCtxSvc.ctx.sublocation.nameToken == 'com.siemens.splm.client.search:AdvancedSearchSubLocation') {

            var sValuePAMOrPnP = 'PAM Specs';
            if (data.radioVEType.dbValue == "PAM" || _SelectedComp == "PAM") {
                sValuePAMOrPnP = 'PAM Specs';
            } else if (data.radioVEType.dbValue == "PNP" || _SelectedComp == "PNP") {
                sValuePAMOrPnP = 'PnP';
            } else if (data.radioVEType.dbValue == "PML" || _SelectedComp == "PML") {
                sValuePAMOrPnP = 'PML (PNG)';
            }

            var activeFilterMap = {
                SampleStringKey: [{
                    searchFilterType: 'StringFilter',
                    stringValue: sValuePAMOrPnP
                }
                ]
            };

            searchInputData = {
                searchInput: {
                    providerName: 'Awp0SavedQuerySearchProvider',
                    searchCriteria: _appCtxSvc.ctx.advancedSearch.criteria,
                    startIndex: 0,
                    maxToReturn: 50000,
                    maxToLoad: 50000,
                    searchFilterFieldSortType: 'Priority',
                    searchFilterMap: activeFilterMap,
                    searchSortCriteria: [],
                    attributesToInflate: ['object_id', 'object_name']
                }
            };
            _soaSvc.post('Internal-AWS2-2017-12-Finder', 'performSearchViewModel2', searchInputData).then(function (searchInputResponse) {
                console.log('\n Search Done');
                console.log(searchInputResponse);
                getAllPAMAdvancedSearch(searchInputResponse, data, function (selectedPamAll) {
                    if (selectedPamAll.length > 0) {
                        createValueExt(valueExtName, data, function (newValueExtRev) {
                            _commUtil.getCurrentUserNewstuffFolder(function (newstuffFolder) {
                                var addChildrenInputData = {
                                    inputData: [{
                                        parentObj: newstuffFolder,
                                        childrenObj: [newValueExtRev],
                                        propertyName: ''
                                    }
                                    ]
                                };

                                _soaSvc.post('Core-2014-10-DataManagement', 'addChildren', addChildrenInputData).then(function (addChildrenResponse) {
                                    var packetCount = Math.ceil(selectedPamAll.length / inputDataPerPacket);
                                    addPamRecursively(data, selectedPamAll, 0, packetCount, newValueExtRev, 'DD_CREATE', 0, callback);
                                });
                            });
                        });
                    } else {
                        _messagingSvc.showError('No valid ' + sValuePAMOrPnP + ' found in the search . Please change the filter.');
                        return;
                    }
                });
            });
        }
    }
}

var createValueExt = function (valueExtName, data, callback) {
    var createItemsInputData = {
        properties: [{
            name: valueExtName,
            type: 'U4_ValueExt',
            description: 'Item for Value Extractor operation.'
        }
        ]
    };
    var veType = "PAM";
    if (data.radioVEType.dbValue == "PNP" || _SelectedComp == "PNP") {
        veType = "PNP";
    }
    if (data.radioVEType.dbValue == "PML" || _SelectedComp == "PML") {
        veType = "PML";
    }

    /*  var createItemsInputData = {
    input: [{
    clientId: 'AWClient',
    data: {
    boName: 'U4_ValueExt',
    stringProps: {
    object_name: valueExtName,
    object_desc: 'Item for Value Extractor operation.'
    }
    }
    }
    ]
    };*/

    _soaSvc.post('Core-2006-03-DataManagement', 'createItems', createItemsInputData).then(function (createItemsResponse) {
        //_soaSvc.post('Core-2008-06-DataManagement', 'createObjects', createItemsInputData).then(function (createItemsResponse) {

        /*  var setPropertiesInputData = {
        info: [{
        object: createItemsResponse.output[0].itemRev,
        vecNameVal: [{
        name: 'u4_ve_type',
        values: [veType]
        }
        ]
        }
        ],
        options: []
        };*/

        var setPropertiesInputData = {
            info: [{
                object: createItemsResponse.output[0].itemRev,
                vecNameVal: [{
                    name: 'u4_ve_type',
                    values: [veType]
                }
                ]
            }
            ],
            options: []
        };

        _soaSvc.post('Core-2010-09-DataManagement', 'setProperties', setPropertiesInputData).then(function (setPropertiesResponse) {
            callback(createItemsResponse.output[0].itemRev);
            // callback(createItemsResponse.output[0].objects[2]);
        });
        //callback(createItemsResponse.output[0].itemRev);
    });
};

var addPamRecursively = function (dataOrg, data, packetCounter, maxPacket, ValueExtRevisionObj, actionNewOrUpdate, pamsAlreadyPresent, callback) {
    let callbackReturn = '';

    _commUtil.addChildrenUnderParent(data.slice(packetCounter * inputDataPerPacket, (packetCounter + 1) * inputDataPerPacket), ValueExtRevisionObj, 'U4_ValueExtRelation', function () {
        packetCounter++;
        var veType;

        if (packetCounter < maxPacket) {
            addPamRecursively(dataOrg, data, packetCounter, maxPacket, ValueExtRevisionObj, actionNewOrUpdate, pamsAlreadyPresent, callback);
        }

        if (packetCounter === maxPacket) {
            if (dataOrg.radioVEType.dbValue == "PNP" || _SelectedComp == "PNP") {
                veType = 'PNP';
            } else if (dataOrg.radioVEType.dbValue == "PAM" || _SelectedComp == "PAM") {
                veType = 'PAM';
            }
            if (dataOrg.radioVEType.dbValue == "PML" || _SelectedComp == "PML") {
                veType = 'PML';
            }

            if (actionNewOrUpdate === 'DD_UPDATE') {
                if (pamsAlreadyPresent > 0) {
                    _messagingSvc.showInfo(pamsAlreadyPresent + ' ' + veType + '(s) in selection already present under Value Extractor. ' + data.length + ' ' + veType + '(s) added to Value Extractor Successfully.');
                } else {
                    _messagingSvc.showInfo(data.length + ' ' + veType + '(s) added to Value Extractor Successfully.');
                }
            } else {
                const pamStr = (data.length === 1) ? veType : (data.length + ' ' + veType + '\'s');
                const newValueExtName = ValueExtRevisionObj.props.object_name.dbValues[0];

                _messagingSvc.showInfo("New Value Extractor <strong>" + newValueExtName + "</strong> created with selected " + pamStr + " and added to NewStuff folder.", true);

                callbackReturn = ValueExtRevisionObj;
            }

            callback(callbackReturn);
        }
    });
}

var addToValueExt = function (ValueExtRevisionObj, data, callback) {
    var selectedPam = getSelectedPAM().selectedObjects;

    var selectedPAMorPNP = [];
    var strPamOrPnP = '';
    if (data.radioVEType.dbValue == "PAM" || _SelectedComp == "PAM") {
        selectedPAMorPNP = getSelectedPAM().selectedObjects;
        strPamOrPnP = 'PAM';
    } else if (data.radioVEType.dbValue == "PNP" || _SelectedComp == "PNP") {
        selectedPAMorPNP = getSelectedPNP().selectedObjects;
        strPamOrPnP = 'PNP';
    } else if (data.radioVEType.dbValue == "PML" || _SelectedComp == "PML") {
        selectedPAMorPNP = getSelectedPML().selectedObjects;
        strPamOrPnP = 'PML';
    }

    if (selectedPAMorPNP.length > 0) {
        var getPropertiesInputData = {
            objects: [ValueExtRevisionObj],
            attributes: ['U4_ValueExtRelation']
        };

        _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', getPropertiesInputData).then(function (getPropertiesResponse) {
            var pamsPnPToAdd = [];
            var pamsPnPAlreadyPresent = 0;
            var uids = getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]].props.U4_ValueExtRelation.dbValues;

            for (var h in selectedPAMorPNP) {
                var isAlreadyPresent = false;

                for (var t in uids) {
                    var pamPnpUid = getPropertiesResponse.modelObjects[uids[t]].uid;

                    if (selectedPAMorPNP[h].uid === pamPnpUid) {
                        isAlreadyPresent = true;
                    }
                }

                if (isAlreadyPresent) {
                    ++pamsPnPAlreadyPresent;
                } else {
                    pamsPnPToAdd.push(selectedPAMorPNP[h]);
                }
            }

            if (pamsPnPToAdd.length > 0) {
                let packetCount = Math.ceil(pamsPnPToAdd.length / inputDataPerPacket);
                addPamRecursively(data, pamsPnPToAdd, 0, packetCount, ValueExtRevisionObj, 'DD_UPDATE', pamsPnPAlreadyPresent, callback);
            } else {
                _messagingSvc.showInfo('All ' + strPamOrPnP + '(s) in selection already present under Value Extractor.');
            }
        });
    } else {

        var searchInputData;
        if (_appCtxSvc.ctx.sublocation.nameToken == 'com.siemens.splm.client.search:SearchResultsSubLocation') {
            searchInputData = {
                searchInput: {
                    providerName: _appCtxSvc.ctx.search.provider,
                    searchCriteria: {
                        searchString: _appCtxSvc.ctx.search.criteria.searchString
                    },
                    startIndex: 0,
                    maxToReturn: -1,
                    maxToLoad: -1,
                    searchFilterMap: _appCtxSvc.ctx.search.activeFilterMap,
                    searchFilterFieldSortType: _appCtxSvc.ctx.search.sortType,
                    attributesToInflate: ['object_id', 'object_name']
                }
            };

            _soaSvc.post('Query-2014-11-Finder', 'performSearch', searchInputData).then(function (searchInputResponse) {
                console.log('\n Search Done');
                console.log(searchInputResponse);
                getAllPAMorPNP(searchInputResponse, data, function (selectedPamAll) {
                    if (selectedPamAll.length > 0) {
                        var getPropertiesInputData = {
                            objects: [ValueExtRevisionObj],
                            attributes: ['U4_ValueExtRelation']
                        };
                        _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', getPropertiesInputData).then(function (getPropertiesResponse) {
                            var pamsToAdd = [];
                            var pamsAlreadyPresent = 0;
                            var uids = getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]].props.U4_ValueExtRelation.dbValues;

                            for (var h in selectedPamAll) {
                                var isAlreadyPresent = false;

                                for (var t in uids) {
                                    var pamUid = getPropertiesResponse.modelObjects[uids[t]].uid;

                                    if (selectedPamAll[h].uid === pamUid) {
                                        isAlreadyPresent = true;
                                    }
                                }

                                if (isAlreadyPresent) {
                                    ++pamsAlreadyPresent;
                                } else {
                                    pamsToAdd.push(selectedPamAll[h]);
                                }
                            }

                            if (pamsToAdd.length > 0) {
                                var packetCount = Math.ceil(pamsToAdd.length / inputDataPerPacket);
                                addPamRecursively(data, pamsToAdd, 0, packetCount, ValueExtRevisionObj, 'DD_UPDATE', pamsAlreadyPresent, callback);
                            } else {
                                _messagingSvc.showInfo('All ' + strPamOrPnP + '(s) in selection already present under Value Extractor.');
                            }
                        });
                    } else {
                        _messagingSvc.showError('No valid ' + strPamOrPnP + ' found in the search . Please change the filter.');
                        return;
                    }
                });
            });
        } else if (_appCtxSvc.ctx.sublocation.nameToken == 'com.siemens.splm.client.search:AdvancedSearchSubLocation') {

            console.log('\n *******Inside Advanced Search');
            var sValuePAMOrPnP = 'PAM Specs';
            if (data.radioVEType.dbValue == "PAM" || _SelectedComp == "PAM") {
                sValuePAMOrPnP = 'PAM Specs';
            } else if (data.radioVEType.dbValue == "PNP" || _SelectedComp == "PNP") {
                sValuePAMOrPnP = 'PnP';
            } else if (data.radioVEType.dbValue == "PML" || _SelectedComp == "PML") {
                sValuePAMOrPnP = 'PML (PNG)';
            }
            var activeFilterMap = {
                SampleStringKey: [{
                    searchFilterType: 'StringFilter',
                    stringValue: sValuePAMOrPnP
                }
                ]
            };

            searchInputData = {
                searchInput: {
                    providerName: 'Awp0SavedQuerySearchProvider',
                    searchCriteria: _appCtxSvc.ctx.advancedSearch.criteria,
                    startIndex: 0,
                    maxToReturn: 50000,
                    maxToLoad: 50000,
                    searchFilterFieldSortType: 'Priority',
                    searchFilterMap: activeFilterMap,
                    searchSortCriteria: [],
                    attributesToInflate: ['object_id', 'object_name']
                }
            };

            _soaSvc.post('Internal-AWS2-2017-12-Finder', 'performSearchViewModel2', searchInputData).then(function (searchInputResponse) {
                console.log('\n Search Done');
                console.log(searchInputResponse);
                getAllPAMAdvancedSearch(searchInputResponse, data, function (selectedPamAll) {
                    if (selectedPamAll.length > 0) {
                        var getPropertiesInputData = {
                            objects: [ValueExtRevisionObj],
                            attributes: ['U4_ValueExtRelation']
                        };
                        _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', getPropertiesInputData).then(function (getPropertiesResponse) {
                            var pamsToAdd = [];
                            var pamsAlreadyPresent = 0;
                            var uids = getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]].props.U4_ValueExtRelation.dbValues;

                            for (var h in selectedPamAll) {
                                var isAlreadyPresent = false;

                                for (var t in uids) {
                                    var pamUid = getPropertiesResponse.modelObjects[uids[t]].uid;

                                    if (selectedPamAll[h].uid === pamUid) {
                                        isAlreadyPresent = true;
                                    }
                                }

                                if (isAlreadyPresent) {
                                    ++pamsAlreadyPresent;
                                } else {
                                    pamsToAdd.push(selectedPamAll[h]);
                                }
                            }

                            if (pamsToAdd.length > 0) {
                                var packetCount = Math.ceil(pamsToAdd.length / inputDataPerPacket);
                                addPamRecursively(data, pamsToAdd, 0, packetCount, ValueExtRevisionObj, 'DD_UPDATE', pamsAlreadyPresent, callback);
                            } else {
                                _messagingSvc.showInfo('All ' + strPamOrPnP + '(s) in selection already present under Value Extractor.');
                            }
                        });
                    } else {
                        _messagingSvc.showError('No valid ' + strPamOrPnP + ' found in the search . Please change the filter.');
                        return;
                    }
                });
            });
        }
    }
}

export default exports = {
    checkPamSelection,
    emptyAction,
    loadCurrentUserValueExts,
    loadCurrentUserValueExts1,
    createAndAddAction,
    addAction,
    typeSelectionButtonAction,
    selectionChanged
};

export let moduleServiceNameToInject = 'valueExtAddService';

app.factory('valueExtAddService', () => exports);
