import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';

'use strict';

var exports = {};
var CompCheck = []; let dataInPanel;

export let openInitalPage = function () {
    _appCtxSvc.registerCtx('_pnpRev_UID', _appCtxSvc.ctx.selected.uid);
    _appCtxSvc.registerCtx('_pnpRev_type', _appCtxSvc.ctx.selected.type);
    _appCtxSvc.registerCtx('ViewModeContext', {
        ViewModeContext: 'ListView'
    });
    window.location.href = (window.location.origin + window.location.pathname + '#/' + "ConsumerUnit" + "?" + "PnPuid=" + _appCtxSvc.ctx.selected.uid);
}

export let initialize = function (data) {
    console.log("Entered in initialize");
    // dummy function to avoid repeated call for reveal function

}

export let ctxChange = function () {
    if (_appCtxSvc.ctx.ViewModeContext.ViewModeContext !== 'ListView') {
        exports.changeToListView();
    }
}

export let changeToListView = function () {
    _appCtxSvc.registerCtx('ViewModeContext', {
        ViewModeContext: 'ListView'
    });
    // _messagingSvc.showError('This session does not support views other than list view.');
}



export let getListofPams = function (ctx, data, relation) {
    dataInPanel = [];
    var url = window.location.href;
    var urlsplit = url.split("?PnPuid=");
    var urluid = urlsplit[1];

    var urluid = _appCtxSvc.getCtx('_pnpRev_UID');
    // collect datasets information
    var inputdata = {
        inputPnP: {
            uid: urluid,
            type: 'U4_PnPSpecRevision'
        },
        relationtype: relation
    };

    _soaSvc.post('AWC-2016-10-PNP', 'getPamSpecToDisplay', inputdata).then(function (response) {
        if (response.infoMessage) {
            // error message change as part Aug 2020 release CR
            _messagingSvc.showInfo(response.infoMessage);
            return;
        }
        else if (response.iTotalPamSpecs == 0 && response.iTotalOtherPamSpecs == 0) {
            _messagingSvc.showInfo("No PAM Specifications available to add.");
            return;
        }

        if (response.iTotalPamSpecs == 0) {
            _messagingSvc.showInfo("No Recommended PAM Specifications Available. Please choose from the Other PAMs section.");
        }
        else {
            CompCheck = [];
            if (response.pamSpecs) {
                var PamRevs = [];
                for (var obj in response.pamSpecs) {
                    PamRevs.push(response.pamSpecs[obj].uid);
                    CompCheck.push(response.pamSpecs[obj].uid);
                }
            }
            if (PamRevs.length > 0) {
                var loadObjectsInputData =
                {
                    uids: PamRevs
                };
                //to load the objects from uid
                _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', loadObjectsInputData).then(function (loadObjectsResponse) {
                    var outputArray = [];
                    for (var i in PamRevs) {
                        var uid = PamRevs[i];
                        var pamRev = loadObjectsResponse.modelObjects[uid];
                        outputArray.push(pamRev);
                    }
                    data.RecommendPamResults = outputArray;
                    data.totalRecommendPamFound = outputArray.length;
                    _appCtxSvc.registerCtx('selRecommendedPam', data.RecommendPamResults);
                    data.dataProviders.listAvailablePAMProvider.update(data.RecommendPamResults, data.totalRecommendPamFound);
                });
            }
        }

        if (response.iTotalOtherPamSpecs != 0) {
            if (response.otherPamSpecs) {
                var OtherPamRevs = [];
                for (var obj in response.otherPamSpecs) {
                    OtherPamRevs.push(response.otherPamSpecs[obj].uid);
                }
            }
            if (OtherPamRevs.length > 0) {
                var loadObjectsInputData =
                {
                    uids: OtherPamRevs
                };
                //to load the objects from uid
                _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', loadObjectsInputData).then(function (loadObjectsResponse) {
                    var outputArray1 = [];
                    for (var i in OtherPamRevs) {
                        var uid = OtherPamRevs[i];
                        var OtherpamRev = loadObjectsResponse.modelObjects[uid];
                        outputArray1.push(OtherpamRev);
                    }
                    data.OtherPamResults = outputArray1;
                    data.totalOtherPamFound = outputArray1.length;

                    data.dataProviders.listOtherPAMProvider.update(data.OtherPamResults, data.totalOtherPamFound);
                });
            }
        }
    });
    return;
}

export let getListofSelectedPams = function (ctx, data) {
    console.log("getListofSelectedPams called");
    var selectedObjs = data.dataProviders.listAvailablePAMProvider.selectedObjects;
    var otherselectedObjs = data.dataProviders.listOtherPAMProvider.selectedObjects;

    var RecommendPamResults = data.dataProviders.listAvailablePAMProvider.viewModelCollection.loadedVMObjects;
    var OtherPamResults = data.dataProviders.listOtherPAMProvider.viewModelCollection.loadedVMObjects;

    if (selectedObjs.length < 1 && otherselectedObjs.length < 1) {
        return;
    }

    if (!dataInPanel.length) {
        data.PamSelectedResult = [];
    }
    else {
        data.PamSelectedResult = [];
        data.PamSelectedResult = dataInPanel;
    }

    var containsCheck = function (arr, element) {
        for (var i = 0; i < arr.length; i++) {
            if (arr[i].uid === element.uid) {
                return true;
            }
        }
        return false;
    }

    for (var i in selectedObjs) {
        for (var j in RecommendPamResults) {
            if (RecommendPamResults[j].uid === selectedObjs[i].uid) {
                if (!containsCheck(data.PamSelectedResult, RecommendPamResults[j])) {
                    data.PamSelectedResult.push(RecommendPamResults[j]);
                    RecommendPamResults.splice(RecommendPamResults.indexOf(RecommendPamResults[j]), 1);
                }
                break;
            }
        }
    }

    for (var i in otherselectedObjs) {
        for (var j in OtherPamResults) {
            if (OtherPamResults[j].uid === otherselectedObjs[i].uid) {
                if (!containsCheck(data.PamSelectedResult, OtherPamResults[j])) {
                    data.PamSelectedResult.push(OtherPamResults[j]);
                    OtherPamResults.splice(OtherPamResults.indexOf(OtherPamResults[j]), 1);
                }
                break;
            }
        }
    }

    dataInPanel = data.PamSelectedResult;
    if (RecommendPamResults != undefined) {
        data.dataProviders.listAvailablePAMProvider.update(RecommendPamResults, RecommendPamResults.length);
    }

    if (OtherPamResults != undefined) {
        data.dataProviders.listOtherPAMProvider.update(OtherPamResults, OtherPamResults.length);
    }

    _appCtxSvc.registerCtx('_pamInPanel', data.PamSelectedResult.length);
}

export let removeCompAction = function (ctx, data) {
    var selectedObjs = data.dataProviders.listSelectedPAMProvider.selectedObjects;
    if (!selectedObjs || selectedObjs.length < 1) {
        return;
    }
    var RecommendPamResults = data.dataProviders.listAvailablePAMProvider.viewModelCollection.loadedVMObjects;
    var OtherPamResults = data.dataProviders.listOtherPAMProvider.viewModelCollection.loadedVMObjects;
    var flagRecommend = 0;
    if (RecommendPamResults != undefined) {
        for (var selObj in selectedObjs) {
            for (var obj in CompCheck) {
                if (CompCheck[obj] === selectedObjs[selObj].uid) {
                    flagRecommend++;
                }
            }
        }
    }

    var PamSelectedResult = data.dataProviders.listSelectedPAMProvider.viewModelCollection.loadedVMObjects;
    for (var selObj in selectedObjs) {
        for (var obj in PamSelectedResult) {
            if (PamSelectedResult[obj].uid === selectedObjs[selObj].uid) {
                if (flagRecommend != 0) {
                    //remove object
                    RecommendPamResults.push(PamSelectedResult[obj]);
                    PamSelectedResult.splice(PamSelectedResult.indexOf(PamSelectedResult[obj]), 1);
                }
                else {
                    OtherPamResults.push(PamSelectedResult[obj]);
                    PamSelectedResult.splice(PamSelectedResult.indexOf(PamSelectedResult[obj]), 1);
                }
            }
        }
    }
    if (RecommendPamResults != undefined) {
        data.dataProviders.listAvailablePAMProvider.update(RecommendPamResults, RecommendPamResults.length);
    }

    if (data.OtherPamResults != undefined) {
        data.dataProviders.listOtherPAMProvider.update(OtherPamResults, OtherPamResults.length);
    }
    dataInPanel = PamSelectedResult;
    _appCtxSvc.registerCtx('_pamInPanel', PamSelectedResult.length);
    data.dataProviders.listSelectedPAMProvider.update(PamSelectedResult, PamSelectedResult.length);

}

export let attachPAMsAction = function (ctx, data, relation) {
    var pams = [];
    var selectedPAM = data.dataProviders.listSelectedPAMProvider.viewModelCollection.loadedVMObjects;
    for (var j in selectedPAM) {
        pams.push({
            uid: selectedPAM[j].uid,
            type: selectedPAM[j].type
        });
    }

    var urluid = _appCtxSvc.getCtx('_pnpRev_UID');

    var inputdata = {
        relationName: relation,
        pnpObject: {
            uid: urluid,
            type: 'U4_PnPSpecRevision'
        },
        pamObjects: pams
    };

    _soaSvc.post('AWC-2016-10-PNP', 'attachPAMs', inputdata).then(function (response) {
        if (response) {
            _messagingSvc.showInfo(response.outmsg);
        }
    });
}

export let storePnPUID = function () {
    var url = window.location.href;
    var urlsplit = url.split("?PnPuid=");
    var urluid = urlsplit[1];
    if (urluid) {
        sessionStorage.setItem("PnpUID", urluid);
    }
}

export let moduleServiceNameToInject = 'AssociatedPAMSpecsService';
export default exports = {
    openInitalPage,
    initialize,
    ctxChange,
    changeToListView,
    getListofPams,
    getListofSelectedPams,
    removeCompAction,
    attachPAMsAction,
    storePnPUID,
    moduleServiceNameToInject
};

