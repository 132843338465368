// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
define
*/

import msgSvc from 'js/messagingService';
import awPromiseService from 'js/awPromiseService';

import app from 'app';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import eventBus from 'js/eventBus';
import _appCtxSvc from 'js/appCtxService';
import 'js/TcSessionData';
import 'soa/kernel/propertyPolicyService';


/**
* @module js/DeleteProjectService
*/

'use strict';

var exports = {};

export let alert = function( text ) {
	alert( text );
};
/**
 * In this function, validating the prechecks for Project Delete Operation
 * 
 * @param {Object} data ViewModel object
 * @return
 * 
 **/
export let projectDeletePreValidations = function( data ) {
	try{
		var deferred = awPromiseService.instance.defer();
		data.delete_displayProjectDeletePopUp = true;
		data.delete_selectProjectObjString    = _appCtxSvc.ctx.selected.props.object_string.dbValues;
		data.delete_selectObjType             = _appCtxSvc.ctx.selected.type;
		data.delete_selectObjUID              = _appCtxSvc.ctx.selected.uid;            

		if(_appCtxSvc.ctx.selected.props.is_modifiable.dbValues[0] == "0")
		{
			msgSvc.showError("Access Denied");
			data.delete_displayProjectDeletePopUp = false;
			return;
		}
		
		var request = {
			selObj:{
				uid: _appCtxSvc.ctx.selected.uid,
				type: _appCtxSvc.ctx.selected.type
			}
		};
		
		_soaSvc.post("AWC-2017-12-AwcCommon", "validateProjectIsEmpty", request).then(function (response) {
			var validate = response.out;
			if(validate == false){
				data.delete_displayProjectDeletePopUp = false; 
				msgSvc.showError("Unable to delete project, selected project has components or DDEs attached");
			}
			deferred.resolve();
		});
		return deferred.promise;

	}catch(err){
		msgSvc.showError("Unable to delete,contact administrator");
  }
};

/**
 * This function will delete the design and Tc Projects.
 * 
 * @param {Object} data ViewModel object
 * @return
 * 
**/
export let deleteProjects = function(data){ 

	data.delete_selectedParentObj       = _appCtxSvc.ctx.pselected;
		var request = {
			selObj:{
				uid: _appCtxSvc.ctx.selected.uid,
				type: _appCtxSvc.ctx.selected.type
			}
		};  
		_soaSvc.post("AWC-2017-12-AwcCommon", "deleteUnwantedProject", request).then(function (response) {
			var validate = response.out;              
			if(validate == "Delete Project Initiated..."){                    
				//msgSvc.showInfo(validate);
				msgSvc.showInfo("Project Delete Initiated, it may take a few minutes to complete");					
				if(typeof(data.delete_selectedParentObj) == "undefined" || data.delete_selectedParentObj.type == "U4_Project") {  
					let homeFolderurl = window.location.origin + window.location.pathname + '#/MyProject';
					window.location.href = homeFolderurl;
				}
				/*
				else{
					eventBus.publish("cdm.relatedModified", {
						relatedModified: [data.delete_selectedParentObj]
					});
				  }                       
				location.reload();
				*/
			}
			else{
				msgSvc.showError(validate);
			}
		});  
};

export let moduleServiceNameToInject = 'DeleteProjectService';
export default exports = {
	alert,
	projectDeletePreValidations,
	deleteProjects,
	moduleServiceNameToInject
};
app.factory('DeleteProjectService', () => exports );



