// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */
import app from 'app';
import _ from 'lodash';
import _commUtil from 'js/UnileverCommonUtil';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import awPromiseService from 'js/awPromiseService';
import _MctUtil from 'js/Mct_PnP_Util';
import _MctSubSvc from 'js/Mct_PnP_SublocationService';
import _lovUtil from 'js/UnileverLovUtil';

/**
 * Mct list service
 *
 * @module js/Mct_PnP_Service
 */

'use strict';

var exports = {};




var _MctItemandRevision = null;

export let checkPnpSelection = function (data) {

    var selectedPnPs = _MctUtil.getSelectedPnP('pnp', data);
    var deferred = awPromiseService.instance.defer();
    if (selectedPnPs.length > 0) {
        _MctUtil.getAllTypeOfPnP(selectedPnPs, data, function () {
            if (selectedPnPs) {
                data._canAddMct = true;
            } else {
                data._canAddMct = false;
            }
            if (data.mctPnPType === "showMetricAndImperial") {
                _appCtxSvc.updateCtx("_mctPnPrcButtonStatus", true);
            }
            else if (data.mctPnPType === "showMetric") {
                _appCtxSvc.updateCtx("_mctPnPrcButtonStatus", false);
            } else if (data.mctPnPType === "showImperial") {
                _appCtxSvc.updateCtx("_mctPnPrcButtonStatus", false);
            }
            deferred.resolve();
        });
    }

    return deferred.promise;
}


export let emptyAction_PnP = function () { }



export let pnploadCurrentUserMCTs = function (data) {

    _MctSubSvc.MctAddViewVIEWMODEL = data;

    var deferred = awPromiseService.instance.defer();
    _MctSubSvc.getCurrentUserMCTs(function (MctItemandRevision) {
        _MctItemandRevision = MctItemandRevision;
        var MctItems = [];
        var MctItemRevs_metric = [];
        var MctItemRevs_imperial = [];
        for (var g in MctItemandRevision) {
            if (MctItemandRevision[g].type === 'U4_MCT_PnP') {
                var MctItem = MctItemandRevision[g];
                var latestRev = _MctSubSvc.getLatestRev(MctItem, MctItemandRevision);
                var pnpCount = latestRev.props.U4_MCT_PnP_Relation.dbValues.length;
                MctItem.props.awp0CellProperties.dbValues.push('PnP Count \\:' + pnpCount);
                MctItems.push(MctItem);
                if (latestRev.props.u4_mctPnPType.dbValues[0] === "Metric") {
                    MctItemRevs_metric.push(MctItem);
                } else if (latestRev.props.u4_mctPnPType.dbValues[0] === "Imperial") {
                    MctItemRevs_imperial.push(MctItem);
                }
            }

        }

        if (MctItems.length === 0) {
            data.addNote.uiValue = 'No MCT found.';
        }

        if (_appCtxSvc.ctx._mctPnPType === "showMetricAndImperial") {
            if (data.radioMctPnPType.dbValue === true) {
                data.currentUserMcts = [];
                data.currentUserMcts = MctItemRevs_metric;
                //_commUtil.sortComponents(data, 'currentUserMctsMetric', 0, 0);
            }
            else if (data.radioMctPnPType.dbValue === false) {
                data.currentUserMcts = [];
                data.currentUserMcts = MctItemRevs_imperial;
                //_commUtil.sortComponents(data, 'currentUserMctsImperial', 0, 0);
            }
        } else if (_appCtxSvc.ctx._mctPnPType === "showMetric") {
            data.currentUserMcts = [];
            data.currentUserMcts = MctItemRevs_metric;
        } else if (_appCtxSvc.ctx._mctPnPType === "showImperial") {
            data.currentUserMcts = [];
            data.currentUserMcts = MctItemRevs_imperial;
        }
        //data.currentUserMcts = MctItems;
        //_commUtil.sortComponents(data, 'currentUserMcts', 0, 0);


        deferred.resolve();
    });
    return deferred.promise;
}


export let PnpcreateAndAddAction = function (data, conditions) {

    let isMctAlreadyAvailable = checkMctAlreadyAvailable(data);
    if (!isMctAlreadyAvailable) {

        //conditions.disableButton = false;
        data.CreateButtonVisibility = true;

        var MctName = data.MctName.dbValue;
        _MctUtil.createAndAddToMct(MctName, data, function (newMctRev) {
            _commUtil.closeTNIpanel();
            openMct(data, newMctRev);
        });

    } else {
        _messagingSvc.showError('MCT already available with this name. Please try with different name.');
    }
    return data.CreateButtonVisibility ;

}

let checkMctAlreadyAvailable = function (data) {

    let isMctAlreadyAvailable = false;
    if (_MctItemandRevision) {
        let mctName = data.MctName.dbValue;
        if (mctName) {
            for (let i in _MctItemandRevision) {
                if (_MctItemandRevision[i].type === 'U4_MCT_PnP') {
                    let mctObjName = _MctItemandRevision[i].props.object_name.dbValues[0];
                    if (mctName === mctObjName) {
                        isMctAlreadyAvailable = true;
                        break;
                    }
                }
            }
        }
    } else {
        _messagingSvc.showError('Data not loaded yet. Please wait some time and try again.');
    }
    return isMctAlreadyAvailable;
}

export let pnpaddAction = function (data, conditions) {


    var selectedMctItem = data.dataProviders.MctItemsDataprovider.selectedObjects[0];
    var latestRevMct = _MctSubSvc.getLatestRev(selectedMctItem, _MctItemandRevision);

    _MctUtil.addToMct(latestRevMct, data, function () {
        _commUtil.closeTNIpanel();
        openMct(data, latestRevMct);
    });

}

var openMct = function (data, MctRev) {
    var willOpenMct = data.openOnAdd.dbValue;
    if (willOpenMct) {
        _MctSubSvc.openMct(MctRev.uid);
    }
}

export let openMctPnpSublocation = function () {

    _appCtxSvc.registerCtx('ViewModeContext', {
        ViewModeContext: 'SummaryView'
    });

    window.location.href = (window.location.origin + window.location.pathname + '#/' + 'Mct_PnP_Sublocation');

}

export let changeToPnpSummaryView = function () {


    _appCtxSvc.registerCtx('ViewModeContext', {
        ViewModeContext: 'SummaryView'
    });
    _messagingSvc.showInfo('MCT Operation does not support views other than summary view.');
}

export let pnpCtxChange = function () {

    if (_appCtxSvc.ctx.ViewModeContext.ViewModeContext !== 'SummaryView') {
        exports.changeToPnpSummaryView();
    }
}
//export let moduleServiceNameToInject = 'Mct_PnP_Service';
export default exports = {
    checkPnpSelection,
    emptyAction_PnP,
    pnploadCurrentUserMCTs,
    PnpcreateAndAddAction,
    pnpaddAction,
    openMctPnpSublocation,
    changeToPnpSummaryView,
    pnpCtxChange
    //moduleServiceNameToInject
};
app.factory('Mct_PnP_Service', () => exports);