import _soaSvc from 'soa/kernel/soaService';
import _ from 'lodash';
import _commonUtil from 'js/UnileverCommonUtil';
import _lovUtil from 'js/UnileverLovUtil';
import _messagingSvc from 'js/messagingService';
import _appCtxSvc from 'js/appCtxService';

'use strict';

var exports = {};

var matFormResponse;
var currentdeliForm;
var currentpamFrameType;
var matFormResponse;

var conditionsTest;
var globalConditions;
var compRevResponse;
var compRevProp;
var currentpamComponentClass;
var requiredText = 'Required';
var noLOVtext = 'Select a value for the property ';
var technology_description = null;


export let callRevealAction = async function (ctx, data, conditions) {
    //to Handle the selection Change for PCR Related Frames
    conditionsTest = conditions;
    if (!ctx.activeToolsAndInfoCommand) {
        return;
    }
    else {
        copyPAMOptionChanged(data, ctx, true);
        if (data._internal && data._internal.conditionStates) {
            conditions = data._internal.conditionStates;
        }
    }

    if (!conditions)
        return;

    var inputData = {
        selectedUser: ctx.user,
        selectedRev: {
            uid: ctx.selected.uid,
            type: ctx.selected.type
        }
    };
    //A check to find whether property exists in the selected object.
    if (ctx.selected.props.object_desc != undefined)
        data.description.dbValue = ctx.selected.props.object_desc.dbValues[0];
    data.dispatch({ path: 'data.description', value: data.description });
    // soa call to get on load data
    var projResponse = await _soaSvc.post('AWC-2016-10-SaveAS', 'getUserProjectList', inputData);
    if (projResponse) {
        data.hasAssembly = projResponse.hasAssembly;
        data.selectedTab = projResponse.selectedTab;
        data.projectNameList = [];
        // to add empty row
        data.projectNameList.push(_lovUtil.getEmptyLov()[0]);
        // adding all projects
        for (var i = 0; i < projResponse.projectList.length; i++) {
            var projectName = projResponse.projectList[i];
            data.projectNameList.push({
                propDisplayValue: projectName,
                propDisplayDescription: '',
                dispValue: projectName,
                propInternalValue: projectName
            });
        }
        data.dispatch({ path: 'data.projectNameList', value: data.projectNameList });
    }
    try {
        globalConditions = conditions;
        var compRev = {
            uid: ctx.selected.uid
        };
        var inputData = {
            objects: [compRev],
            attributes: ['u4_component_class', 'u4_component_commodity', 'u4_material_class', 'u4_material_commodity', 'U4_MatlClassRelation', 'u4_technology', 'u4_consumer_unit', 'u4_component_type', 'u4_base_uom', 'u4_design_name']
        };
        var compResponse = await _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData);
        if (compResponse) {
            compRevResponse = compResponse.modelObjects[compResponse.plain[0]];
            console.log("compRevResponse : ", compRevResponse);
            if (compRevResponse.props.U4_MatlClassRelation) {
                var matForm = {
                    uid: compRevResponse.props.U4_MatlClassRelation.dbValues[0]
                };
                var inputData = {
                    objects: [matForm],
                    attributes: ['u4_delivered_form', 'u4_pam_frame_type', 'u4_material_class']
                };

                var matResponse = await _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData);
                matFormResponse = matResponse.modelObjects[matResponse.plain[0]];
                data.technology.dbValue = compRevResponse.props.u4_technology.dbValues[0];
                data.matClass.dbValue = matFormResponse.props.u4_material_class.dbValues[0];
                data.matCommodity.dbValue = compRevResponse.props.u4_material_commodity.dbValues[0];
                data.deliForm.dbValue = matFormResponse.props.u4_delivered_form.dbValues[0];
                console.log("Deli form value is:", data.deliForm.dbValue);
                data.pamFrameType.dbValue = matFormResponse.props.u4_pam_frame_type.dbValues[0];
                //CR1310
                if (ctx.selected && ctx.preferences.U4_PCR_Technology_Types &&
                    ctx.preferences.U4_PCR_Technology_Types.length > 0) {
                    var componentType = ctx.selected.props.object_type.uiValues[0];
                    if (ctx.preferences.U4_PCR_Technology_Types.includes(componentType) == true) {
                        if (data.matClass.dbValue == "Plastic" && data.matCommodity.dbValue.includes('Recycled') == true) {
                            data.pcrSaveAs.dbValue = true;
                            data.pcrSaveAs.uiValue = true;
                            data.pcrSaveAs.isEnabled = false;
                        } else {
                            data.pcrSaveAs.isEnabled = true;
                        }
                    }
                }

                data.componentType.dbValue = compRevResponse.props.u4_component_type.dbValues[0];
                data.designname.dbValue = compRevResponse.props.u4_design_name.dbValues[0];

                loadCompClassLov(data, conditions, ctx);
            }
        }
    } catch (error) {
        _messagingSvc.showError('Something went wrong in pack component save as action.');
        conditions.ChangeButtonEnability = true;
    }

    if (ctx.selected.type != 'U4_DDERevision' && ctx.selected.type != 'U4_CADComponentRevision') {
        data.parentUom = [];
        //setValueToLOv(data.baseUOM, projResponse.parentUom);

        //Added By Siemens for AutoSuggestion of UOM
        var inputData = {
            objects: [{
                uid: ctx.selected.uid,
                type: ctx.selected.type
            }],
            attributes: ['u4_component_class', 'u4_component_commodity', 'u4_material_class', 'U4_MatlClassRelation', 'u4_base_uom']
        };
        var response11 = await _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData);
        if (response11) {
            compRevProp = response11.modelObjects[response11.plain[0]];
            var currentCompClass = compRevProp.props.u4_component_class.dbValues;
            var currentCompCommodity = compRevProp.props.u4_component_commodity.dbValues;
            var currentMatClass = compRevProp.props.u4_material_class.dbValues;
            var currentMatCommodity = compRevProp.props.u4_material_commodity.dbValues;
            var currentbaseUOM = compRevProp.props.u4_base_uom.dbValues;
            var matForm = {
                uid: compRevProp.props.U4_MatlClassRelation.dbValues[0]
            };
            var inputDataDeliveredForm = {
                objects: [matForm],
                attributes: ['u4_delivered_form', 'u4_pam_frame_type', 'u4_component_class']
            };

            var responseDeliveredForm = await _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputDataDeliveredForm);
            matFormResponse = responseDeliveredForm.modelObjects[responseDeliveredForm.plain[0]];

            currentdeliForm = matFormResponse.props.u4_delivered_form.dbValues[0];
            currentpamFrameType = matFormResponse.props.u4_pam_frame_type.dbValues[0];
            currentpamComponentClass = matFormResponse.props.u4_component_class.dbValues[0];
        }

        //End of AUto Suggestion
    }
    if (ctx.selected.type === 'U4_DDERevision') {
        if (ctx.selected.props.u4_cu_pack_type != undefined) {
            var consumerUnitPackTypeSelected = ctx.selected.props.u4_cu_pack_type.dbValues[0];
        }

        data.consumerUnitPackTypeList = [];
        _lovUtil.getLOVvalue(ctx.selected.type, 'u4_cu_pack_type', 'lov_values', '', null, data.consumerUnitPackTypeList, function (serviceData) {
            if (consumerUnitPackTypeSelected && consumerUnitPackTypeSelected.length > 0) {
                setValueToLOv(data.consumerUnitPackType, consumerUnitPackTypeSelected);
                data.dispatch({ path: 'data.consumerUnitPackTypeList', value: data.consumerUnitPackTypeList });
                //data.dispatch({ path: 'data.consumerUnitPackType', value: consumerUnitPackTypeSelected });
            }
        });
    }

}

export let copyPAMOptionChanged = function (data, ctx, fromReveal) {
    var vCompTypes = [];
    vCompTypes.push({
        propDisplayValue: 'New Component - Rollout',
        propDisplayDescription: '',
        propInternalValue: 'New Component - Rollout'
    });
    vCompTypes.push({
        propDisplayValue: 'New Component - Unique',
        propDisplayDescription: '',
        propInternalValue: 'New Component - Unique'
    });
    vCompTypes.push({
        propDisplayValue: 'New Component - Stock',
        propDisplayDescription: '',
        propInternalValue: 'New Component - Stock'
    });
    vCompTypes.push({
        propDisplayValue: 'Resize',
        propDisplayDescription: '',
        propInternalValue: 'Resize'
    });
    vCompTypes.push({
        propDisplayValue: 'Material Change',
        propDisplayDescription: '',
        propInternalValue: 'Material Change'
    });
    _appCtxSvc.ctx.isPlasticFrame = false;
    if (ctx.selected && ctx.preferences.U4_PCR_Technology_Types &&
        ctx.preferences.U4_PCR_Technology_Types.length > 0) {
        var componentType = ctx.selected.props.object_type.uiValues[0];
        if (ctx.preferences.U4_PCR_Technology_Types.includes(componentType) == true) {
            _appCtxSvc.ctx.isPlasticFrame = true;
            vCompTypes.push({
                propDisplayValue: 'Post Consumer Recycle',
                propDisplayDescription: '',
                propInternalValue: 'Post Consumer Recycle'
            });
        }
    }

    if (fromReveal == true) {
        data.pcr_percentageSaveAs.dbValue = "";
        data.pcr_percentageSaveAs.uiValue = "";
        data.relateToPCR.dbValue = false;
        data.componentType.dbValue = vCompTypes[0].propDisplayValue;
        data.componentType.uiValue = vCompTypes[0].propDisplayValue;
    }
    data.compTypeLOV = vCompTypes;
};

var setValueToLOv = function (lovViewmodelObj, valueSetToLov) {
    if (valueSetToLov && valueSetToLov.length > 0) {
        lovViewmodelObj.dbValue = valueSetToLov;
        lovViewmodelObj.displayValues = [valueSetToLov];
        lovViewmodelObj.uiValue = valueSetToLov;
    }
}

export let loadCompClassLov = async function (data, conditions, ctx) {

    var vCompTypes = [];
    //set required text to required properties. Because, required text was missing even if property is set required in viewmodel.
    data.compClass.propertyRequiredText = requiredText;
    data.compCommodity.propertyRequiredText = requiredText;
    data.matClass.propertyRequiredText = requiredText;
    data.matCommodity.propertyRequiredText = requiredText;

    data.deliForm.propertyRequiredText = requiredText;
    data.pamFrameType.propertyRequiredText = requiredText;
    data.technology.propertyRequiredText = requiredText;
    data.reasonforissue.propertyRequiredText = requiredText;
    data.componentType.propertyRequiredText = requiredText;

    console.log("reveal action");
    console.log(data);

    if (ctx.gmcItemID == undefined) {
        _appCtxSvc.registerCtx('gmcItemID', ctx.selected.uid);
    } else {
        _appCtxSvc.updateCtx('gmcItemID', ctx.selected.uid);
    }

    var inputData = {
        lovName: 'U4_ComponentClassLOV'
    };
    var compClassResponse = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
    if (compClassResponse) {
        data.compClassLOVValues = convertDataToLov(compClassResponse.lovpair); // set new lov
        data.compClass.dbValue = compRevResponse.props.u4_component_class.dbValues[0]; // set to empty
        data.compClass.uiValue = compRevResponse.props.u4_component_class.dbValues[0];
        data.dispatch({ path: 'data.compClassLOVValues', value: data.compClassLOVValues });
        data.dispatch({ path: 'data.compClass', value: data.compClass });
        onCompClassSelected(data, conditions, data.compClass.dbValue);
        _commonUtil.logOnConsole('Component Class loaded.');
    }

    vCompTypes.push({
        propDisplayValue: 'New Component - Rollout',
        propDisplayDescription: '',
        propInternalValue: 'New Component - Rollout'
    });
    vCompTypes.push({
        propDisplayValue: 'New Component - Unique',
        propDisplayDescription: '',
        propInternalValue: 'New Component - Unique'
    });
    vCompTypes.push({
        propDisplayValue: 'New Component - Stock',
        propDisplayDescription: '',
        propInternalValue: 'New Component - Stock'
    });
    vCompTypes.push({
        propDisplayValue: 'Resize',
        propDisplayDescription: '',
        propInternalValue: 'Resize'
    });
    vCompTypes.push({
        propDisplayValue: 'Material Change',
        propDisplayDescription: '',
        propInternalValue: 'Material Change'
    });
    _appCtxSvc.ctx.isPlasticFrame = false;
    if (ctx.selected && ctx.preferences.U4_PCR_Technology_Types &&
        ctx.preferences.U4_PCR_Technology_Types.length > 0) {
        var componentType = ctx.selected.props.object_type.uiValues[0];
        if (ctx.preferences.U4_PCR_Technology_Types.includes(componentType) == true) {
            _appCtxSvc.ctx.isPlasticFrame = true;
            vCompTypes.push({
                propDisplayValue: 'Post Consumer Recycle',
                propDisplayDescription: '',
                propInternalValue: 'Post Consumer Recycle'
            });
        }
    }

    data.compTypeLOV = vCompTypes;
    data.componentType.dbValue = "New Component - Rollout";
    data.componentType.uiValue = "New Component - Rollout";
    data.dispatch({ path: 'data.compTypeLOV', value: data.compTypeLOV });
    data.dispatch({ path: 'data.componentType', value: data.componentType });
    /* Fetching Base UOM LOV value */

    data.baseUOMLOVValues = [];
};

export let convertDataToLov = function (lovPair) {

    var lov = [];
    lov.push({
        propDisplayValue: '',
        propDisplayDescription: '',
        propInternalValue: '',
        propDep: ''
    });
    for (var i in lovPair) {
        // sub LOV will be stored in 'propDep' property
        var val__ = lovPair[i].lovvalue;
        var subLov__ = lovPair[i].lovname;
        lov.push({
            propDisplayValue: val__,
            propDisplayDescription: '',
            propInternalValue: val__,
            propDep: subLov__
        });

    }

    return lov;
};

export let getDisplayValue = function (lovPair) {
    var lov = [];
    for (var i in lovPair) {
        var val__ = lovPair[i].lovvalue;
        lov.push(val__);
    }
    return lov;
};

export let onCompClassSelected = async function (data, conditions, selValue) {
    console.log("Enter component class");

    var inputData = {
        selectedRev: {
            uid: _appCtxSvc.ctx.selected.uid,
            type: _appCtxSvc.ctx.selected.type
        }
    };

    var gmcResponse = await _soaSvc.post('AWC-2016-10-SaveAS', 'saveAsPreCheck', inputData);
    // _commonUtil.logOnConsole(response);
    if (gmcResponse) {
        console.log("SavePrecheck : ", gmcResponse.preCheckBoolVal, gmcResponse.precheckInt);
        _appCtxSvc.ctx.isWrongGMC = (gmcResponse.precheckInt == 5) ? true : false;
    }

    var currentCompClass;

    if (selValue && selValue.length > 0)
        currentCompClass = selValue;
    else
        currentCompClass = data.compClass.dbValue;

    if (currentCompClass && currentCompClass.length > 0) {
        var lovName1 = getLOVValueIn(currentCompClass, data.compClassLOVValues);

        if (lovName1) {
            //get comp commodity lov
            var inputData = {
                lovName: lovName1
            };
            var commResponse = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
            if (commResponse) {
                data.compCommodityLOVValues = convertDataToLov(commResponse.lovpair); // set new lov
                data.compCommodity.dbValue = compRevResponse.props.u4_component_commodity.dbValues[0]; // set to empty
                data.compCommodity.uiValue = compRevResponse.props.u4_component_commodity.dbValues[0]; // set to empty
                onCompCommoditySelected(data, conditions, data.compCommodity.dbValue, data.compClass.dbValue);
                data.dispatch({ path: 'data.compCommodityLOVValues', value: data.compCommodityLOVValues });
                var listVal = getDisplayValue(commResponse.lovpair);
                var isDiffVal = listVal.some(r => data.compCommodity.dbValue.indexOf(r) >= 0);
                if (_appCtxSvc.ctx.isWrongGMC && isDiffVal) {
                    data.dispatch({ path: 'data.compCommodity', value: data.compCommodity });
                } else if (!_appCtxSvc.ctx.isWrongGMC && isDiffVal) {
                    data.dispatch({ path: 'data.compCommodity', value: data.compCommodity });
                } else {
                    const newCompCommVal = _.clone(data.compCommodity);
                    newCompCommVal.uiValue = "";
                    newCompCommVal.dbValue = "";
                    data.dispatch({ path: 'data.compCommodity', value: newCompCommVal });
                }
                _commonUtil.logOnConsole('Component Commodity updated.');
            }
        }

    } else {

        var compclassStr = '\"Component Class\".';

        //set respective empty lov text
        data.compCommodity.lovNoValsText = noLOVtext + compclassStr;
        data.matClass.lovNoValsText = noLOVtext + compclassStr;
        data.matCommodity.lovNoValsText = noLOVtext + compclassStr;

        data.deliForm.lovNoValsText = noLOVtext + compclassStr;
        data.pamFrameType.lovNoValsText = noLOVtext + compclassStr;
        data.technology.lovNoValsText = noLOVtext + compclassStr;

        // reset dependent properties
        setEmptyValue(data, data.compCommodity, "data.compCommodity");
        data.dispatch({ path: 'data.compCommodityLOVValues', value: undefined });
    }

    console.log("End of the component class");
    // // reset dependent properties
    setEmptyValue(data, data.matClass, "data.matClass");
    setEmptyValue(data, data.matCommodity, "data.matCommodity");
    setEmptyValue(data, data.deliForm, "data.deliForm");
    setEmptyValue(data, data.pamFrameType, "data.pamFrameType");
    setEmptyValue(data, data.technology, "data.technology");

    data.dispatch({ path: 'data.matClassLOVValues', value: undefined });
    data.dispatch({ path: 'data.matCommodityLOVValues', value: undefined });
    data.dispatch({ path: 'data.deliFormLOVValues', value: undefined });
    data.dispatch({ path: 'data.pamFrameTypeLOVValues', value: undefined });
    data.dispatch({ path: 'data.technologyLOVValues', value: undefined });
};

export let resetLov = function (data, propString) {

    data[(propString + 'LOVValues')] = [''];
    data[propString].dbValue = '';
    data[propString].displayValues = undefined;
    data[propString].uiValue = undefined;
    data[(propString + 'LOVValues')] = [];

    return;
};


export let getLOVValueIn = function (selected, lovArray) {
    for (var i in lovArray) {
        if (lovArray[i].propDisplayValue === selected) {
            return lovArray[i].propDep;
        }
    }
};

export let baseUOMCustomization = function (data, ctx, selValue) {
    console.log("Inside baseUOMCustomization");
    var currentCompClass;

    currentCompClass = data.compClass.dbValue;
    console.log("CurrentCompClass\n", currentCompClass);
    if (currentCompClass === "Flexible Packaging") {
        var curBUOMVal = ctx.mselected[0].props.u4_base_uom.dbValues[0];
        console.log("curBUOMVal\n", curBUOMVal);
        data.parentUom.push({
            propDisplayValue: curBUOMVal,
            propInternalValue: curBUOMVal,
            propDisplayDescription: ""
        });

        data.dispatch({ path: 'data.parentUom', value: data.parentUom });
    }
};

export let onCompCommoditySelected = async function (data, conditions, selValue, compClassValue) {

    var currentCompCommodity;
    var currentCompClass;

    if (selValue && selValue.length > 0)
        currentCompCommodity = selValue;
    else
        currentCompCommodity = data.compCommodity.dbValue;
    if (compClassValue && compClassValue.length > 0)
        currentCompClass = compClassValue;
    else
        currentCompClass = data.compClass.dbValue;

    if (currentCompCommodity && currentCompCommodity.length > 0) {
        var lovName1 = getLOVValueIn(currentCompCommodity, data.compCommodityLOVValues);
        if (lovName1) {
            var inputData = {
                lovName: lovName1
            };
            var matClassResponse = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
            if (matClassResponse) {
                data.matClassLOVValues = convertDataToLov(matClassResponse.lovpair); // set new lov
                data.matClass.dbValue = matFormResponse.props.u4_material_class.dbValues[0]; // set to empty
                data.matClass.uiValue = matFormResponse.props.u4_material_class.dbValues[0]; // set to empty
                onmatClassSelected(data, conditions, data.matClass.dbValue)
                data.dispatch({ path: 'data.matClassLOVValues', value: data.matClassLOVValues });
                var matListVal = getDisplayValue(matClassResponse.lovpair);
                var isDiffMatVal = matListVal.some(r => data.matClass.dbValue.indexOf(r) >= 0);
                if (_appCtxSvc.ctx.isWrongGMC && isDiffMatVal) {
                    data.dispatch({ path: 'data.matClass', value: data.matClass });
                } else if (!_appCtxSvc.ctx.isWrongGMC && isDiffMatVal) {
                    data.dispatch({ path: 'data.matClass', value: data.matClass });
                } else {
                    const newMatClassVal = _.clone(data.matClass);
                    newMatClassVal.uiValue = "";
                    newMatClassVal.dbValue = "";
                    data.dispatch({ path: 'data.matClass', value: newMatClassVal });
                }
                _commonUtil.logOnConsole('Material Class updated.');

            }
        }

    } else {

        var compCommStr = '\"Component Commodity\".';

        setEmptyValue(data, data.matClass, "data.matClass");
        data.dispatch({ path: 'data.matClassLOVValues', value: undefined });

        data.matClass.lovNoValsText = noLOVtext + compCommStr;
        data.matCommodity.lovNoValsText = noLOVtext + compCommStr;
        data.deliForm.lovNoValsText = noLOVtext + compCommStr;
        data.pamFrameType.lovNoValsText = noLOVtext + compCommStr;
        data.technology.lovNoValsText = noLOVtext + compCommStr;

    }

    setEmptyValue(data, data.matCommodity, "data.matCommodity");
    setEmptyValue(data, data.deliForm, 'data.deliForm');
    setEmptyValue(data, data.pamFrameType, 'data.pamFrameType');
    setEmptyValue(data, data.technology, 'data.technology');
    data.dispatch({ path: 'data.matCommodityLOVValues', value: undefined });
    data.dispatch({ path: 'data.deliFormLOVValues', value: undefined });
    data.dispatch({ path: 'data.pamFrameTypeLOVValues', value: undefined });
    data.dispatch({ path: 'data.technologyLOVValues', value: undefined });
};

export let onmatClassSelected = async function (data, conditions, selValue) {
    console.log("Material class Beginning*****************");
    var currentmatClass;

    if (selValue && selValue.length > 0)
        currentmatClass = selValue;
    else
        currentmatClass = data.matClass.dbValue;

    if (currentmatClass && currentmatClass.length > 0) {
        var lovName1 = getLOVValueIn(currentmatClass, data.matClassLOVValues);

        if (lovName1) {
            var inputData = {
                lovName: lovName1
            };
            var matResponse = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
            if (matResponse) {
                data.matCommodityLOVValues = convertDataToLov(matResponse.lovpair); // set new lov
                console.log("Inside Material class   ", _appCtxSvc.ctx.mselected[0].props);
                data.matCommodity.dbValue = _appCtxSvc.ctx.mselected[0].props.u4_material_commodity.dbValues[0]; // set to empty
                data.matCommodity.uiValue = _appCtxSvc.ctx.mselected[0].props.u4_material_commodity.dbValues[0]; // set to empty
                onmatCommoditySelected(data, conditions, data.matCommodity.dbValue, data.matClass.dbValue, data.compClass.dbValue, data.compCommodity.dbValue);
                data.dispatch({ path: 'data.matCommodityLOVValues', value: data.matCommodityLOVValues });
                var matComListVal = getDisplayValue(matResponse.lovpair);
                var isDiffMatComVal = matComListVal.some(r => data.matCommodity.dbValue.indexOf(r) >= 0);

                if (_appCtxSvc.ctx.isWrongGMC && isDiffMatComVal) {
                    data.dispatch({ path: 'data.matCommodity', value: data.matCommodity });
                } else if (!_appCtxSvc.ctx.isWrongGMC && isDiffMatComVal) {
                    data.dispatch({ path: 'data.matCommodity', value: data.matCommodity });
                } else {
                    const newMatCommVal = _.clone(data.matCommodity);
                    newMatCommVal.uiValue = "";
                    newMatCommVal.dbValue = "";
                    data.dispatch({ path: 'data.matCommodity', value: newMatCommVal });
                }
                _commonUtil.logOnConsole('Material Commodity updated.');
            }
        }

    } else {

        var matClassStr = '\"Material Class\".';
        setEmptyValue(data, data.matCommodity, 'data.matCommodity');
        data.dispatch({ path: 'data.matCommodityLOVValues', value: undefined });
        data.matCommodity.lovNoValsText = noLOVtext + matClassStr;
        data.deliForm.lovNoValsText = noLOVtext + matClassStr;
        data.pamFrameType.lovNoValsText = noLOVtext + matClassStr;
        data.technology.lovNoValsText = noLOVtext + matClassStr;

    }

    setEmptyValue(data, data.deliForm, 'data.deliForm');
    setEmptyValue(data, data.pamFrameType, 'data.pamFrameType');
    setEmptyValue(data, data.technology, 'data.technology');
    data.dispatch({ path: 'data.deliFormLOVValues', value: undefined });
    data.dispatch({ path: 'data.pamFrameTypeLOVValues', value: undefined });
    data.dispatch({ path: 'data.technologyLOVValues', value: undefined });

    console.log("Material commodity lovs: ", data.matCommodityLOVValues);
    console.log("Material class end **************");

};

export let onmatCommoditySelected = async function (data, conditions, selValue, matclassValue, compclassValue, compCommodityValue) {
    console.log("Common ctx :", _appCtxSvc.ctx);
    clearPCRPercentage(data, _appCtxSvc.ctx);
    //CR1310
    if (_appCtxSvc.ctx.selected && _appCtxSvc.ctx.preferences.U4_PCR_Technology_Types && _appCtxSvc.ctx.preferences.U4_PCR_Technology_Types.length > 0) {
        var componentType = _appCtxSvc.ctx.selected.props.object_type.uiValues[0];
        if (_appCtxSvc.ctx.preferences.U4_PCR_Technology_Types.includes(componentType) == true) {
            if (data.matClass.dbValue == "Plastic" && data.matCommodity.dbValue.includes('Recycled') == true) {
                data.pcrSaveAs.dbValue = true;
                data.pcrSaveAs.uiValue = true;
                data.pcrSaveAs.isEnabled = false;
            } else {
                data.pcrSaveAs.isEnabled = true;
            }
        }
    }
    var currentmatCommodity;
    var currentmatClass = data.matClass.dbValue;
    var currentCompCommodity = data.compCommodity.dbValue;
    var currentCompClass = data.compClass.dbValue;
    if (selValue && selValue.length > 0)
        currentmatCommodity = selValue;
    else
        currentmatCommodity = data.matCommodity.dbValue;

    if (currentmatCommodity && currentmatCommodity.length > 0) {

        var lovName1 = getLOVValueIn(currentmatCommodity, data.matCommodityLOVValues);

        if (lovName1) {
            var inputData = {
                lovName: lovName1
            };
            var deliResponse = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
            if (deliResponse) {
                data.deliFormLOVValues = convertDataToLov(deliResponse.lovpair); // set new lov
                data.deliForm.dbValue = matFormResponse.props.u4_delivered_form.dbValues[0]; // set to empty
                data.deliForm.uiValue = matFormResponse.props.u4_delivered_form.dbValues[0]; // set to empty
                ondeliFormSelected(data, data.deliForm.dbValue);
                data.dispatch({ path: 'data.deliFormLOVValues', value: data.deliFormLOVValues });
                var deliListVal = getDisplayValue(deliResponse.lovpair);
                var isDiffDeliComVal = deliListVal.some(r => data.deliForm.dbValue.indexOf(r) >= 0);
                /*if (_appCtxSvc.ctx.isWrongGMC && isDiffDeliComVal) {
                    data.dispatch({ path: 'data.deliForm', value: data.deliForm });
                } else if (!_appCtxSvc.ctx.isWrongGMC && isDiffDeliComVal) {
                    data.dispatch({ path: 'data.deliForm', value: data.deliForm });
                }
				*/
				if (!_appCtxSvc.ctx.isWrongGMC && isDiffDeliComVal) {
                    data.dispatch({ path: 'data.deliForm', value: data.deliForm });
                }
                else {
                    const newDeliVal = _.clone(data.deliForm);
                    newDeliVal.uiValue = "";
                    newDeliVal.dbValue = "";
                    data.dispatch({ path: 'data.deliForm', value: newDeliVal });
                }
                _commonUtil.logOnConsole('Delivered Form updated.');
	
            }

        }
    } else {
        var matCommStr = '\"Material Commodity\".';
        setEmptyValue(data, data.deliForm, 'data.deliForm');
        data.dispatch({ path: 'data.deliFormLOVValues', value: undefined });
        data.deliForm.lovNoValsText = noLOVtext + matCommStr;
        data.pamFrameType.lovNoValsText = noLOVtext + matCommStr;
        data.technology.lovNoValsText = noLOVtext + matCommStr;
    }

    setEmptyValue(data, data.pamFrameType, "data.pamFrameType");
    setEmptyValue(data, data.technology, "data.technology");
    data.dispatch({ path: 'data.pamFrameTypeLOVValues', value: undefined });
    data.dispatch({ path: 'data.technologyLOVValues', value: undefined });
};

var setEmptyValue = function (data, vmo, propname) {
    var vmoClone = _.clone(vmo);
    vmoClone.dbValue = "";
    vmoClone.uiValue = "";
    data.dispatch({ path: propname, value: vmoClone });
}

export let clearPCRPercentage = function (data, ctx) {
    data.pcr_percentageSaveAs.propertyRequiredText = "";

    data.pcr_percentageSaveAs.uiValue = "";
    data.pcr_percentageSaveAs.dbValue = "";

    var currentmatClass = ctx.selected.props.u4_material_class.dbValues[0];

    if (data.pcrSaveAs.dbValue == true && currentmatClass == "Plastic") {
        if (data.matCommodity.dbValue.includes('Recycled') == true) {
            data.pcr_percentageSaveAs.propertyRequiredText = "Enter Only 100";
            data.pcr_percentageSaveAs.uiValue = "100";
            data.pcr_percentageSaveAs.dbValue = "100";
        }
        else {
            data.pcr_percentageSaveAs.propertyRequiredText = "100 is not allowed";
        }
    }
}

export let ondeliFormSelected = async function (data, selValue) {
    var currentdeliForm;
    var currentCompClass = data.compClass.dbValue;
    var currentCompCommodity = data.compCommodity.dbValue;
    var currentmatClass = data.matClass.dbValue;
    var currentmatCommodity = data.matCommodity.dbValue;
    if (selValue && selValue.length > 0)
        currentdeliForm = selValue;
    else
        currentdeliForm = data.deliForm.dbValue;

    if (currentdeliForm && currentdeliForm.length > 0 && currentdeliForm != ' ') {

        var lovName1 = getLOVValueIn(currentdeliForm, data.deliFormLOVValues);
        if (lovName1) {
            var inputData = {
                lovName: lovName1
            };
            var pamResponse = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
            if (pamResponse) {
                data.pamFrameTypeLOVValues = convertDataToLov(pamResponse.lovpair); // set new lov
                data.pamFrameType.dbValue = matFormResponse.props.u4_pam_frame_type.dbValues[0]; // set to empty
                data.pamFrameType.uiValue = matFormResponse.props.u4_pam_frame_type.dbValues[0]; // set to empty
                data.dispatch({ path: 'data.pamFrameTypeLOVValues', value: data.pamFrameTypeLOVValues });
                //data.dispatch({ path: 'data.pamFrameType', value: data.pamFrameType });
                //onpamFrameTypeSelected(data, data.pamFrameType.dbValue);
                //_commonUtil.logOnConsole('PAM Frame Type updated.');
				var flag=0;
				for (var i in data.pamFrameTypeLOVValues)
				{
					var Lovvalue = data.pamFrameTypeLOVValues[i].propDisplayValue;
					if(Lovvalue == data.pamFrameType.uiValue)
					{
						console.log('Inside pamFrameType values checking.');
						flag=flag+1;
						console.log(flag);
						break;
					}
				}
				if (flag == 1)
				{
					console.log('flag 1');			
					data.dispatch({ path: 'data.pamFrameType', value: data.pamFrameType });
					onpamFrameTypeSelected(data, data.pamFrameType.dbValue);
					_commonUtil.logOnConsole('PAM Frame Type updated.');
				}
				else
				{
					console.log('PAM Frame Type is not available');	
				
				}
				
            }

            data.parentUom = [];
            var lExistingUOM = false;

            var uombasedonClassNCom = 'U4_UOM_basedon_Class_Commodity';
            if (_commonUtil.isPreferencesAvailable([uombasedonClassNCom], true)) {
                var base_uom_values = _appCtxSvc.ctx.preferences[uombasedonClassNCom];
                if (base_uom_values) {
                    for (var inx = 0; inx < base_uom_values.length; inx++) {
                        var str = base_uom_values[inx].trim().split(':');
                        if (str.length == 2)
                            var classncomValues = str[0].trim().split('#');
                        data.newbaseUOMLOVValues = [];
                        if (classncomValues.length == 3) {
                            if (currentCompClass == classncomValues[0] & currentCompCommodity == classncomValues[1] & currentdeliForm == classncomValues[2]) {

                                var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                data.parentUom.push({
                                    propDisplayValue: onlyUOM[0],
                                    propInternalValue: onlyUOM[0],
                                    propDisplayDescription: propDesc[1]
                                });
                                if (onlyUOM[0] === _appCtxSvc.ctx.mselected[0].props.u4_base_uom.dbValues[0]) {
                                    lExistingUOM = true;
                                }
                            }
                        } else if (classncomValues.length == 2) {
                            if (currentCompClass == classncomValues[0] & currentCompCommodity == classncomValues[1]) {

                                var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);

                                data.parentUom.push({
                                    propDisplayValue: onlyUOM[0],
                                    propInternalValue: onlyUOM[0],
                                    propDisplayDescription: propDesc[1]
                                });
                                if (onlyUOM[0] === _appCtxSvc.ctx.mselected[0].props.u4_base_uom.dbValues[0]) {
                                    lExistingUOM = true;
                                }
                            }
                            if (currentCompClass == classncomValues[0] & currentdeliForm == classncomValues[1]) {

                                var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                data.parentUom.push({
                                    propDisplayValue: onlyUOM[0],
                                    propInternalValue: onlyUOM[0],
                                    propDisplayDescription: propDesc[1]
                                });
                                if (onlyUOM[0] === _appCtxSvc.ctx.mselected[0].props.u4_base_uom.dbValues[0]) {
                                    lExistingUOM = true;
                                }
                            }

                        } else if (classncomValues.length == 4) {
                            if (currentCompClass == classncomValues[0] & currentCompCommodity == classncomValues[1] & currentmatClass == classncomValues[2] & currentmatCommodity == classncomValues[3]) {

                                var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                data.parentUom.push({
                                    propDisplayValue: onlyUOM[0],
                                    propInternalValue: onlyUOM[0],
                                    propDisplayDescription: propDesc[1]
                                });
                                if (onlyUOM[0] === _appCtxSvc.ctx.mselected[0].props.u4_base_uom.dbValues[0]) {
                                    lExistingUOM = true;
                                }
                            }
                        } else if (classncomValues.length == 1) {
                            if (currentCompClass == str[0]) {

                                var propDesc = base_uom_values[inx].trim().match(/\((.*)\)/);
                                var onlyUOM = str[1].match(/([A-Z0-9]+)(?=\()/g);
                                data.parentUom.push({
                                    propDisplayValue: onlyUOM[0],
                                    propInternalValue: onlyUOM[0],
                                    propDisplayDescription: propDesc[1]
                                });
                                if (onlyUOM[0] === _appCtxSvc.ctx.mselected[0].props.u4_base_uom.dbValues[0]) {
                                    lExistingUOM = true;
                                }
                            }

                        }
                    }

                    data.dispatch({ path: 'data.parentUom', value: data.parentUom });
                    data.dispatch({ path: 'data.baseUOM', value: data.baseUOM });
                    if (!lExistingUOM) {
                        baseUOMCustomization(data, _appCtxSvc.ctx, selValue);
                    }
                    console.log("On DF selection    :   ", data.parentUom);
                    if (data.parentUom.length === 0) {
                        data.baseUOMLOVValues = [];
                        _lovUtil.getLOVvalue('U4_ComponentRevision', 'u4_base_uom', 'lov_values', 'lov_value_descriptions', null, data.baseUOMLOVValues, function (serviceData) {

                            console.log('Type of change loaded');
                            data.parentUom = Array.from(data.baseUOMLOVValues);
                            data.dispatch({ path: 'data.parentUom', value: data.parentUom });
                        });
                    }

                }
            }
        }

    } else {
        var delFormStr = '\"Delivered Form\".';
        setEmptyValue(data, data.pamFrameType, 'data.pamFrameType');
        data.dispatch({ path: 'data.pamFrameTypeLOVValues', value: undefined });
        data.pamFrameType.lovNoValsText = noLOVtext + delFormStr;
        data.technology.lovNoValsText = noLOVtext + delFormStr;
    }
    setEmptyValue(data, data.technology, 'data.technology');
    data.dispatch({ path: 'data.technologyLOVValues', value: undefined });
};

export let onpamFrameTypeSelected = async function (data, selValue) {
    clearPCRPercentage(data, _appCtxSvc.ctx);

    var currentpamFrameType;
    var currentpamComponentClass;

    if (selValue && selValue.length > 0) {
        currentpamFrameType = selValue;
        currentpamComponentClass = selValue;
    }
    else {
        currentpamFrameType = data.pamFrameType.dbValue;
        currentpamComponentClass = data.compClass.dbValue;
    }

    if (currentpamFrameType && currentpamFrameType.length > 0) {

        var lovName1 = getLOVValueIn(currentpamFrameType, data.pamFrameTypeLOVValues);

        if (lovName1) {

            var inputData = {
                lovName: lovName1
            };
            var techResponse = await _soaSvc.post('AWC-2016-10-Create', 'getLovNSubLov', inputData);
            if (techResponse) {
                data.technologyLOVValues = convertDataToLov(techResponse.lovpair); // set new lov
                data.technology.dbValue = compRevResponse.props.u4_technology.dbValues[0]; // set to empty
                data.technology.uiValue = compRevResponse.props.u4_technology.dbValues[0]; // set to empty
                data.dispatch({ path: 'data.technologyLOVValues', value: data.technologyLOVValues });
                //data.dispatch({ path: 'data.technology', value: data.technology });
                //consumerunitTypeUpdate(data, _appCtxSvc.ctx, data.technology.dbValue);
                //_commonUtil.logOnConsole('Technology updated.');
				var flag=0;
				for (var i in data.technologyLOVValues)
				{
					var Lovvalue = data.technologyLOVValues[i].propDisplayValue;
					if(Lovvalue == data.technology.uiValue)
					{
						console.log('Inside Technology values checking.');
						flag=flag+1;
						console.log(flag);
						break;
						
					}
				}
				if (flag == 1)
				{
					console.log('flag 1');			
					data.dispatch({ path: 'data.technology', value: data.technology });//need to compare 
					consumerunitTypeUpdate(data, _appCtxSvc.ctx, data.technology.dbValue);
					_commonUtil.logOnConsole('Technology updated.');
				}
				else
				{
					console.log('Techonology is not available');	
				}
				
            }
        }
    } else {
        var pamframeStr = '\"PAM Frame Type\".';
        setEmptyValue(data, data.technology, "data.technology");
        data.dispatch({ path: 'data.technologyLOVValues', value: undefined });
        data.technology.lovNoValsText = noLOVtext + pamframeStr;
    }
};

export let consumerunitTypeUpdate = function (data, ctx, selValue) {
    if (selValue && selValue.length > 0) {
        if (compRevResponse.props.u4_consumer_unit.dbValues[0] == 1)
            data.consumerUnitPackType.dbValue = true;
        else
            data.consumerUnitPackType.dbValue = false;

    } else {
        var prefValue = [];
        var prefValue2 = [];
        var story = [];
        var frame = data.pamFrameType.dbValue;
        var technoValue = data.technology.dbValue;

        if (technoValue && technoValue.length > 0) {
            var prefName1 = 'U4_GMCPackComponentType';
            var prefName2 = 'UL_primary_packaging_list';

            prefValue = ctx.preferences[prefName1];
            prefValue2 = ctx.preferences[prefName2];

            if (_commonUtil.isPreferencesAvailable([prefName1, prefName2], true)) {
                for (var i = 0; i < prefValue.length; i++) {
                    story = prefValue[i].split('#');

                    if ((story.length == 3) && (story[0] == frame) && (story[1] == technoValue)) {
                        var techFromPref = story[2];
                    }
                }
                let found = false;
                for (var j = 0; j < prefValue2.length; j++) {
                    if (prefValue2[j] == techFromPref) {
                        found = true;
                        break;
                    }

                }
                data.consumerUnitPackType.dbValue = found;
            }
        }
        showTechnologyInfo(technoValue);
    }
    return;
}

var showTechnologyInfo = function (technoValue) {
    if (technoValue) {
        var techDescPref = 'U4_TechnologyDescription';
        if (technology_description == undefined && _commonUtil.isPreferencesAvailable([techDescPref], true)) {
            technology_description = {};
            var technology_description_values = _appCtxSvc.ctx.preferences[techDescPref];
            if (technology_description_values) {
                for (var inx = 0; inx < technology_description_values.length; inx++) {
                    var str = technology_description_values[inx].trim().split('#');
                    if (str.length == 2)
                        technology_description[str[0]] = str[1];
                }
            }
        }
        if (technology_description && technology_description[technoValue] != undefined) {
            var desc_value = technology_description[technoValue];
            if (desc_value)
                _messagingSvc.showError(desc_value);
        }
    }
}

export let onComponentTypeSelection = function (data, ctx) {
    data.pcr_percentageSaveAs.propertyRequiredText = "";

    if (ctx.selected && ctx.preferences.U4_PCR_Technology_Types &&
        ctx.preferences.U4_PCR_Technology_Types.length > 0) {
        var componentType = ctx.selected.props.object_type.uiValues[0];
        if (ctx.preferences.U4_PCR_Technology_Types.includes(componentType) == true) {
            var currentmatClass = ctx.selected.props.u4_material_class.dbValues[0];
            if (currentmatClass == "Plastic") {
                if (data.matCommodity.dbValue.includes('Recycled') == true) {
                    data.pcr_percentageSaveAs.propertyRequiredText = "Enter Only 100";
                    data.pcr_percentageSaveAs.dbValue = "100";
                    data.pcr_percentageSaveAs.uiValue = "100";
                } else {
                    if (data.pcr_percentageSaveAs.dbValue === "100") {
                        data.pcr_percentageSaveAs.dbValue = "";
                        data.pcr_percentageSaveAs.uiValue = "";
                    }
                    data.pcr_percentageSaveAs.propertyRequiredText = "100 is not allowed";
                }
            }
            if (data.componentType.dbValue == "Post Consumer Recycle") {
                data.copyPAMSpec.dbValue = true;
                data.relateToPCR.dbValue = true;
                data.dataProviders.datasetsProvider.selectAll();
            }
        }
        else {
            data.pcr_percentageSaveAs.propertyRequiredText = "";
            data.pcr_percentageSaveAs.dbValue = "";
            data.pcr_percentageSaveAs.uiValue = "";
            data.pcrSaveAs.dbValue = false;
        }
    }
};

export let assemblyRetainCloneSwitch = function (param, data) {
    if (param == 'retain') {
        data.assemblyClone.dbValue = !data.assemblyRetain.dbValue;
    }
    if (param == 'clone') {
        data.assemblyRetain.dbValue = !data.assemblyClone.dbValue;
    }
}

export let getDatasetsInfo = async function (ctx, data) {
    // collect datasets information
    var dsRelsToCopy = {
        inputs: [{
            clientId: 'clientId',
            obj: {
                uid: ctx.selected.uid,
                type: ctx.selected.type
            },
            propertyNames: ['IMAN_specification', 'IMAN_Rendering']
        }]
    };
    // soa call to get datasets
    var childDatasets = [];
    var datasetResponse = await _soaSvc.post('Core-2013-05-DataManagement', 'getChildren', dsRelsToCopy);
    if (datasetResponse && datasetResponse.objectWithChildren.length > 0) {
        var childDsLevel1 = [];
        var childDsLevel2 = [];
        for (var inx = 1; inx < datasetResponse.objectWithChildren.length; inx++) {

            for (var inx2 = 0; inx2 < datasetResponse.objectWithChildren[inx].length; inx2++) {
                childDsLevel1 = datasetResponse.objectWithChildren[inx];
                for (var inx3 = 0; inx3 < childDsLevel1.length; inx3++) {
                    childDsLevel2 = childDsLevel1[inx3];
                    if (childDsLevel2) {
                        for (var inx4 = 0; inx4 < childDsLevel2.length; inx4++) {
                            if (childDsLevel2[inx4].children) {
                                for (var inx5 = 0; inx5 < childDsLevel2[inx4].children.length; inx5++) {
                                    childDatasets.push(childDsLevel2[inx4].children[inx5]);
                                }
                            }
                        }
                    }
                }
            }
            ++inx;
        }
        data.datasetsResults = childDatasets;
    }
};

export let setMaterialFormPropList = function (ctx, data) {
    if (ctx.selected.type !== 'U4_DDERevision' && ctx.selected.type !== 'U4_CADComponentRevision') {
        matFormResponse = data.responseDeliveredForm.modelObjects[data.responseDeliveredForm.plain[0]];
        currentdeliForm = matFormResponse.props.u4_delivered_form.dbValues[0];
        currentpamFrameType = matFormResponse.props.u4_pam_frame_type.dbValues[0];
        _appCtxSvc.registerCtx("currentdeliForm", currentdeliForm);
        _appCtxSvc.registerCtx("currentpamFrameType", currentpamFrameType);
    }
}

export let saveAsAction = function (ctx, data, conditions) {
    try {
        //   Disables save button once it is clicked. Not allowing user to click again.
        data.disableSaveAsCreate = true;
        if (conditions.isDesignNameVisible === false) {
            data.designname.dbValue = "";
        }


        var designNameVal = data.designname.dbValue === null ? "" : data.designname.dbValue;
        var outputUOMinfo = getChildrenUomselection(data, conditions);

        // Update selected datasets if applicable
        var updatedSelectedDatasets = verifyAndUpdateSelectedDatasets(data);

        // If array of String convert to a string
        if (Array.isArray(data.baseUOM.dbValue)) {
            data.baseUOM.dbValue = data.baseUOM.dbValue[0];
        }
        var pdfPrintValue = "";
        if (conditions.isPDFPrintSectionVisible1 == true) {
            pdfPrintValue = data.pdf_print.dbValue == true ? "Yes" : "No";
        } else {
            pdfPrintValue = "";
        }
        var pcrValue = "";
        if (data.pcrSaveAs.dbValue == true || data.componentType.dbValue === 'Post Consumer Recycle') {
            pcrValue = data.pcr_percentageSaveAs.dbValue != "" ? data.pcr_percentageSaveAs.dbValue : "";
        }

        if (data.pcrSaveAs.dbValue == false && data.componentType.dbValue !== 'Post Consumer Recycle') {
            var pcrValue = "0";
        }

        var inputData = {
            'description': data.description.dbValue,
            'projectName': data.projectName.dbValue,
            'baseUOM': data.baseUOM.dbValue,
            'copyPAMSpec': data.copyPAMSpec.dbValue,
            //'datasetsToSaveAs': data.dataProviders.datasetsProvider.selectedObjects,
            'datasetsToSaveAs': updatedSelectedDatasets,
            'assySaveAsOpt': data.assemblyClone.dbValue,
            'selectedRev': {
                'uid': ctx.selected.uid,
                'type': ctx.selected.type
            },
            'uomInfo': outputUOMinfo,
            'matCommNewValue': data.matCommodity.dbValue,
            'consumerUnitPackTypeNewSelectedNewValue': data.consumerUnitPackType.dbValue.toString(),
            'oneUppComponentType': data.componentType.dbValue,
            'pcrPercentage': pcrValue,
            'inputProps': {
                'inputProps': {
                    'u4_is_printed_material': pdfPrintValue,
                    'u4_component_commodity': data.compCommodity.dbValue,
                    'u4_material_class': data.matClass.dbValue,
                    'u4_delivered_form': data.deliForm.dbValue,
                    'designnameUpp': designNameVal,
                    'u4_reason_for_issue': data.reasonforissue.dbValue
                }
            }
        };
        if (conditions.isRelateToPCRVisible == true && data.relateToPCR.dbValue == true) {
            inputData.oneUppComponentType += "#CreateVirginPCRLink";
        }
        var willOpenOnCreate = data.OpenOnCreate.dbValue;
        var willCopyToClipboard = data.CopyToClipboard.dbValue;
        _commonUtil.logOnConsole('\nProperties of new item : ' + JSON.stringify(inputData));
        _soaSvc.post('AWC-2016-10-SaveAS', 'saveAsOp3', inputData).then(function (response) {

            try {
                var newItemObj = response.modelObjects[response.created[0]];
                data.newCreatedItemName = newItemObj.props.object_string.dbValues[0];
                if (newItemObj.type == 'U4_DDE') {
                    _messagingSvc.reportNotyMessage(data, data._internal.messages, 'saveassuccessmsg1');
                }
                if ((newItemObj.modelType.parentTypeName == 'U4_Component')) {
                    _.forEach(response.modelObjects, function (modelObject) {
                        if (modelObject.props && modelObject.props.items_tag && modelObject.props.items_tag.dbValues.includes(newItemObj.uid) === true) {
                            data.newCreatedItem = modelObject;
                            return false;
                        }
                    });
                    _messagingSvc.reportNotyMessage(data, data._internal.messages, 'saveassuccessmsg');
                }
                if (newItemObj.type == 'U4_CADComponent') {
                    _messagingSvc.reportNotyMessage(data, data._internal.messages, 'saveassuccessmsg2');
                }
                if (willCopyToClipboard) {
                    _commonUtil.pushToAWCclipboard([newItemObj]);
                }

                if (!willOpenOnCreate) {
                    var objs = _commonUtil.getSelectedObjsToRefresh(ctx);
                    _commonUtil.refreshObjectsInUI(objs);
                    _commonUtil.closeTNIpanel();
                } else {
                    _commonUtil.closeTNIpanel();
                    _commonUtil.openNewObject(newItemObj.uid);
                }
                delete _appCtxSvc.ctx.isWrongGMC;
                delete _appCtxSvc.ctx.isPlasticFrame;
            } catch (error) {
                _messagingSvc.showError('Save as failed.');
            }
        });

    } catch (err) {
        _messagingSvc.showError('Something went wrong in pack component saveas action.');
        data.disableSaveAsCreate = false;
    }
    return data.disableSaveAsCreate;
};

var getChildrenUomselection = function (data, conditions) {

    var outputUOMinfo = [];
    if (conditions.childUOMvisibility) {
        for (let index = 0; index < data.uomListboxList.length; index++) {
            const element = data.uomListboxList[index].prop;
            var itemId = element.propertyDisplayName;
            var selectedUOM = element.dbValue;
            outputUOMinfo.push(itemId + '|||' + selectedUOM);
        }
    }
    return outputUOMinfo;
}

var verifyAndUpdateSelectedDatasets = function (data) {

    var masterSelected = false;
    var partSelected = false;
    var modelSelectedDatasets = [];
    if (data.dataProviders.datasetsProvider.selectedObjects &&
        data.dataProviders.datasetsProvider.selectedObjects.length > 0) {
        for (var index = 0; index < data.dataProviders.datasetsProvider.selectedObjects.length; index++) {
            var aModelDs = {
                uid: data.dataProviders.datasetsProvider.selectedObjects[index].uid,
                type: data.dataProviders.datasetsProvider.selectedObjects[index].type
            };
            modelSelectedDatasets.push(aModelDs);
            if (data.dataProviders.datasetsProvider.selectedObjects[index].type == 'UGMASTER') {
                masterSelected = true;
            }
            if (data.dataProviders.datasetsProvider.selectedObjects[index].type == 'UGPART') {
                partSelected = true;
            }
        }
        // Adding UGMASTER if not present along with UGPART
        if (masterSelected == false && partSelected == true) {
            for (var index2 = 0; index2 < data.datasetsResults.length; index2++) {
                if (data.datasetsResults[index2].type == 'UGMASTER') {
                    var aModelDs = {
                        uid: data.datasetsResults[index2].uid,
                        type: data.datasetsResults[index2].type
                    };
                    modelSelectedDatasets.push(aModelDs);
                    break;
                }
            }
        }
        // Adding all UGPARTs if not present along with UGMASTER
        if (masterSelected == true && partSelected == false) {
            for (var index3 = 0; index3 < data.datasetsResults.length; index3++) {
                if (data.datasetsResults[index3].type == 'UGPART') {
                    var aModelDs = {
                        uid: data.datasetsResults[index3].uid,
                        type: data.datasetsResults[index3].type
                    };
                    modelSelectedDatasets.push(aModelDs);
                }
            }
        }
    }
    return modelSelectedDatasets;
}

export let enableDisableSaveAsCreatebtn = function (data) {
    data.disableSaveAsCreate = false;
    return data.disableSaveAsCreate;
}

export default exports = {
    callRevealAction,
    copyPAMOptionChanged,
    loadCompClassLov,
    onCompClassSelected,
    onCompCommoditySelected,
    onmatClassSelected,
    onmatCommoditySelected,
    ondeliFormSelected,
    onpamFrameTypeSelected,
    getDatasetsInfo,
    clearPCRPercentage,
    consumerunitTypeUpdate,
    onComponentTypeSelection,
    assemblyRetainCloneSwitch,
    saveAsAction,
    enableDisableSaveAsCreatebtn,
    setMaterialFormPropList
};
