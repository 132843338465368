// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * Simple view mode service.
 *
 * @module js/SimpleViewModeService_DDE
 */
import notyService from 'js/NotyModule';
import appCtxService from 'js/appCtxService';
'use strict';

let exports = {};


/**
 * The possible view modes
 *
 * @member viewModes
 * @memberOf NgControllers.SimpleViewModeService
 */
//var viewModes = [ 'SummaryView', 'ListView', 'TableView', 'TableSummaryView', 'ImageView' ];
var viewModes = ['ListView'];

/**
 * Index of the view mode that is currently active
 *
 * @member currentViewModeIdx
 * @memberOf NgControllers.SimpleViewModeService
 */
var currentViewModeIdx = 0;

/**
 * Change the view mode
 *
 * @function changeViewMode
 * @param {String} viewMode - (Optional) The name of the view mode to change to
 * @memberOf NgServices.SimpleViewModeService
 */
export let changeViewMode = function (viewMode) {
    if (viewMode) {
        //If it is not a valid view mode show a warning
        if (viewModes.indexOf(viewMode) === -1) {
            notyService.showError('Unknown view mode ' + viewMode + ' remaining in ' + viewModes[currentViewModeIdx] + ' view');
            return;
        }
        else {
            currentViewModeIdx = viewModes.indexOf(viewMode);
        }
    }
    else {
        var currentViewMode = appCtxService.getCtx('ViewModeContext.ViewModeContext');
        currentViewModeIdx = (viewModes.indexOf(currentViewMode) + 1) % viewModes.length;
    }

    appCtxService.registerCtx('ViewModeContext', {
        ViewModeContext: viewModes[currentViewModeIdx]
    });

    notyService.showInfo('Changed to ' + viewModes[currentViewModeIdx]);
};


export let moduleServiceNameToInject = 'SimpleViewModeService_DDE';
export default exports = {
    changeViewMode,
    moduleServiceNameToInject
};

