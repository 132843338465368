import eventBus from 'js/eventBus';
import _soaSvc from 'soa/kernel/soaService';
import _messagingSvc from 'js/messagingService';
import AIValidation from 'js/AIValidationService';

'use strict';

var exports = {};

export let convertDatasetType = function (ctx, commandContext) {
    var conversionType = ctx.selected.type == "U4_PrintPDF" ? "U4_StructuredPDF" : "U4_PrintPDF";
    var oldName = ctx.selected.props.object_name.dbValues[0];
    _soaSvc.post('AWC-2017-12-UpdateProps', 'convertDatasetTypeV42', {
        convertDatasetRequest: {
            stringProps: {
                "itemUID": ctx.pselected.uid,
                "datasetUID": ctx.selected.uid,
                "convertFrom": ctx.selected.type,
                "convertTo": conversionType
            }
        }
    }).then(function (result) {
        if (result.out && result.out.length == 14) {
            _messagingSvc.showInfo("Dataset - " + oldName + " Type converted sucessfully");
            console.log("OCR Start");
            AIValidation.callServiceWithMessageJS(ctx, {
                "method": "POST",
                "url": "/ValidationPDF/ReportService.asmx/PDFAIValidation",
                "headers": {
                    "Content-type": "application/json; charset=utf-8"
                },
                "contentType": "application/json; charset=utf-8",
                "dataType": "json",
                "data": {
                    "uid": result.out,
                    "type": conversionType,
                    "SelectedTab": commandContext.pageContext.primaryActiveTabId,
                    "CurrentUser": ctx.userSession.props.user_id.dbValues[0],
                    "Port": ctx.preferences.AWC_U4_ReportService_Port[0]
                }
            });
            console.log("OCR Completed");
            eventBus.publish('cdm.relatedModified', {
                refreshLocationFlag: true,
                relatedModified: [ctx.pselected]
            });
            console.log("Replace Completed");
        } else {
            _messagingSvc.showError("Dataset- " + oldName + " Type conversion failed.");
        }
    }, function (error) {
        _messagingSvc.showError("Dataset- " + oldName + " Type conversion failed.");
    });
}

export let moduleServiceNameToInject = 'convertDatasetTypeService';
export default exports = {
    convertDatasetType,
    moduleServiceNameToInject
};
