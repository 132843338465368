// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/* global
 alert
 */

/**
 * Simple Alert service for sample command Handlers
 *`
 * @module js/showPCRRelationsService
 */
 
import app from 'app';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import $ from 'jquery';
import eventBus from 'js/eventBus';
import awGraphService from "js/awGraphService";

   var exports = {};

    /**
     * Dummy alert.
     * @param {String} text - text to display
     */
    export let showPCRSpecs = function (ctx) {
        if (ctx.graph) {
            var legendData = ctx.graph.legendData;
            var legendState = ctx.graph.legendState;
            if (legendState && legendData && legendData.legendViews && legendState.activeView) {
                var activeViewName = legendState.activeView.internalName == "PCR" ? "General" : "PCR";
                var realActiveViewName = activeViewName ? activeViewName : legendData.defaultActiveView;
                if (realActiveViewName) {
                    exports.selectView(_.find(legendData.legendViews, function (legendView) {
                        return realActiveViewName === legendView.internalName;
                    }), legendState, ctx);
                }
            }
        }
    };

    export let selectView = function (activeView, legendState, ctx) {
        if (!legendState) {
            return;
        }
        if (activeView === legendState.activeView) {
            // toggle expansion on the same legend view
            activeView.expand = !activeView.expand;
        }
        exports.setActiveView(activeView, legendState, ctx);
    };

    export let setActiveView = function (activeView, legendState, ctx) {
        if (!legendState) {
            return;
        }

        if (activeView !== legendState.activeView) {
            if (legendState.activeView) {
                legendState.activeView.showEnabled = false;
                legendState.activeView.expand = false; // de-select all categories in old view

                _.forEach(legendState.activeView.categoryTypes, function (categoryType) {
                    _.forEach(categoryType.categories, function (category2) {
                        if (category2.isSelected) {
                            category2.isSelected = false;
                        }
                    });
                });
            }

            activeView.showEnabled = true;
            activeView.expand = true;
            legendState.activeView = activeView;
            awGraphService.clearGraph(ctx.graph.graphModel);
            eventBus.publish('awGraphLegend.activeViewChanged');
        }
    };


export default exports = {
	setActiveView,
	selectView,
	showPCRSpecs
};
app.factory('showPCRRelationsService', () => exports );