import app from 'app';
import ngModule from 'angular';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import eventBus from 'js/eventBus';
import parsingUtils from 'js/parsingUtils';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import _cbService from 'js/tcClipboardService';
import _queryUtil from 'js/UnileverQueryUtil';
import awPromiseService from 'js/awPromiseService';

    'use strict';

    var exports = {};



    /**
     * 
     * goal of this function is if a already opened command is clicked by user,
     * it shouldnot go into validations in module.json. it should just close the command panel.
     *
     * 
     * maintains state of every TNI command whether it has already opened or not.
     * decides whether a TNI command to be opened or closed by its state which was stored in ctx.
     * 
     * @param {String} commandId ID of the TNI command
     */
    export let commandActivationCheck = function (commandId) {

        var ctx = _appCtxSvc.ctx;
        if (commandId == "CreatePackComp") {
            ctx.addObjectPanelInfo = {};
            ctx.addObjectPanelInfo.isPopupopen = true;
        }

        if (ctx.UnileverWorkspace == undefined || ctx.UnileverWorkspace[commandId] == undefined) {

            var obj = (ctx.UnileverWorkspace == undefined) ? {} : ctx.UnileverWorkspace;
            obj[commandId] = (obj[commandId] == undefined) ? {} : obj[commandId];
            obj[commandId].isAlreadyOpened = false;
			ctx.UnileverWorkspace = obj

        } else {
            if (ctx.UnileverWorkspace[commandId].isAlreadyOpened) {
                ctx.UnileverWorkspace[commandId].isAlreadyOpened = false;
            } else {
                /*
                 * if a command's panel is opened already successfull, the command will be stored in ctx.activeToolsAndInfoCommand.commandId. using this boolean
                 * to find whether the command's panel was already opened or not. Bcoz there is a chance of panel not getting opened by some validation in further actions.
                 */
                if (ctx.activeToolsAndInfoCommand != undefined && ctx.activeToolsAndInfoCommand.commandId != undefined && ctx.activeToolsAndInfoCommand.commandId == commandId) {
                    ctx.UnileverWorkspace[commandId].isAlreadyOpened = true;
                }
            }
        }
        //prints action of a command (open or close)
        exports.logOnConsole('CommandId : ' + commandId + ', Action : ' + ((ctx.UnileverWorkspace[commandId].isAlreadyOpened) ? 'Close' : 'Open'));

        return;

    }

    export let doPilotUpdatePreValidation = function (ctx, data) {

        data.unileverUpdateType = 'pilot';
        data.selectedModelObjArray = exports.getSelectedModelObjArray(ctx);

        return;
    };

    export let doProductionUpdatePreValidation = function (ctx, data) {

        data.unileverUpdateType = 'production';
        data.selectedModelObjArray = exports.getSelectedModelObjArray(ctx);

        return;
    };

    export let getSelectedModelObjArray = function (ctx) {


        var selectedModelObjArray = [];
        for (let index = 0; index < ctx.mselected.length; index++) {
            const element = ctx.mselected[index];
            selectedModelObjArray.push({
                uid: element.uid,
                type: element.type
            });

        }
        return selectedModelObjArray;
    };

    export let getSelectedObjsToRefresh = function (ctx) {

        var array = [];
        if (ctx.selected) {
            array.push(ctx.selected);
        }
        if (ctx.pselected) {
            array.push(ctx.pselected);
        }
        return array;
    }

    export let openNewObject = function (newObjectUid) {

        if (newObjectUid) {
            var newURLwithNewObject = window.location.origin + window.location.pathname + '#/com.siemens.splm.clientfx.tcui.xrt.showObject?uid=' + newObjectUid;
            // window.open(newURLwithNewObject); // to open in new tab
            window.location.href = newURLwithNewObject;
        }

        return;

    }

    export let pushToAWCclipboard = function (objsArray) {


        var isPropsNotPresent = false;
        var array = [];

        if (objsArray && objsArray.length > 0) {

            for (let index = 0; index < objsArray.length; index++) {
                const element = objsArray[index];
                if (element) {
                    array.push(element);
                    if (element['props'] == undefined || element['props']['object_string'] == undefined) {
                        isPropsNotPresent = true;
                    }

                }

            }
        }
        if (isPropsNotPresent) {

            var array2 = [];

            for (var k = 0; k < array.length; k++) {
                array2.push(array[k].uid);
            }

            var inputData = {
                uids: array2
            };

            _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', inputData).then(function (response) {

                var array1 = [];

                if (response && response.modelObjects) {
                    for (var k = 0; k < array2.length; k++) {
                        if (response.modelObjects[array2[k]])
                            array1.push(response.modelObjects[array2[k]]);
                    }
                }
                if (array1.length > 0)
                    pushToCBandShowMessage(array1);

            });
        } else
            pushToCBandShowMessage(array);


        return;
    }

    export let refreshObjectsInUI = function (objs) {

        eventBus.publish('cdm.relatedModified', {
            relatedModified: objs
        });
    }

    export let closeTNIpanel = function () {

        eventBus.publish('complete', {
            source: 'toolAndInfoPanel'
        });

    }

    export let raiseEvent = function (eventName, data) {

        eventBus.publish(eventName, {
            'scope': {
                'data': data
            }
        });
    }

    var pushToCBandShowMessage = function (array) {
        if (array.length > 0) {

            _cbService.copyHyperlinkToClipboard(array);

            var message = '';

            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                message = message + element.props.object_string.dbValues[0] + ', ';
            }
            message = message.substring(0, message.length - 2);
            message = message + ' was copied to Teamcenter and OS clipboard.';

            //when pushing object to clipboard programmatically, notifying is not happening. so doing it explicitly
            _messagingSvc.showInfo(message);

        }
    }

    export let isPreferencesAvailable = function (prefs, willShowError) {

        var preferencesAvailable = true;


        if (prefs == undefined || prefs.length == 0) {
            return false;
        }

        var array = [];
        for (var i = 0; i < prefs.length; ++i) {
            if (prefs[i] && _appCtxSvc.ctx.preferences[prefs[i]] == undefined) {
                array.push(prefs[i]);
                preferencesAvailable = false;
            }
        }

        if (preferencesAvailable) {
            return true;
        } else {

            if (willShowError) {
                var msg = (array.length > 1) ? 'Preferences ' : 'Preference ';
                for (var i = 0; i < array.length; ++i) {
                    if (i == (array.length - 1))
                        msg = msg + '\"' + array[i] + '\"';
                    else
                        msg = msg + '\"' + array[i] + '\", ';
                }

                msg = msg + ' not found.';

                _messagingSvc.showError(msg);
            }
            return false;
        }

    }

    export let postPilotProductionUpdate = function (updateRevision, updateItem, isPrimary, callback) {

        var inputData = {
            objects: [{
                uid: updateRevision.uid,
                type: updateRevision.type
            }],
            attributes: {
                u4_IsPrimary: {
                    stringVec: [isPrimary.toString()]
                }
            }
        };

        _soaSvc.post('Core-2007-01-DataManagement', 'setProperties', inputData).then(function (response) {

            exports.getCurrentUserNewstuffFolder(function (newstuffFolder) {

                var createRelationsInputData = {
                    input: [{
                        clientId: 'AWClient',
                        relationType: '',
                        primaryObject: {
                            uid: newstuffFolder.uid,
                            type: newstuffFolder.type
                        },
                        secondaryObject: {
                            uid: updateItem.uid,
                            type: updateItem.type
                        }
                    }]
                };
                _soaSvc.post('Core-2006-03-DataManagement', 'createRelations', createRelationsInputData).then(function (createRelationsResponse) {
                    callback();
                });

            });

        });

    }

    export let getCurrentUserNewstuffFolder = function (callback) {

        _soaSvc.post('Service-2016-10-DataExtractionAWC', 'getNewStuffFolder', {}).then(function (response) {

            var newstuffFolder = null;

            for (var h in response.modelObjects) {
                if (response.modelObjects[h].type === 'Newstuff Folder') {
                    newstuffFolder = response.modelObjects[h];
                    break;
                }
            }
            callback(newstuffFolder);

        });

    }



    /**
     * sort collection of modelobjects in a dataprovider
     * 
     * @param {*} data object where dataprovider resides
     * @param {*} dataproviderName dataprovider name
     * @param {*} index sort by name or id ( 0 or 1 )
     * @param {*} order sort by ascending or descending ( 0 or 1 )
     */
    export let sortDataProviderObjects = function (data, dataproviderName, index, order) {

        //decide ascending or descending
        var j = (order) ? 1 : -1;
        var k = (order) ? -1 : 1;

        var cellHeader = (index == 0) ? 'cellHeader1' : 'cellHeader2';
        /* data.dataProviders[dataproviderName].viewModelCollection.loadedVMObjects[0].cellProperties.isPrimary = {
            key: 'Primary:',
            value: 'Secoundary'
        };
        console.log(data.dataProviders[dataproviderName].viewModelCollection.loadedVMObjects[0]); */
        data.dataProviders[dataproviderName].viewModelCollection.loadedVMObjects.sort(function (a, b) {
            if (!a[cellHeader] || !b[cellHeader]) {
                return -1;
            }
            var str1 = a[cellHeader];
            var str2 = b[cellHeader];
            if (str1 < str2) return j;
            if (str1 > str2) return k;
            return 0;
        });

    }

    /**
     * sort collection of modelobjects
     * 
     * @param {*} data object where array resides
     * @param {*} str array variable name
     * @param {*} index sort by name or id ( 0 or 1 )
     * @param {*} order sort by ascending or descending ( 0 or 1 )
     */
    export let sortComponents = function (data, str, index, order) {



        //decide ascending or descending
        var j = (order) ? 1 : -1;
        var k = (order) ? -1 : 1;

        data[str].sort(function (a, b) {
            if (!a.props.awp0CellProperties || !b.props.awp0CellProperties) {
                return -1;
            }
            var str1 = a.props.awp0CellProperties.dbValues[index];
            var str2 = b.props.awp0CellProperties.dbValues[index];
            if (str1 < str2) return j;
            if (str1 > str2) return k;
            return 0;
        });

    }

    export let logOnConsole = function (str) {

        if (str)
            console.log('Log : ' + str);

    }

    export let getCurrentTheme = function () {
        let themeState = {};
        let stylesheets = document.styleSheets;
        for (let i = 0; i < stylesheets.length; ++i) {
            if (stylesheets[i].href && _.includes(stylesheets[i].href, 'ui-lightTheme.css')) {
                themeState['light'] = true;
                break;
            }
            if (stylesheets[i].href && _.includes(stylesheets[i].href, 'ui-darkTheme.css')) {
                themeState['dark'] = true;
                break;
            }
        }
        return themeState;
    }


    export let showSoaError = function (errorJSO) {
        if (errorJSO)
            _messagingSvc.showError(_messagingSvc.getSOAErrorMessage(errorJSO));
    }

    export let compareToIgnoreCase = function (str1, str2) {

        if (str1 && str2)
            return (str1.toUpperCase() === str2.toUpperCase());

    }

    export let getParametersFromURL = function (sublocName) {

        let params = {};
        if (sublocName) {
            let paramsArr = window.location.href.substring((window.location.origin + window.location.pathname + '#/' + sublocName).length).replace('?', '').split('&');
            for (let i in paramsArr) {
                let arr = paramsArr[i].split('=');
                params[arr[0]] = arr[1];
            }
        }
        return params;
    }

    export let addChildrenUnderParent = function (children, parent, relation, callback) {
        /*var addChildrenInputData = {
            inputData: [{
                parentObj: parent,
                childrenObj: children,
                propertyName: (relation) ? relation : ''
            }]
        };
        _soaSvc.post('Core-2014-10-DataManagement', 'addChildren', addChildrenInputData).then(function (addChildrenResponse) {
            callback();
            //   console.log(addChildrenResponse);
        });*/

        // OOTB addChildren gives delay. 
        var children1 = [];
        if (children && (children.length > 0)) {
            for (var t in children) {
                children1.push({
                    uid: children[t].uid
                });
            }
        }
        var parent1 = null;
        if (parent) {
            parent1 = {
                uid: parent.uid
            }
        }

        if ((children1.length > 0) && parent1) {

            var addChildrenInputData = {
                primary: parent1,
                secoundary: children1,
                relationName: (relation) ? relation : ''
            };
            _soaSvc.post('AWC-2016-10-MctService', 'attachPrimaryToSecoundary', addChildrenInputData).then(function (addChildrenResponse) {
                callback();
                //   console.log(addChildrenResponse);
            });
        } else {
            callback();
        }

    }

    export let initiateUnileverWorkflow = function (Objstring, ObjUid, WorkflowName, callback,workflowdescription) {
        var inputData = {
            startImmediately: true,
            observerKey: '',
            name: Objstring,
            subject: '',
            description: workflowdescription?workflowdescription:"",
            contextData: {
                processTemplate: WorkflowName,
                attachmentCount: 1,
                attachments: [ObjUid],
                attachmentTypes: [1],
                processOwner: '',
                dependencyTask: '',
                subscribeToEvents: false,
                subscriptionEventCount: 0,
                subscriptionEventList: [],
                remoteParent: '',
                remoteParentAppguid: '',
                remoteParentUrl: '',
                deadlineDate: '',
                container: '',
                relationType: '',
                processAssignmentList: '',
                processResources: []
            }
        }
        _soaSvc.post('Workflow-2008-06-Workflow', 'createInstance', inputData).then(function (response) {
            callback();
        }).catch(function (error) {
            _messagingSvc.showError(error.message);

        });

    }

    /*
    clears breadcrumb title
    */
    export let clearBreadCrumbText = function (locationName) {
        if (_appCtxSvc.ctx.locationContext && _appCtxSvc.ctx.locationContext['ActiveWorkspace:Location'] && (_appCtxSvc.ctx.locationContext['ActiveWorkspace:Location'] === locationName)) {
            try {
                ngModule.element(document.querySelector('aw-search-breadcrumb')).scope().$parent.provider.title = null;
            } catch (error) {
                //
            }
        }
    }

    /*
    set custom properties to cell.
    */
    export let addCustomPropertiesToModelObj = function (modelObj, customProp) {

        /*  if aw-list is placed on ListSummary view, then if some object is selected on it, the custom properties added is disappearing.
         it was working fine with AWC 3.4. But not in 4.1
         so adding the custom properties to modelobject in "props._awc_customCellProperties.dbValues[0]" and custom snippet added to 
         aw-default-cell-content.directive takes care of displaying this. */

        if (modelObj && modelObj.props) {
            if (!modelObj.props._awc_customCellProperties) {
                modelObj.props._awc_customCellProperties = {
                    dbValues: [{}]
                };
            }
            if (customProp && customProp.key) {
                modelObj.props._awc_customCellProperties.dbValues[0][customProp.key] = customProp;
            }
        }
    }

    export let closePopupWindow = function (ctx) {
        delete ctx.addObjectPanelInfo;
        eventBus.publish("awPopup.close")
    }

    export let getPopUpHeight = function (ctx, type) {

        ctx.addObjectPanelInfo = {};
        ctx.addObjectPanelInfo.isPopupopen = true;
        delete ctx.addPanelTitle;
        if (type == "Dataset" && ctx.userSession.props.role_name.dbValues[0] !== 'DBA') {
			console.log("Test");
            if (ctx.addObject && ctx.preferences.u4_displayableDatasetTypes && ctx.preferences.u4_displayableDatasetTypes.length > 0) {
                ctx.addObject.typeFilterNames = ctx.preferences.u4_displayableDatasetTypes.join(',');
                ctx.addObject.includedTypes = ctx.preferences.u4_displayableDatasetTypes.join(',');
            }
        }
        var popHMap = {};
        var preferenceValues = ctx.preferences.u4_popupHeight;
        for (var i = 0; i < preferenceValues.length; i++) {
            var objType = preferenceValues[i].split(':')[0];
            var height = preferenceValues[i].split(':')[1];
            popHMap[objType] = height;
        }
        ctx.PopUpHeight = popHMap[type] ? popHMap[type] : "45vh";
    }

    export let getCompletedTask = function (response) {

        var lovEntries = [];
        var modelObjects = [];
        var flmodelObjects = [];

        if (response.searchResultsJSON) {
            var searchResults = parsingUtils.parseJsonString(response.searchResultsJSON);

            if (searchResults) {
                for (var i = 0; i < searchResults.objects.length; i++) {
                    var uid = searchResults.objects[i].uid;
                    var obj = response.ServiceData.modelObjects[uid];
                    modelObjects.push(obj);
                }
            }

            if (modelObjects) {
                flmodelObjects = _.filter(modelObjects, function (modelObject) {
                    return modelObject.props.last_mod_user.uiValues[0] == _appCtxSvc.ctx.user.props.object_string.uiValue

                });
            }
        }
        return flmodelObjects
    }

    export let updatePopUpTitle = function (ctx, data) {
        if (data.creationType && data.creationType.props.parent_types && ctx.addObjectPanelInfo) {
            if (data.creationType.props.type_name.dbValues[0].indexOf("Revision") > 0) {
                ctx.addObjectPanelInfo.selectedAddType = data.creationType.props.type_name.dbValues[0];
            }
            else {
                ctx.addObjectPanelInfo.selectedAddType = data.creationType.props.type_name.dbValues[0] + "Revision";
            }
            if (data.creationType.props.parent_types.displayValues.length > 0 && data.creationType.props.parent_types.displayValues.includes("Dataset") == true) {
                ctx.addPanelTitle = "Add \t" + data.creationType.props.object_string.dbValue;
            }
        }
    }


    export let precheckCompMetaDataChange = function (ctx, data) {

        console.log("Entering precheckCompMetaDataChange");
        var deferred = awPromiseService.instance.defer();
        console.log("ctx.GMCChangePreCheck : ",ctx.GMCChangePreCheck);
        if (ctx.GMCChangePreCheck === undefined) {
            _appCtxSvc.registerCtx('GMCChangePreCheck', false);
            deferred.resolve();
        }
        deferred.resolve();
        _appCtxSvc.updateCtx('GMCChangePreCheck', true);
        console.log("Leaving precheckCompMetaDataChange");
        return deferred.promise;
    }

export default exports = {
	commandActivationCheck,
	doPilotUpdatePreValidation,
	doProductionUpdatePreValidation,
	getSelectedModelObjArray,
	getSelectedObjsToRefresh,
	openNewObject,
	pushToAWCclipboard,
	refreshObjectsInUI,
	closeTNIpanel,
	raiseEvent,
	isPreferencesAvailable,
	postPilotProductionUpdate,
	getCurrentUserNewstuffFolder,
	sortDataProviderObjects,
	sortComponents,
	logOnConsole,
	getCurrentTheme,
	showSoaError,
	compareToIgnoreCase,
	getParametersFromURL,
	addChildrenUnderParent,
	initiateUnileverWorkflow,
	clearBreadCrumbText,
	addCustomPropertiesToModelObj,
	closePopupWindow,
	getPopUpHeight,
	getCompletedTask,
	updatePopUpTitle,
	precheckCompMetaDataChange
};
app.factory('UnileverCommonUtil', () => exports );