// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * Mct list service
 *
 * @module js/MctUtil
 */
import app from 'app';
import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import _commUtil from 'js/UnileverCommonUtil';
import _appCtxSvc from 'js/appCtxService';
import _messagingSvc from 'js/messagingService';
import _policySvc from 'soa/kernel/propertyPolicyService';
import _createTemp from 'js/CreatePamTemplate';
import _mctSublocSvc from 'js/MctSublocationService';
import eventBus from 'js/eventBus'
import awPromiseService from 'js/awPromiseService';
import _cdm from 'soa/kernel/clientDataModel';
'use strict';

var exports = {};

var BulkUpdate = false;
var MatClassRelation = [];
var MctDataStructure = null;

export let addToMct = async function (MctRevisionObj, data) {
    var selectedComp = [];
    var selectedPam = getSelectedPAM('pam');
    var selectedPack = await getSelectedPACK('pack');
    for (var i in selectedPack) {
        selectedComp.push(selectedPack[i]);
    }
    for (var i in selectedPam) {
        selectedComp.push(selectedPam[i]);
    }
    data.MatClassRelation = [];
    data.MatClassRelation = MatClassRelation;

    if (selectedComp) {
        var getPropertiesInputData = {
            objects: [MctRevisionObj],
            attributes: ['U4_MOP_List_Relation']
        };

        var getPropertiesResponse = await _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', getPropertiesInputData);
        if (getPropertiesResponse) {
            var pamsToAdd = [];
            var pamsAlreadyPresent = 0;
            var uids = getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]].props.U4_MOP_List_Relation.dbValues;
            for (var h in selectedComp) {
                var isAlreadyPresent = false;
                for (var t in uids) {
                    var pamUid = getPropertiesResponse.modelObjects[uids[t]].uid;
                    if (selectedComp[h].uid === pamUid) {
                        isAlreadyPresent = true;
                    }
                }
                if (isAlreadyPresent) {
                    ++pamsAlreadyPresent;
                } else {
                    pamsToAdd.push(selectedComp[h]);
                }

            }
            if (pamsToAdd.length > 0) {
                var tempChildren = await addChildrenUnderParent(pamsToAdd, MctRevisionObj, 'U4_MOP_List_Relation');
                var createdTemps = await _createTemp.createPamTemplates(MctRevisionObj, data);
                if (pamsAlreadyPresent > 0) {
                    _messagingSvc.showError(pamsAlreadyPresent + ' PAM(s) in selection already present under MCT. ' + selectedPam.length + ' PAM(s) added to MCT Successfully.');
                } else {
                    _messagingSvc.showError(selectedPam.length  + ' PAM(s) added to MCT Successfully.');
                }
                // callback();
                //});

                // });
            } else {
                _messagingSvc.showError('All PAM(s) in selection already present under MCT.');
            }
        }


        //});
    }
}

export let createAndAddToMct = async function (MctName, MctCategory, data) {
    var selectedComp = [];
    var selectedPam = getSelectedPAM('pam');
    var selectedPack = await getSelectedPACK('pack');
    for (var i in selectedPack) {
        selectedComp.push(selectedPack[i]);
    }
    for (var i in selectedPam) {
        selectedComp.push(selectedPam[i]);
    }
    data.MatClassRelation = [];
    data.MatClassRelation = MatClassRelation;

    if (selectedComp) {
        var newMctRev = await createMct(MctName, MctCategory);
        if (newMctRev) {
            var pamInMCT = await addChildrenUnderParent(selectedComp, newMctRev, 'U4_MOP_List_Relation');
            var createdPAMTemps = await _createTemp.createPamTemplates(newMctRev, data);
            var newstuffFolder = await getCurrentUserNewstuffFolder();
            if (newstuffFolder) {
                var addChildrenInputData = {
                    inputData: [{
                        parentObj: newstuffFolder,
                        childrenObj: [newMctRev],
                        propertyName: ''
                    }]
                };
                var addChildrenResponse = await _soaSvc.post('Core-2014-10-DataManagement', 'addChildren', addChildrenInputData);
                if (addChildrenResponse) {
                    var pamStr = (selectedPam.length === 1) ? 'PAM' : 'PAM\'s';
                    var newMctName = newMctRev.props.object_name.dbValues[0];
                    _messagingSvc.showInfo('MCT ' + newMctName + ' created with selected ' + pamStr + ' and added to NewStuff folder.');
                    return newMctRev;
                }
            }
        }
    }
}

export let addChildrenUnderParent = async function (children, parent, relation) {
    // OOTB addChildren gives delay. 
    var children1 = [];
    if (children && (children.length > 0)) {
        for (var t in children) {
            children1.push({
                uid: children[t].uid
            });
        }
    }
    var parent1 = null;
    if (parent) {
        parent1 = {
            uid: parent.uid
        }
    }

    if ((children1.length > 0) && parent1) {
        var addChildrenInputData = {
            primary: parent1,
            secoundary: children1,
            relationName: (relation) ? relation : ''
        };
        var addChildrenResponse = await _soaSvc.post('AWC-2016-10-MctService', 'attachPrimaryToSecoundary', addChildrenInputData);
        if (addChildrenResponse) {
            return addChildrenResponse;
        }
    }
}

export let getCurrentUserNewstuffFolder = async function () {
    var response = await _soaSvc.post('Service-2016-10-DataExtractionAWC', 'getNewStuffFolder', {});
    if (response) {
        var newstuffFolder = null;
        for (var h in response.modelObjects) {
            if (response.modelObjects[h].type === 'Newstuff Folder') {
                newstuffFolder = response.modelObjects[h];
                break;
            }
        }
        return newstuffFolder;
    }
}

export let getSelectedPAM = function (type) {
    var isPamOperation = (type === 'pam');
    var isPamTemplateOperation = (type === 'pamTemplate');
    var selectedObjs = _appCtxSvc.getCtx('mselected');
    if (0 === selectedObjs.length) {
        _messagingSvc.showError('Please select to proceed.');
        return;
    }

    var selectedPam = [];
    var mctPamTemplateSelectionCount = 0;
    var pamTemplateSelectionCount = 0;
    var pamSelectionCount = 0;
    var releasedPamCount = 0;
    var ModifiableCount = 0;

    for (var i in selectedObjs) {
        var isValidPam = true;
        if (!(selectedObjs[i].modelType.typeHierarchyArray.indexOf('U4_PAMRevision') !== -1)) {
            isValidPam = false; // not a pam
        } else {
            var isMCTPamTemplate = (selectedObjs[i].props.object_string.dbValues[0].substring(0, 1) === 'T');
            if ((!isMCTPamTemplate) && ((!selectedObjs[i].props.u4_technology) || (!selectedObjs[i].props.u4_technology.dbValues[0]) || (_.trim(selectedObjs[i].props.u4_technology.dbValues[0]).length === 0))) {
                isValidPam = false; // not a pam template
                pamTemplateSelectionCount++;
            }
            if (isPamOperation && isMCTPamTemplate) {
                isValidPam = false;
                mctPamTemplateSelectionCount++;
            }

            if (isPamTemplateOperation && !isMCTPamTemplate) {
                isValidPam = false;
                pamSelectionCount++;
            }

            if ((selectedObjs[i].props.release_status_list.dbValues.length > 0) && (selectedObjs[i].props.release_status_list.dbValues[0])) {
                isValidPam = false;
                releasedPamCount++;

            }
            if ((selectedObjs[i].props.process_stage_list.dbValues.length > 0) && (selectedObjs[i].props.process_stage_list.dbValues[0])) {
                isValidPam = false;
            }
            if (selectedObjs[i].props.is_modifiable.dbValues[0] == 0) {
                isValidPam = false;
                ModifiableCount++;
            }
        }

        if (isValidPam) {
            selectedPam.push({
                uid: selectedObjs[i].uid,
                type: selectedObjs[i].type
            });
        }
    }

    if (pamTemplateSelectionCount === selectedObjs.length) {
        _messagingSvc.showError('Only PAM Template(s) found in the selection. Please change the selection.');
        return;
    }

    if ((!isPamTemplateOperation) && (mctPamTemplateSelectionCount === selectedObjs.length)) {
        _messagingSvc.showError('Only PAM Template(s) found in the selection. Please change the selection.');
        return;
    }

    if ((!isPamOperation) && (pamSelectionCount === selectedObjs.length)) {
        _messagingSvc.showError('Only PAM(s) found in the selection. Please change the selection.');
        return;
    }

    if (releasedPamCount === selectedObjs.length) {
        _messagingSvc.showError('Only released PAM(s) found in the selection. Please change the selection.');
        return;
    }

    if(ModifiableCount === selectedObjs.length)
    {
        _messagingSvc.showError('The PAM\'s cannot be added or removed as there is no write access.');
        return;
    }

    if (selectedPam.length === 0) {
        _messagingSvc.showError('No valid PAM\'s are found in selection. Please change the selection.');
        return;
    }
    
    return selectedPam;
}


export let getSelectedPACK = async function (type) {
    MatClassRelation = [];
    var isPackOperation = (type === 'pack');
    var isPackTemplateOperation = (type === 'packTemplate');
    var selectedObjs = [];
    var selectedObject;
    if (isPackOperation) {
        var selectedPackComp, selectedPamComp;
        selectedPamComp = _appCtxSvc.ctx.mselected;
        var packRevObjUID = await getPackFromPam(selectedPamComp);
        for (var x in packRevObjUID) {
            selectedPackComp = _cdm.getObject(packRevObjUID[x]);
            selectedObject = selectedPackComp;
            selectedObjs.push(selectedPackComp);
            MatClassRelation.push({
                type: selectedObject.type,
                MatFormUID: selectedObject.props.U4_MatlClassRelation.dbValues[0]
            });
        }

        if (0 === selectedObjs.length) {
            _messagingSvc.showInfo('For selected component, Pack does not exist');
            return;
        }
    }
    else {
        selectedObjs = _appCtxSvc.ctx.mselected;

        if (0 === selectedObjs.length) {
            _messagingSvc.showError('Please select to proceed.');
            return;
        }
    }



    var selectedPack = [];

    var mctPackTemplateSelectionCount = 0;
    var packTemplateSelectionCount = 0;
    var packSelectionCount = 0;
    var releasedPackCount = 0;
    var ModifiableCount = 0;

    for (var i in selectedObjs) {

        var isValidPack = true;

        if (!(selectedObjs[i].modelType.typeHierarchyArray.indexOf('U4_ComponentRevision') !== -1)) {
            isValidPack = false; // not a pam
        } else {

            var isMCTPackTemplate = (selectedObjs[i].props.object_string.dbValues[0].substring(0, 1) === 'T');

            //if ((!isMCTPackTemplate) && ((!selectedObjs[i].props.object_type) || (!selectedObjs[i].props.object_type.dbValues[0]) || (_.trim(selectedObjs[i].props.object_type.dbValues[0]).length === 0))) 
            if ((!isMCTPackTemplate) && ((!selectedObjs[i].props.u4_technology) || (!selectedObjs[i].props.u4_technology.dbValues[0]) || (_.trim(selectedObjs[i].props.u4_technology.dbValues[0]).length === 0))) {

                isValidPack = false; // not a pack template
                packTemplateSelectionCount++;
            }

            if (isPackOperation && isMCTPackTemplate) {
                isValidPack = false;
                mctPackTemplateSelectionCount++;
            }

            if (isPackTemplateOperation && !isMCTPackTemplate) {
                isValidPack = false;
                packSelectionCount++;
            }

            if ((selectedObjs[i].props.release_status_list.dbValues.length > 0) && (selectedObjs[i].props.release_status_list.dbValues[0])) {
                isValidPack = false;
                releasedPackCount++;
            }

            if (selectedObjs[i].props.is_modifiable.dbValues[0] == 0) {
                isValidPack = false;
                ModifiableCount++;
            }

            if ((selectedObjs[i].props.process_stage_list.dbValues.length > 0) && (selectedObjs[i].props.process_stage_list.dbValues[0])) {
                isValidPack = false;
            }

        }
        if (isValidPack) {
            selectedPack.push({
                uid: selectedObjs[i].uid,
                type: selectedObjs[i].type
            });
        }

    }

    if (packTemplateSelectionCount === selectedObjs.length) {
        _messagingSvc.showError('Only PACK Template(s) found in the selection. Please change the selection.');
        return;
    }

    if ((!isPackTemplateOperation) && (mctPackTemplateSelectionCount === selectedObjs.length)) {
        _messagingSvc.showError('Only PACK Template(s) found in the selection. Please change the selection.');
        return;
    }

    if ((!isPackOperation) && (packSelectionCount === selectedObjs.length)) {
        _messagingSvc.showError('Only PACK(s) found in the selection. Please change the selection.');
        return;
    }

    if (releasedPackCount === selectedObjs.length) {
        if (!isPackOperation) {
            _messagingSvc.showError('Only released PACK(s) found in the selection. Please change the selection.');
        }
        return;
    }

    if (ModifiableCount === selectedObjs.length) {
        _messagingSvc.showError('The PACK\'s cannot be added  or removed as there is no write access.');
        return;
    }

    if (selectedPack.length === 0) {
        _messagingSvc.showError('No valid PACK\'s are found in selection. Please change the selection.');
        return;
    }

    return selectedPack;
}


var createMct = async function (MctName, MctCategory) {
    var createItemsInputData = {
        properties: [{
            name: MctName,
            type: 'U4_MOP_List',
            description: 'Item for MCT operation.'
        }]
    };

    var createItemsResponse = await _soaSvc.post('Core-2006-03-DataManagement', 'createItems', createItemsInputData);
    if (createItemsResponse) {
        var setPropertiesInputData = {
            info: [{
                object: createItemsResponse.output[0].itemRev,
                vecNameVal: [{
                    name: 'u4_category',
                    values: [MctCategory]
                }]
            }],
            options: []
        };

        var setPropertiesResponse = await _soaSvc.post('Core-2010-09-DataManagement', 'setProperties', setPropertiesInputData);
        if (setPropertiesResponse) {
            return createItemsResponse.output[0].itemRev;
        }
    }
}


var getSelectedMct = function () {
    return {
        uid: sessionStorage._MctRev_UID
    };
}


var MctOperation = async function (operation, pamTemplatesToRefresh, pamTemplateToUpdate, _MctRev) {
    console.log("Entering in MctOperation");
    var isUpdateOperation = (operation === 'update');
    var isSinglePamTemplateUpdate = (operation === 'singlePamTemplateUpdate');
    var isRefreshTemplateOperation = (operation === 'refreshTemplate');
    var isRefreshNewTemplateOperation = (operation === 'refreshNewTemplate');

    MctDataStructure = {};

    var MctRev = null;

    if (isRefreshNewTemplateOperation) {
        MctRev = _MctRev;
    } else {
        MctRev = getSelectedMct();
    }

    if (isUpdateOperation) {
        BulkUpdate = true;
    }
    else {
        BulkUpdate = false;
    }

    if (MctRev) {
        _appCtxSvc.ctx._mctRevision = MctRev;
        var pamRelations = await exports.getRelationsUnderPAM();
        if (pamRelations) {
            console.log("Entering inside getRelationsUnderPAM");
            MctDataStructure.pamRelations = pamRelations;
            var isTemplateOperation = (isRefreshTemplateOperation || isRefreshNewTemplateOperation);
            var smnRes = await getPAMsFromMct(MctRev, isTemplateOperation, isSinglePamTemplateUpdate, pamTemplateToUpdate);
            if (MctDataStructure.U4_MOP_Templates.length === 0) {
                _messagingSvc.showError('No PAM or PACK Templates found under MCT');
            } else if (isUpdateOperation && (MctDataStructure.U4_MOP_List_Relation.length === 0) && (MctDataStructure.hasReleasePam)) {
                _messagingSvc.showError('Update can not be done since only released PAM\'(s) or PACK\'(s) in process stage available under MCT.');
            } else if (isUpdateOperation && (MctDataStructure.U4_MOP_List_Relation.length === 0) && (MctDataStructure.hasNotPrivilegedPam)) {
                _messagingSvc.showError('Update can not be done since only non-privileged PAM\'(s) or PACK\'(s) available under MCT.');
            } else if (isUpdateOperation && (MctDataStructure.U4_MOP_List_Relation.length === 0)) {
                _messagingSvc.showError('No PAM\'(s) or PACK\'(s) found under MCT.');
            } else if (isSinglePamTemplateUpdate && (MctDataStructure.U4_MOP_List_Relation.length === 0) && (MctDataStructure.hasReleasePam)) {
                _messagingSvc.showError('Update can not be done since only released PAM\'(s) or PACK\'(s) available for the selected PAM template under MCT.');
            } else if (isSinglePamTemplateUpdate && (MctDataStructure.U4_MOP_List_Relation.length === 0) && (MctDataStructure.hasNotPrivilegedPam)) {
                _messagingSvc.showError('Update can not be done since only non-privileged PAM\'(s) or PACK\'(s) available for the selected PAM template under MCT.');
            } else if (isSinglePamTemplateUpdate && (MctDataStructure.U4_MOP_List_Relation.length === 0)) {
                _messagingSvc.showError('No PAM\'s or PACK\'s found for the selected PAM template under MCT.');
            } else {
                //console.log("Entering in else of loadFormsOfPAM");
                await loadFormsOfPAM();

                if (isUpdateOperation || isSinglePamTemplateUpdate) {
                    updatePamFromTemplate_AND_updateTemplateFromMasterTemplate(false, null);
                }

                if (isTemplateOperation) {
                    updatePamFromTemplate_AND_updateTemplateFromMasterTemplate(true, pamTemplatesToRefresh);
                }
            }
        }
    }
}



var updatePamFromTemplate_AND_updateTemplateFromMasterTemplate = async function (isTemplateOperation, pamTemplatesToRefresh) {
    //console.log("Entering in updatePamFromTemplate_AND_updateTemplateFromMasterTemplate");

    var itemIds = [];
    var refreshInfo = [];
    for (var g in MctDataStructure.U4_MOP_Templates) {
        var canClear = (isTemplateOperation) ? (pamTemplatesToRefresh.indexOf(MctDataStructure.U4_MOP_Templates[g].pamTemplateObject.uid) !== -1) : (true);
        if (canClear) {

            var masterTemplateId = MctDataStructure.U4_MOP_Templates[g].pamTemplateObject.props.u4_template_id.dbValues[0];
            var relatedModelObjs = {};
            for (var y in MctDataStructure.U4_MOP_Templates[g]) {
                if (y !== 'pamTemplateObject') {
                    for (var z in MctDataStructure.U4_MOP_Templates[g][y]) {
                        var obj = MctDataStructure.U4_MOP_Templates[g][y][z].templateFormObject;
                        if (obj) {
                            relatedModelObjs[obj.uid] = obj;
                        }
                    }
                }
            }

            refreshInfo.push({
                masterTemplateId: masterTemplateId,
                pamTemplate: MctDataStructure.U4_MOP_Templates[g].pamTemplateObject,
                relatedModelObjs: relatedModelObjs
            });
            if (!_.includes(itemIds, masterTemplateId))
                itemIds.push(masterTemplateId);

        }
    }

    if (refreshInfo.length === 0) {
        // callback();
    } else {
        var revisionToRevisionSetInfo = [];
        console.log("Entering in else of updatePamFromTemplate_AND_updateTemplateFromMasterTemplate");
        var commonRes = await _createTemp.loadMasterTemplatesAndForms(itemIds);
        if (commonRes[0] && commonRes[1]) {
            console.log("the value of commonRes", commonRes);
            var loadObjectsResponse = commonRes[0];
            var masterTemplatesInfo = commonRes[1];
            // masterTemplatesInfo, loadObjectsResponse
            for (var k in refreshInfo) {
                var info = {};

                info.toRevision = {
                    modelObj: refreshInfo[k].pamTemplate,
                    relatedObjs: refreshInfo[k].relatedModelObjs
                };
                var obj1 = _.set({}, 'itemModelObj.props.item_id.dbValues[0]', refreshInfo[k].masterTemplateId);
                var obj2 = _.find(masterTemplatesInfo, obj1);
                if (obj2 && obj2.latestRevModelObj) {
                    info.fromRevision = {
                        modelObj: obj2.latestRevModelObj,
                        relatedObjs: loadObjectsResponse.modelObjects
                    };
                }

                revisionToRevisionSetInfo.push(info);
            }
            var i = 0;
            let uniquetypevalues = [];
            var UniqueResponse = [];
            revisionToRevisionSetInfo.forEach(element => {
                if (!uniquetypevalues.includes(element)) {
                    uniquetypevalues.push(element);
                    UniqueResponse.push(revisionToRevisionSetInfo[i]);
                }
                i++;
            });

            setRevisionToRevision(revisionToRevisionSetInfo, isTemplateOperation);
        }
    }
}


var setRevisionToRevision = async function (revisionToRevisionSetInfo, isTemplateOperation) {
    var pamRelations = await getRelationsUnderPAM();
    if (pamRelations) {
        var info = [];
        for (var b in revisionToRevisionSetInfo) {

            var fromRevisionModelObj = revisionToRevisionSetInfo[b].fromRevision.modelObj;
            var fromRevisionRelatedObjs = revisionToRevisionSetInfo[b].fromRevision.relatedObjs;

            var toRevisionModelObj = revisionToRevisionSetInfo[b].toRevision.modelObj;
            var toRevisionRelatedObjs = revisionToRevisionSetInfo[b].toRevision.relatedObjs;

            for (var c in pamRelations) {
                if (pamRelations[c] == 'U4_ComplianceRuleRelation') {

                    if (toRevisionModelObj.props[pamRelations[c]]) {
                        var to_relatedObjUids = toRevisionModelObj.props[pamRelations[c]].dbValues;
                        for (var e in to_relatedObjUids) {

                            var toFrom = toRevisionRelatedObjs[to_relatedObjUids[e]];
                            var vecNameVal = [];
                            var u4Props = exports.getU4Props(toFrom);
                            for (var propName in toFrom.props) {
                                if (_.includes(u4Props, propName)) {
                                    if (toFrom.props[propName].dbValues[0]) {
                                        var propValue = toFrom.props[propName].dbValues[0];
                                        propValue = (propValue === null) ? '' : propValue;
                                        vecNameVal.push({
                                            name: propName,
                                            values: [propValue]
                                        });
                                    }
                                }
                            }

                            if (vecNameVal.length > 0) {
                                if (!isTemplateOperation) {
                                    var pamTempobj = revisionToRevisionSetInfo[b].toRevision.modelObj;
                                    var info1 = getUpdateInfoFromPAMTemplateToPAM(pamTempobj, pamRelations[c], toFrom, vecNameVal);
                                    info = info.concat(info1);

                                    var infoValue1 = [];

                                    infoValue1 = ({
                                        type: toFrom.type,
                                        uid: toFrom.uid,
                                    });

                                    var v_obj = {};
                                    v_obj.bObject = toFrom;
                                    v_obj.nameVals = vecNameVal;
                                    v_obj.bObject = infoValue1;
                                    v_obj.nameVals = vecNameVal
                                    info.push(v_obj);
                                }
                                else {

                                    for (var x in vecNameVal) {
                                        if (vecNameVal[x].name == 'u4_confirm' || vecNameVal[x].name == 'u4_justification' || vecNameVal[x].name == 'u4_comments') {
                                            vecNameVal.push({
                                                name: vecNameVal[x].name,
                                                values: ''
                                            });
                                        }
                                    }
                                    var obj = {};
                                    obj.object = toFrom;
                                    obj.vecNameVal = vecNameVal;
                                    info.push(obj);
                                }
                            }
                        }
                    }

                }


                if (fromRevisionModelObj.props[pamRelations[c]]) {
                    var from_relatedObjUids = fromRevisionModelObj.props[pamRelations[c]].dbValues;

                    for (var d in from_relatedObjUids) {

                        var fromForm = fromRevisionRelatedObjs[from_relatedObjUids[d]];
                        if (toRevisionModelObj.props[pamRelations[c]]) {
                            var to_relatedObjUids = toRevisionModelObj.props[pamRelations[c]].dbValues;
                            for (var e in to_relatedObjUids) {

                                var toFrom = toRevisionRelatedObjs[to_relatedObjUids[e]];

                                if ((fromForm.props.object_name.dbValues[0] === toFrom.props.object_name.dbValues[0]) && (fromForm.type === toFrom.type)) {

                                    var vecNameVal = [];
                                    var u4Props = exports.getU4Props(toFrom);
                                    for (var propName in toFrom.props) {
                                        if (_.includes(u4Props, propName)) {
                                            if (fromForm.props[propName].dbValues[0] !== toFrom.props[propName].dbValues[0]) {
                                                var propValue = fromForm.props[propName].dbValues[0];
                                                propValue = (propValue === null) ? '' : propValue;
                                                vecNameVal.push({
                                                    name: propName,
                                                    values: [propValue]
                                                });
                                            }
                                        }
                                    }

                                    if (vecNameVal.length > 0) {
                                        if (!isTemplateOperation) {
                                            var pamTempobj = revisionToRevisionSetInfo[b].toRevision.modelObj;
                                            var info1 = getUpdateInfoFromPAMTemplateToPAM(pamTempobj, pamRelations[c], toFrom, vecNameVal);
                                            info = info.concat(info1);

                                            var infoValue1 = [];

                                            infoValue1 = ({
                                                type: toFrom.type,
                                                uid: toFrom.uid,
                                            });

                                            var v_obj = {};
                                            v_obj.bObject = toFrom;
                                            v_obj.nameVals = vecNameVal;
                                            v_obj.bObject = infoValue1;
                                            v_obj.nameVals = vecNameVal
                                            info.push(v_obj);
                                        }
                                        else {
                                            var obj = {};
                                            obj.object = toFrom;
                                            obj.vecNameVal = vecNameVal;

                                            info.push(obj);


                                        }
                                    }
                                    break;
                                }

                            }
                        }

                    }
                }
            }

        }
        if (info.length > 0 && isTemplateOperation) {
            var setPropertiesInputData = {
                info: info,
                options: []
            };
            var setPropertiesResponse = await _soaSvc.post('Core-2010-09-DataManagement', 'setProperties', setPropertiesInputData);
            if (setPropertiesResponse) {
                _appCtxSvc.ctx.__isUpdateHappened = true;
            }

            // callback();
            // });
        }
        else if (info.length > 0 && !isTemplateOperation) {
            var setPropertiesInputData = {
                info: info,
                tcItemRevision: _appCtxSvc.ctx._mctRevision
            };
            let pamNames = getPamUpdateMsg();
            _messagingSvc.showInfo("MCT update initiated from Template(s) to " + pamNames + ". You will get a notification after it complete.", true);
            var setPropertiesResponse = await _soaSvc.post('AWC-2019-06-MctService', 'setPropertiesToObjects', setPropertiesInputData);
            if (setPropertiesResponse) {
                _appCtxSvc.ctx.__isUpdateHappened = true;
            }
        } else {
            _appCtxSvc.ctx.__isUpdateHappened = false;
            //callback();
        }
    }
}

var getUpdateInfoFromPAMTemplateToPAM = function (pamTempobj, pamRelation, formObj, propertiesChanged) {
    var info = [];
    var objName = formObj.props.object_name.dbValues[0];
    var tempId = pamTempobj.props.u4_template_id.dbValues[0];
    for (var t in MctDataStructure.U4_MOP_List_Relation) {
        if (MctDataStructure.U4_MOP_List_Relation[t].pamObject.props.u4_template_id.dbValues[0] === tempId) {
            var relatedObjs = MctDataStructure.U4_MOP_List_Relation[t][pamRelation];
            for (var u in relatedObjs) {
                var objName1 = relatedObjs[u].pamFormObject.props.object_name.dbValues[0];
                if (objName === objName1) {
                    var vecNameVal = [];
                    for (var v in propertiesChanged) {
                        var propName = propertiesChanged[v].name;
                        if (formObj.props[propName].dbValues[0] !== relatedObjs[u].pamFormObject.props[propName].dbValues[0]) {
                            var propValue = formObj.props[propName].dbValues[0];
                            propValue = (propValue === null) ? '' : propValue;
                            vecNameVal.push({
                                name: propName,
                                values: [propValue]
                            });
                        }
                    }
                    if (vecNameVal.length > 0) {

                        var objVar = {};
                        var infoValue3 = [];
                        infoValue3 = ({
                            type: relatedObjs[u].pamFormObject.type,
                            uid: relatedObjs[u].pamFormObject.uid,
                        });

                        objVar.bObject = infoValue3;
                        objVar.nameVals = vecNameVal;
                        info.push(objVar);
                    }
                    //break;
                }
            }
        }
    }
    return info;
}

export let getRelationsUnderPAM = async function () {
    var options = {
        PropertyExclusions: ["RendererReferences", "NamingRules"],
        TypeExclusions: ["ToolInfo", "DirectChildTypesInfo", "RevisionNamingRules"]
    };
    if (BulkUpdate) {
        var responseName = [];
        var responseType = [];
        var loadObjectsInputData = {
            uids: _appCtxSvc.ctx.selected.props.U4_MOP_Templates.dbValues
        }

        var loadObjectsResponse1 = await _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', loadObjectsInputData);
        if (loadObjectsResponse1) {
            for (var x in loadObjectsResponse1.plain) {
                responseName.push(loadObjectsResponse1.modelObjects[loadObjectsResponse1.plain[x]]);
                if (_.startsWith(responseName[x].type, 'U4_G')) {
                }
                else {
                    responseType.push(responseName[x].type);
                }
            }
            //console.log("Entering in getRelationsUnderPAM", responseType);
        }

        var inputData1 = {
            'typeNames': ['U4_PAMRevision'],
            options: options
        };

        _appCtxSvc.ctx.__PamRevisionRelations = [];
        var relation = [];
        var response1 = await _soaSvc.post('Core-2015-10-Session', 'getTypeDescriptions2', inputData1);
        if (response1) {
            var props = [];
            var propDescArray = response1.types[0].propertyDescriptors;
            for (var t in propDescArray) {
                if (_.startsWith(propDescArray[t].name, 'U4_')) //custom relations always starts with U4_
                    props.push(propDescArray[t].name);
            }
            var inputData2 = {
                'typeNames': props,
                options: options
            };
            var response2 = await _soaSvc.post('Core-2015-10-Session', 'getTypeDescriptions2', inputData2);
            if (response2) {
                for (var t in response2.types) {
                    if (_.includes(response2.types[t].typeHierarchy, 'U4_CommonRelation') || _.includes(response2.types[t].typeHierarchy, 'U4_PamRelation'))
                        _appCtxSvc.ctx.__PamRevisionRelations.push(response2.types[t].name);
                }
            }
        }
        var relation = [];
        for (var type in responseType) {

            var inputData1 = {
                'typeNames': [responseType[type]],
                options: options
            };
            //eslint-disable-next-line no-await-in-loop
            var response1 = await _soaSvc.post('Core-2015-10-Session', 'getTypeDescriptions2', inputData1);
            if (response1) {
                var props = [];
                var propDescArray = response1.types[0].propertyDescriptors;
                for (var t in propDescArray) {
                    if (_.startsWith(propDescArray[t].name, 'U4_')) //custom relations always starts with U4_
                        props.push(propDescArray[t].name);
                }
                var inputData2 = {
                    'typeNames': props,
                    options: options
                };
                //eslint-disable-next-line no-await-in-loop
                var response2 = await _soaSvc.post('Core-2015-10-Session', 'getTypeDescriptions2', inputData2);
                if (response2) {
                    for (var t in response2.types) {
                        if (_.includes(response2.types[t].typeHierarchy, 'U4_CommonRelation') || _.includes(response2.types[t].typeHierarchy, 'U4_PamRelation'))
                            _appCtxSvc.ctx.__PamRevisionRelations.push(response2.types[t].name);
                    }
                }
            }
        }
        return _appCtxSvc.ctx.__PamRevisionRelations;
    }
    else {
        if (_appCtxSvc.ctx.mselected[0].modelType.typeHierarchyArray.includes('U4_PAMRevision')) {
            var inputData1 = {
                'typeNames': ['U4_PAMRevision'],
                options: options
            };
            _appCtxSvc.ctx.__PamRevisionRelations = [];
            var relation = [];
            var response1 = await _soaSvc.post('Core-2015-10-Session', 'getTypeDescriptions2', inputData1);
            if (response1) {
                var props = [];
                var propDescArray = response1.types[0].propertyDescriptors;
                for (var t in propDescArray) {
                    if (_.startsWith(propDescArray[t].name, 'U4_')) //custom relations always starts with U4_
                        props.push(propDescArray[t].name);
                }
                var inputData2 = {
                    'typeNames': props,
                    options: options
                };
                var response2 = await _soaSvc.post('Core-2015-10-Session', 'getTypeDescriptions2', inputData2);
                if (response2) {
                    for (var t in response2.types) {
                        if (_.includes(response2.types[t].typeHierarchy, 'U4_CommonRelation') || _.includes(response2.types[t].typeHierarchy, 'U4_PamRelation'))
                            _appCtxSvc.ctx.__PamRevisionRelations.push(response2.types[t].name);
                    }
                    // _appCtxSvc.ctx.__PamRevisionRelations = relation;
                    return _appCtxSvc.ctx.__PamRevisionRelations;
                }
            }
        }
        else if (_appCtxSvc.ctx.mselected[0].modelType.typeHierarchyArray.includes('U4_ComponentRevision')) {
            var inputData1 = {
                'typeNames': [_appCtxSvc.ctx.selected.type],
                options: options
            };
            _appCtxSvc.ctx.__PackRevisionRelations = [];
            var relation = [];
            var response1 = await _soaSvc.post('Core-2015-10-Session', 'getTypeDescriptions2', inputData1);
            if (response1) {
                var props = [];
                var propDescArray = response1.types[0].propertyDescriptors;
                for (var t in propDescArray) {
                    if (_.startsWith(propDescArray[t].name, 'U4_')) //custom relations always starts with U4_
                        props.push(propDescArray[t].name);
                }
                var inputData2 = {
                    'typeNames': props,
                    options: options
                };
                var response2 = await _soaSvc.post('Core-2015-10-Session', 'getTypeDescriptions2', inputData2);
                if (response2) {
                    for (var t in response2.types) {
                        if (_.includes(response2.types[t].typeHierarchy, 'U4_CommonRelation') || _.includes(response2.types[t].typeHierarchy, 'U4_PamRelation'))
                            _appCtxSvc.ctx.__PackRevisionRelations.push(response2.types[t].name);
                    }
                    return _appCtxSvc.ctx.__PackRevisionRelations;
                }
            }
        }
    }
}


var getPAMsFromMct = async function (MctRev, isTemplateOperation, isSinglePamTemplateUpdate, pamTemplateToUpdate) {
    //console.log("Entering in getPAMsFromMct");
    var inputData = {
        objects: [MctRev],
        attributes: ['U4_MOP_Templates']
    };

    if (!isTemplateOperation) {
        inputData.attributes.push('U4_MOP_List_Relation');
    }
    let policyId = _policySvc.register({
        "types": [{
            "name": "U4_PAMRevision",
            "properties": [{
                "name": "u4_template_id"
            }]
        }, {
            "name": "U4_ComponentRevision",
            "properties": [{
                "name": "u4_template_id"
            }]
        }
        ]
    });
    var getPropertiesResponse = await _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData);
    if (getPropertiesResponse) {
        if (policyId)
            _policySvc.unregister(policyId);

        var pamUids = (!isTemplateOperation) ? getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]].props.U4_MOP_List_Relation.dbValues : [];
        var pamTemplateUids = getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]].props.U4_MOP_Templates.dbValues;
        MctDataStructure.MctRevision = getPropertiesResponse.modelObjects[getPropertiesResponse.plain[0]];
        MctDataStructure.U4_MOP_List_Relation = [];
        MctDataStructure.U4_MOP_Templates = [];
        //set pam templates into datastructure
        if (isSinglePamTemplateUpdate) {
            if (pamTemplateUids.indexOf(pamTemplateToUpdate.uid) !== -1) {
                MctDataStructure.U4_MOP_Templates.push({
                    pamTemplateObject: getPropertiesResponse.modelObjects[pamTemplateToUpdate.uid]
                });
            }
        } else {
            for (var i in pamTemplateUids) {
                var templateObj = {
                    pamTemplateObject: getPropertiesResponse.modelObjects[pamTemplateUids[i]]
                };
                MctDataStructure.U4_MOP_Templates.push(templateObj);
            }
        }

        //set pam's into datastructure
        for (var i in pamUids) {
            var pamObj = {
                pamObject: getPropertiesResponse.modelObjects[pamUids[i]]
            };

            //only take pam's which has pam template attached to mct
            let isPamtemplatePresent = false;
            for (let j in MctDataStructure.U4_MOP_Templates) {
                if (MctDataStructure.U4_MOP_Templates[j].pamTemplateObject.props.u4_template_id.dbValues[0] === pamObj.pamObject.props.u4_template_id.dbValues[0]) {
                    isPamtemplatePresent = true;
                    break;
                }
            }

            if (isPamtemplatePresent) {
                var is_released_pam = ((pamObj.pamObject.props.release_status_list.dbValues.length > 0) && (pamObj.pamObject.props.release_status_list.dbValues[0]));
                //var is_pam_in_process_stage_list = ((pamObj.pamObject.props.process_stage_list.dbValues.length > 0) && (pamObj.pamObject.props.process_stage_list.dbValues[0]));
                //add only non released pams
                if (!is_released_pam) {
                    if (pamObj.pamObject.props.is_modifiable.dbValues[0] === '1') {
                        MctDataStructure.U4_MOP_List_Relation.push(pamObj);
                    } else {
                        MctDataStructure.hasNotPrivilegedPam = true; // pams which has no access for this current user is present in this mct
                    }

                } else {
                    MctDataStructure.hasReleasePam = true;
                }
            }
        }
        return MctDataStructure;
    }
}

var loadFormsOfPAM = async function () {
    //console.log("Entering in loadFormsOfPAM");
    var pams = [];
    for (var i in MctDataStructure.U4_MOP_List_Relation) {
        pams.push({
            uid: MctDataStructure.U4_MOP_List_Relation[i].pamObject.uid,
            type: MctDataStructure.U4_MOP_List_Relation[i].pamObject.type
        });
    }

    for (var i in MctDataStructure.U4_MOP_Templates) {
        pams.push({
            uid: MctDataStructure.U4_MOP_Templates[i].pamTemplateObject.uid,
            type: MctDataStructure.U4_MOP_Templates[i].pamTemplateObject.type
        });
    }

    var propsToGet = MctDataStructure.pamRelations.concat(['u4_template_id']);
    var inputData = {
        objects: pams,
        attributes: propsToGet
    };

    var getPropertiesResponse = await _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData);
    if (getPropertiesResponse) {
        for (var i in MctDataStructure.U4_MOP_List_Relation) {
            MctDataStructure.U4_MOP_List_Relation[i].pamObject = getPropertiesResponse.modelObjects[MctDataStructure.U4_MOP_List_Relation[i].pamObject.uid];
        }

        for (var i in MctDataStructure.U4_MOP_Templates) {
            MctDataStructure.U4_MOP_Templates[i].pamTemplateObject = getPropertiesResponse.modelObjects[MctDataStructure.U4_MOP_Templates[i].pamTemplateObject.uid];
        }

        var forms = [];
        for (var t in getPropertiesResponse.modelObjects) {
            if (getPropertiesResponse.modelObjects[t].modelType.typeHierarchyArray.indexOf('Form') !== -1) {
                forms.push({
                    obj: getPropertiesResponse.modelObjects[t],
                    propsToGet: exports.getU4Props(getPropertiesResponse.modelObjects[t])
                });
            }
        }

        var propsLoaded = await loadCustomPropertiesOfForm(forms);
        if (propsLoaded) {
            setFormsToDataStructure('U4_MOP_List_Relation', 'pamObject', 'pamFormObject', propsLoaded);
            setFormsToDataStructure('U4_MOP_Templates', 'pamTemplateObject', 'templateFormObject', propsLoaded);
            return MctDataStructure;
        }
    }
}

var setFormsToDataStructure = function (pamRelation, pamVariableName, formVariableName, propsLoaded) {
    for (var i in MctDataStructure[pamRelation]) {
        for (var n in MctDataStructure.pamRelations) {
            var relation = MctDataStructure.pamRelations[n];
            if (MctDataStructure[pamRelation][i][pamVariableName].props[relation]) {
                var formUids = MctDataStructure[pamRelation][i][pamVariableName].props[relation].dbValues;
                if (formUids && formUids.length > 0) {
                    MctDataStructure[pamRelation][i][relation] = [];
                    for (var y in formUids) {
                        var form1 = _.zipObjectDeep([formVariableName], [propsLoaded[formUids[y]]]);
                        MctDataStructure[pamRelation][i][relation].push(form1);
                    }
                }
            }
        }
    }
}

var loadCustomPropertiesOfForm = async function (forms) {
    var properties = ['object_name'];
    var formArray = [];
    for (var h in forms) {
        formArray.push(forms[h].obj);
        for (var g in forms[h].propsToGet) {
            var property = forms[h].propsToGet[g];
            if (!(properties.indexOf(property) !== -1)) {
                properties.push(property);
            }
        }
    }

    var inputData = {
        objects: formArray,
        attributes: properties
    };

    var getPropertiesResponse = await _soaSvc.post('Core-2006-03-DataManagement', 'getProperties', inputData);
    if (getPropertiesResponse) {
        return getPropertiesResponse.modelObjects;
    }
}

export let getU4Props = function (formObj) {
    var u4props = [];
    for (var prop in formObj.modelType.propertyDescriptorsMap) {
        if (_.startsWith(prop, 'u4_') || _.startsWith(prop, 'U4_')) {
            var basedOn = formObj.modelType.propertyDescriptorsMap[prop].basedOn;
            if (basedOn && (basedOn.sourceType === (formObj.type + 'S'))) { // dont take runtime properties
                u4props.push(prop);
            }
        }
    }
    return u4props;
}

export let MctRefresh = async function () {
    if (_appCtxSvc.ctx.mselected[0].modelType.typeHierarchyArray.includes('U4_ComponentRevision')) {
        var selectedPamTemplates = await getSelectedPACK('packTemplate');
    }
    else if (_appCtxSvc.ctx.mselected[0].modelType.typeHierarchyArray.includes('U4_PAMRevision')) {
        var selectedPamTemplates = getSelectedPAM('pamTemplate');
    }

    var MctRev = getSelectedMct();
    if (MctRev && selectedPamTemplates) {
        if (selectedPamTemplates.length === 0) {
            _messagingSvc.showError('Please select Template(s) to clear.');
        } else {
            var pamTemplatesUids = [];
            for (var t in selectedPamTemplates) {
                pamTemplatesUids.push(selectedPamTemplates[t].uid);
            }

            MctOperation('refreshTemplate', pamTemplatesUids, null, MctRev, function () {
                if (_appCtxSvc.ctx.__isUpdateHappened)
                    _messagingSvc.showInfo('Template(s) has been cleared.');
                else
                    _messagingSvc.showInfo('No changes were found to clear.');
            });
        }
    }
}

export let MctRemove = async function () {
    if (_appCtxSvc.ctx.mselected[0].modelType.typeHierarchyArray.includes('U4_ComponentRevision')) {
        var selectedPam = await getSelectedPACK('packRemove');
    }
    else if (_appCtxSvc.ctx.mselected[0].modelType.typeHierarchyArray.includes('U4_PAMRevision')) {
        var selectedPam = getSelectedPAM('pam');
    }

    var MctRev = getSelectedMct();
    if (MctRev && selectedPam) {
        if (selectedPam.length === 0) {
            _messagingSvc.showError('Please select Component(s) to proceed.');
        } else {
            var removeChildrenInputData = {
                inputData: [{
                    parentObj: MctRev,
                    childrenObj: selectedPam,
                    propertyName: 'U4_MOP_List_Relation'
                }]
            };
            _soaSvc.post('Core-2014-10-DataManagement', 'removeChildren', removeChildrenInputData).then(function (removeChildrenResponse) {
                _commUtil.refreshObjectsInUI([MctRev]);
                _mctSublocSvc.updateCountAfterPAMremoval(removeChildrenResponse, MctRev);
                var pamStr = (selectedPam.length === 1) ? 'Component' : 'Components\'s';
                _messagingSvc.showInfo('Selected ' + pamStr + ' successfully removed from MCT.');
            });
        }
    }

}

export let MctSingleupdate = async function () {
    if (_appCtxSvc.ctx.mselected[0].modelType.typeHierarchyArray.includes('U4_ComponentRevision')) {
        var selectedPamTemplates = await getSelectedPACK('packTemplate');
    }
    else if (_appCtxSvc.ctx.mselected[0].modelType.typeHierarchyArray.includes('U4_PAMRevision')) {
        var selectedPamTemplates = getSelectedPAM('pamTemplate');
    }
    if (selectedPamTemplates) {
        if (selectedPamTemplates.length > 1) {
            _messagingSvc.showError('Please select only one Template to proceed.');
        } else if (selectedPamTemplates.length === 0) {
            _messagingSvc.showError('Please select a Template to proceed.');
        } else {
            var MctRev = getSelectedMct();
            var selectedPamTemplate = selectedPamTemplates[0];
            if (MctRev && selectedPamTemplate) {
                MctOperation('singlePamTemplateUpdate', null, selectedPamTemplate, MctRev, function () {
                    let pamNames = getPamUpdateMsg();
                    if (_appCtxSvc.ctx.__isUpdateHappened)
                        _messagingSvc.showInfo("Properties successfully updated from Template to Component\'s " + pamNames + ".", true);
                    else
                        _messagingSvc.showInfo('No changes were found to update.');
                });
            }
        }
    }


}

export let MctUpdate = async function () {
    var mctDone = await MctOperation('update', null, null, null);
    if (mctDone) {
        let pamNames = getPamUpdateMsg();
        if (_appCtxSvc.ctx.__isUpdateHappened)
            _messagingSvc.showInfo("Properties successfully updated from Template(s) to " + pamNames + ".", true);
        else {
            _messagingSvc.showInfo('No changes were found to update.');
        }
    }
}

let getPamUpdateMsg = function () {
    let pamNames = '';
    let first = true;
    for (let i in MctDataStructure.U4_MOP_List_Relation) {
        let objName = MctDataStructure.U4_MOP_List_Relation[i].pamObject.props.object_name.dbValues[0];
        pamNames = pamNames + ((first) ? '' : ', ') + objName;
        first = false;
    }
    if (pamNames.length > 0) {
        pamNames = "<strong>" + pamNames + "</strong>";
    }
    return pamNames;
}

/*
clears breadcrumb title for mct sublocation
*/
export let clearBreadCrumbText = function () {
    //send location name
    _commUtil.clearBreadCrumbText('MctLocation');
}

/*
clears breadcrumb title for mct Pnp sublocation
*/
export let clearBreadCrumbTextPnP = function () {
    //send location name
    _commUtil.clearBreadCrumbText('MctPnPLocation');
}

export let sortByIDAscendMctCommandAction = function () {
    eventBus.publish("sortByIDAscendMctCommandAction");
}

export let sortByIDDescendMctCommandAction = function () {
    eventBus.publish("sortByIDDescendMctCommandAction");
}

export let sortByNameAscendMctCommandAction = function () {
    eventBus.publish("sortByNameAscendMctCommandAction");
}

export let sortByNameDescendMctCommandAction = function () {
    eventBus.publish("sortByNameDescendMctCommandAction");
}

export let getPackFromPam = async function (selectedPamComp) {
    var packRevObjUID = [];
    var InputData = {
        secondaryObjects: selectedPamComp,
        pref:
        {
            expItemRev: false,
            returnRelations: false,
            info:
                [{
                    relationTypeName: "U4_PAMSpecification",
                    otherSideObjectTypes: []
                }]
        }
    };
    var response = await _soaSvc.post('Core-2007-09-DataManagement', 'expandGRMRelationsForSecondary', InputData);
    if (response) {
        for (var x in response.output) {
            try {
                packRevObjUID.push(response.output[x].relationshipData[0].relationshipObjects[0].otherSideObject.uid);
            }
            catch (err) {
                //_messagingSvc.showInfo('For selected component, Pack does not exist');
            }
        }
        _appCtxSvc.registerCtx("packRevObjUID", packRevObjUID);
        return packRevObjUID;
    }
}

export default exports = {
    getCurrentUserNewstuffFolder,
    addChildrenUnderParent,
    addToMct,
    createAndAddToMct,
    getSelectedPAM,
    getSelectedPACK,
    getRelationsUnderPAM,
    getU4Props,
    MctRefresh,
    getPackFromPam,
    MctRemove,
    MctSingleupdate,
    MctUpdate,
    clearBreadCrumbText,
    clearBreadCrumbTextPnP,
    sortByIDAscendMctCommandAction,
    sortByIDDescendMctCommandAction,
    sortByNameAscendMctCommandAction,
    sortByNameDescendMctCommandAction
};
app.factory('MctUtil', () => exports);
