// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/**
 * This code is use to clear the selected rows data and swap the data between two rows
 * 
 * @module js/ulClearFormCommandService
 */

import app from 'app';
import _ from 'lodash';
import _editHandlerService from 'js/editHandlerService';
import _notySvc from 'js/NotyModule';
import _appCtxSvc from "js/appCtxService";
import _soaSvc from 'soa/kernel/soaService';
import _messagingSvc from 'js/messagingService';
import awPromiseService from 'js/awPromiseService';
import eventBus from 'js/eventBus';

var exports = {};

export let ulClearSelectedForm = function (data, ctx, propertyNamesToClear) {
    if (_editHandlerService.getActiveEditHandler() && _editHandlerService.getActiveEditHandler().getDataSource() && _editHandlerService.getActiveEditHandler().getDataSource().getDeclViewModel()) {
        var activeEditHandler = _editHandlerService.getActiveEditHandler();
        var dataPro = activeEditHandler.getDataSource().getDeclViewModel().getAtomicData().dpRef.current.dataProviders;
        var sendForSave = false;

        if (dataPro.length && ctx.mselected.length) {
            for (var y = 0; y < dataPro.length; y++) {
                var tableOotb = dataPro[y]();
                for (var y1 = 0; y1 < tableOotb.length; y1++) {
                    if (tableOotb[y1].selected == true) {
                        // console.log("Selected is true", tableOotb[y1]);
                        for (var x = 0; x < ctx.mselected.length; x++) {
                            if (tableOotb[y1].props.object_name.dbValue == ctx.mselected[x].props.object_name.dbValue) {
                                var selectedForms = tableOotb[y1];
                                _.forEach(selectedForms, function (selectedForm) {
                                    _.forEach(propertyNamesToClear, function (propertyName) {
                                        var u4prop = selectedForm[propertyName];
                                        if (u4prop && u4prop.dbValue != "" && u4prop.dbValue != null) {
                                            //  console.log("Entered in Matching Props", u4prop.uiValue);
                                            u4prop.dbValue = "";
                                            u4prop.dbValues[0] = "";
                                            u4prop.displayValues[0] = "";
                                            u4prop.uiValue = "";
                                            u4prop.newValue = "";
                                            u4prop.valueUpdated = true;
                                            sendForSave = true;
                                        }
                                    });
                                });
                            }
                        }
                    }
                }
            }
            SaveULedit(sendForSave);
        }
    }
};

export let ulSwapFormData = function (data, ctx, propertyNamesToSwap) {
    if (propertyNamesToSwap.length > 0) {
        propertyNamesToSwap = propertyNamesToSwap.split(',');
    } else {
        propertyNamesToSwap = [];
    }

    if (ctx.mselected.length == 2) {

        var activeEditHandler = _editHandlerService.getActiveEditHandler();
        var dataPro = activeEditHandler.getDataSource().getDeclViewModel().getAtomicData().dpRef.current.dataProviders;
        var selectedRowPro1 = [];
        if (dataPro.length) {
            for (var y1 = 0; y1 < dataPro.length; y1++) {
                var polyTable = dataPro[y1]();
                for (var x1 = 0; x1 < polyTable.length; x1++) {
                    // var ootbProvider = dataPro[0]()[y1];
                    if (polyTable[x1].selected == true) {
                        console.log("Selected row is", polyTable[x1].props.object_name.dbValue);
                        selectedRowPro1.push(polyTable[x1]);
                    }
                }

            }
        }
        var sendForSave = exports.ulSwapTwoFormData(selectedRowPro1[0], selectedRowPro1[1], propertyNamesToSwap);
        SaveULedit(sendForSave);
    } else {
        var clearRows = [];
        var filledRows = [];
        var selectedObjects = ctx.mselected;
        var activeEditHandler = _editHandlerService.getActiveEditHandler();
        var dataPro = activeEditHandler.getDataSource().getDeclViewModel().getAtomicData().dpRef.current.dataProviders;
        var selRowPro11 = [];
        if (dataPro.length) {
            for (var z1 = 0; z1 < dataPro.length; z1++) {
                var tableOotb = dataPro[z1]();
                for (var x1 = 0; x1 < tableOotb.length; x1++) {
                    if (tableOotb[x1].selected == true) {
                        console.log("Selected row is", tableOotb[x1].props.object_name.dbValue);
                        selRowPro11.push(tableOotb[x1]);
                    }
                }
            }
        }

        _.forEach(selRowPro11, function (selectedObject) {
            var propertyFillCount = 0;
            _.forEach(propertyNamesToSwap, function (propertyName) {
                var u4propRow1 = selectedObject.props[propertyName];
                if (u4propRow1 && u4propRow1.dbValues[0] != '' && u4propRow1.dbValues[0] != null) {
                    propertyFillCount++;
                }
            });
            if (propertyFillCount == 0) {
                clearRows.push(selectedObject);
            } else {
                filledRows.push(selectedObject);
            }
        });
        if (clearRows.length != filledRows.length) {
            _notySvc.showInfo(data.i18n.confirmationSwapPopupTitle);
        } else {
            _.forEach(filledRows, function (filledRow, index) {
                var clearedRow = clearRows[index];
                exports.ulSwapTwoFormData(filledRow, clearedRow, propertyNamesToSwap);
            });
            SaveULedit(true);
        }
    }
}

export let ulSwapTwoFormData = function (selectedRow1, selectedRow2, propertyNamesToSwap) {
    var sendForSave = false;
    _.forEach(propertyNamesToSwap, function (propertyName) {
        var u4propRow1 = selectedRow1.props[propertyName];
        var u4propRow2 = selectedRow2.props[propertyName];
        if (u4propRow1 && u4propRow2 && (u4propRow1.uiValue != u4propRow2.uiValue)) {
            u4propRow1.newValue = u4propRow2.uiValue;
            u4propRow2.newValue = u4propRow1.uiValue;
            u4propRow1.dbValue = u4propRow1.newValue;
            u4propRow2.dbValue = u4propRow2.newValue;
            u4propRow1.valueUpdated = true;
            u4propRow2.valueUpdated = true;
            sendForSave = true;
        }
    });
    return sendForSave;
};

function SaveULedit(sendForSave) {
    var activeEditHandler = _editHandlerService.getActiveEditHandler();
    activeEditHandler.startEdit().then(async function () {
        if (sendForSave == true) {
            activeEditHandler.saveEdits();
            eventBus.publish("setBGColor");
        } else {
            activeEditHandler.cancelEdits();
        }
    })
}

export let confirmationPopup = function (data, ctx, propertyNamesToClear) {
    var editValues = _editHandlerService.getActiveEditHandler().getDataSource().getDeclViewModel();
    console.log("EditValues:", editValues);
    exports.IsOkayToClear().then(function (OkToClear) {
        if (OkToClear === true) {
            if (propertyNamesToClear.length > 0) {
                propertyNamesToClear = propertyNamesToClear.split(',');
            } else {
                propertyNamesToClear = [];
            }
            var buttonArray = [];
            buttonArray.push(createButton("Ok", function ($noty) {
                $noty.close();
                exports.ulClearSelectedForm(data, ctx, propertyNamesToClear);
            }));
            buttonArray.push(createButton("Cancel", function ($noty) {
                $noty.close();
                var activeEditHandler = _editHandlerService.getActiveEditHandler();
                activeEditHandler.cancelEdits();
            }));
            _notySvc.showWarning(data.i18n.confirmationPopupTitle, buttonArray);
        }
        else {
            _messagingSvc.showError("Data cannot be cleared as the PML has been selected");
        }
    });
};

export let IsOkayToClear = function () {
    console.log("Entering IsOkayToClear");
    var deferred = awPromiseService.instance.defer();
    var OkToClear = true;
    var selectedObject = _appCtxSvc.ctx.selected;
    if (selectedObject.type === "U4_LayerStructureForm") {
        var operationName = "expandGRMRelationsForSecondary";
        var InputData = {
            secondaryObjects: [selectedObject],
            pref: {
                expItemRev: false,
                returnRelations: false,
                info: [{
                    relationTypeName: "U4_MaterialsRelation",
                    otherSideObjectTypes: []
                }]
            }
        };
        _soaSvc.post('Core-2007-09-DataManagement', operationName, InputData).then(function (SecObjectResponse) {
            InputData.primaryObjects = [SecObjectResponse.ServiceData.modelObjects[SecObjectResponse.ServiceData.plain[0]]];
            for (var iSecObjCounter = 0; iSecObjCounter < SecObjectResponse.ServiceData.plain.length; iSecObjCounter++) {
                var loadedObjectTypeArray = SecObjectResponse.ServiceData.modelObjects[SecObjectResponse.ServiceData.plain[iSecObjCounter]].modelType.typeHierarchyArray;
                if (loadedObjectTypeArray.includes("U4_PAMRevision") == true) {
                    delete InputData.secondaryObjects;
                    operationName = "expandGRMRelationsForPrimary";
                    var PAMType = SecObjectResponse.ServiceData.modelObjects[SecObjectResponse.ServiceData.plain[iSecObjCounter]].type;
                    if (PAMType === "U4_GLabelRevision" || PAMType === "U4_GCartonsRevision") {
                        InputData.primaryObjects = [SecObjectResponse.ServiceData.modelObjects[SecObjectResponse.ServiceData.plain[iSecObjCounter]]];
                        _soaSvc.post('Core-2007-09-DataManagement', operationName, InputData).then(function (primaryObjResponse) {
                            if (primaryObjResponse.ServiceData.plain.length > 1) {
                                _.forEach(primaryObjResponse.ServiceData.modelObjects, function (modelObject) {
                                    if (primaryObjResponse.ServiceData.plain.includes(modelObject.uid) == true) {
                                        if (PAMType === "U4_GLabelRevision" && modelObject.type === "U4_MaterialDetails2Form") {
                                            if (modelObject.props.u4_type.dbValues[0] !== null) {
                                                //Send Warning that data cannot be cleared
                                                OkToClear = false;
                                            }
                                        }
                                        else if (PAMType === "U4_GCartonsRevision" && modelObject.type === "U4_MaterialPropertiesForm") {
                                            var attr = modelObject.props.u4_attribute;
                                            var prop = modelObject.props.u4_property;
                                            if (attr === "Appr. Board 1" && prop === "Board Grade" && modelObject.props.u4_type.dbValues[0] !== null) {
                                                //Send warning that data cannot be cleared
                                                OkToClear = false;
                                            }
                                        }
                                    }
                                });
                            }
                            deferred.resolve(OkToClear);
                        });
                    }
                    break;
                }
            }
            //deferred.resolve(OkToClear);
        });
    }
    //Change...ENDS
    deferred.resolve(OkToClear); // Code added to clear row - Pankaj K...
    console.log("Leaving IsOkayToClear");
    return deferred.promise;
};

function createButton(label, callback) {
    return {
        addClass: 'btn btn-notify',
        text: label,
        onClick: callback
    };
}


export default exports = {
    ulClearSelectedForm,
    ulSwapFormData,
    ulSwapTwoFormData,
    confirmationPopup,
    IsOkayToClear
};