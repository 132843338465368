// Copyright 2018 Siemens Product Lifecycle Management Software Inc.

/* global
 alert
 */


/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/openInPLMService
 */
import app from 'app';
import browserUtils from 'js/browserUtils';
import _msgSvc from 'js/messagingService';
import _appCtxSvc from 'js/appCtxService';
import _cdm from 'soa/kernel/clientDataModel';

'use strict';

var exports = {};


/**
 * Dummy alert.
 * @param {String} text - text to display
 */
export let openInPLM = function (ctx, awcToPLMStrings, fromtile) {
    var awcURL = "";
    var plmURL = "";
    if (awcToPLMStrings != null && awcToPLMStrings.length > 0) {
        for (var i = 0; i < awcToPLMStrings.length; i++) {
            if (awcToPLMStrings[i].includes("====") && awcToPLMStrings[i].split("===").length == 3) {
                awcURL = awcToPLMStrings[i].split("====")[0];
                if (browserUtils.getBaseURL() == awcURL) {
                    if (fromtile == true) {
                        plmURL = awcToPLMStrings[i].split("====")[1];
                    } else {
                        plmURL = awcToPLMStrings[i].split("====")[1] + awcToPLMStrings[i].split("====")[2];
                    }
                    break;
                }
            }
        }
    }
    if (plmURL != "") {
        if (fromtile == true) {
            window.open(plmURL, "_blank");
        } else {
            if (ctx.selected.modelType.parentTypeName === "U4_PAMRevision") {
                var pam_id = ctx.selected.props.item_id.dbValues[0];
                if (pam_id && pam_id.length > 0 && plmURL.includes("{{0}}")) {
                    window.open(plmURL.replace("{{0}}", pam_id), "_blank");
                } else {
                    _msgSvc.showInfo("Corresponding PLM Link is not available to open")
                }
            }
            else if (ctx.selected.modelType.parentTypeName === "U4_ComponentRevision") {
                var pamUID = _appCtxSvc.ctx.selected.props.U4_PAMSpecification.dbValues[0];
                var selectedPam = _cdm.getObject(pamUID);
                //console.log("Selected Pam: ",selectedPam);
                var pam_id = selectedPam.props.item_id.dbValues[0];
                if (pam_id && pam_id.length > 0 && plmURL.includes("{{0}}")) {
                    window.open(plmURL.replace("{{0}}", pam_id), "_blank");
                } else {
                    _msgSvc.showInfo("Corresponding PLM Link is not available to open")
                }
            }

        }
    } else {
        _msgSvc.showInfo("Corresponding PLM Link is not available to open")
    }
};
export default exports = {
    openInPLM
};
app.factory('openInPLMService', () => exports);